import React, { useState } from "react";
import './settings.css';
import { MenuItem, Select, Tooltip } from "@mui/material";
import { adminSettingsUpdatingKeywords } from "../../../features/admin/adminActions";
import { useDispatch } from "react-redux";
import { BsInfoLg } from 'react-icons/bs';


const PopularKeywordManagement = (props) => {
    const {
        ccSelectStyle,
        pageNameValue,
        setPageNameValue,
        keywordValue,
        setKeywordValue,
        handleAddKeyword
    } = props
    const [removingPageName, setRemovingPageName] = useState("");
    const [removingKeywords, setRemovingKeywords] = useState([]);

    const dispatch = useDispatch()


    const handleRemoveKeyword = async () => {
        const keywords = removingKeywords.split(',').map(keyword => keyword.trim());
        const payload = {
            key: removingPageName,
            value: keywords
        };
        await dispatch(adminSettingsUpdatingKeywords(payload));
    };

    const pageName = [
        {
            label: 'Home',
            value: 'HOME_PAGE'
        },
        {
            label: 'Image',
            value: 'IMAGE_PAGE'
        },
        {
            label: 'Video',
            value: 'VIDEO_PAGE'
        },
        {
            label: 'Music',
            value: 'MUSIC_PAGE'
        },
        {
            label: 'VFX',
            value: 'VFX_PAGE'
        },
        {
            label: 'Vector',
            value: 'VECTOR_PAGE'
        },
        {
            label: 'Editorial',
            value: 'EDITORIAL_PAGE'
        },
        {
            label: 'Exclusive',
            value: 'EXCLUSIVE_PAGE'
        }
    ]


    return (
        <div className="asd-allSetting-ccms">
            <div className="asd-ccms-heading">
                Popular Category Keyword Management System
            </div>
            <div className="asd-ccms-settingsCmnSty">
                <div className="asd-acc-heading">
                    Add Popular Category Keyword
                </div>
                <div className="asd-acc-form">
                    <div className="asd-acc-fieldsContainer">
                        <div className="asd-acc-labelAndField">
                            <label className="asd-acc-labelAndField-label">Page Name</label>
                            <Select
                                placeholder='Select Page Name'
                                sx={ccSelectStyle}
                                value={pageNameValue}
                                onChange={(e) => setPageNameValue(e.target.value)}
                                renderValue={pageNameValue === "" ? () => 'Select Page Name' : ''}
                                displayEmpty
                            >
                                {pageName && pageName.map((page, index) => (
                                    <MenuItem
                                        key={index}
                                        value={page?.value}
                                    >
                                        {page?.label}
                                    </MenuItem>
                                ))
                                }
                            </Select>
                        </div>
                    </div>
                    <div className="asd-acc-fieldsContainer">
                        <div className="asd-acc-labelAndField">
                            <label className="asd-acc-labelAndField-label">
                                Keyword
                                <Tooltip
                                    title={
                                        <div className="asd-acc-tooltip-title">
                                            <span>1. Add one or multiple keywords.</span>
                                            <span>2. Enter keywords in the format you want them to appear on the website.</span>
                                            <span>3. To add multiple keywords, separate each keyword with a comma(,) and a space, except for the last keyword.</span>
                                        </div>
                                    }
                                    placement="top"
                                >
                                    <button className="asd-acc-tooltipBtn"><BsInfoLg /></button>
                                </Tooltip>
                            </label>
                            <textarea
                                className="asd-acc-labelAndField-textArea"
                                placeholder="Enter Keywords"
                                value={keywordValue}
                                onChange={(e) => setKeywordValue(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="asd-acc-btn">
                    <button className="asd-acc-btn-button" onClick={handleAddKeyword}>
                        Add Keyword
                    </button>
                </div>
            </div>
            <div className="asd-ccms-settingsCmnSty">
                <div className="asd-acc-heading">
                    Remove Popular Category Keyword
                </div>
                <div className="asd-acc-form">
                    <div className="asd-acc-fieldsContainer">
                        <div className="asd-acc-labelAndField">
                            <label className="asd-acc-labelAndField-label">Page Name</label>
                            <Select
                                placeholder='Select Page Name'
                                sx={ccSelectStyle}
                                value={removingPageName}
                                onChange={(e) => setRemovingPageName(e.target.value)}
                                renderValue={removingPageName === "" ? () => 'Select Page Name' : ''}
                                displayEmpty
                            >
                                {pageName && pageName.map((page, index) => (
                                    <MenuItem
                                        key={index}
                                        value={page?.value}
                                    >
                                        {page?.label}
                                    </MenuItem>
                                ))
                                }
                            </Select>
                        </div>
                    </div>
                    <div className="asd-acc-fieldsContainer">
                        <div className="asd-acc-labelAndField">
                            <label className="asd-acc-labelAndField-label">
                                Keywords To be Removed
                                <Tooltip
                                    title={
                                        <div className="asd-acc-tooltip-title">
                                            <span>1. Remove one or multiple keywords.</span>
                                            <span>2. Enter keywords in the format as appear on the website.</span>
                                            <span>3. To remove multiple keywords, separate each keyword with a comma(,) and a space, except to the last keyword typed.</span>
                                        </div>
                                    }
                                    placement="top"
                                >
                                    <button className="asd-acc-tooltipBtn"><BsInfoLg /></button>
                                </Tooltip>
                            </label>
                            <input
                                className="asd-acc-labelAndField-input"
                                placeholder="Enter Keywords"
                                value={removingKeywords}
                                onChange={(e) => setRemovingKeywords(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="asd-acc-btn">
                    <button className="asd-acc-btn-button" onClick={handleRemoveKeyword} >Remove Keyword</button>
                </div>
            </div>
        </div>
    )
}

export default PopularKeywordManagement;