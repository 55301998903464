import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const base_url = process.env.REACT_APP_BASE_URL
const setting_URL = `${base_url}/setting`

export const getSettings = createAsyncThunk(
    'setting/get',
    async () => {
            const { data } = await axios.get(setting_URL,{})
            return data?.data
    }
)