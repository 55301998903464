import React from "react";
import './cartConfirmation.css';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { FiDownload } from 'react-icons/fi';


const CartConfirmation = ({ cartData, getIcon }) => {
    return (
        <div className="cartConfirmation">
            <div className="cartConfirmation-message">
                <BsFillCheckCircleFill color="#0CA65C" fontSize={18} />&nbsp;Your order has been successfully confirmed
            </div>
            <div className="cartConfirmation-heading">
                Order Summary
            </div>
            <div className="cartCofirmation-contentAndDownload">
                <div className="cartConfirmation-content">
                    {cartData?.map((item, index) => (
                        <div className="viewCart-orderSummaryContainer-orderDetails" key={index}>
                            <div className="viewCart-orderDetails-name">
                                {getIcon(item?.type)}
                                <p>{item?.name}</p>
                            </div>
                            <div className="viewCart-orderDetails-planAndPrice">
                                <p className="fw-500">One time purchase</p>
                                <p className="fw-600">{`INR ${item?.price}`}</p>
                            </div>
                        </div>
                    ))}
                </div>
                    <button className="cartConfirmation-downloadAllBtn"><FiDownload fontSize={13} fontWeight={800}/>&nbsp;Download All</button>
            </div>
        </div>
    )
}

export default CartConfirmation;