import React, { useEffect, useState } from "react";
import { Navbar } from "../../common/Navbar";
import { BecomeASeller } from '../../common/BecomeASeller';
import { Header } from "../../pageContent/Header";
import { Footer } from "../../common/Footer";
import './home.css';
import SubHeading from "../../pageContent/SubHeading/SubHeading";
import { getCategories, getCategoryData } from "../../../api";
import { getCartItems } from "../../../features/search/searchActions";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfileDetails } from '../../../features/users/userActions';
import previewBannerVideo from '../../../assets/mastHead/mastheadBanner.mp4';
import fallBackImage from '../../../assets/images/fallbackImage.jpeg';
import companyLogo from '../../../assets/logo/WorldArchivesLight.png';
import { useNavigate } from "react-router-dom";
import PopularCategory from "../../common/PopularCategories/PopularCategory";
import { getAdminSetting } from "../../../features/admin/adminActions";

const Home = () => {
    const [imgArr, setImgArr] = useState([])
    const [categories, setCategories] = useState([])
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {popularCategoryKeywords} = useSelector(state => state.admin)

    useEffect(() => {
        getCategories('IMAGES').then(res => setCategories(res))
        localStorage.setItem('selectTerm', 'IMAGE')
        getCartDetails()
        getUserDetails()
    }, [dispatch])

    useEffect(() => {
        if (!categories || !categories.length) return
        const getAllCategoriesData = async () => {
            let catData = await getCategories('IMAGES').then(res => res)
            const finalData = await Promise.all(catData?.map(async (category) => {
                return await getCategoryData('IMAGE', category)
            })
            )
            if (categories.length && (categories.length === finalData.length)) setImgArr(finalData)
        }
        getAllCategoriesData()
    }, [categories])
    const userToken = localStorage.getItem('userToken')
    async function getCartDetails() {
        if (userToken) await dispatch(getCartItems())
        else return
    }
    async function getUserDetails() {
        if (userToken) await dispatch(getUserProfileDetails())
        else return
    }

    const onClickSellerContent = () => {
        navigate('/seller')
    }
    useEffect(()=>{
        dispatch(getAdminSetting('HOME_PAGE'))
    },[dispatch])

    return (
        <div className="home">
            <div className="home-container">
                <Navbar companyLogo={companyLogo} logoStyles='navbar-logoStyle-common' menuItemStyles="home-menu-items" cartColor="#FFFFFF" hamburgerColor="#ffffff" />
                <Header
                    headerHeadingContent='Captured Across the Majestic Earthly Terrains'
                    headerSubHeading="Images of the most intricate movements of the world & pieces of time."
                    headerTrending="mountains,dark,nature,flowers,space"
                    width='300px'
                    searchBarStyles='home-SearchBar'
                    initialState='IMAGE'
                    headerHeight='homeHeaderHeight'
                />
                <video className="home-banner-video" autoPlay loop muted width="100%">
                    <source src={previewBannerVideo} type="video/mp4" />
                    {/* Fallback image */}
                    <img src={fallBackImage} alt="Fallback Image" />
                </video>
            </div>
            <PopularCategory popularCategoryKeywords={popularCategoryKeywords} selectTerm="IMAGE"/>
            <div className="home-pageContent">
                {categories.length && categories.map((el, idx) => <SubHeading key={idx} heading={el} requiredArray={imgArr[idx]} pageName="IMAGE" />)}
            </div>
            <BecomeASeller onClickSellerContent={onClickSellerContent}/>
            <Footer />

        </div>
    )
}

export default Home;