import React, { useEffect } from 'react';
import './viewAllResultsPage.css';
import Navbar from '../../common/Navbar/Navbar';
import SearchBar from '../../reusable/SearchBar/index';
import Footer from '../../common/Footer/Footer';
import logoMain from '../../../assets/logo/WorldArchivesDark.png';
import { useSelector, useDispatch } from 'react-redux';
import ImageGallery from '../../reusable/ImageGallery/ImageGallery';
import { Loader } from '../../common/Loader'
import { getMediaSearchPagination } from '../../../features/search/searchActions';

const ViewAllResultsPage = () => {
    const dispatch = useDispatch()
    const { searchData, loading, nextKey, stampCategory } = useSelector((state) => state.search)
    let categoryName = localStorage.getItem('categoryName')
    let categoryPageName = localStorage.getItem('categoryPageName')

    useEffect(() => {
        dispatch(getMediaSearchPagination({ category: categoryName, pageName: categoryPageName }))
    }, [categoryName, categoryPageName])

    return (
        <div className='varp'>
            <Navbar menuItemStyles="navbar-menu-items" logoStyles="navbar-logoStyle" companyLogo={logoMain} cartColor="#666666" />
            <div className='varp-container'>
                <div className='varp-searchBar'>
                    <SearchBar searchBarStyles="varp-searchbar-styles" initialState={categoryPageName} />
                </div>
                {categoryName ? <div className='varp-container-heading'>
                    <p>Showing results for</p><span className='fw-700'>&nbsp;{categoryName?.length ? categoryName : categoryPageName}</span>
                </div> : null}
            </div>
            <div>
                {loading ? <Loader /> :
                    <>{searchData === null ?
                        <div className='txt-center' style={{ margin: "6.75rem" }}>No results found</div> :
                        <div style={{ margin: "3rem" }}><ImageGallery imageArray={searchData} pageName={categoryPageName} />
                        </div>
                    }</>}
            </div>
            <Footer />
        </div>
    )
}

export default ViewAllResultsPage;