import React, { useState } from "react";
import './exclusivePage.css';
import { Navbar } from "../../common/Navbar";
import { Header } from "../../pageContent/Header";
import { BecomeASeller } from "../../common/BecomeASeller";
import { Footer } from "../../common/Footer";
import companyLogo from '../../../assets/logo/WorldArchivesLight.png';
import previewBannerVideo from '../../../assets/mastHead/mastheadBanner.mp4';
import ipfallBackImage from '../../../assets/images/ImageBanner.png';
import backgroundImageOne from '../../../assets/images/excArchitecture.jpg';
import backgroundImageTwo from '../../../assets/images/excBehindTheScenes.png';
import backgroundImageThree from '../../../assets/images/excBollywood.png';
import backgroundImageFour from '../../../assets/images/excClassic.jpg';
import backgroundImageFive from '../../../assets/images/excEvents.png';
import backgroundImageSix from '../../../assets/images/excFashion.jpg';
import backgroundImageSeven from '../../../assets/images/excHealth.png';
import backgroundImageEight from '../../../assets/images/excSports.png';
import backgroundImageNine from '../../../assets/images/excTravel.jpg';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useNavigate } from "react-router-dom";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50vw',
    bgcolor: 'background.paper',
    border: 'none',
    outline: 'none',
    boxShadow: 24,
    p: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '1rem',
    borderRadius: '1em',
};

const ExclusivePage = () => {
    const [openComingSoon, setOpenComingSoon] = useState(false)
    const navigate = useNavigate()

    const onClickHome = () => {
        navigate('/')
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const onClickExcImage = () => {
        setOpenComingSoon(true)
    }

    const handleCloseComingSoon = () => {
        setOpenComingSoon(false)
    }

    const imgArr = [backgroundImageOne, backgroundImageTwo, backgroundImageThree, backgroundImageFour, backgroundImageFive, backgroundImageSix, backgroundImageSeven, backgroundImageEight, backgroundImageNine]

    return (
        <div className="exclusivePage">
            <div className="exclusivePage-banner">
                <Navbar companyLogo={companyLogo} logoStyles='navbar-logoStyle-common' menuItemStyles="exclusivePage-menu-items" cartColor="#FFFFFF" hamburgerColor="#ffffff" />
                <Header
                    headerHeadingContent='Originality at its finest'
                    headerSubHeading="Immerse yourself in the arts like never before."
                    headerTrending="mountains,dark,nature,flowers,space"
                    searchBarStyles='exclusivePage-SearchBar'
                    initialState='EXCLUSIVE'
                    headerHeight="exclusivePageHeaderHeight"
                />
                <video className="exclusive-banner-video" autoPlay loop muted width="100%">
                    <source src={previewBannerVideo} type="video/mp4" />
                    <img src={ipfallBackImage} alt="Fallback Image" />
                </video>
            </div>
            <div className="exclusivePage-pageContent">
                <div className="exclusivePage-heading">
                    Exclusive
                </div>
                <div className="exclusivePage-imageGallery">
                    {
                        imgArr.map((imgSrc, idx) => {
                           return  <img onClick={onClickExcImage} key={idx} className="exclusivePage-imageGallery-image" src={imgSrc} />
                        })
                    }
                </div>
            </div>
            <BecomeASeller />
            <Footer />
            <Modal
                open={openComingSoon}
                onClose={handleCloseComingSoon}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="exc-ht">Hold Tight!</div>
                    <div className="exc-cs">The Page is Coming Soon...</div>
                    <div className="exc-support ">If you have any queries, suggestion, or operational technical issues, please reach out to us at our email <span className="exc-supportEmail">support@worldarchives.in</span></div>
                    <button onClick={onClickHome} className="exc-homeBtn">Back To Home</button>
                </Box>
            </Modal>
        </div>
    )
}

export default ExclusivePage;