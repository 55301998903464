import { createSlice } from "@reduxjs/toolkit";
import { sellerLogin, sellerRegistration } from "./sellerAction";

// initialize sellerToken, sellerInfo from local storage
const sellerToken = localStorage.getItem('sellerToken')
    ? localStorage.getItem('sellerToken')
    : null
const sellerInfo = localStorage.getItem('sellerInfo')
    ? JSON.parse(localStorage.getItem('sellerInfo'))
    : null

const initialState = {
    loading: false,
    loginSuccess: false,
    error: null,
    registrationSuccess: false,
    registrationSuccessData: null,
    sellerInfo,
    sellerToken
}

export const sellerSlice = createSlice({
    name: 'seller',
    initialState,
    reducers: {
        sellerLogout: (state) => {
            localStorage.clear()
            state.loading = false
            state.loginSuccess = false
            state.error = null
            state.registrationSuccess = false
            state.registrationSuccessData = null
            state.sellerInfo = null
            state.sellerToken = null
        }
    },
    extraReducers: {
        [sellerRegistration.pending]: (state) => {
            state.loading = true;
            state.error = null;
        },
        [sellerRegistration.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.registrationSuccess = true;
            state.registrationSuccessData = payload
        },
        [sellerRegistration.rejected]: (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        },
        [sellerLogin.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [sellerLogin.fulfilled]: (state, { payload }) => {
            state.loginSuccess = true
            state.loading = false
            state.sellerInfo = JSON.stringify(payload?.data?.findUser)
            state.sellerToken = JSON.stringify(payload?.data?.tokenData?.token)
        },
        [sellerLogin.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },
    }
})

export const { sellerLogout } = sellerSlice.actions

export default sellerSlice.reducer;