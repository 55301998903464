import { createSlice } from "@reduxjs/toolkit";
import {
    searchResults,
    searchResultsById,
    getResultsByStampCategory,
    getMediaSearchPagination,
    getCartItems,
    getUserAddress,
    updateUserAddress,
    addToCart,
    getWishlistItems,
    removeCartItems,
    removeWishlistItem,
    cartCheckout,
    purchaseHistory,
    ownedAssets,
} from "./searchActions"
const initialState = {
    loading: false,
    error: null,
    success: false,
    searchData: null,
    searchDataSideImage:[],
    searchIdData: null,
    nextKey: null,
    stampCategory: null,
    cartData: [],
    cartDiscount: 0,
    cartFinalPrice: 0,
    cartItems: 0,
    cartTotal: 0,
    userAddress: [],
    wishlistData: [],
    paymentSessionId: null,
    paymentSummary: null,
    purchaseHistoryData: [],
    ownedAssetsData: []
}

export const searchSlice = createSlice({
    name: 'search',
    initialState,
    extraReducers: {
        [searchResults.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [searchResults.fulfilled]: (state, { payload }) => {
            state.success = true
            state.loading = false
            state.searchData = payload
        },
        [searchResults.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },
        [searchResultsById.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [searchResultsById.fulfilled]: (state, { payload }) => {
            state.success = true
            state.loading = false
            state.searchIdData = payload
        },
        [searchResultsById.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },
        [getResultsByStampCategory.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [getResultsByStampCategory.fulfilled]: (state, { payload }) => {
            state.success = true
            state.loading = false
            state.searchData = payload
        },
        [getResultsByStampCategory.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },
        [getMediaSearchPagination.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [getMediaSearchPagination.fulfilled]: (state, { payload }) => {
            state.success = true
            state.loading = false
            state.searchData = payload.finalData
            state.nextKey = payload.nextKey
            state.stampCategory = payload.category
        },
        [getMediaSearchPagination.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },
        [addToCart.pending]: (state) => {
            state.error = null
        },
        [addToCart.fulfilled]: (state, { payload }) => {
            state.success = true
            state.cartItems = state.success && payload ? state.cartItems + 1 : state.cartItems
        },
        [addToCart.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
            state.cartItems = state.cartData - 1
        },
        [getCartItems.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [getCartItems.fulfilled]: (state, { payload }) => {
            state.success = true
            state.loading = false
            state.cartData = payload.finalMedia
            state.cartTotal = payload.total
            state.cartDiscount = payload.discount
            state.cartFinalPrice = payload.finalPrice
            state.cartItems = payload.cartItems
        },
        [getCartItems.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },
        [removeCartItems.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [removeCartItems.fulfilled]: (state, { payload }) => {
            state.success = true
            state.loading = false
            let removedPrice = state.cartData.find(ele => ele.id === payload).price;
            state.cartFinalPrice = state.cartFinalPrice - removedPrice;
            state.cartData = state.cartData.filter(el => el.id !== payload)
            state.cartItems = state.cartItems - 1
        },
        [removeCartItems.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },
        [getUserAddress.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [getUserAddress.fulfilled]: (state, { payload }) => {
            state.success = true
            state.loading = false
            state.userAddress = payload
        },
        [getUserAddress.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },
        [updateUserAddress.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [updateUserAddress.fulfilled]: (state, { payload }) => {
            state.success = true
            state.loading = false
            state.userAddress = payload
        },
        [updateUserAddress.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },

        [getWishlistItems.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [getWishlistItems.fulfilled]: (state, { payload }) => {
            state.success = true
            state.loading = false
            state.wishlistData = payload
        },
        [getWishlistItems.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },
        [removeWishlistItem.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [removeWishlistItem.fulfilled]: (state, { payload }) => {
            state.success = true
            state.loading = false
            state.wishlistData = state.wishlistData.filter(el => el.id !== payload)
        },
        [removeWishlistItem.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },
        [cartCheckout.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [cartCheckout.fulfilled]: (state, { payload }) => {
            state.success = true
            state.loading = false
            state.paymentSessionId = payload?.payment_session_id
        },
        [cartCheckout.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },
        [purchaseHistory.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [purchaseHistory.fulfilled]: (state, { payload }) => {
            state.success = true
            state.loading = false
            state.purchaseHistoryData = payload
        },
        [purchaseHistory.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },
        [ownedAssets.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [ownedAssets.fulfilled]: (state, { payload }) => {
            state.success = true
            state.loading = false
            state.ownedAssetsData = payload
        },
        [ownedAssets.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },
    }
})


export default searchSlice.reducer