import * as React from 'react';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import './adminContent.css'

const AdminDatePicker = ({ dateValue, handleDateChange }) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
                className='adminDatePicker'
                inputFormat="DD/MM/YYYY"
                value={dateValue}
                onChange={handleDateChange}
                renderInput={(params) => <TextField sx={{
                    '.css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input': {
                        fontSize: '13px',
                        color: '#888888'
                    }
                }} size="small" {...params} />}
            />
        </LocalizationProvider>
    )
}

export default AdminDatePicker;