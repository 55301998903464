import React, { useEffect, useState } from 'react';
import './adminSidebar.css';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useAdminPagesContext } from '../../../contexts/AdminPagesContext';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import CopyrightIcon from '@mui/icons-material/Copyright';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate } from 'react-router-dom';



const drawerWidth = 203;
const drawerStyle = {
  width: drawerWidth,
  flexShrink: 0,
  [`& .MuiDrawer-paper`]: {
    width: drawerWidth,
    boxSizing: 'border-box',
  },
  '& .css-1nxmd3h-MuiListItem-root.Mui-selected': {
    background: '#F5F5F5',
    color: '#4946C3',
    borderLeft: '3px solid #4946C3',
    '& .css-i4bv87-MuiSvgIcon-root': {
      color: '#4946C3'
    }
  }
}


function AdminSidebar() {
  const navigate = useNavigate()
  const currentPath = window.location.pathname

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        sx={drawerStyle}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <List>
            <ListItem disablePadding sx={currentPath === '/cms/dashboard' ? {borderLeft:'5px solid #4946c3', background:'#f5f5f5'} : null}>
              <ListItemButton
                onClick={() => {
                  navigate('/cms/dashboard')
                }}
              >
                <ListItemIcon sx={{ minWidth: '2rem', color: currentPath === '/cms/dashboard' ? '#4946c3' : '#666666' }}><AutoAwesomeMosaicIcon /></ListItemIcon>
                <ListItemText primary = 'Dashboard' sx={currentPath === '/cms/dashboard' ? {color:'#4946c3'} : {color:'#666666'}}/>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding sx={currentPath === '/cms/content' ? {borderLeft:'5px solid #4946c3', background:'#f5f5f5'} : null}>
              <ListItemButton
                onClick={() => {
                  navigate('/cms/content')
                }}
              >
                <ListItemIcon sx={{ minWidth: '2rem', color: currentPath === '/cms/content' ? '#4946c3' : '#666666' }}><ContentCopyIcon /></ListItemIcon>
                <ListItemText primary = 'Content' sx={currentPath === '/cms/content' ? {color:'#4946c3'} : {color:'#666666'}}/>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding sx={currentPath === '/cms/analytics' ? {borderLeft:'5px solid #4946c3', background:'#f5f5f5'} : null}>
              <ListItemButton
                onClick={() => {
                  navigate('/cms/analytics')
                }}
              >
                <ListItemIcon sx={{ minWidth: '2rem', color: currentPath === '/cms/analytics' ? '#4946c3' : '#666666' }}><AnalyticsIcon /></ListItemIcon>
                <ListItemText primary = 'Analytics' sx={currentPath === '/cms/analytics' ? {color:'#4946c3'} : {color:'#666666'}}/>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding sx={currentPath === '/cms/audit-logs' ? {borderLeft:'5px solid #4946c3', background:'#f5f5f5'} : null}>
              <ListItemButton
                onClick={() => {
                  navigate('/cms/audit-logs')
                }}
              >
                <ListItemIcon sx={{ minWidth: '2rem', color: currentPath === '/cms/audit-logs' ? '#4946c3' : '#666666' }}><WorkHistoryIcon /></ListItemIcon>
                <ListItemText primary = 'Audit Logs' sx={currentPath === '/cms/audit-logs' ? {color:'#4946c3'} : {color:'#666666'}}/>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding sx={currentPath === '/cms/user-management' ? {borderLeft:'5px solid #4946c3', background:'#f5f5f5'} : null}>
              <ListItemButton
                onClick={() => {
                  navigate('/cms/user-management')
                }}
              >
                <ListItemIcon sx={{ minWidth: '2rem', color: currentPath === '/cms/user-management' ? '#4946c3' : '#666666' }}><ManageAccountsIcon /></ListItemIcon>
                <ListItemText primary = 'User Management' sx={currentPath === '/cms/user-management' ? {color:'#4946c3'} : {color:'#666666'}}/>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding sx={currentPath === '/cms/copyrights' ? {borderLeft:'5px solid #4946c3', background:'#f5f5f5'} : null}>
              <ListItemButton
                onClick={() => {
                  navigate('/cms/copyrights')
                }}
              >
                <ListItemIcon sx={{ minWidth: '2rem', color: currentPath === '/cms/copyrights' ? '#4946c3' : '#666666' }}><CopyrightIcon /></ListItemIcon>
                <ListItemText primary = 'Copyrights' sx={currentPath === '/cms/copyrights' ? {color:'#4946c3'} : {color:'#666666'}}/>
              </ListItemButton>
            </ListItem> 
            <ListItem disablePadding sx={currentPath === '/cms/settings' ? {borderLeft:'5px solid #4946c3', background:'#f5f5f5'} : null}>
              <ListItemButton
                onClick={() => {
                  navigate('/cms/settings')
                }}
              >
                <ListItemIcon sx={{ minWidth: '2rem', color: currentPath === '/cms/settings' ? '#4946c3' : '#666666' }}><SettingsIcon /></ListItemIcon>
                <ListItemText primary = 'Settings' sx={currentPath === '/cms/settings' ? {color:'#4946c3'} : {color:'#666666'}}/>
              </ListItemButton>
            </ListItem> 
          </List>
        </Box>
      </Drawer>
    </Box>
  );
}

export default AdminSidebar;