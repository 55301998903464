  import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import './viewCart.css';
import { useAdminPagesContext } from "../../../contexts/AdminPagesContext";
import { useDispatch, useSelector } from "react-redux";
import { removeCartItems, getCartItems } from "../../../features/search/searchActions";
import { Loader } from "../../common/Loader";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ViewCart = ({ getIcon }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const userToken = localStorage.getItem('userToken')
    useEffect(() => {
        getCartDetails()
    }, [dispatch])

    async function getCartDetails() {
        if (userToken) await dispatch(getCartItems())
        else return
    }

    let { cartData, cartDiscount, cartFinalPrice, cartItems, cartTotal, loading } = useSelector(state => state.search)
    const { cartActiveStep, setCartActiveStep } = useAdminPagesContext()
    const onClickViewCartProceed = () => {
        setCartActiveStep(cartActiveStep + 1)
    }

    const onClickContinueShopping = () => {
        navigate('/')
    }

    const handleRemove = async (id, resolution) => {
        await dispatch(removeCartItems({ id, resolution }))
    }

    const aws_url = process.env.REACT_APP_S3_HOST
    
    return (
        <div className="viewCart">
            <div className="viewCart-content">
                <div className="viewCart-headingsCommonSty">
                    {`Your Cart (${cartItems} items)`}
                </div>
                {!loading ?
                    <div className="viewCart-content-items">
                        {cartData && cartData?.length > 0 ? cartData?.map((data, index) => (
                            <div className="viewCart-content-itemDetails" key={index}>
                                <div className="viewCart-itemDetails-image">
                                    <img src={`${aws_url}/${data.thumbNail}`} alt="" />
                                </div>
                                <div className="viewCart-itemDetails-info">
                                    <div className="viewCart-itemDetails-nameFormat">
                                        <div className="viewCart-itemDetails-name">
                                            {getIcon(data?.category)}
                                            <p>{data?.name}</p>
                                        </div>
                                        <div className="viewCart-itemDetails-format">
                                            {data?.format}
                                        </div>
                                    </div>
                                    <div className="viewCart-itemDetails-ResoLice">
                                        <div className="viewCart-itemDetails-Reso">
                                            <p className="viewCart-itemDetails-headCommonSty">Resolution</p>
                                            <p className="viewCart-itemDetails-headDataCommonSty">{data?.resolution}</p>
                                        </div>
                                        <div className="viewCart-itemDetails-Lice">
                                            <p className="viewCart-itemDetails-headCommonSty">License</p>
                                            <p className="viewCart-itemDetails-headDataCommonSty">Standard</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="viewCart-itemDetails-priceAndRemove">
                                    <div className="viewCart-itemDetails-price">
                                        {`INR ${data?.price}`}
                                    </div>
                                    <button className="viewCart-itemDetails-removeBtn" id={data.id}
                                        onClick={() => handleRemove(data.id, cartData.find(ele => ele.id === data.id).resolution)}>
                                        Remove
                                    </button>
                                </div>
                            </div>
                        )) : <div className="viewCart-noDataDiv">No items in the cart</div>
                        }
                    </div> :
                    <div style={{ height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Loader /></div>
                }
            </div>
            {loading || cartItems === 0 ? <></> : <div className="viewCart-orderSummary">
                <div className="viewCart-headingsCommonSty">
                    Order Summary
                </div>
                <div className="viewCart-orderSummaryContainer">
                    <div className="viewCart-orderSummaryContainer-orders">
                        {cartData?.map((item, index) => (
                            <div className="viewCart-orderSummaryContainer-orderDetails" key={item.id}>
                                <div className="viewCart-orderDetails-name">
                                    {getIcon(item?.category)}
                                    <p>{item?.name}</p>
                                </div>
                                <div className="viewCart-orderDetails-planAndPrice">
                                    <p className="fw-500">One time purchase</p>
                                    <p className="fw-600">{`INR ${item?.price}`}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="viewCart-orderTotalAmount">
                        <p>Estimated amount due today</p>
                        <p>{`INR ${cartFinalPrice}`}</p>
                    </div>
                    <button onClick={onClickViewCartProceed} className="viewCartPtc-button">Proceed to checkout</button>
                    <div className="viewCartCs-button">
                        <button onClick={onClickContinueShopping}>Continue Shopping</button>
                    </div>
                </div>
            </div>}
            <ToastContainer limit={3} theme="colored" autoClose={1200} />
        </div>
    )
}

export default ViewCart;