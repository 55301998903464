import React, { useState, useEffect } from "react";
import './sellerNavbar.css';
import { HiUpload } from "react-icons/hi";
import { AiFillCaretDown } from "react-icons/ai";
import { Box, Modal, Select, MenuItem, Menu, OutlinedInput, IconButton, InputAdornment, Paper } from '@mui/material';
import companyLogo from '../../../assets/logo/WorldArchivesDark.png'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RxCross2 } from 'react-icons/rx';
import { MdOutlineCancel } from 'react-icons/md';
import Loader from "../../../components/common/Loader/Loader";
import { FileUploader } from "react-drag-drop-files";
import { getSellerProfile, sellerContentUpload } from "../../../api";
import { sellerLogout } from '../../../features/seller/sellerSlice'

const uploadModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    height: 480,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
    outline: "none",
    borderRadius: "10px"
};

const SellerNavbar = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [files, setFiles] = useState([]);
    const open = Boolean(anchorEl);
    const [openUpload, setOpenUpload] = useState(false);
    const [sellerProfile, setSellerProfile] = useState([])
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(()=>{
        const getProfileInfo = async()=>{
            const sellerData = await getSellerProfile()
            setSellerProfile(sellerData)
        }
        getProfileInfo()
    },[])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const onClickUploadBtn = () => {
        setOpenUpload(true)
    }
    const handleCloseUploadPopup = () => {
        setOpenUpload(false);
    }

    const handleChange = (file) => {
        const chosenFiles = Array.prototype.slice.call(file)
        setFiles(chosenFiles);
    };

    const handleCancel = (e) => {
        let modifiledFiles = files.filter(el => el.name !== e.target.id)
        setFiles(modifiledFiles)
    }


    const handleUpload = async () => {
        setOpenUpload(false)
        await sellerContentUpload(files)
        setFiles([])
    }

    const handleLogout = () => {
        dispatch(sellerLogout())
        navigate('/login')
    }

    return (
        <div className="sellerNavbar-container">
            <div className="sellerNavbar-container-cLogo" >
                <img src={companyLogo} alt="Company Logo" />
            </div>
            <div className="sellerNavbar-container-buttonAndUserInfo">
                <div className="sellerNavbar-ccAndUpBtn">
                    <button onClick={onClickUploadBtn}>
                        <HiUpload fontSize="14px" />
                        <span className="adminNabvarUpload">Upload</span>
                    </button>
                </div>
                <div className="sellerNavbar-userInfo">
                    <span className="sellerNavbar-userInitials" style={{ textTransform: "capitalize" }}>{sellerProfile.Initials}</span>
                    <span className="sellerNavbar-userName" style={{ textTransform: "capitalize" }}>{sellerProfile.Name}</span>
                    <AiFillCaretDown
                        style={{ cursor: 'pointer' }}
                        fontSize="15px"
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick} />
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem>Add Users</MenuItem>
                        <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    </Menu>
                </div>
            </div>
            <div>
                <Modal
                    open={openUpload}
                    onClose={handleCloseUploadPopup}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={uploadModalStyle}>
                        <div className="upload-modalContent">
                            <div className="uploadModal-headingAndCross">
                                <div className="uploadModal-heading">
                                    Upload Content
                                </div>
                                <div style={{ cursor: "pointer" }} onClick={handleCloseUploadPopup}>
                                    <MdOutlineCancel fontSize="19px" />
                                </div>
                            </div>
                            <div className="uploadModal-dragAndSelectBtn">
                                <FileUploader
                                    multiple={true}
                                    handleChange={handleChange}
                                    name="file"
                                    // types={fileTypes}
                                    maxSize="500000"
                                />
                                {files.length > 0
                                    ? files?.map((el, idx) =>
                                        <div key={idx}>
                                            <span>{el.name} </span>
                                            <span><RxCross2 onClick={handleCancel} id={el.name} style={{ cursor: 'pointer', color: 'red' }} /></span>
                                        </div>)
                                    : <p>no files selected yet</p>}
                                {files.length > 0 && <button onClick={handleUpload}>Upload</button>}
                            </div>
                            <div className="uploadModal-tosAndCgAndLm">
                                <span className="uploadModal-p">
                                    By submitting your content to WorldArchive, you acknowledge that you agree to WorldArchive's<span className="uploadModal-pLink"> Terms of Service </span>and<span className="uploadModal-pLink"> Community Guidelines. </span>Please be sure not to violate other's copyright or privacy rights.<span className="uploadModal-pLink"> Learn more</span>
                                </span>
                            </div>
                        </div>
                    </Box>
                </Modal>
            </div>
            {/* <div>
                <Modal
                    open={openAddUser}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={addUserPopupStyle}>
                        <div className="adminNavbar_addUser">
                            <div className="addUser_headingAndCancel">
                                <div className="fw-700 fs-22">
                                    Add User
                                </div>
                                <div className='aaddUser_close' onClick={handleCloseAddUserPopup}>
                                    <MdOutlineCancel style={{ fontSize: "19px", cursor: 'pointer' }} />
                                </div>
                            </div>
                            <form onSubmit={handleSubmit(onSubmitUserData)}>
                                <div className="addUser_container">
                                    <div className="addUser_labelInputContainer">
                                        <label className="fs-14 fw-600">Email</label>
                                        <input
                                            className="addUser_inputField"
                                            placeholder="Enter Email"
                                            type="email"
                                            {...register("email",
                                                {
                                                    required: "Email is required"
                                                })}
                                        // onChange={(e) => setAddUserData({ ...addUserData, email: e.target.value })}
                                        />
                                        <div className="addUser-text-error">{errors.email ? `${'*'}${errors.email.message}` : null}</div>
                                    </div>
                                    <div className="addUser_labelInputContainer">
                                        <label className="fs-14 fw-600">Password</label>
                                        <OutlinedInput
                                            placeholder='Enter Password'
                                            type={showPassword ? 'text' : 'password'}
                                            {...register("password", {
                                                required: "Password is required",
                                                pattern: {
                                                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,}$/,
                                                    message: `Entered value does not match our password policy`
                                                },
                                                minLength: { value: 8, message: "password is too short" },
                                            })}
                                            // onChange={(e) => setAddUserData({ ...addUserData, password: e.target.value })}
                                            sx={addUserPasswordStyle}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOff style={{ fontSize: "1.3rem", color: "#666666", marginRight: '0.2rem' }} /> : <Visibility style={{ fontSize: "1.3rem", color: "#666666", marginRight: '0.2rem' }} />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                        <div className="addUser-text-error">{errors.password ? `${'*'}${errors.password.message}` : null}</div>
                                    </div>
                                    <div className="addUser_labelInputContainer">
                                        <label className="fs-14 fw-600">Role</label>
                                        <Select
                                            size="small"
                                            placeholder="Add User"
                                            sx={addUserRoleStyle}
                                            // onChange={(e) => setAddUserData({ ...addUserData, roles: e.target.value })}
                                            displayEmpty
                                            // renderValue={addUserData?.roles !== "" ? undefined : () => "Select Role"}
                                            {...register("roles", {
                                                required: 'Role is required'
                                            })}
                                        >
                                            {
                                                ['Editor', 'Meta Tagger', 'Scheduler'].map(el =>
                                                    <MenuItem value={el} key={el}>{el}</MenuItem>
                                                )
                                            }
                                        </Select>
                                        <div className="addUser-text-error">{errors.roles ? `${'*'}${errors.roles.message}` : null}</div>
                                    </div>
                                    <div className="addUser_btn">
                                        <button type="submit">Add User</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </Box>
                </Modal>
                <Modal
                    open={openUpload}
                    onClose={handleCloseUploadPopup}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={uploadModalStyle}>
                        <div className="upload-modalContent">
                            <div className="uploadModal-headingAndCross">
                                <div className="uploadModal-heading">
                                    Upload Content
                                </div>
                                <div style={{ cursor: "pointer" }} onClick={handleCloseUploadPopup}>
                                    <MdOutlineCancel fontSize="19px" />
                                </div>
                            </div>
                            <div className="uploadModal-dragAndSelectBtn">
                                <FileUploader
                                    multiple={true}
                                    handleChange={handleChange}
                                    name="file"
                                    // types={fileTypes}
                                    maxSize="500000"
                                />
                                {files.length > 0
                                    ? files?.map((el, idx) =>
                                        <div key={idx}>
                                            <span>{el.name} </span>
                                            <span><RxCross2 onClick={handleCancel} id={el.name} style={{ cursor: 'pointer', color: 'red' }} /></span>
                                        </div>)
                                    : <p>no files selected yet</p>}
                                {files.length > 0 && <button onClick={handleUpload}>Upload</button>}
                            </div>
                            <div className="uploadModal-tosAndCgAndLm">
                                <span className="uploadModal-p">
                                    By submitting your content to WorldArchive, you acknowledge that you agree to WorldArchive's<span className="uploadModal-pLink"> Terms of Service </span>and<span className="uploadModal-pLink"> Community Guidelines. </span>Please be sure not to violate other's copyright or privacy rights.<span className="uploadModal-pLink"> Learn more</span>
                                </span>
                            </div>
                        </div>
                    </Box>
                </Modal>
                <Modal
                    open={openCuratedContent}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={curatedContentModalStyle}>
                        <div className="curatedContent">
                            <div className="uploadModal-headingAndCross">
                                <div className="uploadModal-heading">
                                    Curated Content
                                </div>
                                <div style={{ cursor: "pointer" }} onClick={handleCloseCuratedContent}>
                                    <MdOutlineCancel fontSize="19px" />
                                </div>
                            </div>
                            <div className="cc-dropdowns">
                                <div className="cc-dropdowns_roleAndUserCmnSty">
                                    <label className="fs-14 fw-600">Role</label>
                                    <Select
                                        required
                                        size="small"
                                        id="ccRoles"
                                        name="ccRoles"
                                        placeholder="Select Role"
                                        sx={addUserRoleStyle}
                                        onChange={(e) => {
                                            setSelectedRole(e.target.value)
                                            handleRoleChange(e.target.value)
                                        }}
                                        displayEmpty
                                        renderValue={selectedRole !== '' ? undefined : () => "Select Role"}
                                    >
                                        {
                                            ccRoles?.map((el, idx) =>
                                                <MenuItem value={el} key={idx}>{el}</MenuItem>
                                            )
                                        }
                                    </Select>
                                </div>
                                <div className="cc-dropdowns_roleAndUserCmnSty">
                                    <label className="fs-14 fw-600">Users</label>
                                    <Select
                                        required
                                        size="small"
                                        id="ccUsers"
                                        name="ccUsers"
                                        placeholder="Select User"
                                        sx={addUserRoleStyle}
                                        onChange={(e) => {
                                            setSelectedUser(e.target.value)
                                            handleUserChange(e.target.value)
                                        }}
                                        displayEmpty
                                        renderValue={selectedUser !== '' ? undefined : () => "Select User"}

                                    >
                                        {
                                            ccUsers?.map(el =>
                                                <MenuItem value={el} key={el}>{el}</MenuItem>
                                            )
                                        }
                                    </Select>
                                </div>
                            </div>
                            <div className="cc-table">
                                <Paper sx={{ width: '100%', overflow: 'hidden', border: '1px solid #C8C7C7', boxShadow: 'none' }}>
                                    <TableContainer sx={{ maxHeight: 360 }}>
                                        <Table stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{ borderRight: '1px solid #C8C7C7' }}>Actions</TableCell>
                                                    <TableCell>Counts</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {!ccLoading ? (newRoleData && newRoleData?.length) ? newRoleData?.map(obj =>
                                                    <TableRow>
                                                        <TableCell sx={{ borderRight: '1px solid #C8C7C7' }}>{obj?.type}</TableCell>
                                                        <TableCell>{obj?.total}</TableCell>
                                                    </TableRow>) :
                                                    <TableRow>
                                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '34vh', marginLeft: '70%', width: '35%' }}>No Data Found</div>
                                                    </TableRow>
                                                    : <TableRow>
                                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '34vh', marginLeft: '100%' }}><Loader /></div>
                                                    </TableRow>
                                                }

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </div>
                        </div>
                    </Box>
                </Modal>
            </div> */}
        </div>
    )
}

export default SellerNavbar;