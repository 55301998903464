import React, { useEffect, useState } from "react";
import { Navbar } from "../../common/Navbar";
import './pricingForPlan.css';
import logoMain from '../../../assets/logo/WorldArchivesDark.png';
import { useDispatch, useSelector } from "react-redux";
import { getSubscriptions } from "../../../features/users/userActions";
import { getUserAddress } from "../../../features/search/searchActions";
import { subscriptionCheckout } from "../../../api"
import { Loader } from "../../common/Loader";

const PricingForPlans = () => {
    const dispatch = useDispatch()
    const { userSubscriptions } = useSelector(state => state.user)
    const { userAddress } = useSelector(state => state.search)
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        async function getUserSubscriptions() {
            await dispatch(getSubscriptions())
            await dispatch(getUserAddress())
        }
        getUserSubscriptions()
    }, [dispatch])

    const handleJoin = async (e) => {
        setLoading(true)
        await subscriptionCheckout(userAddress.id, Number(e.target.id)).then(res => handleCheckout(res))
    }

    function handleCheckout(id) {
        const cashfree = new window.Cashfree(id);
        cashfree.redirect();
    }

    return (
        <div className="pricingForPlans">
            <Navbar menuItemStyles="navbar-menu-items" imgStyles="navbar-logoStyle" logo={logoMain} cartColor="#666666" />
            <div className="pricingForPlans-container">
                <div className="pricingForPlans-heading">
                    Get the ideal image plan for your projects
                </div>
                <div className="pricingForPlans-subHeading">
                    Access the best content from our massive library -- all with worry-free licensing.
                </div>
                <div className="pricingForPlans-infoCardsContainer">
                    {!loading ?
                    userSubscriptions && userSubscriptions?.length ?
                        userSubscriptions?.map(obj =>
                            <div className="pricingForPlans-cardsCommonSty" key={obj.name}>
                                <div className="pricingForPlans-cardHeadingCommonSty">
                                    {obj?.name}
                                </div>
                                <div className="pricingForPlans-cardSubHeadingCommonSty">
                                    A sweet deal on images, plus tools for planning and organizing content. Single user.
                                </div>
                                <div className="pricingForPlans-cardsOptionOneCommonSty">
                                    <span className="pricingForPlans-cardCommonSty-one">Downloads per month</span>
                                    <span className="pricingForPlans-cardCommonSty-two">{obj?.total}</span>
                                </div>
                                <div className="pricingForPlans-cardsOptionTwoCommonSty">
                                    <span className="pricingForPlans-cardCommonSty-one">License</span>
                                    <span className="pricingForPlans-cardCommonSty-three">{obj?.license}</span>
                                </div>
                                <div className="pricingForPlans-priceCommonSty">
                                    &#8377; {obj?.price}
                                </div>
                                <button className="pricingForPlans-buttonCommonSty pfp-addStyles" onClick={handleJoin} id={obj.id}>Join Now</button>
                            </div>
                        )
                        :
                        <></>
                        : <div style={{ height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center', width:"100%" }}><Loader /></div>
                    }
                </div>
            </div>
        </div>
    )
}

export default PricingForPlans;