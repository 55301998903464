import React, { useEffect } from "react";
import './pHistoryPage.css';
import logoMain from '../../../assets/logo/WorldArchivesDark.png';
import { Navbar } from "../../common/Navbar";
import { BiDownload } from 'react-icons/bi';
import { downloadReceipt } from "../../../api";
import { purchaseHistory } from "../../../features/search/searchActions";
import { useSelector, useDispatch } from "react-redux";

const PHistoryPage = () => {
    const dispatch = useDispatch()
    const { purchaseHistoryData, loading } = useSelector(state => state.search)

    useEffect(() => {
        async function getPurchaseHistory() {
            await dispatch(purchaseHistory())
        }
        getPurchaseHistory()
    }, [dispatch])

    const handleReceipt = async (e) => {
        await downloadReceipt(e.target.id).then(res => window.open(res, '_blank'))
    }

    const dateOptions = { year: "numeric", month: "short", day: "numeric"};

    return (
        <div className="history">
            <Navbar menuItemStyles="navbar-menu-items" logoStyles="navbar-logoStyle" companyLogo={logoMain} cartColor="#666666" hamburgerColor='#000000'/>
            <div className="history-container">
                <div className="history-heading">
                    Purchase History
                </div>
                <div className="history-contentTable">
                    <div className="history-contentTable-header">
                        <div className="history-contentTable-headerDate">
                            <span className="historyHeader-headingCommonSty">Date</span>
                        </div>
                        <div className="history-contentTable-headerDescription">
                            <span className="historyHeader-headingCommonSty">Description</span>
                        </div>
                        <div className="history-contentTable-headerTotal">
                            <span className="historyHeader-headingCommonSty">Total</span>
                        </div>
                        <div className="history-contentTable-headerReceipt">
                            <span className="historyHeader-headingCommonSty">Receipt</span>
                        </div>
                    </div>
                    <div className="history-contentTable-data">
                        {!loading ?
                            purchaseHistoryData && purchaseHistoryData.length > 0 ? purchaseHistoryData.map((item, idx) =>
                                <div className="history-contentTable-dataFields" key={idx}>
                                    <div className="history-contentTable-dataFields-date">
                                        <span className="history-dataDate">{new Date(item.date).toLocaleString('en-US', dateOptions)}</span>
                                    </div>
                                    <div className="history-contentTable-dataFields-subs">
                                        <span className="history-dataCommonSty">On-Demand Subscription</span>
                                    </div>
                                    <div className="history-contentTable-dataFields-price">
                                        <span className="history-dataCommonSty">&#8377;{item.total}</span>
                                    </div>
                                    <div className="history-contentTable-dataFields-receipt" >
                                        <div className="history-downloadSty" onClick={handleReceipt} id={item?.orderId}><BiDownload fontSize={19} id={item?.orderId} />&nbsp;&nbsp;Download Receipt</div>
                                    </div>
                                </div>
                            )
                                :
                                <div className="history-contentTable-dataFields-noData">
                                    <div style={{ textAlign: 'center' }}>
                                        <span>No Data Found</span>
                                    </div>
                                </div>
                            :
                            <div className="history-contentTable-dataFields-noData">
                                <div style={{ textAlign: 'center' }}>
                                    <span>Loading...</span>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

export default PHistoryPage;