import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify"

const base_url = process.env.REACT_APP_BASE_URL
const LOGIN_URL = `${base_url}/customer/auth/login`
const GOOGLE_URL = `${base_url}/customer/auth/google-login`
const FB_URL = `${base_url}/customer/auth/facebook-login`
const LOGIN_WITH_SSO_URL = 'login-with-sso-url'
const SIGNUP_URL = `${base_url}/customer/auth/register`
const FORGOT_PASSWORD_URL = `${base_url}/customer/auth/forgot-password`
const RESET_PASSWORD_URL = `${base_url}/customer/auth/reset-password`

const config = {
    headers: {
        'Content-Type': 'application/json',
    }
}

export const userLogin = createAsyncThunk(
    'user/userLogin',
    async ({ email, password }, { rejectWithValue }) => {
        try {
            const body = { email: email, password: password }
            const { data } = await axios.post(LOGIN_URL, body, config)
            localStorage.setItem('userToken', data?.data?.tokenData?.token)
            localStorage.setItem('userInfo', JSON.stringify(data?.data?.findUser))
            toast.success('User Login Successful')
            return data

        } catch (error) {
            if (error.response && error.response.data.message) {
                toast.error(error.response.data.message)
                return rejectWithValue(error.response.data)
            } else {
                toast.error(error.message)
                return rejectWithValue(error.message)
            }
        }
    }
)

export const userGoogleLogin = createAsyncThunk(
    'user/userGoogleLogin',
    async (tokenId, { rejectWithValue }) => {

        try {
            const body = { token: tokenId }
            const { data } = await axios.post(GOOGLE_URL, body, config)
            localStorage.setItem('userToken', data?.data?.tokenData?.token)
            localStorage.setItem('userInfo', JSON.stringify(data?.data?.user))
            toast.success('Google Signin Successful')
            return data

        } catch (error) {
            if (error.response && error.response.data.message) {
                toast.error(error.response.data.message)
                return rejectWithValue(error.response.data.message)
            } else {
                toast.error(error.message)
                return rejectWithValue(error.message)
            }
        }
    }
)

export const userFacebookLogin = createAsyncThunk(
    'user/userFacebookLogin',
    async (tokenId, { rejectWithValue }) => {
        try {
            const body = { code: tokenId }
            const { data } = await axios.post(FB_URL, body, config)
            localStorage.setItem('userToken', data?.data?.tokenData?.token)
            localStorage.setItem('userInfo', JSON.stringify(data?.data?.user))
            toast.success('Facebook Signin Successful')
            return data

        } catch (error) {
            if (error.response && error.response.data.message) {
                toast.error(error.response.data.message)
                return rejectWithValue(error.response.data.message)
            } else {
                toast.error(error.message)
                return rejectWithValue(error.message)
            }
        }
    }
)

export const userLoginWithSSO = createAsyncThunk(
    'user/userLoginWithSSO',
    async ({ email }, { rejectWithValue }) => {
        try {
            const { data } = await axios.post(LOGIN_WITH_SSO_URL, { email }, config)
            localStorage.setItem('userToken', data.tokenData.token)
            return data

        } catch (error) {
            if (error.response && error.response.data.message) {
                toast.error(error.response.data.message)
                return rejectWithValue(error.response.data.message)
            } else {
                toast.error(error.message)
                return rejectWithValue(error.message)
            }
        }
    }
)

export const userSignup = createAsyncThunk(
    'user/userSignup',
    async ({ firstName, lastName, email, password, company }, { rejectWithValue }) => {
        const body = { firstName: firstName, lastName: lastName, email, password, company_name: company, company_gstin: company }
        try {
            toast.success('User Signup Successful')
        } catch (error) {
            if (error.response && error.response.data.message) {
                toast.error(error.response.data.message)
                return rejectWithValue(error.response.data.message)
            } else {
                toast.error(error.message)
                return rejectWithValue(error.message)
            }
        }
    }
)

export const userForgotPassword = createAsyncThunk(
    'user/userForgotPassword',
    async ({ email }, { rejectWithValue }) => {
        const body = { email }
        try {
            const { data } = await axios.post(FORGOT_PASSWORD_URL, body, config)
            return data
        } catch (error) {
            if (error.response && error.response.data.message) {
                toast.error(error.response.data.message)
                return rejectWithValue(error.response.data.message)
            } else {
                toast.error(error.message)
                return rejectWithValue(error.message)
            }
        }
    }
)

export const userResetPassword = createAsyncThunk(
    'user/userResetPassword',
    async ({ resetPasswordToken, newPassword }, { rejectWithValue }) => {
        const body = { resetPasswordToken, newPassword }
        try {
            await axios.post(RESET_PASSWORD_URL, body, config)
        } catch (error) {
            if (error.response && error.response.data.message) {
                toast.error(error.response.data.message)
                return rejectWithValue(error.response.data.message)
            } else {
                toast.error(error.message)
                return rejectWithValue(error.message)
            }
        }
    }
)

export const getUserProfileDetails = createAsyncThunk(
    'user/getUserProfileDetails',
    async (_, { rejectWithValue }) => {
        const userToken = localStorage.getItem('userToken')
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userToken}`
            }
        }
        try {
            const { data } = await axios.get(`${base_url}/customer`, config)
            return data?.data?.customer

        } catch (error) {
            if (error.response && error.response.data.message) {
                toast.error(error.response.data.message)
                return rejectWithValue(error.response.data.message)
            } else {
                toast.error(error.message)
                return rejectWithValue(error.message)
            }
        }
    }
)

export const updateUserProfileDetails = createAsyncThunk(
    'user/updateUserProfileDetails',
    async ({ firstName, lastName, gender, dob, password }) => {
        const userToken = localStorage.getItem('userToken')
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userToken}`
            }
        }
        const body = {
            "first_name": firstName,
            "last_name": lastName,
            "name": `${firstName} ${lastName}`,
            "gender": gender,
            "dob": dob,
            "password": password
        }
        try {
            const { data } = await axios.put(`${base_url}/customer`, body, config)
            toast.success('Profile Updated Successfully')
            return data?.data

        } catch (error) {
            toast.error("something went wrong")
        }
    }
)

export const userLogout = createAsyncThunk(
    'user/userLogout',
    async (token) => {
        try {
            await axios.post(`${base_url}/customer/auth/logout`, {}, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
            );
            toast.success('You have been logged out successfully.')
        }
        catch (error) {
            return error
        }
    }
)

export const getSubscriptions = createAsyncThunk(
    'user/getSubscriptions',
    async (_, { rejectWithValue }) => {
        const userToken = localStorage.getItem('userToken')
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userToken}`
            }
        }
        try {
            const { data } = await axios.get(`${base_url}/subscription`, config)
            const returnData = data?.data?.allSubscriptions
            const finalData = returnData.map(obj => ({
                id: obj?.id,
                name: obj?.name,
                price: obj?.price,
                type: obj?.type,
                total: obj?.totalAsset,
                license: obj?.license
            }))
            return finalData
        } catch (error) {
            toast.error(error.response.data.message)
            return rejectWithValue(error.response)
        }
    }
)