import React from "react";
import { Navbar } from "../../common/Navbar";
import './cart.css';
import logoMain from '../../../assets/logo/WorldArchivesDark.png';
import { Step, StepLabel, Stepper, useMediaQuery } from "@mui/material";
import ViewCart from "../../pageContent/ViewCart/ViewCart";
import { useAdminPagesContext } from "../../../contexts/AdminPagesContext";
import CartCheckout from "../../pageContent/CartCheckout/CartCheckout";
import CartConfirmation from "../../pageContent/CartConfirmation/CartConfirmation";
import { RxImage } from 'react-icons/rx';
import SmartDisplayOutlinedIcon from '@mui/icons-material/SmartDisplayOutlined';
import { HiOutlineMusicNote } from 'react-icons/hi';
import { BsBox } from 'react-icons/bs';
import { useSelector } from "react-redux";

const cartSteps = [
    "View Cart",
    "Checkout",
    "Confirmation"
]

const cartStepperSty = {
    '@media (min-width : 481px) and (max-width : 767px)': {
        '& .css-1hv8oq8-MuiStepLabel-label': {
            fontSize: '0.75rem'
        },

        '& .css-vnkopk-MuiStepLabel-iconContainer': {
            paddingRight: '4px'
        }
    }
}

const Cart = () => {
    const { cartData } = useSelector(state => state.search)
    const { cartActiveStep } = useAdminPagesContext()
    const isMobile = useMediaQuery('(max-width:600px)');

    const getIcon = (type) => {
        switch (type) {
            case "IMAGE":
                return <RxImage color="#666666" />
            case "VIDEO":
                return <SmartDisplayOutlinedIcon style={{ color: "#666666", fontSize: '18px' }} />
            case 'MUSIC':
                return <HiOutlineMusicNote color="#666666" />
            case 'VFX':
                return <BsBox color="#666666" />
            default:
                return <RxImage color="#666666" />
        }
    }

    const getCartStepContent = (cartActiveStep) => {
        switch (cartActiveStep) {
            case 0:
                return <ViewCart getIcon={getIcon} />
            case 1:
                return <CartCheckout cartData={[cartData]} getIcon={getIcon} />
            case 2:
                return <CartConfirmation cartData={[cartData]} getIcon={getIcon} />
            default:
                return null
        }
    }

    return (
        <div className="cart">
            <Navbar menuItemStyles="navbar-menu-items" logoStyles="navbar-logoStyle" companyLogo={logoMain} cartColor="#666666" hamburgerColor="#000000" />
            <div className="cart-container">
                <div className="cart-stepper">
                    {isMobile ? <Stepper
                        alternativeLabel
                        activeStep={cartActiveStep}
                        sx={cartStepperSty}
                    >
                        {cartSteps.map((label) => (
                            <Step sx={{
                                '& .css-1hv8oq8-MuiStepLabel-label.MuiStepLabel-alternativeLabel':{
                                    marginTop: '0.2rem',
                                    fontSize: '0.8rem'
                                },
                                '& .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root':{
                                    width:'0.8em',
                                    height:'0.8em'
                                }
                            }} key={label} >
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper> :
                        <Stepper
                            activeStep={cartActiveStep}
                            sx={cartStepperSty}
                        >
                            {cartSteps.map((label) => (
                                <Step key={label} >
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    }
                </div>
                {getCartStepContent(cartActiveStep)}
            </div>
        </div>
    )
}

export default Cart;