import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Footer } from '../../common/Footer';
import { Navbar } from '../../common/Navbar';
import './vectorDetailsPage.css';
import InputAdornment from '@mui/material/InputAdornment';
import { Button } from '@mui/material';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import ShareIcon from '@mui/icons-material/Share';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import imageDetailsPageLogo from '../../../assets/logo/WorldArchivesVectorDark.png';
import { useSelector, useDispatch } from 'react-redux';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { useParams, useNavigate } from 'react-router-dom';
import { searchResultsSideImage, getSearchResults } from '../../../api'
import { searchResultsById, addToCart, addToWishlist, searchResults } from '../../../features/search/searchActions'
import { Loader } from '../../common/Loader';
import ImageGallery from '../../reusable/ImageGallery/ImageGallery';
import DataTable from '../../common/DataTable';
import CurrencyInput from '../../reusable/Currency/Currency';

const idpSrchTextField = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: "100%",
    width: '100%',
    fontFamily: 'Open Sans',
    fontSize: '12px',
    "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
        outline: "none"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
        border: "none"
    },
    ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
        padding: '0px',
        height: '100%'
    },
    "@media (min-width : 481px) and (max-width : 767px)": {
        fontSize: '0.8rem'
    },
    "@media (min-width : 320px) and (max-width : 465px)": {
        '.css-8j6b76-MuiInputBase-root-MuiOutlinedInput-root': {
            fontSize: '0.8rem'
        },
        '.css-1pnmrwp-MuiTypography-root': {
            fontSize: '0.85rem',
            fontWeight: '600'
        }
    }
}

const idpSrchBtn = {
    backgroundColor: '#ED652B',
    color: 'white',
    width: 'fit-content',
    height: '100%',
    textTransform: 'none',
    marginRight: '10px',
    '&:hover': {
        background: '#ED652B'
    },
    "@media (min-width : 320px) and (max-width : 465px)": {
        fontSize: '0.8rem',
        width: '1rem'
    }
}

const VectorDetailsPage = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const dispatch = useDispatch()
    const user = localStorage.getItem('userInfo')
    const { searchIdData, loading } = useSelector(state => state.search)
    const img_url = process.env.REACT_APP_S3_HOST
    const [query, setQuery] = useState('');
    const [sideVectors, setSideVectors] = useState([]);
    const [similarVectors, setSimilarVectors] = useState([]);
    const onClickSaveIcon = () => {
        saveAs(`${img_url}/${searchIdData?.contentSizes[0]?.previewSrc}`, `${searchIdData?.contentSizes[0]?.displayName}${searchIdData?.contentSizes[0]?.format}`);
    }

    const onClickShare = async () => {
        let currentUrl = window.location.href
        await navigator.clipboard.writeText(currentUrl)
        toast.success('Copied to clipboard', { autoClose: 1500 })
    }

    const handleCart = async () => {
        await dispatch(addToCart({ id, resolution: searchIdData?.contentSizes[0]?.size }))
    }

    const handleWishlist = async () => {
        await dispatch(addToWishlist({ id, resolution: searchIdData?.contentSizes[0]?.size }))
    }

    function getInitials(name) {
        const words = name ? name?.split(' ') : "";
        const initials = [];
        for (const word of words) {
            if (word === "Mr." || word === "Dr." || word === "Ms." || word === "Mrs." || word === "Miss." || word === "Mx.") {
                continue;
            }
            initials.push(word[0]);
        }
        return initials.join('');
    }

    async function handleSubmit(event) {
        event.preventDefault();
        const searchTerm = decodeURIComponent(query.replace(/[^\w\s]/gi, function (c) {
            return '%' + c.charCodeAt(0).toString(16);
        }).replace(/\s+/g, '+'));
        navigate(`/search_results?keyword=${searchTerm}`)
        await dispatch(searchResults({ fieldTerm: query, selectTerm: 'VECTOR' }))
    }

    function loginFunction() {
        toast.info('Please login to add items to card', { autoClose: 1500 })
    }

    function wishlistLogin() {
        toast.info('Please login to wishlist media', { autoClose: 1500 })
    }

    const handleKeywordClick = async (keyword) => {
        localStorage.setItem('fieldTerm', keyword)
        navigate(`/search_results?keyword=${keyword}`)
        await dispatch(searchResults({ fieldTerm: keyword, selectTerm: 'VECTOR' }))
    }

    const initials = getInitials(searchIdData && searchIdData?.contributorName);
    const category = searchIdData?.category ? searchIdData?.category : ''
    const photoFormats = searchIdData && searchIdData?.mediainfo ? searchIdData?.mediainfo : {}
    const resolution = photoFormats && photoFormats?.resolution ? photoFormats?.resolution : ""

    useEffect(() => {
        dispatch(searchResultsById({ Id: id }))
        window.scrollTo(0, 0)
    }, [dispatch, id])

    useEffect(() => {
        const fetchSideVectors = async () => {
            const tenthKeyword = searchIdData?.keywords[9]
            const sideVectors = await searchResultsSideImage({ selectTerm: 'VECTOR', limit: 3, keywords: tenthKeyword });
            setSideVectors(sideVectors);
        };

        const fetchSimilarVectors = async () => {
            const firstFiveWords = searchIdData?.keywords?.slice(0, 5)
            const similarVectors = await getSearchResults({ selectTerm: 'VECTOR', limit: 16, keywords: firstFiveWords });
            setSimilarVectors(similarVectors);
        };

        fetchSideVectors();
        fetchSimilarVectors();
    }, [searchIdData?.keywords]);

    const handleViewAllClick = async (e) => {
        localStorage.setItem('fieldTerm', '')
        navigate(`/search_results`)
        await dispatch(searchResults({ fieldTerm: searchIdData?.keywords[9], selectTerm: 'IMAGE' }))
    }

    const handleSideImageClick = async (e) => {
        const id = e.currentTarget.getAttribute('id');
        await dispatch(searchResultsById({ Id: id }))
        navigate(`/details_image/${id}`)
    }

    return (
        <div className='vectorDetailsPage'>
            <Navbar menuItemStyles="vectorDetailsPage-menu-items" companyLogo={imageDetailsPageLogo} cartColor="#666666" logoStyles="navbar-logoStyle" hamburgerColor="#000000" />
            <div className='vectorDetailsPage-container'>
                <form onSubmit={handleSubmit}>
                    <div className='vectorDetailsPage-container-searchBar'>
                        <TextField
                            fullWidth
                            placeholder={`Try searching for Vector`}
                            value={query}
                            onChange={(event) => setQuery(event.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        Vector
                                    </InputAdornment>
                                ),
                            }}
                            sx={idpSrchTextField}
                        />
                        <Button
                            onClick={handleSubmit}
                            sx={idpSrchBtn}
                        >Search</Button>
                    </div>
                </form>
                {loading ? <Loader /> :
                    <div className='vectorDetailsPage-container-views'>
                        <div className='vectorDetailsPage-container-views-image' style={{ backgroundImage: `url(${img_url}/${searchIdData?.contentSizes[0]?.previewSrc})` }}>
                            {searchIdData?.isPremium && <div className="vector-premium-tag">Premium</div>}
                        </div>
                        <div className='vectorDetailsPage-container-views-imageCollection'>
                            {sideVectors?.map((item, index) => {
                                if (index === 2) {
                                    return (
                                        <div key={index} className="image-overlay">
                                            <img id={item.id} src={`${img_url}/${item?.mediaSrc}`} alt="" />
                                            <div className="overlay-content">
                                                <span className="overlay-text" onClick={handleViewAllClick}>View All</span>
                                            </div>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <img
                                            key={index}
                                            id={item.id}
                                            src={`${img_url}/${item?.mediaSrc}`}
                                            alt=""
                                            onClick={handleSideImageClick}
                                            style={{ cursor: 'pointer' }}
                                        />
                                    ); 
                                }
                            })}
                        </div>
                    </div>}
                {!loading ?
                    <div className='vectorDetailsPage-container-optionsAndContentId' >
                        <div className='vectorDetailsPage-container-contentId'>
                            <span className='vector-contentId'>Content ID :- </span>
                            <span className='vector-contentId-value'>{id}</span>
                        </div>
                        <div className='vectorDetailsPage-container-options'>
                            <span className='vectorDetailsPage-options-icons' onClick={user && (user !== null || user !== undefined) ?
                                handleWishlist : wishlistLogin}>
                                <BookmarkBorderIcon fontSize='18px' />&nbsp;Wishlist
                            </span>
                            <span className='vectorDetailsPage-options-icons' onClick={onClickShare}>
                                <ShareIcon fontSize='18px' />&nbsp;Share
                            </span>
                            <span className='vectorDetailsPage-options-icons' onClick={onClickSaveIcon}>
                                <SaveAltIcon fontSize='18px' />&nbsp;Save
                            </span>
                            <button
                                onClick={user && (user !== null || user !== undefined) ? handleCart : loginFunction}
                                className='vector-addToCartBtn'
                            >Add to cart</button>
                        </div>
                    </div> : <></>}
                {!loading ? <>
                    <div className='vector-container-titleAndPrice'>
                        <div className='vector-detailsContent-commonSty'>
                            <span className='vector-heading'>Title</span>
                            <span className='vector-details'>{searchIdData && searchIdData?.title}</span>
                        </div>
                        <div className='vector-detailsContent-commonSty'>
                            <span className='vector-heading'>Price</span>
                            <div className='vector-detailsContent-price-selection'>
                                <CurrencyInput/>
                                <span className='idp-details-priceDesc idp-descAddSty'>{searchIdData && searchIdData?.price}</span>
                            </div>
                            {/* <span className='vector-details-priceDesc vector-descAddSty'>{searchIdData && `Rs.${searchIdData?.price}`}</span> */}
                        </div>
                    </div>
                    <div className='vector-container-desc'>
                        <span className='vector-heading'>Description</span>
                        <div className='vector-details-priceDesc vector-descAddSty'>
                            {searchIdData && searchIdData?.description}
                        </div>
                    </div>
                    <div className='vector-container-detailsCommonSty'>
                        <div className='vector-detailsContent-container-cmnSty'>
                            <div className='vector-detailsContent-commonSty'>
                                <span className='vector-heading'>Contributor</span>
                                <div className='vectorDetailsPage-contributor-details'>
                                    <span className='vector-contributor-nameInitials'>{initials}</span>
                                    <span className='vector-contributor-name'>{searchIdData && searchIdData?.contributorName}</span>
                                </div>
                            </div>
                            <div className='vector-container-cc'>
                                <span className='vector-heading'>Category</span>
                                <span className='vector-details'>{category && category[0].toUpperCase() + category.slice(1).toLowerCase()}</span>
                            </div>
                        </div>
                        <div className='vector-detailsContent-container-cmnSty'>
                            <div className='vector-detailsContent-commonSty'>
                                <span className='vector-heading'>Shoot Location</span>
                                <span className='vector-details'>{searchIdData && searchIdData?.shootLocation}</span>
                            </div>
                            <div className='vector-detailsContent-commonSty'>
                                <span className='vector-heading'>Type of Angle</span>
                                <span className='vector-details'>{searchIdData && searchIdData?.typeOfAngle}</span>
                            </div>
                        </div>
                    </div>
                    <div className='vector-container-detailsCommonSty'>
                        <div className='vector-detailsContent-container-cmnSty'>
                            <div className='vector-container-form'>
                                <span className='vector-heading'>Available Formats</span>
                                <span className='vector-details vector-details-addSty vector-details-addSty'>{photoFormats && `${photoFormats?.width} × ${photoFormats?.height} pixels • ${photoFormats?.imageWidthCm} × ${photoFormats?.imageHeightCm} cm • DPI ${photoFormats?.dpi} • ${photoFormats?.format}`}</span>
                                {/* <span className='vector-details vector-details-addSty vector-details-addSty'>1000 × 590 pixels • 8.5 × 5 cm • DPI 300 • JPG</span> */}
                            </div>
                            <div className='vector-detailsContent-commonSty'>
                                <span className='vector-heading'>Camera</span>
                                <span className='vector-details'>{searchIdData && searchIdData?.camera}</span>
                            </div>
                        </div>
                        <div className='vector-detailsContent-container-cmnSty'>
                            <div className='vector-detailsContent-commonSty'>
                                <span className='vector-heading'>Resolution</span>
                                <span className='vector-details'>{resolution && resolution[0].toUpperCase() + resolution.slice(1).toLowerCase()}</span>
                            </div>
                            <div className='vector-detailsContent-commonSty'>
                                <span className='vector-heading'>POV</span>
                                <span className='vector-details'>{searchIdData && searchIdData?.pov}</span>
                            </div>
                        </div>
                    </div>
                    <div className='vector-container-desc'>
                        <span className='vector-heading'>Keywords</span>
                        <div className='vector-details-priceDesc'>
                            {searchIdData?.keywords?.map((keyword, index) => {
                                return (
                                    <span onClick={() => handleKeywordClick(keyword)} className='vector-details-keywordsSty' key={index}>{keyword}</span>
                                )
                            })}
                        </div>
                    </div>
                    <DataTable mediaId={id} tableData={searchIdData && searchIdData?.contentSizes}/>
                </> : <></>}
                {/* <div className='imageDetailsPage-container-bundleDeals'>
                    <span className='vector-bundleDeals-heading'>Bundle Deals</span>
                </div> */}
                {!loading ? <div className='vectorDetailsPage-container-similarImages'>
                    <div className='vector-similarImages-heading'>Similar Images</div>
                    <ImageGallery imageArray={similarVectors} pageName="VECTOR" />
                </div> : <></>}
            </div>
            {
                !loading ? <Footer /> : <></>
            }
        </div>
    )
}

export default VectorDetailsPage;