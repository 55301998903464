import { createSlice } from "@reduxjs/toolkit";
import { userLogin, userGoogleLogin, userFacebookLogin, userLoginWithSSO, userSignup, userForgotPassword, userResetPassword, getUserProfileDetails, userLogout, getSubscriptions } from "./userActions"

// initialize userToken, userInfo from local storage
const userToken = localStorage.getItem('userToken')
    ? localStorage.getItem('userToken')
    : null
const userInfo = localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : null

const initialState = {
    loading: false,
    userInfo,
    userToken,
    error: null,
    success: false,
    signupSuccess: false,
    forgetPasswordSuccess: false,
    resetPasswordSuccess: false,
    userProfile: null,
    fbSuccess: false,
    googleSuccess: false,
    userSubscriptions: []
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        logout: (state) => {
            localStorage.clear()
            state.loading = false
            state.success = false
            state.userInfo = null
            state.userToken = null
            state.error = null
            state.userProfile = null
        },
        resetSignupSuccess: (state) => {
            state.signupSuccess = false
        } 
    },
    extraReducers: {
        [userLogin.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [userLogin.fulfilled]: (state, { payload }) => {
            state.success = true
            state.loading = false
            state.userInfo = JSON.stringify(payload?.data?.findUser)
            state.userToken = JSON.stringify(payload?.data?.tokenData?.token)
        },
        [userLogin.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },

        [userGoogleLogin.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [userGoogleLogin.fulfilled]: (state, { payload }) => {
            state.success = true
            state.googleSuccess = true
            state.loading = false
            state.userInfo = payload?.data?.user
            state.userToken = payload?.data?.tokenData?.token
        },
        [userGoogleLogin.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },

        [userFacebookLogin.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [userFacebookLogin.fulfilled]: (state, { payload }) => {
            state.success = true
            state.fbSuccess = true
            state.loading = false
            state.userInfo = payload?.data?.user
            state.userToken = payload?.data?.tokenData?.token
        },
        [userFacebookLogin.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },
        // user login with sso 
        [userLoginWithSSO.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [userLoginWithSSO.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.userInfo = payload
            state.userToken = payload?.userToken
        },
        [userLoginWithSSO.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },

        // register user
        [userSignup.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [userSignup.fulfilled]: (state) => {
            state.loading = false
            state.signupSuccess = true // registration successful
        },
        [userSignup.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },

        // forgot password
        [userForgotPassword.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [userForgotPassword.fulfilled]: (state) => {
            state.loading = false
            state.forgetPasswordSuccess = true
        },
        [userForgotPassword.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },

        // reset password
        [userResetPassword.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [userResetPassword.fulfilled]: (state) => {
            state.loading = false
            state.resetPasswordSuccess = true
        },
        [userResetPassword.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },
        //getUserProfileDetails
        [getUserProfileDetails.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [getUserProfileDetails.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.userProfile = payload
        },
        [getUserProfileDetails.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },

        //userLogout
        [userLogout.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [userLogout.fulfilled]: (state) => {
            localStorage.clear()
            state.loading = false
            state.success = false
            state.fbSuccess = false
            state.googleSuccess = false
            state.userInfo = null
            state.userToken = null
            state.error = null
            state.userProfile = null
            state.userSubscriptions = null
        },
        [userLogout.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },
        [getSubscriptions.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [getSubscriptions.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.userSubscriptions = payload
        },
        [getSubscriptions.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },

    },
});

export const { logout, resetSignupSuccess } = userSlice.actions

export default userSlice.reducer