import React, { useEffect, useState } from "react";
import './myAccountPage.css';
import { Navbar } from "../../common/Navbar";
import logoMain from '../../../assets/logo/WorldArchivesDark.png';
import { FcGoogle } from 'react-icons/fc';
import { BsFacebook } from 'react-icons/bs';
import { useDispatch, useSelector } from "react-redux";
import { updateUserProfileDetails, getUserProfileDetails } from "../../../features/users/userActions";
import 'react-toastify/dist/ReactToastify.css';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { RxCross2 } from 'react-icons/rx';
import { MenuItem, Select } from "@mui/material";
import Loader from "../../common/Loader/Loader";

const MyAccountPage = () => {
    const dispatch = useDispatch()
    const { userProfile, loading } = useSelector(state => state.user)
    const [showPassword, setShowPassword] = React.useState(false);
    const [disableFirstName, setDisableFirstName] = useState(true)
    const [disableLastName, setDisableLastName] = useState(true)
    const [disableGender, setDisableGender] = useState(true)
    const [disableDob, setDisableDob] = useState(true)
    const [disablePassword, setDisablePassword] = useState(true)
    const [dataToUpdate, setDataToUpdate] = useState({
        "firstName": userProfile?.first_name,
        "lastName": userProfile?.last_name,
        "gender": userProfile?.gender,
        "dob": userProfile?.dob,
        "password": userProfile?.password
    })

    const [fields, setFields] = useState({
        enableFirstName: false,
        enableLastName: false,
        enableGender: false,
        enableDob: false,
        enablePassword: false
    })

    const genderStyle = {
        fontFamily: 'Open Sans',
        fontSize: '13px',
        fontWeight: '600',
        color: ' #262626',
        border: '1px solid #D9DBE0',
        borderRadius: '5px',
        height: '35px',
        '& .MuiOutlinedInput-notchedOutline': {
            border: "#D9DBE0",
            outline: 'none',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            border: '#D9DBE0',
        },
        '@media (min-width : 481px) and (max-width : 767px)':{
            height:'28px',
            fontSize: '10px'
        }
    }

    React.useEffect(() => {
        dispatch(getUserProfileDetails())
    }, [dispatch])

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    const onClickFirstNameEditBtn = () => {
        setFields({ ...fields, enableFirstName: true })
        setDisableFirstName(false)
    }

    const onClickLastNameEditBtn = () => {
        setFields({ ...fields, enableLastName: true })
        setDisableLastName(false)
    }

    const onClickGenderEditBtn = () => {
        setFields({ ...fields, enableGender: true })
        setDisableGender(false)
    }

    const onClickDobEditBtn = () => {
        setFields({ ...fields, enableDob: true })
        setDisableDob(false)
    }

    const onClickPasswordEditBtn = () => {
        setFields({ ...fields, enablePassword: true })
        setDisablePassword(false)
    }

    const onClickFirstNameCancel = () => {
        setDisableFirstName(true)
        setFields({ ...fields, enableFirstName: false })
    }

    const onClickLastNameCancel = () => {
        setDisableLastName(true)
        setFields({ ...fields, enableLastName: false })
    }

    const onClickGenderCancel = () => {
        setFields({ ...fields, enableGender: false })
    }

    const onClickDobCancel = () => {
        setDisableDob(true)
        setFields({ ...fields, enableDob: false })
    }

    const onClickPasswordCancel = () => {
        setDisablePassword(true)
        setFields({ ...fields, enablePassword: false })
    }

    const handleUpdate = async (e) => {
        e.preventDefault()
        await dispatch(updateUserProfileDetails({ firstName: dataToUpdate?.firstName, lastName: dataToUpdate?.lastName, gender: dataToUpdate?.gender, dob: dataToUpdate?.dob, password: dataToUpdate?.password }))
        await dispatch(getUserProfileDetails())
        setFields({
            enableFirstName: false,
            enableLastName: false,
            enableGender: false,
            enableDob: false,
            enablePassword: false
        })
    }

    const profileIntials = `${userProfile?.first_name ? userProfile?.first_name.substring(0, 1)?.toUpperCase() : ''}${userProfile?.last_name ? userProfile?.last_name.substring(0, 1)?.toUpperCase() : ''}`

    const handleLoginType = (type) => {
        switch (type) {
            case 'GOOGLE':
                return <>Logged in Via&nbsp;<FcGoogle />&nbsp;Google Account</>
            case 'FACEBOOK':
                return <>Logged in Via&nbsp;<BsFacebook style={{ color: '#4267B2' }} />&nbsp;Facebook Account</>
            case 'EMAIL_PASSWORD':
                return <>Logged in Via&nbsp;Email and Password</>
        }
    }

    const dateOptions = { year: "numeric", month: "short", day: "numeric" };

    return (
        <div className="account">
            <Navbar menuItemStyles="navbar-menu-items" logoStyles="navbar-logoStyle" companyLogo={logoMain} cartColor="#666666" hamburgerColor='#000000' />
            <div className="account-container">
                <div className="account-heading">
                    My Account
                </div>
                <div className="account-profilePicture">
                    <span className="account-profilePicture-name">{profileIntials}</span>
                    {/* <button>Edit Profile Picture</button> */}
                </div>
                {loading ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}><Loader /></div> : <form onSubmit={handleUpdate}>
                    <div className="account-profileInfo">
                        <div className="account-profile-fieldsCommonSty">
                            <div className="account-profile-fieldNameCommonSty">First Name</div>
                            {fields?.enableFirstName === false ? <div className="account-profile-fieldDataCommonSty">{userProfile?.first_name ? userProfile?.first_name : ''}</div> :
                                <div className="account-profile-inputAndCancel-commonSty">
                                    <input
                                        className="account-editableInputFields"
                                        id="firstName"
                                        name="firstName"
                                        type="text"
                                        disabled={disableFirstName}
                                        onChange={(e) => setDataToUpdate({ ...dataToUpdate, firstName: e.target.value })}
                                        autoFocus
                                    />
                                    <span className="crossButtonSpan" onClick={onClickFirstNameCancel}><RxCross2 /></span>
                                </div>
                            }
                            <div className="account-profile-editCommonSty">
                                <span
                                    onClick={onClickFirstNameEditBtn}
                                    hidden={fields?.enableFirstName}
                                >Edit</span>
                            </div>
                        </div>
                        <div className="account-profile-fieldsCommonSty">
                            <div className="account-profile-fieldNameCommonSty">Last Name</div>
                            {fields?.enableLastName === false ? <div className="account-profile-fieldDataCommonSty">{userProfile?.last_name ? userProfile?.last_name : ''}</div> :
                                <div className="account-profile-inputAndCancel-commonSty">
                                    <input
                                        className="account-editableInputFields"
                                        id="lastName"
                                        name="lastName"
                                        type="text"
                                        disabled={disableLastName}
                                        onChange={(e) => setDataToUpdate({ ...dataToUpdate, lastName: e.target.value })}
                                        autoFocus

                                    />
                                    <span className="crossButtonSpan" onClick={onClickLastNameCancel}><RxCross2 /></span>
                                </div>
                            }
                            <div className="account-profile-editCommonSty">
                                <span
                                    onClick={onClickLastNameEditBtn}
                                    hidden={fields?.enableLastName}
                                >Edit</span>
                            </div>
                        </div>
                        <div className="account-profile-fieldsCommonSty">
                            <div className="account-profile-fieldNameCommonSty">UID</div>
                            <div className="account-profile-fieldDataCommonSty">{userProfile && userProfile?.id}</div>
                            <div style={{ width: '100%' }}></div>
                        </div>
                        <div className="account-profile-fieldsCommonSty">
                            <div className="account-profile-fieldNameCommonSty">Gender</div>
                            {fields?.enableGender === false ? <div className="account-profile-fieldDataCommonSty">{userProfile?.gender ? userProfile?.gender : ''}</div>
                                : <div className="account-profile-inputAndCancel-commonSty">
                                    <Select
                                        className="account-editableInputFields"
                                        disabled={disableGender}
                                        id="gender"
                                        name="gender"
                                        onChange={(e) => setDataToUpdate({ ...dataToUpdate, gender: e.target.value })}
                                        inputProps={{ "aria-label": "Without label" }}
                                        sx={genderStyle}
                                        placeholder='Select Gender'
                                    >
                                        {
                                            ['Male', 'Female', 'Prefer not to say'].map(el =>
                                                <MenuItem value={el} key={el}>{el}</MenuItem>
                                            )
                                        }
                                    </Select>
                                    <span className="crossButtonSpan" onClick={onClickGenderCancel}><RxCross2 /></span>
                                </div>
                            }
                            <div className="account-profile-editCommonSty">
                                <span
                                    onClick={onClickGenderEditBtn}
                                    hidden={fields?.enableGender}
                                >Edit</span>
                            </div>
                        </div>
                        <div className="account-profile-fieldsCommonSty">
                            <div className="account-profile-fieldNameCommonSty">Date of Birth</div>
                            {fields?.enableDob === false ? <div className="account-profile-fieldDataCommonSty">{userProfile && (new Date(userProfile?.dob)).toLocaleString('en-US', dateOptions)}</div>
                                : <div className="account-profile-inputAndCancel-commonSty">
                                    <input
                                        className="account-editableInputFields"
                                        id="dob"
                                        name="dob"
                                        type="date"
                                        disabled={disableDob}
                                        onChange={(e) => setDataToUpdate({ ...dataToUpdate, dob: e.target.value })}
                                        autoFocus
                                        placeholder={new Date()}
                                        max={new Date().toISOString().split("T")[0]}
                                    />
                                    <span className="crossButtonSpan" onClick={onClickDobCancel}><RxCross2 /></span>
                                </div>
                            }
                            <div className="account-profile-editCommonSty">
                                <span
                                    onClick={onClickDobEditBtn}
                                    hidden={fields?.enableDob}
                                >Edit</span>
                            </div>
                        </div>
                        <div className="account-profile-fieldsCommonSty">
                            <div className="account-profile-fieldNameCommonSty">Username</div>
                            <div className="account-profile-fieldDataCommonSty">{userProfile && userProfile?.email}</div>
                            <div className="account-profile-emptyDiv"></div>
                        </div>
                        <div className="account-profile-fieldsCommonSty" style={{ border: '0px' }}>
                            <div className="account-profile-fieldNameCommonSty">Password</div>
                            {fields?.enablePassword === false ? <div className="account-profile-fieldDataCommonSty">**************</div>
                                : <div className="account-profile-inputAndCancel-commonSty">
                                    <OutlinedInput
                                        name="password"
                                        placeholder='Enter new Password'
                                        id="password"
                                        type={showPassword ? 'text' : 'password'}
                                        disabled={disablePassword}
                                        onChange={(e) => setDataToUpdate({ ...dataToUpdate, password: e.target.value })}
                                        autoFocus
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '35px',
                                            width: '100%',
                                            border: "1px solid #D9DBE0",
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                border: "none",
                                                outline: "none"
                                            },
                                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                                border: "none"
                                            },
                                        }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff style={{ fontSize: "1.3rem", color: "#666666" }} /> : <Visibility style={{ fontSize: "1.3rem", color: "#666666" }} />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                    <span className="crossButtonSpan" onClick={onClickPasswordCancel}><RxCross2 /></span>
                                </div>
                            }
                            <div className="account-profile-editCommonSty">
                                <span
                                    onClick={onClickPasswordEditBtn}
                                    hidden={fields?.enablePassword}
                                >Edit</span>
                            </div>
                        </div>
                    </div>
                    <div className="account-pupdAndAccDetails">
                        <div className="account-signInAccDetails">
                            {handleLoginType(userProfile?.type)}
                        </div>
                        <button>Update</button>
                    </div>
                </form>}
            </div>
        </div>
    )
}

export default MyAccountPage;