import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import './imageGallery.css';
import { searchResultsById } from '../../../features/search/searchActions'
import { Loader } from "../../common/Loader";

const ImageGallery = ({ imageArray, pageName }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const img_url = process.env.REACT_APP_S3_HOST
    let imgArr = []
    imageArray?.map(el => imgArr.push(Object.values(el)))

    const handleClick = async (e) => {
        const id = e.currentTarget.getAttribute('id');
        await dispatch(searchResultsById({ Id: id }))
        switch (pageName) {
            case "VFX":
                navigate(`/details_vfx/${id}`)
                break
            case "VECTOR":
                navigate(`/details_vector/${id}`)
                break
            case "IMAGE":
                navigate(`/details_image/${id}`)
                break
            case "VIDEO":
                navigate(`/details_video/${id}`)
                break
            case "MUSIC":
                navigate(`/details_music/${id}`)
                break
        }
        window.scrollTo(0, 0)
    }

    function Video({ previewSrc, videoSrc, id }) {
        const [isHovering, setIsHovering] = useState(false);
        const [playDuration, setPlayDuration] = useState(2);
        const videoRef = useRef(null);

        function handleTimeUpdate() {
            if (videoRef.current.currentTime >= playDuration) {
                videoRef.current.currentTime = 0;
                videoRef.current.play();
            }
        }

        function handleVideoEnd() {
            videoRef.current.currentTime = 0;
            videoRef.current.play();
        }

        function handleMouseOver() {
            setIsHovering(true);
            videoRef.current.play();
        }

        function handleMouseOut() {
            setIsHovering(false);
            videoRef.current.pause();
        }

        return (
            <div
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
            >
                {isHovering ? (
                    <video
                        id={id}
                        ref={videoRef}
                        width="100%"
                        height="100%"
                        src={videoSrc}
                        onClick={handleClick}
                        onTimeUpdate={handleTimeUpdate}
                        onEnded={handleVideoEnd}
                    />
                ) : (
                    <div className="imageGallery-tile" style={{ backgroundImage: `url(${previewSrc})` }} />
                )}
            </div>
        );
    }


    return (
        imgArr?.length === 0 ? imageArray?.length === 0 && imgArr?.length === 0 ? <p className="imageGallery-ndf">No Data Found</p> : <div style={{ margin: '6rem' }}><Loader /></div>
            : <div className="imageGallery">
                {
                    imageArray?.map((item) => {
                        return (
                            <div key={item.id} className="imageGallery-tile" id={item.id} style={{ backgroundImage: `url(${img_url}/${item.mediaSrc})` }} onClick={handleClick}>
                                {
                                    pageName === 'VIDEO' || pageName === 'VFX' ?
                                        <Video
                                            key={item}
                                            previewSrc={`${img_url}/${item.mediaSrc}`}
                                            videoSrc={`${img_url}/${item.previewSrc}`}
                                            id={item.id}
                                        /> : <></>
                                }
                            </div>


                        )
                    })
                }
            </div>
    )
}

export default ImageGallery;