import React from "react";
import PictureTiles from "../../reusable/PictureTiles/PictureTiles";
import Logo from '../../../assets/logo/WorldArchivesDark.png'
import { useForm } from "react-hook-form";
import Button from '../../reusable/Button'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userForgotPassword } from "../../../features/users/userActions";
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import './forgotPassword.css';
import { ToastContainer } from "react-toastify";

const ForgotPassword = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { forgetPasswordSuccess } = useSelector((state) => state.user)

    const onSubmit = (data) => {
        dispatch(userForgotPassword(data))
    }

    const onClickLogInButton = () => {
        navigate('/login')
    }

    const onClickGoBackToLogin = () => {
        navigate('/login')
    }

    return (
        <div className="forgotPassword">
            <div className="forgotPassword-form">
                <div>
                    <img src={Logo} alt="logo" className="forgotPassword-companyLogo" onClick={() => navigate('/')} />
                </div>
                <div className="forgotPassword-form-content">
                    {forgetPasswordSuccess ? <div className="forgotPassword-msg-container">
                        <span><MarkEmailReadIcon style={{ fontSize: '3rem', color: '#ed652b' }} /></span>
                        <span className="forgotPassword-success-msg fw-600">We've sent you an email with a link to reset your password.</span>
                        <span className="forgotPassword-spam-msg fw-500">If you do not see the email in your inbox, please check your spam or junk mail folder. If you still cannot find the email, please contact us for assistance.</span>
                        <button onClick={onClickGoBackToLogin}>Go back to login</button>
                        <div className="forgotPassword-form-support">
                            <span className="support_heading">Contact us on</span>
                            <span className="support_emailId">support@worldarchives.in</span>
                        </div>
                    </div>
                        :
                        <>
                            <span className='login-heading'>Forgot your password?</span>
                            <span className="forgotPassword-heading-subHeading">Enter your email address below and we'll send you a link to reset your password.</span>
                            <form className="forgotPassword-form-container" onSubmit={handleSubmit(onSubmit)}>
                                <div className='forgotPassword-form-emailTextfield'>
                                    <input
                                        className={errors.email ? 'forgotPassword-input outline-error' : 'forgotPassword-input'}
                                        placeholder='Email address'
                                        type="email"
                                        {...register("email",
                                            {
                                                required: "Email is required",
                                            })}
                                    />
                                    {<div className="forgotPassword-text-error">{errors.email ? `${'*'}${errors.email.message}` : null}</div>}
                                </div>
                                <div className="forgotPassword-submit-button">
                                    <Button btnStyle="button forgotPassword-submit" type="submit">Send reset link</Button>
                                </div>
                                <div className="forgotPassword-backOption">
                                    <span onClick={onClickLogInButton}>Go back to Login</span>
                                </div>
                            </form>
                        </>
                    }
                </div>
            </div>
            <ToastContainer limit={3} theme="colored" autoClose={1200} />
        </div>
    )
}

export default ForgotPassword;