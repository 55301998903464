import { Box, Modal } from "@mui/material";
import React from "react";
import './dashboardDetailsModal.css';
import { MdOutlineCancel } from 'react-icons/md';
import { useNavigate } from "react-router-dom";
import { useAdminPagesContext } from "../../../contexts/AdminPagesContext";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    outline: 'none',
    boxShadow: 24,
    padding: '1rem',
    borderRadius: '0.3rem'
};

const DashboardDetailsModal = ({ handleCloseDashboardDetailsModal, openDashboardDetailsModal, dashboardModalHeading }) => {
    const { handleTabsChange, setModifiedContent, setModalHeading } = useAdminPagesContext()
    const navigate = useNavigate()
    const handleImageClick = (e) => {
        handleTabsChange(e, 0)
        navigate('/cms/content')
        setModalHeading(dashboardModalHeading)
        setModifiedContent(true)
    }
    const handleVideoClick = (e) => {
        handleTabsChange(e, 1)
        navigate('/cms/content')
        setModalHeading(dashboardModalHeading)
        setModifiedContent(true)
    }
    return (
        <>
            <Modal
                open={openDashboardDetailsModal}
                onClose={handleCloseDashboardDetailsModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="dDModal">
                        <div className='dDModal-headingAndClose'>
                            <div className="dDModal-heading">
                                {`${dashboardModalHeading} Files`}
                            </div>
                            <div className='dDModal-close' onClick={handleCloseDashboardDetailsModal}>
                                <MdOutlineCancel fontSize="19px" />
                            </div>
                        </div>
                        <div className="dDModal-buttons">
                            <button onClick={handleImageClick}>Image</button>
                            <button onClick={handleVideoClick}>Video</button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    )
}

export default DashboardDetailsModal;