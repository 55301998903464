import React from 'react';
import AdminNavbar from '../../adminCommon/adminNavbar/AdminNavbar';
import AdminSidebar from '../../adminCommon/adminSidebar/AdminSidebar';
import './adminAnalytics.css';

const AdminAnalytics = () => {
    window.FreshworksWidget('hide')
    return (
        <div className='adminAnalytics'>
            <div className='adminAnalytics-navbar' >
                <AdminNavbar />
            </div>
            <div className="adminAnalytics-sidebarAndData">
                <div className="adminAnalytics-sidebar">
                    <AdminSidebar />
                </div>
                <div className="adminAnalytics-data">
                    <h1>Admin Analytics...</h1>
                </div>
            </div>
        </div>
    )
}

export default AdminAnalytics;