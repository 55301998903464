import React, { useEffect } from "react";
import { Home } from "./components/pages/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Login } from "./components/pages/Login";
import { SignUp } from "./components/pages/SignUp"
import { ForgotPassword } from "./components/pages/ForgotPassword";
import { ResetPassword } from "./components/pages/ResetPassword";
import ImagesPage from "./components/pages/ImagesPage/ImagesPage";
import VideosPage from "./components/pages/VideosPage/VideosPage";
import MusicPage from "./components/pages/MusicPage/MusicPage";
import VectorPage from "./components/pages/VectorPage/VectorPage";
import VfxPage from "./components/pages/VfxPage/VfxPage";
import ViewAllResultsPage from "./components/pages/ViewAllResultsPage/ViewAllResultsPage";
import ImagesDetailsPage from "./components/pages/ImagesDetailsPage/ImagesDetailsPage";
import VideosDetailsPage from "./components/pages/VideosDetailsPage/VideosDetailsPage";
import MusicsDetailsPage from "./components/pages/MusicsDetailsPage/MusicsDetailsPage";
import VfxDetailsPage from "./components/pages/VfxDetailsPage/VfxDetailsPage";
import VectorDetailsPage from "./components/pages/VectorDetailsPage/VectorDetailsPage";
import AdminLogin from './admin/adminPages/AdminLogin/AdminLogin';
import AdminContent from "./admin/adminPages/AdminContent/AdminContent";
import AdminCopyright from "./admin/adminPages/AdminCopyright/AdminCopyright";
import AdminAnalytics from "./admin/adminPages/AdminAnalytics/AdminAnalytics";
import AuditLogs from "./admin/adminPages/AuditLogs/AuditLogs";
import AdminPagesProvider from "./contexts/AdminPagesContext";
import Cart from "./components/pages/Cart/Cart";
import DownloadsPage from "./components/pages/DownloadsPage/DownloadsPage";
import MyAccountPage from "./components/pages/MyAccountPage/MyAccountPage";
import PHistoryPage from "./components/pages/PHistoryPage/PHistoryPage";
import WishlistPage from "./components/pages/WishlistPage/WishlistPage";
import PricingForPlans from "./components/pages/PricingForPlans/PricingForPlans";
import Mission from "./components/staticPages/mission/Mission";
import AboutUs from "./components/staticPages/aboutUs/AboutUs";
import Support from "./components/staticPages/support/Support";
import { useSelector } from 'react-redux';
import TermsAndConditions from "./components/staticPages/TermsAndConditions/TermsAndConditions";
import LicenseAgreement from "./components/staticPages/LicenceAgreement/LicenceAgreement";
import PrivacyPolicy from "./components/staticPages/PrivacyPolicy/PrivacyPolicy";
import { OrderSummary } from "./components/pages/OrderSummary"
import AdminDashboard from "./admin/adminPages/AdminDashboard/AdminDashboard";
import UserManagement from "./admin/adminPages/UserManagement/UserManagement";
import { PrivateRoutes, CmsPrivateRoutes, RedirectRoutes } from "./helpers/PrivateRoutes";
import DataDeletionPolicy from "./components/staticPages/DataDeletionPolicy/DataDeletionPolicy";
import EditorialPage from "./components/pages/EditorialsPage/Editorial";
import ExclusivePage from "./components/pages/ExclusivePage/ExclusivePage";
import PaymentForm from "./components/pageContent/CartCheckout/PaymentForm";
import SearchResultsPage from "./components/pages/SearchResultsPage/SearchResultsPage";
import JoinCommunity from "./seller/JoinCommunity/JoinCommunity";
import SellerPagesProvider from "./contexts/SellerPagesContext";
import Registration from "./seller/Registration/Registration";
import SellerDashboard from "./seller/sellerPages/SellerDashboard/SellerDashboard";
// import Settings from "./admin/adminPages/Settings/Settings";
import Settings from "./admin/adminPages/Settings/Settings";
import SellerMedia from "./seller/sellerPages/SellerMedia/SellerMedia";
import SellerEarnings from "./seller/sellerPages/SellerEarnings/SellerEarnings";

function App() {
  const loggedInUser = useSelector(state => state.user)
  const userInformation = loggedInUser?.userInfo ? loggedInUser?.userProfile : {}
  const name = userInformation ? userInformation?.name : '';
  const email = userInformation ? userInformation?.email : '';
  const UID = userInformation ? userInformation?.id : '';

  useEffect(() => {
    window.FreshworksWidget('identify', 'ticketForm', {
      name: name,
      email: email,
      'customFields.cf_uid': UID
    }, {
      formId: 1234 // Ticket Form ID
    });
  }, [userInformation]);

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/image" element={<ImagesPage />} />
          <Route path="/video" element={<VideosPage />} />
          <Route path="/vector" element={<VectorPage />} />
          <Route path="/music" element={<MusicPage />} />
          <Route path="/vfx" element={<VfxPage />} />
          <Route path="/editorial" element={<EditorialPage />} />
          <Route path="/exclusive" element={<ExclusivePage />} />
          <Route element={<PrivateRoutes />}>
            <Route path="/plan_pricing" element={<PricingForPlans />} />
            <Route path='/cart' element={<AdminPagesProvider><Cart /></AdminPagesProvider>} />
            <Route path="/payment/ccavenue/:id" element={<PaymentForm />} />
            <Route path="/downloads" element={<DownloadsPage />} />
            <Route path="/myAccount" element={<MyAccountPage />} />
            <Route path="/phistory" element={<PHistoryPage />} />
            <Route path="/wishlist" element={<WishlistPage />} />
            <Route path="/handle/payment" element={<OrderSummary />} />
          </Route>
          <Route path="/viewAll_results" element={<ViewAllResultsPage />} />
          <Route path="/search_results" element={<SearchResultsPage />} />
          <Route path="/details_image" element={<ImagesDetailsPage />} />
          <Route path="/details_image/:id" element={<ImagesDetailsPage />} />
          <Route path="/details_video" element={<VideosDetailsPage />} />
          <Route path="/details_video/:id" element={<VideosDetailsPage />} />
          <Route path="/details_music/:id" element={<MusicsDetailsPage />} />
          <Route path="/details_vfx/:id" element={<VfxDetailsPage />} />
          <Route path="/details_vector/:id" element={<VectorDetailsPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          {/* <Route path="/sso" element={<LoginWithSSO />} /> */}
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path='/cms/login' element={<AdminLogin />} exact />
          <Route element={<CmsPrivateRoutes />}>
            <Route path="/cms/dashboard" element={<AdminPagesProvider><AdminDashboard /></AdminPagesProvider>} />
            <Route path="/cms/content" element={<AdminPagesProvider><AdminContent /></AdminPagesProvider>} />
            <Route path="/cms/analytics" element={<AdminPagesProvider><AdminAnalytics /></AdminPagesProvider>} />
            <Route path="/cms/audit-logs" element={<AdminPagesProvider><AuditLogs /></AdminPagesProvider>} />
            <Route path="/cms/user-management" element={<AdminPagesProvider><UserManagement /></AdminPagesProvider>} />
            <Route path="/cms/copyrights" element={<AdminPagesProvider><AdminCopyright /></AdminPagesProvider>} />
            <Route path="/cms/settings" element={<AdminPagesProvider><Settings /></AdminPagesProvider>} />
          </Route>
          <Route path='/mission' element={<Mission />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/support" element={<Support />} />
          <Route path="/terms-conditions" element={<TermsAndConditions />} />
          <Route path="/licence-agreement" element={<LicenseAgreement />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/data-deletion-policy" element={<DataDeletionPolicy />} />

          {/* All the routes related to Seller */}
          <Route path="/seller" element={<SellerPagesProvider><JoinCommunity /></SellerPagesProvider>} />
          <Route path="/seller/registration" element={<SellerPagesProvider><Registration /></SellerPagesProvider>} />
          <Route path="/seller/dashboard" element={<SellerDashboard />} />
          <Route path="/seller/media" element={<SellerPagesProvider><SellerMedia /></SellerPagesProvider>} />
          <Route path="/seller/earnings" element={<SellerPagesProvider><SellerEarnings /></SellerPagesProvider>} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
