import React, { useEffect, useState } from "react";
import PictureTiles from "../../reusable/PictureTiles/PictureTiles";
import './login.css';
import Logo from '../../../assets/logo/WorldArchivesDark.png';
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Button from '../../reusable/Button';
import { FcGoogle } from 'react-icons/fc';
import { BsFacebook } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux'
import { userLogin, userGoogleLogin, userFacebookLogin } from '../../../features/users/userActions'
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { OutlinedInput, IconButton, InputAdornment, MenuItem, Select } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, Modal } from '@mui/material';
import { sellerLogin } from "../../../features/seller/sellerAction";

const maxDeviceStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '30rem',
  bgcolor: 'background.paper',
  borderRadius: '10px',
  outline: 'none',
  boxShadow: 24,
  padding: '0.7rem 1.5rem',
  height: '35rem'
};

const loginPasswordStyle = {
  fontFamily: 'Open Sans',
  fontSize: '0.85rem',
  fontWeight: '600',
  border: '1px solid #c5c5c5',
  borderRadius: '5px',
  height: '2.4rem',
  width: '100%',
  padding: '0px 10px',
  '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
    fontFamily: 'Open Sans',
    padding: '0rem',
    fontWeight: '500',
    letterSpacing: '0px',
    color: '#000000'
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: "#C8C7C7",
    outline: 'none',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    border: '#C8C7C7',
  }
}

const loginOptionDesign = {
  fontFamily: 'Open Sans',
  fontSize: '13px',
  color: '#888888',
  border: '1px solid #C8C7C7',
  borderRadius: '5px',
  height: '2.4rem',
  width: '100%',
  '& .MuiOutlinedInput-notchedOutline': {
    border: "#C8C7C7",
    outline: 'none',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    border: '#C8C7C7',
  },
}

const Login = () => {
  const { success, error } = useSelector((state) => state.user)
  const [showMaxDevice, setShowMaxDevice] = useState(false)
  const [showPassword, setShowPassword] = React.useState(false)
  const [loginType, setLoginType] = useState('')
  const dispatch = useDispatch()
  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID
  const fbAppId = process.env.REACT_APP_FB_APP_ID
  const { register, handleSubmit, formState: { errors } } = useForm();
  const navigate = useNavigate()
  const { loginSuccess } = useSelector((state) => state.seller)

  const onClickSignUpForFree = () => {
    navigate('/signup')
  }

  const onSubmit = (data) => {
    if (loginType === 'user') {
      dispatch(userLogin(data))
    } else if (loginType === 'contributor') {
      dispatch(sellerLogin(data))
    }
  }

  const responseGoogle = (data) => {
    dispatch(userGoogleLogin(data.credential))
  }

  const responseFb = ({ accessToken }) => {
    dispatch(userFacebookLogin(accessToken))
  }

  const onClickForgotPassword = () => {
    navigate('/forgot-password')
  }

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleCloseMaxDevicePopup = () => {
    setShowMaxDevice(false)
  }

  useEffect(() => {
    error?.success === false && error?.message === 'Max Device Login Reach' && (error?.allLoginHistory).length
      ? setShowMaxDevice(true) : setShowMaxDevice(false)
  }, [error?.message === 'Max Device Login Reach'])


  useEffect(() => {
    if (success) setTimeout(() => navigate('/'), 2500)
    else if (loginSuccess) setTimeout(() => navigate('/seller/dashboard'), 2500)
    else return
  }, [loginSuccess, success, navigate])

  return (
    <GoogleOAuthProvider
      clientId={googleClientId}>

      <div className="login">
        <div className="login-form">
          <div>
            <img src={Logo} alt="logo" className="login-companyLogo" onClick={() => navigate('/')} />
          </div>
          <div className="login-form-content">
            <span className='login-heading'>Sign In</span>
            <div className="login-option">
              <Select
                placeholder='Select Login Type'
                sx={loginOptionDesign}
                value={loginType}
                onChange={(e) => setLoginType(e.target.value)}
                renderValue={loginType === "" ? () => 'Select Login Type' : null}
                displayEmpty
              >
                <MenuItem
                  key="user"
                  value="user"
                >
                  Login as a Buyer
                </MenuItem>
                <MenuItem
                  key="contributor"
                  value="contributor"
                >
                  Login as a Contributor
                </MenuItem>
              </Select>
            </div>
            <form className='login-form-container' onSubmit={handleSubmit(onSubmit)}>
              <div className="login-form-emailTextField">
                <input
                  className={errors.email ? 'login-input outline-error' : 'login-input'}
                  placeholder='Email address'
                  type="email"
                  {...register("email",
                    {
                      required: "Email is required",
                    })}
                />
                {<div className="login-text-error">{errors.email ? `${'*'}${errors.email.message}` : null}</div>}
              </div>
              <div className="login-form-passwordTextField login-passwordInp-addSty">
                <OutlinedInput
                  className={errors.password ? 'login-input outline-error' : 'login-input'}
                  {...register("password", {
                    required: "Password is required"
                  })}
                  placeholder='Enter Password'
                  type={showPassword ? 'text' : 'password'}
                  autoFocus
                  sx={loginPasswordStyle}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff style={{ fontSize: "1.3rem", color: "#666666", marginRight: '0.2rem' }} /> : <Visibility style={{ fontSize: "1.3rem", color: "#666666", marginRight: '0.2rem' }} />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {<div className="login-text-error">{errors.password ? `${'*'}${errors.password.message}` : null}</div>}
              </div>
              <div className='login-submit'>
                <span className='login-link' onClick={onClickForgotPassword}>Forgot password?</span>
                <Button btnStyle="button" style={{ float: 'right' }} type="submit">Sign In</Button>
              </div>
            </form>
            {loginType === 'user' ? <div className="login-wrapper">
              <div className="login-line"></div>
              <div className="login-line-text">
                Or
              </div>
              <div className="login-line"></div>
            </div> : <></>}
            {loginType === 'user' ? <div className="login-btn-container">
              <GoogleLogin
                clientId={googleClientId}
                render={renderProps => (
                  <Button onClick={renderProps.onClick} disabled={renderProps.disabled}><FcGoogle style={{ width: '100%', fontSize: '20px', marginRight: '10px' }} /> Sign in with Google</Button>
                )}
                buttonText="Login"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={'single_host_origin'}
              />
              <FacebookLogin
                appId={fbAppId}
                render={
                  renderProps => (
                    <Button onClick={renderProps.onClick} disabled={renderProps.disabled}><BsFacebook style={{ fontSize: '20px', marginRight: '5px', color: '#4267B2' }} />&nbsp;&nbsp;Sign in with Facebook </Button>
                  )
                }
                fields="name,email,picture"
                scope="public_profile,email"
                callback={responseFb}
              />
            </div> : <></>}
            {loginType === 'user' ? <div className='login-text'>
              <span style={{ color: '#888888' }}>New to worldarchives?</span>
              <span onClick={onClickSignUpForFree} className='login-form-redirection'>Sign up for free</span>
            </div> : <></>}
          </div>
        </div>

        <ToastContainer limit={3} theme="colored" autoClose={1200} />
        {error?.message === 'Max Device Login Reach' ? <Modal
          open={showMaxDevice}
          onClose={handleCloseMaxDevicePopup}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={maxDeviceStyle}>
            <div className='maxDevice'>
              <div className='maxDevice-heading'>
                Warning !
              </div>
              <div className='maxDevice-warningMsg fs-16 fw-600'>
                {error?.message}
              </div>
              <div className='maxDevice-emailAndUserId'>
                <div className='maxDevice-emailAndUserCmnSty'>
                  <span className='emailAndUserHeading_cmcSty fw-600'>Your UserId</span>
                  <span className='fw-600'>:</span>
                  <span className='emailAndUserData_cmnSty'>{error?.allLoginHistory[0]?.email}</span>
                </div>
              </div>
              <div className='maxDevice-deviceDetails'>
                <div className='dotdyali'>Details of the devices you are logged In :</div>
                {error?.allLoginHistory && error?.allLoginHistory?.map((deviceData, index) => {
                  const {
                    device,
                    browser,
                    loginAt
                  } = deviceData
                  return (
                    <div className='maxDevice-individualDeviceDetails' key={index}>
                      <div className='maxDevice-individualDetailsCommonSty'>
                        <span className='deviceDetails_headCmnSty'>Device Name</span>
                        <span>:</span>
                        <span className='deviceDetails_dataCmnSty'>{device}</span>
                      </div>
                      <div className='maxDevice-individualDetailsCommonSty'>
                        <span className='deviceDetails_headCmnSty'>Browser Name</span>
                        <span>:</span>
                        <span className='deviceDetails_dataCmnSty'>{browser}</span>
                      </div>
                      <div className='maxDevice-individualDetailsCommonSty'>
                        <span className='deviceDetails_headCmnSty'>Login Date</span>
                        <span>:</span>
                        <span className='deviceDetails_dataCmnSty'>{(new Date(loginAt)).toLocaleDateString()}</span>
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className='maxDevice-solutionMsg'>
                <span className='fw-600'>Note</span>
                <span>&nbsp;&nbsp;:-&nbsp;&nbsp;</span>
                <span>You have exceeded the maximum number of active devices. Please log out from above of the devices to solve the issue.</span>
              </div>
              <div className='maxDevice_closeBtn'>
                <button onClick={handleCloseMaxDevicePopup}>Close</button>
              </div>
            </div>
          </Box>
        </Modal> : null}
      </div>
    </GoogleOAuthProvider>
  )
}

export default Login;