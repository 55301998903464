import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Paper, Checkbox, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux'
import { adminContent } from '../../../features/adminSearch/adminSearchActions'
import { useAdminPagesContext } from '../../../contexts/AdminPagesContext';
import DeletePopup from './DeletePopup';
import PopupContainer from './PopupContainer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getAdminContentMediaById } from '../../../features/adminSearch/adminSearchActions'
import { truncateString } from '../../utils';
import './adminTable.css';
import Loader from '../../../components/common/Loader/Loader';
import AdminScrollTop from '../adminScrollTop/adminScrollTop';
import AddPriceModal from './AddPriceModal';

const tableHeadStyles = {
  fontFamily: 'Open Sans',
  fontSize: '0.87rem',
  fontWeight: '500'
}

const loaderStyleAdmin = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '40vh',
  marginLeft: '1000%'
}

const loaderStyleOther = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '40vh',
  marginLeft: '540%'
}

const noDataStyleAdmin = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '40vh',
  marginLeft: '850%',
  width: '250%'
}

const noDataStyleOther = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '40vh',
  marginLeft: '195%',
  width: '250%'
}

function stableSort(array) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { role } = useSelector(state => state.admin) ?? localStorage.getItem('loggedRole')
  const headCells = [
    {
      id: 'name',
      leftAlign: true,
      disablePadding: role === "admin" ? true : false,
      label: 'Title',
    },
    {
      id: 'contentId',
      leftAlign: false,
      disablePadding: false,
      label: 'Content ID',
    },
    {
      id: 'date',
      leftAlign: false,
      disablePadding: false,
      label: 'Date',
    },
    {
      id: 'status',
      leftAlign: false,
      disablePadding: false,
      label: 'Status',
    },
    {
      id: 'visibility',
      leftAlign: false,
      disablePadding: false,
      label: 'Visibility',
    },
    {
      id: 'user',
      leftAlign: false,
      disablePadding: false,
      label: 'Contributor',
    },
    {
      id: 'price',
      leftAlign: false,
      disablePadding: false,
      label: 'Price',
    },
    {
      id: 'Actions',
      leftAlign: false,
      disablePadding: false,
      label: 'Actions',
    }
  ];
  const { onSelectAllClick, numSelected, rowCount } = props;
  return (
    <TableHead>
      <TableRow>
        {role === "admin" ?
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </TableCell> : <></>}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.leftAlign ? 'left' : 'center'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sx={tableHeadStyles}
          >{headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function AdminTable() {
  const [openPriceModal, setOpenPriceModal] = React.useState(false);
  const {
    tabValue,
    openViewPopup,
    setOpenViewPopup,
    id,
    setId,
    viewCheck,
    setViewCheck,
    handleCloseViewPopup,
    handleContentView,
    modifiedContent,
    modalHeading,
    setSelectedIds,
    page,
    setPage
  } = useAdminPagesContext()
  const [openDeletePopup, setOpenDeletePopup] = useState(false)
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const dispatch = useDispatch()
  const { contentData, loading } = useSelector(state => state.adminSearch)
  const { role } = useSelector(state => state.admin) ?? localStorage.getItem('loggedRole')
  const rows = contentData && contentData.length ? contentData.map(el => el) : []
  useEffect(() => {
    if (modalHeading?.length === 0 && !modifiedContent) {
      switch (tabValue) {
        case 0:
          dispatch(adminContent({ pageName: 'IMAGE' }))
          break;
        case 1:
          dispatch(adminContent({ pageName: 'VIDEO' }))
          break;
        default:
          return
      }
    }
    else if (modalHeading?.length > 0 && modifiedContent) {
      switch (tabValue) {
        case 0:
          handleContentView(modalHeading, 'IMAGE')
          break;
        case 1:
          handleContentView(modalHeading, 'VIDEO')
          break;
      }
    }

  }, [tabValue, modalHeading])

  const handleOpenViewPopup = (e) => {
    setOpenViewPopup(true)
    setId(e.target.id)
    setViewCheck(true)
    dispatch(getAdminContentMediaById({ id: e.target.id }))
  }

  const handleOpenEditViewPopup = async (e) => {
    setOpenViewPopup(true)
    setId(e.target.id)
    setViewCheck(false)
    dispatch(getAdminContentMediaById({ id: e.target.id }))
  }

  const handleOpenDeletePopup = (e) => {
    setOpenDeletePopup(true)
    setId(e.target.id)
  }

  const handleCloseDeletePopup = () => {
    setOpenDeletePopup(false)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.contentId);
      setSelected(newSelected);
      setSelectedIds(newSelected)
      return;
    }
    setSelected([]);
    setSelectedIds([])
  };

  const handleClick = (event, contentId) => {
    const selectedIndex = selected.indexOf(contentId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, contentId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
    setSelectedIds(newSelected)
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (contentId) => selected.indexOf(contentId) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const formatDate = (data) => {
    let newDate = new Date(data)
    const dateOptions = { year: "numeric", month: "short", day: "numeric", hour: '2-digit', minute: '2-digit' };
    return newDate.toLocaleString('en-US', dateOptions)
  }

  const applyStyle = (data) => {
    switch (data) {
      case "Accepted":
        return <span style={{
          background: '#97FFCD', padding: '4px 8px', borderRadius: '3px'
        }}>{data}</span>
      case "Rejected":
        return <span style={{
          background: '#FFCBCB', padding: '4px 8px', borderRadius: '3px'
        }}>{data}</span>
      default:
        return <span>{data}</span>;
    }
  }

  const img_url = process.env.REACT_APP_S3_HOST

  const tableCellAddSty = {
    padding: '12px 6px',
    fontFamily: 'Open Sans',
    fontSize: '0.775rem',
    fontWeight: '600'
  }

  const handleOpenPriceModal = (e) => {
    setId(e.target.id)
    setOpenPriceModal(true);
  }
  const handleClosePriceModal = () => setOpenPriceModal(false);

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%' }}>
        <TableContainer sx={{ minHeight: '290px', maxHeight: "100%", height: '100%' }}>
          <Table
            stickyHeader aria-label="sticky table"
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size='medium'
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            {loading ?
              <TableBody>
                <TableRow colSpan={4} >
                  <div style={role == "admin" ? loaderStyleAdmin : loaderStyleOther}><Loader /></div>
                </TableRow>
              </TableBody> :
              rows.length === 0 ?
                <TableBody>
                  <TableRow colSpan={4} >
                    <div style={role == "admin" ? noDataStyleAdmin : noDataStyleOther}>No Data Found</div>
                  </TableRow>
                </TableBody>
                :
                <TableBody>
                  {stableSort(rows)
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.contentId);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.contentId}
                          selected={isItemSelected}
                        >
                          {role === 'admin' ? <TableCell
                            padding="checkbox"
                          >
                            <Checkbox
                              onClick={(event) => handleClick(event, row.contentId)}
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId,
                              }}
                            />
                          </TableCell> : <></>}
                          <TableCell
                            sx={tableCellAddSty}
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            <>
                              <div className='adminTable_imageAndName'>
                                {row.status === "PROCESSING" ? <div style={{ fontSize: '11px', color: '#4946C3' }}>Processing...</div> :
                                  <img src={`${img_url}/${row.img}`} alt="" style={{ height: '30px', width: '30px', borderRadius: '5px', marginRight: '5px' }} />
                                }
                                {row.imgName}
                              </div>
                            </>
                          </TableCell>
                          <TableCell sx={tableCellAddSty} align="center" overflow="hidden">
                            <>
                              <Tooltip title={row.contentId} arrow placement='top'>
                                <span className='adminTable_contentId'>{truncateString(row.contentId, 16)}</span>
                              </Tooltip>
                            </>
                          </TableCell>
                          <TableCell sx={tableCellAddSty} align="center">{formatDate(row.date)}</TableCell>
                          <TableCell sx={tableCellAddSty} align="center">
                            <Tooltip title={row.status === "ACCEPTED" ? "" : row.reason} arrow placement="top">{row.status === "PROCESSING" ?
                              <div style={{ fontSize: '11px', color: '#4946C3' }}>Processing...</div> :
                              applyStyle(row.status ? (row.status).slice(0, 1).toUpperCase().concat((row.status).slice(1).toLowerCase()) : '')}
                            </Tooltip>
                          </TableCell>
                          <TableCell sx={tableCellAddSty} align="center">

                            {row.visibility === "SCHEDULED" ?
                              <Tooltip title={row.scheduleDate} arrow placement="top">
                                <span>{(row.visibility).slice(0, 1).toUpperCase().concat((row.visibility).slice(1).toLowerCase())}</span>
                              </Tooltip> :
                              row.visibility ?
                                <span>{(row.visibility).slice(0, 1).toUpperCase().concat((row.visibility).slice(1).toLowerCase())}</span> :
                                ''}
                          </TableCell>
                          <TableCell sx={tableCellAddSty} align="center">{row.user}</TableCell>
                          <TableCell sx={tableCellAddSty} align="center">
                            <button
                              id={row.id}
                              onClick={handleOpenPriceModal}
                              className="adminTable_actions-common at_actions-cmnSty">
                              Add Price
                            </button>
                          </TableCell>
                          <TableCell sx={tableCellAddSty} align='center'>
                            {role !== "scheduler" ? <button onClick={handleOpenViewPopup} className="adminTable_actions-common at_actions-cmnSty" id={row.id}>View</button> : <></>}
                            {role == "admin" || role == "scheduler" ? <button onClick={handleOpenEditViewPopup} className="adminTable_actions-common at_actions-cmnSty" id={row.id}>Edit</button> :
                              role !== "editor" && <button
                                onClick={handleOpenEditViewPopup}
                                className="adminTable_actions-common at_actions-cmnSty"
                                id={row.id}
                                disabled={row.visibility === 'PUBLISHED' ? true : false}
                              >
                                Edit
                              </button>}
                            {role == "admin" ? <button className="adminTable_actions-common" onClick={handleOpenDeletePopup} id={row.id}>Delete</button> : (role !== "metatagger" && role !== "scheduler") ?
                              <button
                                onClick={handleOpenDeletePopup}
                                className="adminTable_actions-common"
                                id={row.id}
                                disabled={(row.status === 'ACCEPTED' && row.visibility === 'PUBLISHED') ? true : false}
                              >
                                Delete
                              </button> : <></>}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>}
          </Table>
        </TableContainer>
        <TablePagination
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            '& .MuiTypography-root': {
              marginRight: '1rem',
            },
          }}
          rowsPerPageOptions={[100, 150, 200]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <PopupContainer open={openViewPopup} handleCloseViewPopup={handleCloseViewPopup} itemId={id} viewCheck={viewCheck} />
      <DeletePopup open={openDeletePopup} handleCloseDeletePopup={handleCloseDeletePopup} itemId={id} />
      <ToastContainer limit={3} theme="colored" autoClose={1200} />
      <AdminScrollTop />
      <AddPriceModal
        openPriceModal={openPriceModal}
        handleClosePriceModal={handleClosePriceModal}
        mediaId={id}
      />
    </Box>
  );
}