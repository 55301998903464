import React, { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';


export const PrivateRoutes = () => {
    let userToken = localStorage.getItem('userToken') ?? ''
    return (
        userToken?.length ? <Outlet /> : <Navigate to="/login" />
    )
}

export const CmsPrivateRoutes = () => {
    let adminToken = localStorage.getItem('adminToken') ?? ''
    return (
        adminToken?.length ? <Outlet /> : <Navigate to='/cms/login' />
    )
}




export function RedirectRoutes() {
    const cms_url = process.env.REACT_APP_BASE_CMS_URL;
    const redirectRoutes = {
        '/cms/login': `${cms_url}/login`,
        '/cms/dashboard': `${cms_url}/dashboard`,
        '/cms/content': `${cms_url}/content`,
        '/cms/analytics': `${cms_url}/analytics`,
        '/cms/audit-logs': `${cms_url}/audit-logs`,
        '/cms/user-management': `${cms_url}/user-management`,
        '/cms/copyrights': `${cms_url}/copyrights`,
    };
    const currentPath = window.location.pathname;
    const redirectTo = redirectRoutes[currentPath];

    useEffect(() => {
        if (redirectTo) {
            window.location.href = redirectTo;
        }
    }, [redirectTo]);

    return null;
}

