import React, { useState, useEffect } from "react";
import './index.css';
import { ImPriceTag } from 'react-icons/im';
import { Radio } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";

const tableHeading = ['Media Type', 'Dimension (Pixel)', 'File Format', 'Actual Price', 'Discount Price (Rs)*']

const DataTable = ({ mediaId, tableData }) => {
    const dispatch = useDispatch()
    const [selectedRowIndex, setSelectedRowIndex] = useState(-1);

    const handleRowSelection = (index) => {
        setSelectedRowIndex(index);
    };
    
    return (
        <div className="table">
            <div className="table-container">
                <div className="table-head table-rows-addStyle">
                    {tableHeading?.map((heading, index) => {
                        return (
                            <div key={index} className="table-cell">{heading}</div>
                        )
                    })}
                </div>
                {tableData?.map((data, index) => {
                    return (
                        <div key={index} className="table-data table-rows-addStyle">
                            <div className="table-cell">{data.size}</div>
                            <div className="table-cell">{data.dimension || "testing"}</div>
                            <div className="table-cell">{data.format}</div>
                            <div className="table-cell">{data.price || 0}</div>
                            <div className="table-cell-radioBtn">
                                <div className="table-cell-div">{<ImPriceTag style={{ color: 'red', fontSize: '1rem' }} />}&nbsp;{data.discount || 0}</div>
                                <div className="table-cell-div">
                                    <Radio
                                        checked={selectedRowIndex === index}
                                        onChange={() => handleRowSelection(index)}
                                        value={index}
                                        name="radio-button"
                                        inputProps={{ 'aria-label': 'Select Row' }}
                                        sx={{
                                            '& .MuiSvgIcon-root': {
                                                fontSize: 16,
                                            },
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    )
                })}
                <div className="table-instructionsAndBtn">
                    <div className="table-instruction">
                        <span>*Tax @18% as applicable will be charged extra.</span>
                        <span>**The image type 'WEB' can only be used for online media and cannot be used in any kind of print media or otherwise.</span>
                    </div>
                    <button className="table-btn">Add to Cart</button>
                </div>
            </div>
        </div>
    )
}

export default DataTable;