import axios from 'axios';
import React, { useEffect } from 'react';
import { useState } from 'react';
import AdminNavbar from '../../adminCommon/adminNavbar/AdminNavbar';
import AdminSidebar from '../../adminCommon/adminSidebar/AdminSidebar';
import './adminCopyright.css';

const AdminCopyright = () => {
    const [pageData, setPageData] = useState('')
    const getPageData = async () => {
        const adminToken = localStorage.getItem('adminToken')
        const base_url = process.env.REACT_APP_BASE_URL
        const config = {
            headers: {
                'Content-Type': 'text/html',
                "Authorization": `Bearer ${adminToken}`
            }
        }
        const { data } = await axios.get(`${base_url}/admin/copyright-page`, config)
        setPageData(data)
    }

    useEffect(() => {
        getPageData()
    }, [])

    window.FreshworksWidget('hide')

    return (
        <div className='adminCopyright'>
            <div className='adminCopyright-navbar' >
                <AdminNavbar />
            </div>
            <div className="adminCopyright-sidebarAndData">
                <div className="adminCopyright-sidebar">
                    <AdminSidebar />
                </div>
                <div className="adminCopyright-data">
                    {pageData}
                </div>
            </div>
        </div>
    )
}

export default AdminCopyright;