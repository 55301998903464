import React, { useEffect, useState } from "react";
import './adminContent.css';
import { useAdminPagesContext } from "../../../contexts/AdminPagesContext";
import { MenuItem, Modal, Select, Slider } from "@mui/material";
import { Box } from "@mui/system";
import AdminDatePicker from "./DatePicker";
import { adminContent } from '../../../features/adminSearch/adminSearchActions';
import { useDispatch } from "react-redux";
import axios from "axios";



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 520,
    height: 550,
    background: '#ffffff',
    borderRadius: '10px',
    outline: 'none',
    boxShadow: 24,
    padding: '1rem 1.5rem'
};

const resolutionStyle = {
    fontSize: '13px',
    color: '#888888',
    border: '1px solid #C8C7C7',
    borderRadius: '5px',
    height: '35px',
    minWidth: '340px',
    '& .MuiOutlinedInput-notchedOutline': {
        border: "#C8C7C7",
        outline: 'none',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        border: '#C8C7C7',
    }
}

const FilterPopup = (props) => {
    const { showFilterPopup, handleCloseFilterPopup } = props
    const dispatch = useDispatch()
    const [editMin, setEditMin] = useState(false);
    const [editMax, setEditMax] = useState(false);
    const {
        filterData,
        setFilterData,
        tabValue,
        startDate,
        handleStartDateChange,
        endDate,
        handleEndDateChange,
        handleMinChange,
        handleMaxChange,
        minMediaPrice,
        setMinMediaPrice,
        maxMediaPrice,
        setMaxMediaPrice,
        sliderValue,
        setSliderValue,
        setModalHeading
    } = useAdminPagesContext()
    const onSubmitFilterPopup = async () => {
        const { contentCategory, typeOfAngle, pov, shootLocation, resolution, videoDuration, mediaStatus, visibility } = filterData
        switch (tabValue) {
            case 0:
                await dispatch(adminContent({
                    contentCategory: contentCategory && contentCategory?.length ? contentCategory : undefined,
                    mediaStatus: mediaStatus && mediaStatus?.length ? mediaStatus : undefined,
                    visibility: visibility && visibility?.length ? visibility : undefined,
                    typeOfAngle: typeOfAngle && typeOfAngle?.length ? typeOfAngle : undefined,
                    pov: pov && pov?.length ? pov : undefined,
                    shootLocation: shootLocation && shootLocation?.length ? shootLocation : undefined,
                    resolution: resolution && resolution?.length ? resolution : undefined,
                    videoDuration: videoDuration > 0 ? videoDuration : undefined,
                    date_less: startDate,
                    date_more: endDate,
                    price_less: sliderValue[0],
                    price_more: sliderValue[1],
                    pageName: "IMAGE"
                }))
                break;
            case 1:
                await dispatch(adminContent({
                    contentCategory: contentCategory && contentCategory?.length ? contentCategory : undefined,
                    mediaStatus: mediaStatus && mediaStatus?.length ? mediaStatus : undefined,
                    visibility: visibility && visibility?.length ? visibility : undefined,
                    typeOfAngle: typeOfAngle && typeOfAngle?.length ? typeOfAngle : undefined,
                    pov: pov && pov?.length ? pov : undefined,
                    shootLocation: shootLocation && shootLocation?.length ? shootLocation : undefined,
                    resolution: resolution && resolution?.length ? resolution : undefined,
                    videoDuration: videoDuration > 0 ? videoDuration : undefined,
                    date_less: startDate,
                    date_more: endDate,
                    price_less: sliderValue[0],
                    price_more: sliderValue[1],
                    pageName: "VIDEO"
                }))
                break;

            default:
                break;
        }
        await handleCloseFilterPopup()
        setModalHeading('')
    }


    const handleSliderChange = (event, newValue) => {
        setSliderValue(newValue)
    }

    useEffect(() => {
        const getMinMaxPrice = async () => {
            const base_url = process.env.REACT_APP_BASE_URL
            const res = await axios.get(`${base_url}/setting`);
            const minPrice = res.data.data.settings.find(setting => setting.settingKey === 'MIN_MEDIA_PRICE')?.settingValue;
            const maxPrice = res.data.data.settings.find(setting => setting.settingKey === 'MAX_MEDIA_PRICE')?.settingValue;
            setSliderValue([parseInt(minPrice, 10), parseInt(maxPrice, 10)]);
            setMinMediaPrice(minPrice)
            setMaxMediaPrice(maxPrice)
        };
        getMinMaxPrice();
    }, []);

    const handleEditMin = () => {
        setEditMin(!editMin);
    };

    const handleEditMax = () => {
        setEditMax(!editMax);
    };

    return (
        <Modal
            open={showFilterPopup}
            onClose={handleCloseFilterPopup}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div className="adminContent-filterPopup">
                    {/* <form > */}
                    <div className="filterPopup-nameCommonSty">
                        <div className="filterPopup-headingCommonSty">Content category</div>
                        <input
                            placeholder="Add Category"
                            onChange={e => setFilterData({ ...filterData, contentCategory: e.target.value })}
                            name='contentCategory'
                            value={filterData?.contentCategory}
                        />
                    </div>
                    <div className="filterPopup-categoryInput">
                        <span>Media Status</span>
                        <Select
                            name="mediaStatus"
                            placeholder="Select Media Status"
                            onChange={e => setFilterData({ ...filterData, mediaStatus: e.target.value })}
                            displayEmpty
                            sx={resolutionStyle}
                            renderValue={filterData?.mediaStatus !== '' ? undefined : () => 'Select Media Status'}
                        >
                            {
                                ['PENDING', 'PROCESSING', 'ACCEPTED', 'REJECTED'].map(el =>
                                    <MenuItem value={el} key={el}>{el}</MenuItem>
                                )
                            }
                        </Select>
                    </div>
                    <div className="filterPopup-categoryInput">
                        <span>Visibility</span>
                        <Select
                            name="visibility"
                            placeholder="Select Visibility"
                            onChange={e => setFilterData({ ...filterData, visibility: e.target.value })}
                            displayEmpty
                            sx={resolutionStyle}
                            renderValue={filterData?.visibility !== '' ? undefined : () => 'Select Visibility'}
                        >
                            {
                                ['PUBLISHED', 'NOT_PUBLISHED', 'SCHEDULED'].map(el =>
                                    <MenuItem value={el} key={el}>{el}</MenuItem>
                                )
                            }
                        </Select>
                    </div>
                    <div className="filterPopup-nameCommonSty">
                        <div className="filterPopup-headingCommonSty">Type Of Angle</div>
                        <input
                            placeholder="Add Type Of Angle"
                            onChange={e => setFilterData({ ...filterData, typeOfAngle: e.target.value })}
                            name='typeOfAngle'
                        />
                    </div>
                    <div className="filterPopup-nameCommonSty">
                        <div className="filterPopup-headingCommonSty">POV</div>
                        <input
                            placeholder="Add POV"
                            onChange={e => setFilterData({ ...filterData, pov: e.target.value })}
                            name='pov'
                        />
                    </div>
                    <div className="filterPopup-nameCommonSty">
                        <div className="filterPopup-headingCommonSty">Shoot Location</div>
                        <input
                            placeholder="Add Shoot Location"
                            onChange={e => setFilterData({ ...filterData, shootLocation: e.target.value })}
                            name='shootLocation'
                        />
                    </div>
                    <div className="filterPopup-priceRange">
                        <span className="filterPopup-priceRange-heading">Pricing Range</span>
                        <div className="filterPopup-priceRange-valueSlider">
                            <div className="filterPopup-priceRange-value">
                                <div className="filterPopup-priceRange-valueCommonSty">
                                    <span className="priceRangeHeading">Min.</span>
                                    {editMin ? (
                                        <input
                                            type="number"
                                            value={sliderValue[0]}
                                            onChange={handleMinChange}
                                            onBlur={handleEditMin}
                                            className="minMax_inputField"
                                        />
                                    ) : (
                                        <span className="priceRangeValue" onClick={handleEditMin}>
                                            {sliderValue[0]}
                                        </span>
                                    )}
                                </div>
                                <div className="filterPopup-priceRange-valueCommonSty">
                                    <span className="priceRangeHeading">Max.</span>
                                    {editMax ? (
                                        <input
                                            className="minMax_inputField"
                                            type="number"
                                            value={sliderValue[1] === 0 ? 5000 : sliderValue[1]}
                                            onChange={handleMaxChange}
                                            onBlur={handleEditMax}
                                        />
                                    ) : (
                                        <span className="priceRangeValue" onClick={handleEditMax}>
                                            {sliderValue[1] === 0 ? 5000 : sliderValue[1]}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="filterPopup-priceRange-Slider">
                                <Slider
                                    sx={{
                                        '& .css-12rsbf4-MuiSlider-valueLabel': {
                                            fontSize: '10px',
                                            padding: '2px 10px'
                                        }
                                    }}
                                    min={minMediaPrice}
                                    max={maxMediaPrice === 0 ? "5000" : maxMediaPrice}
                                    step={500}
                                    value={sliderValue}
                                    size="small"
                                    onChange={handleSliderChange}
                                    valueLabelDisplay="auto"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="filterPopup-dateRange">
                        <span className="filterPopup-dateRange-heading">Date Range</span>
                        <div className="filterPopup-datePicker">
                            <AdminDatePicker dateValue={startDate} handleDateChange={handleStartDateChange} />
                            <AdminDatePicker dateValue={endDate} handleDateChange={handleEndDateChange} />
                        </div>
                    </div>
                    <div className="filterPopup-categoryInput">
                        <span>Resolution</span>
                        <Select
                            name="resolution"
                            placeholder="Select Resolution"
                            onChange={e => setFilterData({ ...filterData, resolution: e.target.value })}
                            displayEmpty
                            sx={resolutionStyle}
                            renderValue={filterData?.resolution !== '' ? undefined : () => 'Select Resolution'}
                        >
                            {
                                ['HD', 'FULL HD', '2K', 'QHD', '4K', 'UHD', '6K', '8K'].map(el =>
                                    <MenuItem value={el} key={el}>{el}</MenuItem>
                                )
                            }
                        </Select>
                    </div>
                    <div className="filterPopup-categoryInput">
                        <span>Video Duration</span>
                        <Select
                            name="videoDuration"
                            onChange={e => setFilterData({ ...filterData, videoDuration: e.target.value })}
                            displayEmpty
                            placeholder="Select Video Duration"
                            sx={resolutionStyle}
                            renderValue={filterData?.videoDuration !== '' ? undefined : () => 'Select Video Duration'}
                        >
                            <MenuItem value={5}>Less than 5 minutes</MenuItem>
                            <MenuItem value={10}>Less than 10 minutes</MenuItem>
                            <MenuItem value={11}>More than 10 minutes</MenuItem>
                        </Select>
                    </div>
                    <div className='filterPopup-cancelApplyBtn'>
                        <button onClick={handleCloseFilterPopup} className='filterPopup-cancel'>Cancel</button>
                        <button className='filterPopup-apply' onClick={onSubmitFilterPopup}>Apply</button>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}

export default FilterPopup;