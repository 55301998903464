import React from "react";
import './adminTable.css';
import { AiFillCheckCircle } from 'react-icons/ai';
import { MdCancel } from 'react-icons/md';
import { useAdminPagesContext } from "../../../contexts/AdminPagesContext";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Modal, Box } from '@mui/material';
import { MdOutlineCancel } from 'react-icons/md';
import { adminContent, adminContentReject, editAdminContentMedia } from '../../../features/adminSearch/adminSearchActions';
import Loader from "../../../components/common/Loader/Loader";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    border: '1px solid #ffffff',
    borderRadius: '5px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    outline: 'none',
    padding: '1rem 1.5rem'
};

const PopupChecks = () => {
    const [openRejectionModal, setOpenRejectionModal] = React.useState(false)
    const [reason, setReason] = React.useState('')
    const { activeStep, setActiveStep, tabValue, id, details, viewCheck, handleCloseViewPopup, setOpenViewPopup } = useAdminPagesContext()
    const { register, handleSubmit, formState: { errors } } = useForm();
    const dispatch = useDispatch()
    const { mediaData, loading } = useSelector(state => state.adminSearch)


    const onClickChecksNextBtn = () => {
        setActiveStep(activeStep + 1)
    }

    const onClickReject = () => {
        setOpenRejectionModal(true)
    }

    const handleCloseRejectModal = () => {
        setOpenRejectionModal(false)
    }
    const handleRejectSubmit = async () => {
        setOpenRejectionModal(false)
        setOpenViewPopup(false)
        await dispatch(adminContentReject({ id, reason }))
        tabValue === 0 ? await dispatch(adminContent({ pageName: 'IMAGE' })) : await dispatch(adminContent({ pageName: 'VIDEO' }))

    }

    const { role } = useSelector(state => state.admin) ?? localStorage.getItem('loggedRole')
    const aws_url = process.env.REACT_APP_S3_HOST

    const onSubmitDetails = async (data) => {
        let dataN = {
            ...data,
            id: mediaData.uid,
            contentCategory: data.contentCategory.split(',').map(item => item.trim()),
            mediaStatus: 'ACCEPTED',
            keywords: data.keywords.split(',').map(keyword => keyword.trim()),
            isPremium: details?.isPremium
        }
        await dispatch(editAdminContentMedia(dataN))
        tabValue === 0 ? await dispatch(adminContent({ pageName: 'IMAGE' })) : await dispatch(adminContent({ pageName: 'VIDEO' }))
        handleCloseViewPopup()
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmitDetails)}>
                {loading ? <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center', height: '50vh' }}><Loader /></div> :
                    <>
                        <div className="popup-contentInfo-heading">
                            Checks
                        </div>
                        <div className="popup-content">
                            <div className="popup-contentInfo">
                                <div className='popup-contentInfo-inputFields'>
                                    <div className='inputFieldsContainer_cmnSty'>
                                        <label className='headSpanCommonSty'>Title</label>
                                        <input
                                            className={errors.title ? "popup-inputCommonSty outline-error" : 'popup-inputCommonSty'}
                                            placeholder='Title'
                                            type="text"
                                            spellCheck="true"
                                            {...register("title", {
                                                required: "Title is required",
                                            })}
                                            value={details?.title ? details?.title : mediaData?.title}
                                            disabled={viewCheck}
                                        />
                                    </div>
                                    <div className='inputFieldsContainer_cmnSty'>
                                        <label className='headSpanCommonSty'>Description</label>
                                        <textarea
                                            className={errors.description ? 'popup-inputCommonSty checks_descriptionAddStyle outline-error' : "popup-inputCommonSty checks_descriptionAddStyle"}
                                            placeholder='Description'
                                            resize='none'
                                            spellCheck="true"
                                            {...register("description", {
                                                required: "Description is required"
                                            })}
                                            value={details?.description ? details?.description : mediaData?.description}
                                            disabled={viewCheck}
                                        />
                                    </div>
                                    <div className='inputFieldsContainer_cmnSty'>
                                        <label className='headSpanCommonSty'>Content Category</label>
                                        <input
                                            className={errors.contentCategory ? "popup-inputCommonSty outline-error" : "popup-inputCommonSty"}
                                            placeholder='Content category'
                                            type='text'
                                            spellCheck="true"
                                            {...register("contentCategory", {
                                                required: "Content category is required"
                                            })}
                                            value={details?.contentCategory ? details?.contentCategory : mediaData?.contentCategory}
                                            disabled={viewCheck}
                                        />
                                    </div>
                                    <div className='inputFieldsContainer_cmnSty'>
                                        <label className='headSpanCommonSty'>Contributor Name</label>
                                        <input
                                            className={errors.contributorName ? "popup-inputCommonSty outline-error" : "popup-inputCommonSty"}
                                            placeholder='Contributor Name'
                                            type='text'
                                            spellCheck="true"
                                            {...register("contributorName", {
                                                required: "Contributor name is required"
                                            })}
                                            value={details?.contributorName ? details?.contributorName : mediaData?.contributorName}
                                            disabled={viewCheck}
                                        />
                                    </div>
                                    <div className='inputFieldsContainer_cmnSty'>
                                        <label className='headSpanCommonSty'>POV</label>
                                        <input
                                            className={errors.pov ? 'popup-inputCommonSty outline-error' : 'popup-inputCommonSty'}
                                            placeholder='POV'
                                            type="text"
                                            spellCheck="true"
                                            {...register("pov", {
                                                required: "POV is required"
                                            })}
                                            value={details?.pov ? details?.pov : mediaData?.pov}
                                            disabled={viewCheck}
                                        />
                                    </div>
                                    <div className='inputFieldsContainer_cmnSty'>
                                        <label className='headSpanCommonSty'>Type of Angle</label>
                                        <input
                                            className={errors.typeOfAngle ? 'popup-inputCommonSty outline-error' : 'popup-inputCommonSty'}
                                            placeholder='Type of angle'
                                            type="text"
                                            spellCheck="true"
                                            {...register("typeOfAngle", {
                                                required: "Type of angle is required"
                                            })}
                                            value={details?.typeOfAngle ? details?.typeOfAngle : mediaData?.typeOfAngle}
                                            disabled={viewCheck}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="popup-contentPreview">
                                {tabValue === 0 && mediaData.category === 'IMAGE' ? <>
                                    <div className="popup-cp-imageAndTagContainer">
                                        <img src={`${aws_url}/${mediaData?.vidSrc}`} alt="" />
                                        {mediaData?.isPremium === true || details?.isPremium === true ? <div className="premium-tag">Premium</div> : <></>}
                                    </div>
                                    <div className="popup-contentPreview-mediaDataInfo">UID {mediaData.uid}</div>
                                    <span className="popup-contentPreview-mediaDataInfo">{mediaData?.fileName}</span></> :
                                    <>
                                        <div className="popup-cp-imageAndTagContainer">
                                            <video autoPlay height='150' width='100%' style={{ objectFit: 'fill' }} controls controlsList="nodownload">
                                                <source src={`${aws_url}/${mediaData?.vidSrc}`} />
                                            </video>
                                            {mediaData?.isPremium === true || details?.isPremium === true ? <div className="premium-tag">Premium</div> : <></>}
                                        </div>
                                        <div className="popup-contentPreview-mediaDataInfo">UID {mediaData.uid}</div>
                                        <span className="popup-contentPreview-mediaDataInfo">{mediaData?.fileName}</span>
                                    </>
                                }
                                <div className='popup-content-rowTwo'>
                                    <div className='inputFieldsContainer_cmnSty'>
                                        <label className='headSpanCommonSty'>Camera</label>
                                        <input
                                            className={errors.camera ? 'popup-inputCommonSty outline-error' : 'popup-inputCommonSty'}
                                            placeholder='Camera'
                                            type="text"
                                            spellCheck="true"
                                            {...register("camera", {
                                                required: "Camera is required"
                                            })}
                                            value={details?.camera ? details?.camera : mediaData?.camera}
                                            disabled={viewCheck}
                                        />
                                    </div>
                                    <div className='inputFieldsContainer_cmnSty'>
                                        <label className='headSpanCommonSty'>Shoot Location</label>
                                        <input
                                            className={errors.shootLocation ? 'popup-inputCommonSty outline-error' : 'popup-inputCommonSty'}
                                            placeholder='Shoot Location'
                                            spellCheck="true"
                                            {...register("shootLocation", {
                                                required: "Shoot Location is required"
                                            })}
                                            value={details?.shootLocation ? details?.shootLocation : mediaData?.shootLocation}
                                            disabled={viewCheck}
                                        />
                                    </div>
                                    <div className='inputFieldsContainer_cmnSty'>
                                        <label className='headSpanCommonSty'>Keywords</label>
                                        <textarea
                                            className={errors.keywords ? 'popup-inputKeyword-sty outline-error' : 'popup-inputKeyword-sty'}
                                            placeholder='Keywords'
                                            spellCheck="true"
                                            {...register("keywords", {
                                                required: "Keywords are required"
                                            })}
                                            value={details?.keywords ? details.keywords.join(', ') : mediaData?.keywords}
                                            disabled={viewCheck}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='popup-acceptRejectNextBtn'>
                            <div className='popup-acceptReject'>
                                {(!viewCheck && role !== "scheduler") && <button className='popup-commonBtbSty enableButton' type="submit">
                                    <AiFillCheckCircle style={{ fontSize: '25px', marginRight: '5px', color: '#0CA65C' }} />
                                    Accept
                                </button>}
                                {(!viewCheck) && <button type="button" className='popup-commonBtbSty enableButton' onClick={onClickReject} >
                                    <MdCancel style={{ fontSize: '24px', marginRight: '5px', color: '#FF2121' }} />
                                    Reject
                                </button>}

                            </div>
                            {!viewCheck && (role !== "metatagger") ?
                                <div style={{ display: 'flex', justifyContent: 'end' }}>
                                    {role === "scheduler" ? <></> :
                                        <button type="button" onClick={() => setActiveStep(activeStep - 1)} className='popupVisibility-backBtn' style={{ marginRight: '10px' }}>
                                            Back
                                        </button>}
                                    <button type="button" onClick={onClickChecksNextBtn} className='popup-nextBtnSty'>
                                        Next
                                    </button>
                                </div> :
                                <button type="button" onClick={() => setActiveStep(activeStep - 1)} className='popupVisibility-backBtn'>
                                    Back
                                </button>}
                        </div>
                    </>
                }
            </form>
            <Modal
                open={openRejectionModal}
                onClose={handleCloseRejectModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className='rejectPopup-headingCrossBtn'>
                        <div className="rejectPopup-heading">
                            Are you sure you want to reject ?
                        </div>
                        <div className='rejectPopup-close' onClick={handleCloseRejectModal}>
                            <MdOutlineCancel fontSize="19px" />
                        </div>
                    </div>
                    <textarea className='popup-inputCommonSty descriptionAddStyle' placeholder='Reason' resize='none' onChange={e => setReason(e.target.value)} />
                    <div className='rejectPopup-buttons'>
                        <button onClick={handleCloseRejectModal} className='rejectPopup-cancelBtn'>Cancel</button>
                        <button className='rejectPopup-rejectBtn' onClick={handleRejectSubmit}>Reject</button>
                    </div>
                </Box>
            </Modal>
        </>
    )
}

export default PopupChecks;