import React from "react";
import './sellerSidebar.css';
import { Box, CssBaseline, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import PaymentsIcon from '@mui/icons-material/Payments';


const drawerWidth = 200;
const drawerStyle = {
    width: drawerWidth,
    flexShrink: 0,
    [`& .MuiDrawer-paper`]: {
        width: drawerWidth,
        boxSizing: 'border-box',
    },
    '& .css-1nxmd3h-MuiListItem-root.Mui-selected': {
        background: '#F5F5F5',
        color: '#4946C3',
        borderLeft: '3px solid #4946C3',
        '& .css-i4bv87-MuiSvgIcon-root': {
            color: '#4946C3'
        }
    }
}

const SellerSidebar = () => {
    const navigate = useNavigate()
    const currentPath = window.location.pathname

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Drawer
                variant="permanent"
                sx={drawerStyle}
            >
                <Toolbar />
                <Box sx={{ overflow: 'auto' }}>
                    <List>
                        <ListItem disablePadding sx={currentPath === '/seller/dashboard' ? { borderLeft: '5px solid #4946c3', background: '#f5f5f5' } : null}>
                            <ListItemButton
                                onClick={() => {
                                    navigate('/seller/dashboard')
                                }}
                            >
                                <ListItemIcon sx={{ minWidth: '2rem', color: currentPath === '/seller/dashboard' ? '#4946c3' : '#666666' }}><AutoAwesomeMosaicIcon /></ListItemIcon>
                                <ListItemText primary='Dashboard' sx={currentPath === '/seller/dashboard' ? { color: '#4946c3' } : { color: '#666666' }} />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding sx={currentPath === '/seller/media' ? { borderLeft: '5px solid #4946c3', background: '#f5f5f5' } : null}>
                            <ListItemButton
                                onClick={() => {
                                    navigate('/seller/media')
                                }}
                            >
                                <ListItemIcon sx={{ minWidth: '2rem', color: currentPath === '/seller/media' ? '#4946c3' : '#666666' }}><PermMediaIcon /></ListItemIcon>
                                <ListItemText primary='Media' sx={currentPath === '/seller/media' ? { color: '#4946c3' } : { color: '#666666' }} />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding sx={currentPath === '/seller/earnings' ? { borderLeft: '5px solid #4946c3', background: '#f5f5f5' } : null}>
                            <ListItemButton
                                onClick={() => {
                                    navigate('/seller/earnings')
                                }}
                            >
                                <ListItemIcon sx={{ minWidth: '2rem', color: currentPath === '/seller/earnings' ? '#4946c3' : '#666666' }}><PaymentsIcon /></ListItemIcon>
                                <ListItemText primary='Earnings' sx={currentPath === '/seller/earnings' ? { color: '#4946c3' } : { color: '#666666' }} />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Box>
            </Drawer>
        </Box>
    )
}

export default SellerSidebar;