import React, { useEffect, useState } from 'react'
import './orderStatus.css'
import { useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from "react-toastify"
import { Navbar } from '../../common/Navbar';
import orderSummaryPageCompanyLogo from '../../../assets/logo/WorldArchivesDark.png';
import { Footer } from '../../common/Footer';

const OrderSummary = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const orderId = params.get('order_id');
    const pgType = params.get('payment_gateway_type')
    const [orderData, setOrderData] = useState(null)
    const base_url = process.env.REACT_APP_BASE_URL

    async function getOrderSummary(paymentGatewayOrderId, paymentGatewayType) {
        const userToken = localStorage.getItem('userToken')
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userToken}`
            }
        }
        const body = {
            paymentGatewayOrderId,
            paymentGatewayType
        }
        try {
            const { data } = await axios.post(`${base_url}/order/payment-gateway`, body, config)
            if (data) setOrderData(data?.data?.order)
            return data.data.order
        } catch (error) {
            toast.error(error.response.data.message)
        }
    }

    useEffect(() => {
        getOrderSummary(orderId, pgType)
    }, [orderId, pgType])

    return (
        <div className='orderSummary'>
            <Navbar menuItemStyles="orderSummary-menu-items" companyLogo={orderSummaryPageCompanyLogo} cartColor="#666666" logoStyles="navbar-logoStyle" hamburgerColor="#000000" />
            <div className='orderSummary-container'>
                <div className='oS-heading'>Order Summary</div>
                <div className='oS-details'>
                    <div className='oS-details-commonSty'>
                        <span className='oS-details-headStyCmn'>Email :</span>
                        <span>{orderData && orderData?.email}</span>
                    </div>
                    <div className='oS-details-commonSty'>
                        <span className='oS-details-headStyCmn'>Total Amount :</span>
                        <span>{orderData && orderData?.total}</span>
                    </div>
                    <div className='oS-details-commonSty'>
                        <span className='oS-details-headStyCmn'>Tax :</span>
                        <span>{orderData && orderData?.tax}</span>
                    </div>
                    <div className='oS-details-commonSty'>
                        <span className='oS-details-headStyCmn'>Final Price :</span>
                        <span>{orderData && orderData?.finalPrice}</span>
                    </div>
                </div>
                <Link to="/" className='oS-link-home'>Continue Shopping</Link>
            </div>
            <Footer />
        </div>
    )
}

export default OrderSummary