import React, { useEffect, useState } from 'react';
import './adminDashboard.css';
import { AiOutlineDownload } from 'react-icons/ai';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import { useSelector, useDispatch } from 'react-redux';
import { adminDashboard, adminDashboardDisplayContentNum } from '../../../features/admin/adminActions';
import { Loader } from '../../../components/common/Loader';
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { useAdminPagesContext } from '../../../contexts/AdminPagesContext';
import PopupContainer from '../../adminCommon/adminTable/PopupContainer';
import { getAdminContentMediaById } from '../../../features/adminSearch/adminSearchActions';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import VerifiedIcon from '@mui/icons-material/Verified';
import DashboardDetailsModal from '../../adminCommon/dashboardDetailsModal/DashboardDetailsModal';
import AdminNavbar from '../../adminCommon/adminNavbar/AdminNavbar';
import AdminSidebar from '../../adminCommon/adminSidebar/AdminSidebar';
import Switch from '@mui/material/Switch';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { CircularProgress, Tooltip } from '@mui/material'



const AdminDashboard = () => {
    const [isTotalContentChecked, setIsTotalContentChecked] = useState(JSON.parse(localStorage.getItem('toggle-button-checked')) || false);
    const [totalContent, setTotalContent] = useState(localStorage.getItem('total-content') || '');
    const [isActive, setIsActive] = useState(false);
    const { openViewPopup, handleCloseViewPopup, viewCheck, setViewCheck, setId, id, setOpenViewPopup } = useAdminPagesContext()
    const dispatch = useDispatch()
    const img_url = process.env.REACT_APP_S3_HOST
    const [openDashboardDetailsModal, setOpenDashboardDetailsModal] = useState(false)
    const [dashboardModalHeading, setDashboardModalHeading] = useState('')
    const { role } = useSelector(state => state.admin) ?? localStorage.getItem('loggedRole')

    const handleOpenViewPopup = (e) => {
        setOpenViewPopup(true)
        setId(e.target.id)
        setViewCheck(true)
        dispatch(getAdminContentMediaById({ id: e.target.id }))
    }

    const {
        loading,
        downloadCount,
        wishlistCount,
        publishedCount,
        unpublishedCount,
        publishedFiles,
        unpublishedFiles,
        approveMedia,
        rejectMedia,
        pendingMedia
    } = useSelector(state => state.admin)


    useEffect(() => {
        const getDashboardData = async () => {
            await dispatch(adminDashboard())
        }
        getDashboardData()
    }, [dispatch])

    const handleOpenDashboardDetailsModal = (event) => {
        if (event.target.id === 'unpublished') {
            setDashboardModalHeading('Unpublished')
            setOpenDashboardDetailsModal(true)
        } else if (event.target.id === 'published') {
            setDashboardModalHeading('Published')
            setOpenDashboardDetailsModal(true)
        } else if (event.target.id === 'pending') {
            setDashboardModalHeading('Pending')
            setOpenDashboardDetailsModal(true)
        } else if (event.target.id === 'approved') {
            setDashboardModalHeading('Approved')
            setOpenDashboardDetailsModal(true)
        } else if (event.target.id === 'rejected') {
            setDashboardModalHeading('Rejected')
            setOpenDashboardDetailsModal(true)
        }
    }

    const handleCloseDashboardDetailsModal = () => {
        setOpenDashboardDetailsModal(false)
    }

    const totalNoOfContentlabel = { inputProps: { 'aria-label': 'Switch demo' } };

    useEffect(() => {
        localStorage.setItem('toggle-button-checked', JSON.stringify(isTotalContentChecked));
        localStorage.setItem('total-content', totalContent);
    }, [isTotalContentChecked, totalContent]);

    const onClickToggleBtn = (e) => {
        if (e.target.checked) {
            setIsActive(true)
            dispatch(adminDashboardDisplayContentNum(totalContent))
            setIsTotalContentChecked(true)
        } else {
            setTotalContent('')
            setIsTotalContentChecked(false)
            setIsActive(false)
            dispatch(adminDashboardDisplayContentNum(""))
        }
    }


    window.FreshworksWidget('hide')

    return (
        <div className='adminDashboard'>
            <div className='adminDashboard-navbar'>
                <AdminNavbar />
            </div>
            <div className="adminDashboard-sidebarAndData">
                <div className="adminDashboard-sidebar">
                    <AdminSidebar />
                </div>
                <div className="adminDashboard-data">
                    <div className='adminDashboard-headingAndToggle'>
                        <div className='adminDashboard-heading'>
                            Dashboard
                        </div>
                        {role === 'admin' ? <div className='adminDashboard-toggle'>
                            <Tooltip title="Number to show total Content on the Website." arrow placement='top'>
                                <HelpOutlineIcon style={{ fontSize: '1rem', color: '#4946C3' }} />
                            </Tooltip>
                            <input
                                placeholder='Enter a number.'
                                value={totalContent}
                                onChange={(e) => {
                                    if (!isActive) {
                                        setIsActive(true)
                                    }
                                    setTotalContent(e.target.value)
                                }
                                }
                                type="number"
                            />
                            <Switch
                                checked={isTotalContentChecked}
                                size='small'
                                {...totalNoOfContentlabel}
                                onChange={onClickToggleBtn}
                                disabled={!isActive}
                            />
                        </div> : <div></div>}
                    </div>
                    <div className='adminDashboard-penAppRej'>
                        <div className='admDa-penAppRej-cmnSty'>
                            <PendingActionsIcon sx={{ color: '#077E8C' }} />
                            <div className='admDa-heading-commonSty'>Pending</div>
                            <div id='pending' className='admDa-count-commonSty' onClick={handleOpenDashboardDetailsModal}>{pendingMedia && pendingMedia !== undefined ? pendingMedia : <CircularProgress size={20} />}</div>
                        </div>
                        <div className='admDa-penAppRej-cmnSty'>
                            <VerifiedIcon sx={{ color: '#00FF00' }} />
                            <div className='admDa-heading-commonSty'>Accepted</div>
                            <div id='approved' className='admDa-count-commonSty' onClick={handleOpenDashboardDetailsModal}>{approveMedia && approveMedia !== undefined ? approveMedia : <CircularProgress size={20} />}</div>
                        </div>
                        <div className='admDa-penAppRej-cmnSty'>
                            <CancelRoundedIcon sx={{ color: 'red' }} />
                            <div className='admDa-heading-commonSty'>Rejected</div>
                            <div id='rejected' className='admDa-count-commonSty' onClick={handleOpenDashboardDetailsModal}>{rejectMedia && rejectMedia !== undefined ? rejectMedia : <CircularProgress size={20} />}</div>
                        </div>
                    </div>
                    {/* <div className='adminDashboard-downloadsWishlist'>
                <div className='admDa-commonSty'>
                    <AiOutlineDownload fontSize="23px" color='#5AC8CE' />
                    <div className='admDa-heading-commonSty'>Downloads</div>
                    <div className='admDa-count-commonSty'>{loading ? <Loader /> : downloadCount}</div>
                </div>
                <div className='admDa-commonSty'>
                    <TurnedInNotIcon style={{ fontSize: '23px', color: '#ED652B' }} />
                    <div className='admDa-heading-commonSty'>Wishlisted</div>
                    <div className='admDa-count-commonSty'>{loading ? <Loader /> : wishlistCount}</div>
                </div>
            </div> */}
                    <div className='adminDashboard-publishedUnpublished'>
                        <div className='adminDashboard-mainDivCommonSty'>
                            <div className='adDash-headingViewAllCommonSty'>
                                <div className='adDash-headingCommonSty'>
                                    <span>Published files</span>
                                    <span>({loading ? "..." : publishedCount})</span>
                                </div>
                                {publishedFiles?.length > 0 && <div id='published' className='adDash-viewAllCommonSty' onClick={handleOpenDashboardDetailsModal}>View All</div>}
                            </div>
                            {!loading ?
                                publishedFiles && publishedFiles?.length > 0 ? publishedFiles?.map((obj) => {
                                    return (<div className='adDash-contentCardCommonSty' key={obj.id}>
                                        <div className='adDash-contentCard-contentCommonSty'>
                                            <img src={`${img_url}/${obj.thumbnail}`} alt="" height="28px" width="28px" />
                                            <p>{obj?.title}</p>
                                        </div>
                                        <div className='adDash-contentCard-viewAll' id={obj.id} onClick={handleOpenViewPopup} style={{ cursor: "pointer" }}>
                                            View
                                        </div>
                                    </div>)
                                }) : <div className='txt-center' style={{ height: '50vh', marginTop: '6rem' }}>No Data Found</div>
                                : <h4>Loading</h4>}

                        </div>
                        <div className='adminDashboard-mainDivCommonSty'>
                            <div className='adDash-headingViewAllCommonSty'>
                                <div className='adDash-headingCommonSty'>
                                    <span>Unpublished files</span>
                                    <span>({loading ? "..." : unpublishedCount})</span>
                                </div>
                                {unpublishedFiles?.length > 0 && <div id='unpublished' className='adDash-viewAllCommonSty' onClick={handleOpenDashboardDetailsModal}>View All</div>}
                            </div>
                            {!loading ?
                                unpublishedFiles && unpublishedFiles?.length > 0 ? unpublishedFiles?.map((obj) => {
                                    return (<div className='adDash-contentCardCommonSty' key={obj.id}>
                                        <div className='adDash-contentCard-contentCommonSty'>
                                            <img src={`${img_url}/${obj.thumbnail}`} alt="" height="28px" width="28px" />
                                            <p>{obj?.title}</p>
                                        </div>
                                        <div className='adDash-contentCard-viewAll' id={obj.id} onClick={handleOpenViewPopup} style={{ cursor: "pointer" }}>
                                            View
                                        </div>
                                    </div>)
                                }) : <div className='txt-center' style={{ height: '50vh', marginTop: '6rem' }}>No Data Found</div>
                                : <h4>Loading</h4>
                            }
                        </div>
                    </div>
                    <PopupContainer open={openViewPopup} handleCloseViewPopup={handleCloseViewPopup} itemId={id} viewCheck={viewCheck} />
                    <ToastContainer limit={3} theme="colored" autoClose={1200} />
                    <DashboardDetailsModal dashboardModalHeading={dashboardModalHeading} handleCloseDashboardDetailsModal={handleCloseDashboardDetailsModal} openDashboardDetailsModal={openDashboardDetailsModal} />
                </div>
            </div>
        </div >

    )
}

export default AdminDashboard;