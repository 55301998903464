import React, { useEffect } from 'react';
import { Navbar } from '../../common/Navbar';
import './downloadsPage.css';
import logoMain from '../../../assets/logo/WorldArchivesDark.png';
import { RxImage } from 'react-icons/rx';
import SmartDisplayOutlinedIcon from '@mui/icons-material/SmartDisplayOutlined';
import { HiOutlineMusicNote } from 'react-icons/hi';
import { BsBox } from 'react-icons/bs';
import { BiDownload } from 'react-icons/bi';
import { ownedAssets } from '../../../features/search/searchActions';
import { useSelector, useDispatch } from 'react-redux';
import { Loader } from '../../common/Loader';
import { getDownloadAssetUrl } from '../../../api'

const DownloadsPage = () => {
    const dispatch = useDispatch()
    const { ownedAssetsData, loading } = useSelector(state => state.search)
    const aws_url = process.env.REACT_APP_S3_HOST
    const getIcon = (type) => {
        switch (type) {
            case "image":
                return <RxImage color="#666666" />
            case "video":
                return <SmartDisplayOutlinedIcon style={{ color: "#666666", fontSize: '18px' }} />
            case 'music':
                return <HiOutlineMusicNote color="#666666" />
            case 'vfx':
                return <BsBox color="#666666" />
            default:
                return <RxImage color="#666666" />
        }
    }
    const handleDownload = async (e) => {
        const Id = e.target.id
        const orderId = ownedAssetsData?.find(el => el.id === Id)?.orderId
        const orderItemId = ownedAssetsData?.find(el => el.id === Id)?.orderItemId
        const downloadUrl = await getDownloadAssetUrl({ orderId, orderItemId })
        window.open(downloadUrl, '_blank')
    }
    useEffect(() => {
        async function getAssets() {
            await dispatch(ownedAssets())
        }
        getAssets()
    }, [dispatch])

    const assestData = ownedAssetsData
    const dateOptions = { year: "numeric", month: "short", day: "numeric" };

    return (
        <div className="downloads">
            <Navbar menuItemStyles="navbar-menu-items" logoStyles="navbar-logoStyle" companyLogo={logoMain} cartColor="#666666" hamburgerColor="#000000" />
            <div className='downloads-container'>
                <div className='downloads-heading'>
                    {`Owned Assets(${assestData.length} items)`}
                </div>
                {!loading ?
                    <div className='downloads-content'>
                        {assestData && assestData?.length > 0 ? assestData?.map((data, index) => (

                            <div className="downloads-content-itemDetails" key={index}>
                                <div className="downloads-itemDetails-image">
                                    <img src={`${aws_url}/${data.thumbNail}`} alt="" />
                                </div>
                                <div className="downloads-itemDetails-info">
                                    <div className="downloads-itemDetails-nameFormat">
                                        <div className="downloads-itemDetails-name">
                                            {getIcon(data?.type)}
                                            <p>{data?.name}</p>
                                        </div>
                                        <div className="downloads-itemDetails-format">
                                            {data?.format}
                                        </div>
                                    </div>
                                    <div className="downloads-itemDetails-ResoLiceExp">
                                        {
                                            data?.type === 'music' ? null :
                                                <div className="downloads-itemDetails-Reso">
                                                    <p className="downloads-itemDetails-headCommonSty">Resolution</p>
                                                    <p className="downloads-itemDetails-headDataCommonSty">{data?.resolution}</p>
                                                </div>
                                        }
                                        <div className="downloads-itemDetails-Lice">
                                            <p className="downloads-itemDetails-headCommonSty">License</p>
                                            <p className="downloads-itemDetails-headDataCommonSty">standard</p>
                                        </div>
                                        <div className="downloads-itemDetails-Lice">
                                            <p className="downloads-itemDetails-headCommonSty">Expiry</p>
                                            <p className="downloads-itemDetails-headDataCommonSty">{new Date(data?.expiry).toLocaleString('en-US', dateOptions)}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="downloads-itemDetails-priceAndRemove">
                                    <button className="downloads-itemDetails-downloads" id={data?.id} onClick={handleDownload}><BiDownload fontSize={16} />&nbsp;&nbsp;Download</button>
                                </div>
                            </div>
                        )) : <div className="wishlist-noDataDiv">You haven't purchased any media</div>}
                    </div> :
                    <div style={{ height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Loader /></div>
                }

            </div>

        </div>
    )
}

export default DownloadsPage;