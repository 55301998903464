import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify"

const base_url = process.env.REACT_APP_BASE_URL
const ADMIN_URL = `${base_url}/admin`
const ADMIN_LOGIN_URL = `${ADMIN_URL}/login`
const ADMIN_DASHBOARD_URL = `${ADMIN_URL}/dashboard`
const ADMIN_DASHBOARD_CONTENT_NUMBER_URL = `${ADMIN_URL}/setting`


export const adminLogin = createAsyncThunk(
    'admin/adminLogin',
    async ({ email, password }, { rejectWithValue }) => {
        try {
            const body = { email: email, password: password }
            const { data } = await axios.post(ADMIN_LOGIN_URL, body)
            const role = (data?.data?.allRoles[0]?.name).replaceAll(' ', '').toLowerCase()
            const adminToken = data?.data?.tokenData?.token
            const adminInfo = data?.data?.findUser?.email
            localStorage.setItem('adminToken', adminToken)
            localStorage.setItem('adminInfo', JSON.stringify(adminInfo))
            localStorage.setItem('loggedRole', role)
            toast.success(`${data?.data?.allRoles[0]?.name} Login Successful`)
            return { role, adminToken, adminInfo }

        } catch (error) {
            if (error.response && error.response.data.message) {
                toast.error(error.response.data.message)
                return rejectWithValue(error.response.data.message)
            } else {
                toast.error(error.message)
                return rejectWithValue(error.message)
            }
        }
    }
)

export const adminDashboard = createAsyncThunk(
    'admin/adminDashboard',
    async (_, { rejectWithValue }) => {
        const adminToken = localStorage.getItem('adminToken')
        const config = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${adminToken}`
            }
        }
        try {
            const { data } = await axios.get(ADMIN_DASHBOARD_URL, config)
            const returnData = data?.data
            const downloadCount = returnData?.totalDownload
            const wishlistCount = returnData?.totalWishlist
            const publishedCount = returnData?.totalPublishedMedia
            const unpublishedCount = returnData?.totalUnPublishedMedia
            const approveMedia = returnData?.totalApproveMedia
            const rejectMedia = returnData?.totalRejectMedia
            const pendingMedia = returnData?.totalPendingMedia
            const publishedArr = returnData?.publishedMedia
            const unPublishedArr = returnData?.unPublishedMedia
            const publishedContentSizes = publishedArr?.map(el => el?.contentSizes)
            const unPublishedContentSizes = unPublishedArr?.map(el => el?.contentSizes)
            const publishedUrls = publishedContentSizes.map(el => el[0]?.thumbnailSrc)
            const unPublishedUrls = unPublishedContentSizes.map(el => el[0]?.thumbnailSrc)
            const finalPublished = publishedArr?.map((obj, idx) => ({
                id: obj.id,
                thumbnail: publishedUrls[idx],
                category: obj.category,
                title: obj.title,
                fileName: obj.fileName
            }))
            const finalUnPublished = unPublishedArr?.map((obj, idx) => ({
                id: obj.id,
                thumbnail: unPublishedUrls[idx],
                category: obj.category,
                title: obj.title,
                fileName: obj.fileName
            }))
            let finalObj = { downloadCount, unpublishedCount, wishlistCount, publishedCount, approveMedia, rejectMedia, pendingMedia, finalPublished, finalUnPublished }
            return finalObj
        } catch (error) {
            if (error.response && error.response.data.message) {
                toast.error(error.response.data.message)
                return rejectWithValue(error.response.data.message)
            } else {
                toast.error(error.message)
                return rejectWithValue(error.message)
            }
        }
    }
)

export const adminDashboardDisplayContentNum = createAsyncThunk(
    'admin/contentNumber',
    async (value, { rejectWithValue }) => {
        try {
            const adminToken = localStorage.getItem('adminToken')
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${adminToken}`
                }
            }
            await axios.put(ADMIN_DASHBOARD_CONTENT_NUMBER_URL, {
                key: "TOTAL_NO_OF_CONTENT",
                value: value
            }, config)
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const adminAddRole = createAsyncThunk(
    'admin/adminAddRole',
    async (roleData, { rejectWithValue }) => {
        try {
            const body = {
                email: roleData?.email,
                password: roleData?.password,
                roles: [roleData?.roles],
                status: true
            }
            const adminToken = localStorage.getItem('adminToken')
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${adminToken}`
                }
            }
            await axios.post(ADMIN_URL, body, config)
            toast.success('Role added successfully')
        } catch (error) {
            if (error.response && error.response.data.message) {
                toast.error(error.response.data.message)
                return rejectWithValue(error.response.data.message)
            } else {
                toast.error(error.message)
                return rejectWithValue(error.message)
            }
        }
    }
)

export const adminEditRole = createAsyncThunk(
    'admin/adminEditRole',
    async (roleData, { rejectWithValue }) => {
        try {
            const body = {
                id: roleData?.id,
                email: roleData?.email,
                password: roleData?.password,
                roles: [...roleData?.roles],
                status: true
            }
            const adminToken = localStorage.getItem('adminToken')
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${adminToken}`
                }
            }
            await axios.post(ADMIN_URL, config, body)
            toast.success('Role added successfully')
        } catch (error) {
            if (error.response && error.response.data.message) {
                toast.error(error.response.data.message)
                return rejectWithValue(error.response.data.message)
            } else {
                toast.error(error.message)
                return rejectWithValue(error.message)
            }
        }
    }
)

export const adminAuditLogs = createAsyncThunk(
    'admin/adminAuditLogs',
    async (_, { rejectWithValue }) => {
        const adminToken = localStorage.getItem('adminToken')
        const role = localStorage.getItem('loggedRole')
        const email = JSON.parse(localStorage.getItem('adminInfo'))

        const config = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${adminToken}`
            }
        }
        const body = {
            "search": {
                email: email
            },
            "nextKey": null
        }
        try {
            const { data } = await axios.post(`${ADMIN_URL}/audit-logs`, body, config)
            const resData = data?.data?.allLogs
            const logsData = resData?.map((obj) => ({
                userId: obj.email,
                role: role,
                contentId: obj.contentId,
                fields: obj.fields ?? [],
                actions: obj?.auditModule,
                actionType: obj?.auditAction,
                date: obj?.createdAt
            }))
            return logsData
        } catch (error) {
            if (error.response && error.response.data.message) {
                toast.error(error.response.data.message)
                return rejectWithValue(error.response.data.message)
            } else {
                toast.error(error.message)
                return rejectWithValue(error.message)
            }
        }
    }
)

export const adminAuditLogsSearch = createAsyncThunk(
    'admin/adminAuditLogsSearch',
    async (searchTerm, { rejectWithValue }) => {
        const adminToken = localStorage.getItem('adminToken')
        const role = localStorage.getItem('loggedRole')
        const config = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${adminToken}`
            }
        }
        const body = {
            "search": {
                "auditRole": searchTerm
            },
            "nextKey": null
        }
        try {
            const { data } = await axios.post(`${ADMIN_URL}/audit-logs`, body, config)
            const resData = data?.data?.allLogs
            const logsData = resData?.map((obj) => ({
                userId: obj.email,
                role: role,
                contentId: obj.contentId,
                fields: obj.fields ?? [],
                actions: obj?.auditModule,
                actionType: obj?.auditAction,
                date: obj?.createdAt
            }))
            return logsData
        } catch (error) {
            if (error.response && error.response.data.message) {
                toast.error(error.response.data.message)
                return rejectWithValue(error.response.data.message)
            } else {
                toast.error(error.message)
                return rejectWithValue(error.message)
            }
        }
    }
)

export const curatedContent = createAsyncThunk(
    'admin/curatedContent',
    async ({ role, email }, { rejectWithValue }) => {
        const adminToken = localStorage.getItem('adminToken')
        const config = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${adminToken}`
            }
        }

        try {
            const { data } = await axios.get(`${ADMIN_URL}/curated-matrix?role=${role}&email=${email}`, config)
            const returnData = data?.data
            const rolesData = returnData?.roles
            const userData = returnData?.emails
            const specificRoleActivity = rolesData.map(obj => obj?.activity)
            const specificUserActivity = userData.map(obj => obj?.activity)
            const totalActivity = Object.assign({}, specificRoleActivity, specificUserActivity)
            let mergedData = [];
            for (const key in totalActivity) {
                if (Array.isArray(totalActivity[key])) {
                    for (const item of totalActivity[key]) {
                        mergedData.push(item);
                    }
                }
            }
            return mergedData

        } catch (error) {
            if (error.response && error.response.data.message) {
                toast.error(error.response.data.message)
                return rejectWithValue(error.response.data.message)
            } else {
                toast.error(error.message)
                return rejectWithValue(error.message)
            }
        }
    }
)

export const curatedContentInitial = createAsyncThunk(
    'admin/curatedContentInitial',
    async (_, { rejectWithValue }) => {
        const adminToken = localStorage.getItem('adminToken')
        const config = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${adminToken}`
            }
        }

        try {
            const { data } = await axios.get(`${ADMIN_URL}/curated-matrix`, config)
            const returnData = data?.data
            const rolesData = returnData?.roles
            const userData = returnData?.emails
            const rolesList = rolesData?.map(el => el.role)
            const userList = userData?.map(el => el.email)
            const specificRoleActivity = rolesData.map(obj => obj.activity)
            const specificUserActivity = userData.map(obj => obj.activity)
            const totalActivity = Object.assign({}, specificRoleActivity, specificUserActivity)
            let mergedData = [];
            for (const key in totalActivity) {
                if (Array.isArray(totalActivity[key])) {
                    for (const item of totalActivity[key]) {
                        mergedData.push(item);
                    }
                }
            }
            const finalData = { mergedData, rolesList, userList }
            return finalData

        } catch (error) {
            if (error.response && error.response.data.message) {
                toast.error(error.response.data.message)
                return rejectWithValue(error.response.data.message)
            } else {
                toast.error(error.message)
                return rejectWithValue(error.message)
            }
        }
    }
)

export const getUserByUid = createAsyncThunk(
    'admin/getUserByUid',
    async (id, { rejectWithValue }) => {
        const adminToken = localStorage.getItem('adminToken')
        const config = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${adminToken}`
            }
        }
        try {
            const { data } = await axios.get(`${ADMIN_URL}/customer/${id}`, config)
            const resultData = data?.data
            const userData = resultData?.customerData
            return userData
        } catch (error) {
            console.log(error)
        }
    }
)

export const addContentCategory = createAsyncThunk(
    'admin/addContentCategory',
    async (formData, { rejectWithValue }) => {
        const adminToken = localStorage.getItem('adminToken')
        const config = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${adminToken}`
            }
        }
        const body = formData
        try {
            const { data } = await axios.post(`${ADMIN_URL}/stamp-category`, body, config)
            const resData = data
            toast.success(resData.message)
            return resData

        } catch (error) {
            if (error.response && error.response.data.message) {
                toast.error(error.response.data.message)
                return rejectWithValue(error.response.data.message)
            } else {
                toast.error(error.message)
                return rejectWithValue(error.message)
            }
        }
    }
)

export const getContentCategoryList = createAsyncThunk(
    'admin/getContentCategoryList',
    async (searchFormData, { rejectWithValue }) => {
        const adminToken = localStorage.getItem('adminToken')
        const config = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${adminToken}`
            }
        }
        const body = {
            "page_name": searchFormData
        }
        try {
            const { data } = await axios.post(`${ADMIN_URL}/stamp-category/search`, body, config)
            const searcResultData = data?.data
            return searcResultData
        } catch (error) {
            if (error.response && error.response.data.message) {
                toast.error(error.response.data.message)
                return rejectWithValue(error.response.data.message)
            } else {
                toast.error(error.message)
                return rejectWithValue(error.message)
            }
        }
    }
)

export const removeContentCategory = createAsyncThunk(
    'admin/removeContentCategory',
    async (contentCategoryId, { rejectWithValue }) => {
        const adminToken = localStorage.getItem('adminToken')
        const config = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${adminToken}`
            }
        }
        try {
            const { data } = await axios.delete(`${ADMIN_URL}/stamp-category/${contentCategoryId}`, config)
            toast.success(data?.message)
        } catch (error) {
            if (error.response && error.response.data.message) {
                toast.error(error.response.data.message)
                return rejectWithValue(error.response.data.message)
            } else {
                toast.error(error.message)
                return rejectWithValue(error.message)
            }
        }
    }
)

export const adminSettingsAddingKeywords = createAsyncThunk(
    'admin/addingKeywords',
    async ({ key, value }, { rejectWithValue }) => {
        try {
            const adminToken = localStorage.getItem('adminToken')
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${adminToken}`
                }
            }

            const response = await axios.get("https://api.worldarchives.in/setting", config);
            const settings = response?.data.data.settings;
            const popularKeywordsSetting = settings?.find(s => s.settingKey === key);
            if (popularKeywordsSetting?.settingKey !== key || popularKeywordsSetting?.settingKey === undefined) {
                const successResponse = await axios.put("https://api.worldarchives.in/admin/setting", {
                    key: key,
                    value: value
                }, config)

                successResponse?.data?.success === true ?
                    toast.success('Popular Category Keywords are updated') :
                    toast.error('Failed to update keywords')
            } else {
                const existingKeywords = popularKeywordsSetting?.settingValue;

                const updatedKeywords = [...existingKeywords, ...value];

                const successResponse = await axios.put("https://api.worldarchives.in/admin/setting", {
                    key: key,
                    value: updatedKeywords
                }, config)
                successResponse?.data?.success === true ?
                    toast.success('Popular Category Keywords are updated') :
                    toast.error('Something went wrong')
            }
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const adminSettingsUpdatingKeywords = createAsyncThunk(
    'admin/updatingKeywords',
    async ({ key, value }, { rejectWithValue }) => {
        try {
            const adminToken = localStorage.getItem('adminToken');
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${adminToken}`,
                },
            };

            const response = await axios.get('https://api.worldarchives.in/setting', config);
            const settings = response?.data?.data?.settings;
            const settingToUpdate = settings?.find((s) => s.settingKey === key);
            const existingKeywords = settingToUpdate?.settingValue || [];

            // Filter out the keywords that are already present in the existingKeywords
            const updatedKeywords = existingKeywords.filter(keyword => !value.includes(keyword));

            const successResponse = await axios.put('https://api.worldarchives.in/admin/setting', {
                key,
                value: updatedKeywords,
            }, config);

            if (successResponse?.data?.success === true) {
                toast.success('Keywords have been removed');
            } else {
                toast.error('Failed to update keywords');
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const getAdminSetting = createAsyncThunk(
    'admin/getSetting',
    async (settingKey, { rejectWithValue }) => {
        const adminToken = localStorage.getItem('adminToken');
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${adminToken}`,
            },
        };
        try {
            const response = await axios.get('https://api.worldarchives.in/setting', config);
            const settings = response?.data?.data?.settings || [];

            const setting = settings.find((s) => s.settingKey === settingKey);
            return setting?.settingValue;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);


export const getMediaSizes = createAsyncThunk(
    'admin/getMediaSizes',
    async (id, { rejectWithValue }) => {
        const adminToken = localStorage.getItem('adminToken')
        const config = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${adminToken}`
            }
        }

        try {
            const { data } = await axios.get(`${ADMIN_URL}/media/${id}`, config)
            const resultData = data?.data?.media
            const contentSizes = resultData?.contentSizes
            const mediaSizes = contentSizes?.map(item => item?.size)
            return mediaSizes
        } catch (error) {
            console.log(error.response.data.message)
            return rejectWithValue(error.response.data.message)
        }
    }
)

export const updateMediaPrices = createAsyncThunk(
    'admin/updateMediaPrices',
    async (payload, { rejectWithValue }) => {
        const adminToken = localStorage.getItem('adminToken')
        const config = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${adminToken}`
            }
        }
        try {
            const { data } = await axios.put(`${ADMIN_URL}/media`, payload, config)
            toast.success(data?.message)
        } catch (error) {
            if (error.response && error.response.data.message) {
                toast.error(error.response.data.message)
                return rejectWithValue(error.response.data.message)
            } else {
                toast.error(error.message)
                return rejectWithValue(error.message)
            }
        }
    }
)

