import React from 'react';
import ImageGallery from '../../reusable/ImageGallery/ImageGallery';
import './subHeading.css';
import { useDispatch } from 'react-redux';
import { getMediaSearchPagination } from '../../../features/search/searchActions';
import { useNavigate } from 'react-router-dom';

const SubHeading = ({ heading, requiredArray, pageName }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleClick = (e) => {
    dispatch(getMediaSearchPagination({ category: e.target.id, pageName }))
    navigate(`/viewAll_results?category=${heading}`)
  }

  return (
    <div className='subHeading'>
      <div className='subHeading-heading'>
        <span className='subHeading-heading-h1'>
          {heading}
        </span>
        <span className='subHeading-heading-viewAll' id={heading} onClick={handleClick}>
          View all
        </span>
      </div>
        <ImageGallery imageArray={requiredArray} pageName={pageName} />
    </div>
  )
}

export default SubHeading;