import React, { useState } from "react";
import './registration.css';
import { useNavigate } from "react-router-dom";
import PhoneInput from "../../components/reusable/PhoneInput/PhoneInput";
import company_logo from '../../assets/logo/WorldArchivesDark.png';
import PasswordInput from "../../components/reusable/PasswordInput/PasswordInput";
import { useDispatch, useSelector } from "react-redux";
import { sellerRegistration } from "../../features/seller/sellerAction";
import { ToastContainer } from "react-toastify";
import { Box, Modal } from "@mui/material";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 650,
    height: 'fit-content',
    bgcolor: 'background.paper',
    boxShadow: 24,
    padding: '1rem 1.5rem',
    outline: 'none',
    borderRadius: '10px'
};

const Registration = () => {
    const [openRegistrationConfirmMsgPopup, setOpenRegistrationConfirmMsgPopup] = useState(false)
    const formRenderOption = localStorage.getItem('selectedOption')
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [individualFormData, setIndividualFormData] = useState({
        sellerType: formRenderOption,
        firstName: '',
        lastName: '',
        phone: '',
        address: '',
        aadhaarCardDoc: null,
        panCardDoc: null,
        gstCertificateDoc: null,
        email: '',
        password: '',
    })

    const [companyFormData, setCompanyFormData] = useState({
        sellerType: formRenderOption,
        firstName: "",
        lastName: "",
        representativeName: '',
        companyName: '',
        phone: '',
        address: '',
        email: '',
        password: '',
        gstCertificateDoc: null,
        cinCertificateDoc: null,
        panCardDoc: null
    })

    const onClicklogin = () => {
        navigate('/login')
    }

    const onClickConShop = () => {
        navigate('/')
    }

    const handleIndividualInputChange = (event) => {
        const { name, value } = event.target;

        setIndividualFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleIndividualFileChange = (event) => {
        const { name, files } = event.target;

        setIndividualFormData((prevFormData) => ({
            ...prevFormData,
            [name]: files[0],
        }));
    };

    const handleCompanyInputChange = (event) => {
        const { name, value } = event.target;

        setCompanyFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleCompanyFileChange = (event) => {
        const { name, files } = event.target;

        setCompanyFormData((prevFormData) => ({
            ...prevFormData,
            [name]: files[0],
        }));
    };

    const onSubmitIndividualFormReg = async (e) => {
        e.preventDefault()
        await dispatch(sellerRegistration(individualFormData))

        setIndividualFormData({
            sellerType: '',
            firstName: '',
            lastName: '',
            phone: '',
            address: '',
            aadhaarCardDoc: null,
            panCardDoc: null,
            gstCertificateDoc: null,
            email: '',
            password: '',
        }
        );

        // if (!error) {
        //     setIndividualFormData({
        //         sellerType: '',
        //         firstName: '',
        //         lastName: '',
        //         phone: '',
        //         address: '',
        //         aadhaarCardDoc: null,
        //         panCardDoc: null,
        //         gstCertificateDoc: null,
        //         email: '',
        //         password: '',
        //     });
        //     setOpenRegistrationConfirmMsgPopup(true);
        // }
    }

    const onSubmitCompanyFormReg = async (e) => {
        e.preventDefault()
        await dispatch(sellerRegistration(companyFormData))

        setCompanyFormData(
            {
                sellerType: '',
                firstName: "",
                lastName: "",
                representativeName: '',
                companyName: '',
                phone: '',
                address: '',
                email: '',
                password: '',
                gstCertificateDoc: null,
                cinCertificateDoc: null,
                panCardDoc: null
            }
        )

        // if(!error){
        //     setCompanyFormData(
        //         {
        //             sellerType: '',
        //             firstName: "",
        //             lastName: "",
        //             representativeName: '',
        //             companyName: '',
        //             phone: '',
        //             address: '',
        //             email: '',
        //             password: '',
        //             gstCertificateDoc: null,
        //             cinCertificateDoc: null,
        //             panCardDoc: null
        //         }
        //     )
        //     setOpenRegistrationConfirmMsgPopup(true)
        // }
    }

    return (
        <div className="seller">
            <div className="seller-companyLogo">
                <img src={company_logo} alt="Company Logo" className="navbar-logoStyle-common" />
            </div>
            <div className="seller-container">
                <div className="regis-heading">
                    Join the Community
                </div>
                {formRenderOption === 'INDIVIDUAL' ?
                    <form className="regisIndividual-form" onSubmit={onSubmitIndividualFormReg}>
                        <div className="regisIndividual-form-firstLastName">
                            <input className="regin-form-input"
                                placeholder="First Name"
                                name="firstName"
                                value={individualFormData?.firstName}
                                onChange={handleIndividualInputChange}
                            />
                            <input
                                className="regin-form-input"
                                placeholder="Last Name"
                                name="lastName"
                                value={individualFormData?.lastName}
                                onChange={handleIndividualInputChange}
                            />
                        </div>
                        <PhoneInput
                            name="phone"
                            value={individualFormData?.phone}
                            onChange={handleIndividualInputChange}
                        />
                        <textarea
                            placeholder="Address"
                            className="regin-form-address"
                            name="address"
                            value={individualFormData?.address}
                            onChange={handleIndividualInputChange}
                        />
                        <div className="regisIndividual-form-firstLastName">
                            <div className="regisIndividual-form-aadhaar">
                                <label htmlFor="aadhaarCardDoc">
                                    {
                                        individualFormData && individualFormData?.aadhaarCardDoc ? individualFormData?.aadhaarCardDoc?.name : 'Upload Aadhar Card'
                                    }
                                </label>
                                <input
                                    type="file"
                                    id="aadhaarCardDoc"
                                    name="aadhaarCardDoc"
                                    accept=".pdf,.jpg,.jpeg,.png"
                                    className="regin-form-input"
                                    onChange={handleIndividualFileChange}
                                />
                            </div>
                            <div className="regisIndividual-form-aadhaar">
                                <label htmlFor="panCardDoc">
                                    {
                                        individualFormData && individualFormData?.panCardDoc ? individualFormData?.panCardDoc?.name : 'Upload Pan Card'
                                    }
                                </label>
                                <input
                                    type="file"
                                    id="panCardDoc"
                                    name="panCardDoc"
                                    accept=".pdf,.jpg,.jpeg,.png"
                                    className="regin-form-input"
                                    onChange={handleIndividualFileChange}
                                />
                            </div>
                        </div>
                        <div className="regisIndividual-form-aadhaar">
                            <label htmlFor="gstCertificateDoc">
                                {
                                    individualFormData && individualFormData?.gstCertificateDoc ? individualFormData?.gstCertificateDoc?.name : 'Upload GST Certificate (Optional)'
                                }
                            </label>
                            <input
                                type="file"
                                id="gstCertificateDoc"
                                name="gstCertificateDoc"
                                accept=".pdf,.jpg,.jpeg,.png"
                                className="regin-form-input"
                                onChange={handleIndividualFileChange}
                            />
                        </div>
                        <input
                            className="regin-form-input"
                            placeholder="Email Address"
                            type="email"
                            name="email"
                            value={individualFormData.email}
                            onChange={handleIndividualInputChange}
                        />
                        <div className="password-wrapper">
                            <PasswordInput
                                name="password"
                                value={individualFormData.password}
                                onChange={handleIndividualInputChange}
                            />
                        </div>
                        <button className="regis-button">Submit</button>
                    </form>
                    :
                    formRenderOption === "COMPANY" ?
                        <form className="regisIndividual-form" onSubmit={onSubmitCompanyFormReg}>
                            <input
                                className="regin-form-input"
                                placeholder="Representative's Name"
                                name="representativeName"
                                onChange={handleCompanyInputChange}
                            />
                            <input
                                className="regin-form-input"
                                placeholder="Registered Company Name"
                                name="companyName"
                                onChange={handleCompanyInputChange}
                            />
                            <PhoneInput
                                name="phone"
                                value={companyFormData?.phone}
                                onChange={handleCompanyInputChange}
                            />
                            <textarea
                                placeholder="Address"
                                className="regin-form-address"
                                name="address"
                                onChange={handleCompanyInputChange}
                            />
                            <div className="regisIndividual-form-firstLastName">
                                <div className="regisIndividual-form-aadhaar">
                                    <label htmlFor="gstCertificateDoc">
                                        {
                                            companyFormData && companyFormData?.gstCertificateDoc ? companyFormData?.gstCertificateDoc?.name : 'Upload GST Certificate'
                                        }
                                    </label>
                                    <input
                                        type="file"
                                        id="gstCertificateDoc"
                                        name="gstCertificateDoc"
                                        accept=".pdf,.jpg,.jpeg,.png"
                                        className="regin-form-input"
                                        onChange={handleCompanyFileChange}
                                    />
                                </div>
                                <div className="regisIndividual-form-aadhaar">
                                    <label htmlFor="cinCertificateDoc">
                                        {
                                            companyFormData && companyFormData?.cinCertificateDoc ? companyFormData?.cinCertificateDoc?.name : 'Upload CIN Certificate'
                                        }
                                    </label>
                                    <input
                                        type="file"
                                        id="cinCertificateDoc"
                                        name="cinCertificateDoc"
                                        accept=".pdf,.jpg,.jpeg,.png"
                                        className="regin-form-input"
                                        onChange={handleCompanyFileChange}
                                    />
                                </div>
                            </div>
                            <div className="regisIndividual-form-aadhaar">
                                <label htmlFor="panCardDoc">
                                    {
                                        companyFormData && companyFormData?.panCardDoc ? companyFormData?.panCardDoc?.name : 'Upload Pan Card'
                                    }
                                </label>
                                <input
                                    type="file"
                                    id="panCardDoc"
                                    name="panCardDoc"
                                    accept=".pdf,.jpg,.jpeg,.png"
                                    className="regin-form-input"
                                    onChange={handleCompanyFileChange}
                                />
                            </div>
                            <input
                                className="regin-form-input"
                                placeholder="Email Address"
                                type="email"
                                name="email"
                                onChange={handleCompanyInputChange}
                            />
                            <div className="password-wrapper">
                                <PasswordInput
                                    name="password"
                                    value={companyFormData?.password}
                                    onChange={handleCompanyInputChange}
                                />
                            </div>
                            <button className="regis-button">Submit</button>
                        </form>
                        :
                        <></>
                }
                <div className="regis-link">
                    Already have an account?&nbsp;<span onClick={onClicklogin}>Log In</span>
                </div>
            </div>
            <ToastContainer />
            <Modal
                open={openRegistrationConfirmMsgPopup}
                onClose={() => setOpenRegistrationConfirmMsgPopup(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className='seller-regis'>
                        <div className="seller-regis-welcomeMsg">
                            Welcome to World Archives Contributor Portal!
                        </div>
                        <div className="seller-regis-msg-container">
                            <div className="seller-regis-confirmMsg">
                                Thank You Manoj Kumar!
                            </div>
                            <div className="seller-regis-confirmMsg">
                                We have sent a verification link to the email address you provided.
                                Please click on the link to complete the verification process.
                            </div>
                            <div className="seller-regis-confirmMsg2">
                                <span className="seller-regis-confirmMsg">After the verification of your email address.</span>
                                <span className="seller-highlight-msg">*It will take about 2-3 business days for the verification process of the documents submitted by you. Please be patient.</span>
                                <span className="seller-highlight-msg">*Once the verification of the documents provided is complete. You will receive an email with the confirmation and you can start uploading your content.</span>
                            </div>
                            <div className="seller-regis-confirmMsg">
                                This email has been sent to : <span className="srcm-highlithing-email">mkphotography@gmail.com</span>
                            </div>
                        </div>
                        <div className="seller-regis-sendAgain-continueShopping">
                            <div className="seller-regis-sendAgain">
                                <label>Didn't get an email?</label>
                                <button>Send Again</button>
                            </div>
                            <div className="seller-regis-continueShopping">
                                <button onClick={onClickConShop}>Continue Shopping</button>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default Registration;