import React from "react";
import './popularCategory.css';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { searchResults } from "../../../features/search/searchActions";

const PopularCategory = (props) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {
        popularCategoryKeywords,
        selectTerm
    } = props

    const handlePopularCategoryKeyword = async (keyword) => {
        localStorage.setItem('fieldTerm',keyword)
        navigate(`/search_results?keyword=${keyword}`)
        await dispatch(searchResults({fieldTerm: keyword, selectTerm: selectTerm}))
    }

    return (
        <div className="pc-container">
            <div className="pc-keywordsAndHeading">
                <div className="pc-heading">
                    Popular Categories
                </div>
                <div className="pc-keywords-container">
                    {popularCategoryKeywords?.map((keyword, index) => {
                        return (
                            <div onClick={() => handlePopularCategoryKeyword(keyword)} key={index} className="pc-keyword-style">
                                {keyword}
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default PopularCategory;