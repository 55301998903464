import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { useAdminPagesContext } from '../../../contexts/AdminPagesContext';
import { returnPopupArray } from '../../utils';
import { useSelector } from 'react-redux';

export default function PopupStepper({ viewCheck }) {
  const { role } = useSelector(state => state.admin) ?? localStorage.getItem('loggedRole')
  const { activeStep } = useAdminPagesContext()
  const steps = viewCheck ? ['Details', 'Checks'] : returnPopupArray(role)
  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep} alternativeLabel  >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}