import { Box, Modal } from "@mui/material";
import React, { useState, useEffect } from "react";
import { MdOutlineCancel } from 'react-icons/md';
import './adminTable.css'
import { useSelector, useDispatch } from "react-redux";
import { getMediaSizes, updateMediaPrices } from "../../../features/admin/adminActions";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    outline: 'none',
    boxShadow: 24,
    p: 2,
    borderRadius: '0.7rem'
};

const btnStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: '30px',
    width: '140px',
    border: '1px solid #4946C3',
    borderRadius: '5px',
    color: '#4946C3',
    fontSize: '14px',
    fontWeight: 500,
    background: '#ffffff',
    cursor: 'pointer',
    outline: 'none',
    right: 0,
    marginTop: '8px'
}


const AddPriceModal = (props) => {
    const dispatch = useDispatch()
    const { mediaSizes } = useSelector(state => state.admin)
    const {
        openPriceModal,
        handleClosePriceModal,
        mediaId
    } = props

    useEffect(() => {
        async function getMediaSizesArr() {
            await dispatch(getMediaSizes(mediaId))
        }
        getMediaSizesArr()
    }, [dispatch, mediaId])

    const [formData, setFormData] = useState({});

    const handleInputChange = (size, field, value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [size]: {
                ...prevFormData[size],
                [field]: value
            }
        }));
    };

    async function handleSubmit(event) {
        event.preventDefault();
        const responseData = {
            id: mediaId,
            updateContentSizePrices: mediaSizes && mediaSizes?.length > 0 ?
                mediaSizes?.map((size) => ({
                    size,
                    price: Number(formData[size]?.price) || 0,
                    discount: Number(formData[size]?.discount) || 0,
                    is_visible: formData[size]?.checkbox || false,
                })) : {},
        }
        await dispatch(updateMediaPrices(responseData))
        handleClosePriceModal()
    };

    return (
        <Modal
            open={openPriceModal}
            onClose={handleClosePriceModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div className="priceModal-container">
                    <div className='adminTableModal-headingAndClose'>
                        <div className="adminTableModal-heading">
                            Add Price
                        </div>
                        <div className='adminTableModal-close' onClick={handleClosePriceModal}>
                            <MdOutlineCancel fontSize="19px" />
                        </div>
                    </div>
                    <div className="pm-data">
                        <div className="pm-data-headAndField-cmnSty">
                            <div></div>
                            <div className="pm-head-heading">Image Type</div>
                            <div className="pm-head-heading">Actual Price</div>
                            <div className="pm-head-heading">Discounted Price</div>
                        </div>
                        <div className="pm-data-headAndField-cmnSty">
                            {mediaSizes && mediaSizes.length > 0 ? (
                                mediaSizes.map((mediaSize, idx) => {
                                    return (
                                        <React.Fragment key={idx}>
                                            <input
                                                type="checkbox"
                                                style={{ cursor: 'pointer' }}
                                                checked={formData[mediaSize]?.checkbox || false}
                                                onChange={(e) => handleInputChange(mediaSize, 'checkbox', e.target.checked)}
                                            />
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>{mediaSize}</div>
                                            <input
                                                max={100000}
                                                placeholder="add price"
                                                className="pm-data-input pm-data-inputAddSty"
                                                type="number"
                                                value={formData[mediaSize]?.price || ''}
                                                onChange={(e) => handleInputChange(mediaSize, 'price', e.target.value)}
                                            />
                                            <input
                                                min={0}
                                                max={100000}
                                                placeholder="add discount price"
                                                className="pm-data-input"
                                                type="number"
                                                value={formData[mediaSize]?.discount || ''}
                                                onChange={(e) => handleInputChange(mediaSize, 'discount', e.target.value)}
                                            />
                                        </React.Fragment>
                                    );
                                })
                            ) : (
                                <></>
                            )}
                        </div>
                        <button onClick={handleSubmit} style={btnStyle} >Submit</button>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}

export default AddPriceModal;