import { Button, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Footer } from "../../common/Footer";
import { Navbar } from "../../common/Navbar";
import './videosDetailsPage.css';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import ShareIcon from '@mui/icons-material/Share';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import videoDetailsPageLogo from '../../../assets/logo/WorldArchivesVideoDark.png';
import { useSelector, useDispatch } from 'react-redux';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { useParams, useNavigate } from 'react-router-dom';
import { searchResultsById, addToCart, addToWishlist, searchResults } from '../../../features/search/searchActions'
import { Loader } from "../../common/Loader";
import ImageGallery from "../../reusable/ImageGallery/ImageGallery";
import { searchResultsSideImage, getSearchResults } from '../../../api'
import DataTable from "../../common/DataTable";
import CurrencyInput from '../../reusable/Currency/Currency';

const vdpSrchTextField = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: "100%",
    width: '100%',
    fontFamily: 'Open Sans',
    fontSize: '12px',
    "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
        outline: "none"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
        border: "none"
    },
    ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
        padding: '0px',
        height: '100%'
    },
    "@media (min-width : 481px) and (max-width : 767px)": {
        fontSize: '0.8rem'
    },
    "@media (min-width : 320px) and (max-width : 465px)": {
        '.css-8j6b76-MuiInputBase-root-MuiOutlinedInput-root': {
            fontSize: '0.8rem'
        },
        '.css-1pnmrwp-MuiTypography-root': {
            fontSize: '0.85rem',
            fontWeight: '600'
        }
    }
}

const vdpSrchBtn = {
    backgroundColor: '#ED652B',
    color: 'white',
    width: 'fit-content',
    height: '100%',
    textTransform: 'none',
    marginRight: '10px',
    '&:hover': {
        background: '#ED652B'
    },
    "@media (min-width : 320px) and (max-width : 465px)": {
        fontSize: '0.8rem',
        width: '1rem'
    }
}


const VideosDetailsPage = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const dispatch = useDispatch()
    const user = localStorage.getItem('userInfo')
    const { searchIdData, searchData, loading } = useSelector(state => state.search)
    const img_url = process.env.REACT_APP_S3_HOST
    const [query, setQuery] = useState('');
    const [sideVideos, setSideVideos] = useState([]);
    const [similarVideos, setSimilarVideos] = useState([]);

    const onClickSaveIcon = () => {
        saveAs(`${img_url}/${searchIdData?.contentSizes[0]?.previewSrc}`, `${searchIdData?.contentSizes[0]?.displayName}${searchIdData?.contentSizes[0]?.format}`);
    }

    const onClickShare = async () => {
        let currentUrl = window.location.href
        await navigator.clipboard.writeText(currentUrl)
        toast.success('Copied to clipboard', { autoClose: 2500 })
    }

    const handleCart = async () => {
        await dispatch(addToCart({ id, resolution: searchIdData?.contentSizes[0]?.size }))
    }

    const handleWishlist = async () => {
        await dispatch(addToWishlist({ id, resolution: searchIdData?.contentSizes[0]?.size }))
    }

    function getInitials(name) {
        const words = name ? name?.split(' ') : "";
        const initials = [];
        for (const word of words) {
            if (word === "Mr." || word === "Dr." || word === "Ms." || word === "Mrs." || word === "Miss." || word === "Mx.") {
                continue;
            }
            initials.push(word[0]);
        }
        return initials.join('');
    }

    async function handleSubmit(event) {
        event.preventDefault();
        const searchTerm = decodeURIComponent(query.replace(/[^\w\s]/gi, function (c) {
            return '%' + c.charCodeAt(0).toString(16);
        }).replace(/\s+/g, '+'));
        navigate(`/search_results?keyword=${searchTerm}`)
        await dispatch(searchResults({ fieldTerm: query, selectTerm: 'VIDEO' }))
    }

    function loginFunction() {
        toast.info('Please login to add items to card', { autoClose: 1500 })
    }

    function wishlistLogin() {
        toast.info('Please login to wishlist media', { autoClose: 1500 })
    }

    const initials = getInitials(searchIdData && searchIdData?.contributorName);
    const category = searchIdData && searchIdData?.category
    const videoFormats = searchIdData && searchIdData?.mediainfo ? searchIdData?.mediainfo : {}
    const resolution = videoFormats && videoFormats?.resolution ? videoFormats?.resolution : ""

    const handleKeywordClick = async (keyword) => {
        localStorage.setItem('fieldTerm', keyword)
        navigate(`/search_results?keyword=${keyword}`)
        await dispatch(searchResults({ fieldTerm: keyword, selectTerm: 'VIDEO' }))
    }

    useEffect(() => {
        dispatch(searchResultsById({ Id: id }))
        window.scrollTo(0, 0)
    }, [dispatch, id])

    useEffect(() => {
        const fetchSideVideos = async () => {
            const tenthKeyword = searchIdData?.keywords[9]
            const sideVideos = await searchResultsSideImage({ selectTerm: 'VIDEO', limit: 3, keywords: tenthKeyword });
            setSideVideos(sideVideos);
        };

        const fetchSimilarVideos = async () => {
            const firstFiveWords = searchIdData?.keywords?.slice(0, 5)
            const similarVideos = await getSearchResults({ selectTerm: 'VIDEO', limit: 16, keywords: firstFiveWords })
            setSimilarVideos(similarVideos);
        };

        fetchSideVideos();
        fetchSimilarVideos();
    }, [searchIdData?.keywords]);

    function Video({ previewSrc, videoSrc, id, onClick }) {
        const [isHovering, setIsHovering] = useState(false);
        const [playDuration, setPlayDuration] = useState(2);
        const videoRef = useRef(null);

        function handleTimeUpdate() {
            if (videoRef.current.currentTime >= playDuration) {
                videoRef.current.currentTime = 0;
                videoRef.current.play();
            }
        }

        function handleVideoEnd() {
            videoRef.current.currentTime = 0;
            videoRef.current.play();
        }

        function handleMouseOver() {
            setIsHovering(true);
            videoRef.current.play();
        }

        function handleMouseOut() {
            setIsHovering(false);
            videoRef.current.pause();
        }

        return (
            <div
                onMouseEnter={handleMouseOver}
                onMouseLeave={handleMouseOut}
            >
                {isHovering ? (
                    <video
                        className="vdp-videoPlay"
                        id={id}
                        ref={videoRef}
                        width="100%"
                        height="100%"
                        src={videoSrc}
                        onTimeUpdate={handleTimeUpdate}
                        onEnded={handleVideoEnd}
                        onClick={onClick}
                    />
                ) : (
                    <img
                        src={previewSrc}
                        alt=""
                    />
                )}
            </div>
        );
    }

    const handleViewAllClick = async (e) => {
        navigate(`/search_results`)
        await dispatch(searchResults({ fieldTerm: searchIdData?.keywords[9], selectTerm: 'VIDEO' }))
    }

    const handleSideVideoClick = async (e) => {
        const id = e.currentTarget.getAttribute('id');
        await dispatch(searchResultsById({ Id: id }))
        navigate(`/details_video/${id}`)
    }

    return (
        <div className="vdp">
            <Navbar menuItemStyles="vdp-menu-items" companyLogo={videoDetailsPageLogo} cartColor="#666666" logoStyles="navbar-logoStyle" hamburgerColor="#000000" />
            <div className="vdp-container">
                <form onSubmit={handleSubmit}>
                    <div className="vdp-container-searchBar">
                        <TextField
                            fullWidth
                            placeholder={`Try searching for Videos`}
                            value={query}
                            onChange={(event) => setQuery(event.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        Video
                                    </InputAdornment>
                                ),
                            }}
                            sx={vdpSrchTextField}
                        />
                        <Button
                            onClick={handleSubmit}
                            sx={vdpSrchBtn}
                        >Search</Button>
                    </div>
                </form>
                {loading ? <Loader /> :
                    <div className="vdp-container-video">
                        <div className="vdp-container-video-player">
                            <video autoPlay height='100%' width='100%' style={{ objectFit: 'fill' }} controls controlsList="nodownload">
                                <source src={`${img_url}/${searchIdData?.contentSizes[0]?.previewSrc}`} />
                            </video>
                            {searchIdData?.isPremium && <div className="idp-premium-tag">Premium</div>}
                        </div>
                        <div className="vdp-container-video-imageCollection">
                            {sideVideos?.map((item, index) => {
                                if (index === 2) {
                                    return (
                                        <div className="vdp-thumbnail-side vdp-thumbnail-side-overlay" key={index}>
                                            <Video
                                                previewSrc={`${img_url}/${item.mediaSrc}`}
                                                videoSrc={`${img_url}/${item.previewSrc}`}
                                                id={item.id}
                                            />
                                            <div className="overlay-content">
                                                <span className="overlay-text" onClick={handleViewAllClick}>View All</span>
                                            </div>
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div className="vdp-thumbnail-side" key={index}>
                                            <Video
                                                previewSrc={`${img_url}/${item.mediaSrc}`}
                                                videoSrc={`${img_url}/${item.previewSrc}`}
                                                id={item.id}
                                                onClick={handleSideVideoClick}
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </div>
                                    );
                                }
                            })}
                        </div>

                    </div>
                }
                {!loading ?
                    <div className="vdp-container-optionsAndContentId">
                        <div className='vdp-container-contentId'>
                            <span className='vdp-contentId'>Content ID :- </span>
                            <span className='vdp-contentId-value'>{id && id}</span>
                        </div>
                        <div className='vdp-container-options'>
                            <span className='vdp-options-icons' onClick={user && (user !== null || user !== undefined) ?
                                handleWishlist : wishlistLogin}>
                                <BookmarkBorderIcon fontSize='18px' />&nbsp;Wishlist
                            </span>
                            <span className='vdp-options-icons' onClick={onClickShare}>
                                <ShareIcon fontSize='18px' />&nbsp;Share
                            </span>
                            <span className='vdp-options-icons' onClick={onClickSaveIcon}>
                                <SaveAltIcon fontSize='18px' />&nbsp;Save
                            </span>
                            <button
                                onClick={user && (user !== null || user !== undefined) ? handleCart : loginFunction}
                                className='vdp-addToCartBtn'
                            >Add to cart</button>
                        </div >
                    </div > : <></>}
                {
                    !loading ? <>
                        <div className='vdp-container-titleAndPrice'>
                            <div className='vdp-detailsContent-commonSty'>
                                <span className='vdp-heading'>Title</span>
                                <span className='vdp-details'>{searchIdData && searchIdData?.title}</span>
                            </div>
                            <div className='vdp-detailsContent-commonSty'>
                                <span className='vdp-heading'>Price</span>
                                <div className='vdp-detailsContent-price-selection'>
                                    <CurrencyInput/>
                                    {/* <span className='idp-details-priceDesc idp-descAddSty'>{searchIdData && searchIdData?.price}</span> */}
                                    <span className='idp-details-priceDesc idp-descAddSty'>0</span>
                                </div>
                                {/* <span className='vdp-details-priceDesc vdp-descAddSty'>{searchIdData && `Rs.${searchIdData?.price}`}</span> */}
                            </div>
                        </div>
                        <div className='vdp-container-desc'>
                            <span className='vdp-heading'>Description</span>
                            <div className='vdp-details-priceDesc vdp-descAddSty'>{searchIdData && searchIdData?.description}</div>
                        </div>
                        <div className='vdp-container-detailsCommonSty'>
                            <div className="vdp-detailsContent-container-cmnSty">
                                <div className='vdp-detailsContent-commonSty'>
                                    <span className='vdp-heading'>Contributor</span>
                                    <div className='imageDetailsPage-contributor-details'>
                                        <span className='vdp-contributor-nameInitials'>{initials}</span>
                                        <span className='vdp-contributor-name'>{searchIdData && searchIdData?.contributorName}</span>
                                    </div>
                                </div>
                                <div className='vdp-container-cc'>
                                    <span className='vdp-heading'>Category</span>
                                    <span className='vdp-details'>{category && category[0].toUpperCase() + category.slice(1).toLowerCase()}</span>
                                </div>
                            </div>
                            <div className="vdp-detailsContent-container-cmnSty">
                                <div className='vdp-detailsContent-commonSty'>
                                    <span className='vdp-heading'>Shoot Location</span>
                                    <span className='vdp-details'>{searchIdData && searchIdData?.shootLocation}</span>
                                </div>
                                <div className='vdp-detailsContent-commonSty'>
                                    <span className='vdp-heading'>Type of Angle</span>
                                    <span className='vdp-details'>{searchIdData && searchIdData?.typeOfAngle}</span>
                                </div>
                            </div>
                        </div>
                        <div className='vdp-container-detailsCommonSty'>
                            <div className="vdp-detailsContent-container-cmnSty">
                                <div className='vdp-container-form'>
                                    <span className='vdp-heading'>Available Formats</span>
                                    <span className='vdp-details vdp-details-addSty vdp-details-addSty'>{videoFormats && `${videoFormats?.width} × ${videoFormats?.height} pixels • FR ${videoFormats?.frameRate} • ${videoFormats?.format}`}</span>
                                </div>
                                <div className='vdp-detailsContent-commonSty'>
                                    <span className='vdp-heading'>Camera</span>
                                    <span className='vdp-details'>{searchIdData && searchIdData?.camera}</span>
                                </div>
                            </div>
                            <div className="vdp-detailsContent-container-cmnSty">
                                <div className='vdp-detailsContent-commonSty'>
                                    <span className='vdp-heading'>Resolution</span>
                                    <span className='vdp-details'>{resolution && resolution[0].toUpperCase() + resolution.slice(1).toLowerCase()}</span>
                                </div>
                                <div className='vdp-detailsContent-commonSty'>
                                    <span className='vdp-heading'>POV</span>
                                    <span className='vdp-details'>{searchIdData && searchIdData?.pov}</span>
                                </div>
                            </div>
                        </div>
                        <div className='vdp-container-desc'>
                            <span className='vdp-heading'>Keywords</span>
                            <div className='vdp-details-priceDesc'>
                                {searchIdData?.keywords?.map((keyword, index) => {
                                    return (
                                        <span onClick={() => handleKeywordClick(keyword)} className='vdp-details-keywordsSty' key={index}>{keyword}</span>
                                    )
                                })}
                            </div>
                        </div>
                        <DataTable mediaId={id} tableData={searchIdData && searchIdData?.contentSizes}/>
                    </> : <></>
                }
                {
                    !loading ? <div className='vdp-container-similarVideos'>
                        <div className='vdp-similarVideos-heading'>Similar Videos</div>
                        <ImageGallery imageArray={similarVideos} pageName="VIDEO" />
                    </div> : <></>
                }
            </div >
            {
                !loading ? <Footer /> : <></>
            }
        </div >
    )
}

export default VideosDetailsPage;