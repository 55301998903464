import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom'
import Button from "../../reusable/Button";
import './Navbar.css'
import { GiHamburgerMenu } from 'react-icons/gi'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch, useSelector } from "react-redux";
import { getUserProfileDetails } from "../../../features/users/userActions";
import { getCartItems } from "../../../features/search/searchActions";
import { userLogout } from "../../../features/users/userActions";
import { ToastContainer } from "react-toastify";
import { MdOutlineClose } from "react-icons/md";
import { makeStyles } from '@mui/styles';
import Drawer from '@mui/material/Drawer';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import VideocamIcon from '@mui/icons-material/Videocam';
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';


const useStyles = makeStyles({
    drawer: {
        width: 300,
    },
    drawerPaper: {
        width: 300,
    },
    '@media (min-width : 320px) and (max-width : 465px)':{
        drawer: {
            width: '100vw'
        },
        drawerPaper: {
            width: '100vw',
        },
    }
});


const Navbar = ({companyLogo, logoStyles, menuItemStyles, cartColor, hamburgerColor }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { userProfile } = useSelector(state => state.user)
    const userToken = localStorage.getItem('userToken')
    const [openDrawer, setOpenDrawer] = useState(false)
    const classes = useStyles();

    const handleDrawerOpen = () => {
        setOpenDrawer(true)
    }

    const handleDrawerClose = () => {
        setOpenDrawer(false)
    }

    useEffect(() => {
        getUserDetails()
        getCartDetails()
    }, [])

    async function getUserDetails() {
        if (userToken) await dispatch(getUserProfileDetails())
        else return
    }

    async function getCartDetails() {
        if (userToken) await dispatch(getCartItems())
        else return
    }

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = async () => {
        await dispatch(userLogout(userToken))
        navigate('/login')
    }

    const nameInitials = `${userProfile?.first_name ?
        userProfile?.first_name.substring(0, 1)?.toUpperCase() :
        ''}${userProfile?.last_name ?
            userProfile?.last_name.substring(0, 1)?.toUpperCase() :
            ''}`
    const { cartItems } = useSelector(state => state.search)

    const onClickNavbarLogo = () => {
        navigate('/')
    }

    const onClickCart = () => {
        navigate('/cart')
    }

    const onClickMyAcc = () => {
        navigate('/myAccount')
    }

    const onClickWishlist = () => {
        navigate('/wishlist')
    }

    const onClickDownload = () => {
        navigate('/downloads')
    }

    const onClickPurchaseHistory = () => {
        navigate('/phistory')
    }

    const onClickImageLi = () => {
        navigate('/image')
    }

    const onClickVideoLi = () => {
        navigate('/video')
    }
    const onClickMusicLi = () => {
        navigate('/music')
    }
    const onClickVfxLi = () => {
        navigate('/vfx')
    }
    const onClickVectorLi = () => {
        navigate('/vector')
    }
    const onClickExclusiveLi = () => {
        navigate('/exclusive')
    }
    const onClickEditorialLi = () => {
        navigate('/editorial')
    }

    return (
        <>
            <nav className="navbar">
                <div className="logo">
                    <img src={companyLogo} alt="logo" onClick={onClickNavbarLogo} className={logoStyles} />
                </div>
                <div className="menu-link">
                    <ul>
                        <li >
                            <Link to='/image' className={menuItemStyles}>
                                Images
                            </Link>
                        </li>
                        <li >
                            <Link to='/video' className={menuItemStyles}>
                                Videos
                            </Link>
                        </li>
                        <li >
                            <Link to='/music' className={menuItemStyles}>
                                Music
                            </Link>
                        </li>
                        <li>
                            <Link to='/vfx' className={menuItemStyles}>
                                VFX
                            </Link>

                        </li>
                        <li >
                            <Link to='/editorial' className={menuItemStyles}>
                                Editorial
                            </Link>
                        </li>
                        <li >
                            <Link to='/exclusive' className={menuItemStyles}>
                                Exclusive
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="subscription">
                    {/* <ul>
                        <li >
                            <Link to='/plan_pricing' className={menuItemStyles}>
                                Pricing for plans
                            </Link>
                        </li>
                        <li >
                            <Link to='/sell' className={menuItemStyles}>
                                Switch to selling
                            </Link>
                        </li>
                    </ul> */}
                </div>
                <div className="nav-buttons">
                    <div className="nav-buttons-cart" onClick={onClickCart}>
                        <AiOutlineShoppingCart fontSize='24px' color={cartColor} />
                        <span>{cartItems > 0 ? cartItems : 0}</span>
                    </div>
                    {userToken && userToken !== "undefined" ? <><div className="nav-profile" id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                    ><span className="nav-profile-nameInitials">{nameInitials}</span></div>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={onClickMyAcc} >My Account</MenuItem>
                            <MenuItem onClick={onClickWishlist}>Wishlist</MenuItem>
                            <MenuItem onClick={onClickDownload}>Owned Assets</MenuItem>
                            <MenuItem onClick={onClickPurchaseHistory}>Purchase History</MenuItem>
                            <MenuItem onClick={handleLogout}>Logout</MenuItem>
                        </Menu>
                    </> :
                        <div className="btn-container">
                            <Link to='/login'>
                                <Button btnStyle="button">Log In/Sign Up</Button>
                            </Link>
                        </div>
                    }
                </div>
                <div className="hamburger-menu">
                    {openDrawer === false ? <GiHamburgerMenu 
                        color={hamburgerColor}
                        onClick={handleDrawerOpen}
                    /> : <></>
                    }
                </div>
            </nav>
            <ToastContainer limit={3} theme="colored" autoClose={1200} />
            <Drawer
                sx={{
                    '@media (min-width: 910px)':{
                        display:'none'
                    }
                }}
                anchor="right"
                className={classes.drawer}
                variant="temporary"
                open={openDrawer}
                onClose={handleDrawerClose}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className="drawer">
                    <div className="drawer-cart-cancelBtn">
                        <div className="nav-buttons-cart" onClick={onClickCart}>
                            <AiOutlineShoppingCart fontSize='24px' color="#000000" />
                            <span>{cartItems > 0 ? cartItems : 0}</span>
                        </div>
                        <MdOutlineClose onClick={handleDrawerClose} style={{ fontSize: '1.2rem', fontWeight: '800', cursor: 'pointer' }} />
                    </div>
                    {userToken && userToken !== "undefined" ? <><div className="nav-profile" id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                    ><span className="nav-profile-nameInitials">{nameInitials}</span></div>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={onClickMyAcc} >My Account</MenuItem>
                            <MenuItem onClick={onClickWishlist}>Wishlist</MenuItem>
                            <MenuItem onClick={onClickDownload}>Owned Assets</MenuItem>
                            <MenuItem onClick={onClickPurchaseHistory}>Purchase History</MenuItem>
                            <MenuItem onClick={handleLogout}>Logout</MenuItem>
                        </Menu>
                    </> :
                        <div>
                            <div className="btn-container">
                                <Link to='/login' style={{ width: '100%' }}>
                                    <Button btnStyle="button">Log In/Sign Up</Button>
                                </Link>
                            </div>
                        </div>
                    }
                    <div className="drawer-link-container">
                        <ul className="drawer-menu-link">
                            <li className="drawer-menu-listItemCmnSty" onClick={onClickImageLi} >
                                <span className="drawer-menu-fontSty">
                                    Image
                                </span>
                                <PhotoSizeSelectActualIcon />
                            </li>
                            <li className="drawer-menu-listItemCmnSty" onClick={onClickVideoLi}>
                                <span className="drawer-menu-fontSty">
                                    Video
                                </span>
                                <SmartDisplayIcon />
                            </li>
                            <li className="drawer-menu-listItemCmnSty" onClick={onClickMusicLi}>
                                <span className="drawer-menu-fontSty">
                                    Music
                                </span>
                                <SmartDisplayIcon />
                            </li>
                            <li className="drawer-menu-listItemCmnSty" onClick={onClickVfxLi}>
                                <span className="drawer-menu-fontSty">
                                    VFX
                                </span>
                                <VideocamIcon />
                            </li>
                            <li className="drawer-menu-listItemCmnSty" onClick={onClickVectorLi}>
                                <span className="drawer-menu-fontSty">
                                    Vector
                                </span>
                                <span className="menu-vectorIcon-sty">VE</span>
                            </li>
                            <li className="drawer-menu-listItemCmnSty" onClick={onClickEditorialLi}>
                                <span className="drawer-menu-fontSty">
                                    Editorial
                                </span>
                                <span className="menu-vectorIcon-sty">ED</span>
                            </li>
                            <li className="drawer-menu-listItemCmnSty" onClick={onClickExclusiveLi}>
                                <span className="drawer-menu-fontSty">
                                    Exclusive
                                </span>
                                <span className="menu-vectorIcon-sty">EX</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </Drawer>
        </>
    )
}

export default Navbar;