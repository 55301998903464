import React, { useState, useEffect } from "react";
import './toSubmit.css';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import sampleImage from '../../../assets/images/fallbackImage.jpeg'
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import { Radio } from "@mui/material";
import { getSellerPageMediaByStatus } from '../../../api'

const ToSubmit = ({ status }) => {
    const [selectedImages, setSelectedImages] = useState([]);
    const [imageType, setImageType] = useState('photo');
    const [imageUsage, setImageUsage] = useState('commercial');
    const [mediaDataByStatus, setMediaDataByStatus] = useState([])
    const media_url = process.env.REACT_APP_S3_HOST

    const handleImageTypeChange = (event) => {
        setImageType(event.target.value);
    };

    const handleImageUsageChange = (event) => {
        setImageUsage(event.target.value);
    };

    const handleImageClick = (imageId) => {
        if (selectedImages.includes(imageId)) {
            setSelectedImages([]);
        } else {
            setSelectedImages([imageId]);
        }
    };

    const handlePreviewImageClick = () => {
        const img = document.querySelector('.tsf-preview-image');
        if (img) {
            if (img.requestFullscreen) {
                img.requestFullscreen();
            } else if (img.mozRequestFullScreen) { // For Firefox
                img.mozRequestFullScreen();
            } else if (img.webkitRequestFullscreen) { // For Chrome, Safari, and Opera
                img.webkitRequestFullscreen();
            } else if (img.msRequestFullscreen) { // For IE/Edge
                img.msRequestFullscreen();
            }
        }
    };

    const imageSelectStyle = {
        display: "flex",
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '50vh'
    }

    useEffect(() => {
        async function getSellerMedia() {
            const mediaData = await getSellerPageMediaByStatus("PENDING")
            setMediaDataByStatus(mediaData)
        }
        getSellerMedia()
    }, [status])

    return (
        <div className="toSub">
            <div className="toSub-iconMsg">
                <CheckCircleIcon sx={{ color: "#32CD30", fontSize: '1.35rem' }} />
                <span className="toSub-im-msg">Welcome! Let's get your content approved. Select an item to add details and submit for review.</span>
                <span className="toSub-im-link">Learn More</span>
            </div>
            <div className="toSub-data-submitForm">
                <div className="toSub-data">
                    <div className="tsd-img-container">
                        {mediaDataByStatus?.map((media) => (
                            <div
                                key={media.id}
                                className={`image-wrapper ${selectedImages.includes(media.id) ? 'selected' : ''}`}
                                onClick={() => handleImageClick(media.id)}
                                style={{ cursor: 'pointer' }}
                            >
                                <img src={`${media_url}/${media?.mediaSrc}`} alt="" />
                                <div className="overlay-container">
                                    {!selectedImages.includes(media.id) ? (
                                        <PanoramaFishEyeIcon className="overlay-icon" sx={{ color: "#ffffff", fontSize: '1.1rem' }} />
                                    ) : (
                                        <CheckCircleIcon sx={{ color: "#32CD30", fontSize: '1.1rem' }} className="overlay-icon" />
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {selectedImages.length ?
                    <div className="toSub-submitForm">
                        <div className="tsf-filePreview" onClick={handlePreviewImageClick}>
                            <img src={sampleImage} alt="" className="tsf-preview-image" />
                        </div>
                        <div className="tsf-fileName">
                            Goa top view.jpg
                        </div>
                        <div className="tsf-fileType-usage">
                            <div className="tsf-fileType">
                                <div className="tsf-ft-headCmnSty">Image Type</div>
                                <div className="tsf-ft-selectionCmnSty">
                                    <div className="tsf-ft-radioLabelCmnSty">
                                        <Radio
                                            checked={imageType === 'photo'}
                                            onChange={handleImageTypeChange}
                                            value="photo"
                                            name="radio-buttons"
                                            inputProps={{ 'aria-label': 'PHOTO' }}
                                            size="small"
                                            sx={{ padding: '0.1rem' }}
                                        />
                                        <label className="tsf-ft-labelCmnSty">Photo</label>
                                    </div>
                                    <div className="tsf-ft-radioLabelCmnSty">
                                        <Radio
                                            checked={imageType === 'illustration'}
                                            onChange={handleImageTypeChange}
                                            value="illustration"
                                            name="radio-buttons"
                                            inputProps={{ 'aria-label': 'ILLUSTRATION' }}
                                            size="small"
                                            sx={{ padding: '0.1rem' }}
                                        />
                                        <label className="tsf-ft-labelCmnSty">Illustration</label>
                                    </div>
                                </div>
                            </div>
                            <div className="tsf-fileUsage">
                                <div className="tsf-ft-headCmnSty">Usage</div>
                                <div className="tsf-ft-selectionCmnSty">
                                    <div className="tsf-ft-radioLabelCmnSty">
                                        <Radio
                                            checked={imageType === 'commercial'}
                                            onChange={handleImageTypeChange}
                                            value="commercial"
                                            name="radio-buttons"
                                            inputProps={{ 'aria-label': 'COMMERCIAL' }}
                                            size="small"
                                            sx={{ padding: '0.1rem' }}
                                        />
                                        <label className="tsf-ft-labelCmnSty">Commercial</label>
                                    </div>
                                    <div className="tsf-ft-radioLabelCmnSty">
                                        <Radio
                                            checked={imageType === 'editorial'}
                                            onChange={handleImageTypeChange}
                                            value="editorial"
                                            name="radio-buttons"
                                            inputProps={{ 'aria-label': 'Editorial' }}
                                            size="small"
                                            sx={{ padding: '0.1rem' }}
                                        />
                                        <label className="tsf-ft-labelCmnSty">Editorial</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <form className="tsf-form-container">
                            <div className="tsf-labelField-cmnSty">
                                <label className="tsf-form-labelCmnSty">Title</label>
                                <input
                                    placeholder="Add Title"
                                    className="tsf-form-input"
                                />
                            </div>
                            <div className="tsf-labelField-cmnSty">
                                <label className="tsf-form-labelCmnSty">Description</label>
                                <textarea
                                    placeholder="Add Description"
                                    className="tsf-form-textArea"
                                />
                            </div>
                            <div className="tsf-labelField-cmnSty">
                                <label className="tsf-form-labelCmnSty">Keywords</label>
                                <textarea
                                    placeholder="Add Keywords"
                                    className="tsf-form-textArea"
                                />
                            </div>
                            <div className="tsf-upload-releaseForm">
                                <label htmlFor="releaseForm">
                                    Upload Release Form
                                </label>
                                <input
                                    type="file"
                                    id="releaseForm"
                                    name="releaseForm"
                                    accept=".pdf,.jpg,.jpeg,.png"
                                    className="tsf-form-input"
                                />
                            </div>
                            <button className="tsf-form-subBtn">Submit</button>
                        </form>
                    </div> : <div style={imageSelectStyle}>Select an item to add details </div>
                }
            </div>
        </div>
    )
}

export default ToSubmit;