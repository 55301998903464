import React, { useState, useEffect } from 'react';
import './sellerMedia.css';
import SellerNavbar from '../../sellerCommon/sellerNavbar/SellerNavbar';
import SellerSidebar from '../../sellerCommon/sellerSidebar/sellerSidebar';
import { ToastContainer } from 'react-toastify';
import { getSellerPageMediaByStatus } from '../../../api';
import SellerTabs from '../../sellerCommon/sellerTabs/SellerTabs';
const media_url = process.env.REACT_APP_S3_HOST


const SellerMedia = () => {
    window.FreshworksWidget('hide')

    const [sellerMedia, setSellerMedia] = useState([])

    useEffect(() => {
        async function getSellerMedia() {
            const res = await getSellerPageMediaByStatus("PENDING")
            setSellerMedia(res)
        }
        getSellerMedia()
    }, [])

    return (
        <div className='sellerMedia'>
            <div className='sellerMedia-navbar'>
                <SellerNavbar />
            </div>
            <div className="sellerMedia-sidebarAndData">
                <div className="sellerMedia-sidebar">
                    <SellerSidebar />
                </div>
                <div className="sellerMedia-data">
                    <div className='sellerMedia-heading'>
                        Media Status
                    </div>
                    <div className='sellerMedia-tabs'>
                        <SellerTabs/>
                    </div>
                    {/* <div>
                        {
                            sellerMedia?.map((media, idx) => {
                                return (
                                    <div className="overlay" key={idx}>
                                        <img id={media.id} src={`${media_url}/${media?.mediaSrc}`} alt="" height={75} width={100} />
                                    </div>
                                )
                            })
                        }
                    </div> */}
                    <ToastContainer limit={3} theme="colored" autoClose={1200} />
                </div>
            </div>
        </div >

    )
}

export default SellerMedia;