import axios from "axios";
import { toast } from "react-toastify"

const base_url = process.env.REACT_APP_BASE_URL
const ADMIN_UPLOAD_URL = `${base_url}/admin/media/add-media-content`
const SELLER_UPLOAD_URL = `${base_url}/seller/media/add-media-content`

export const getCategories = async (pageName) => {
    const { data } = await axios.get(`${base_url}/page/stamp-categories`, {
        params: {
            page: pageName
        }
    })
    return data?.data?.stampCategories
}

export const getCategoryData = async (pageName, category) => {
    const { data } = await axios.get(`${base_url}/media/search/full-text`, {
        params: {
            "search[category]": pageName,
            limit: pageName === 'VFX' || 'MUSIC' ? 50 : (pageName === 'VECTOR' ? 16 : 8),
            page: 1,
            "sortBy[viewCount]": true,
            "search[contentCategory]": category
        }
    })
    const returnData = data?.data?.mediaData
    let finalData = returnData.map((obj) => ({
        id: obj.id,
        mediaSrc: obj.contentSizes[0]?.thumbnailSrc,
        previewSrc: obj.contentSizes[0]?.previewSrc
    }))
    return finalData
}

export const adminContentUpload = async (files) => {
    const adminToken = localStorage.getItem('adminToken')
    const config = {
        headers: {
            "Authorization": `Bearer ${adminToken}`
        },
    }
    try {
        await Promise.all(files.map(async (file) => {
            const formData = new FormData()
            formData.append('file', file)
            await axios.post(ADMIN_UPLOAD_URL, formData, config)
        }))
        toast.success('Files Uploaded Successfully')

    } catch (error) {
        toast.error(error.response.data.message)
    }
}

export const getCart = async () => {
    const userToken = localStorage.getItem('userToken')
    const config = {
        headers: {
            "Authorization": `Bearer ${userToken}`,
            'Content-Type': 'application/json',
        }
    }
    try {
        const { data } = await axios.get(`${base_url}/cart`, config)
        const returnData = data?.data
        const discount = returnData?.discount
        const finalPrice = returnData?.finalPrice
        const total = returnData?.total
        const allMedia = returnData?.allMedia
        const finalMedia = allMedia?.map(el => el?.media)
        const thumbNails = finalMedia.map(el => el?.contentSizes[0]?.previewSrc)
        const formats = finalMedia.map(el => el?.contentSizes[0]?.format)
        const names = finalMedia.map(el => el?.contentSizes[0]?.displayName)
        const categories = finalMedia.map(el => el?.category)
        const licenses = finalMedia.map(el => el?.license)
        const mediaIds = finalMedia.map(el => el.id)
        const ItemPrice = allMedia?.map(el => el?.price)
        const ItemResolution = allMedia?.map(el => el?.resolution)
        const finalData = { discount, finalPrice, total, ItemPrice, ItemResolution, thumbNails, formats, names, categories, mediaIds, licenses }
        return finalData
    } catch (error) {
        toast.error('Something went wrong!')
    }
}

export const getProfileInfo = async () => {
    const userToken = localStorage.getItem('userToken')
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${userToken}`
        }
    }
    try {
        const { data } = await axios.get(`${base_url}/customer`, config)
        return data?.data?.customer

    } catch (error) {
        console.log('error', error.message)
    }
}

export const updateUserAddress = async (data) => {
    const userToken = localStorage.getItem('userToken')
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${userToken}`
        }
    }
    const formData = new FormData();
    Object.keys(data).forEach(key => formData.append(key, data[key]))
    try {
        const { data } = axios.post(`${base_url}/address`, formData, config)
        return data?.data?.updateAddress
    } catch (error) {
        console.log(error.response.data.message)
    }
}

export const getUserAddress = async () => {
    const userToken = localStorage.getItem('userToken')
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${userToken}`
        }
    }
    try {
        const { data } = await axios.get(`${base_url}/address`, config)
        return data?.data?.address
    } catch (error) {
        toast.error(error.response.data.message)
        console.log(error.response.data.message)
    }
}

export const cartCheckout = async (addressId) => {
    const userToken = localStorage.getItem('userToken')
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${userToken}`
        }
    }
    const body = {
        addressId,
        "couponCode": "",
        "type": "CART",
        "paymentGatewayType": "CASHFREE"
    }
    try {
        const { data } = await axios.post(`${base_url}/cart/checkout`, body, config)
        return data.data?.body?.payment_session_id
    } catch (error) {
        console.log(error.response.data.message)
    }
}

export const cartCheckoutCCAvenue = async (addressId) => {
    const userToken = localStorage.getItem('userToken')
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${userToken}`
        }
    }
    const body = {
        addressId,
        "couponCode": "",
        "type": "CART",
        "paymentGatewayType": "CCAVENUE"
    }
    try {
        const { data } = await axios.post(`${base_url}/cart/checkout`, body, config)
        return data.data?.body?.signature
    } catch (error) {
        console.log(error.response.data.message)
    }
}

export const downloadReceipt = async (orderId) => {
    const userToken = localStorage.getItem('userToken')
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${userToken}`
        }
    }
    try {
        const { data } = await axios.get(`${base_url}/order/${orderId}/receipt`, config)
        return data?.data?.url
    } catch (error) {
        console.log(error.response.data.message)
    }
}

export const updateThumbnail = async (file, id) => {
    const userToken = localStorage.getItem('adminToken')
    const config = {
        headers: {
            'Authorization': `Bearer ${userToken}`
        }
    }
    const formData = new FormData();
    formData.append("thumbnailSrc", file)
    formData.append("id", id)
    try {
        await axios.put(`${base_url}/admin/media`, formData, config)
        toast.success('thumbnail update successful')
    } catch (error) {
        toast.error(error.response.data.message)
        console.log(error.response.data.message)
    }
}

export const updatePreview = async (file, id) => {
    const userToken = localStorage.getItem('adminToken')
    const config = {
        headers: {
            'Authorization': `Bearer ${userToken}`
        }
    }
    const formData = new FormData();
    formData.append("previewSrc", file)
    formData.append("id", id)
    try {
        await axios.put(`${base_url}/admin/media`, formData, config)
        toast.success('preview Image update successful')
    } catch (error) {
        toast.error(error.response.data.message)
        console.log(error.response.data.message)
    }
}

export const assetVersioning = async (file, id) => {
    const userToken = localStorage.getItem('adminToken')
    const config = {
        headers: {
            'Authorization': `Bearer ${userToken}`
        }
    }
    const formData = new FormData();
    formData.append("id", id)
    formData.append("newVersion", file)
    try {
        await axios.put(`${base_url}/admin/media`, formData, config)
        toast.success('asset version update successful')
    } catch (error) {
        toast.error(error.response.data.message)
        console.log(error.response.data.message)
    }
}

export const subscriptionCheckout = async (addressId, subscriptionMasterId) => {
    const userToken = localStorage.getItem('userToken')
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${userToken}`
        }
    }
    const body = {
        addressId,
        "couponCode": "",
        "type": "SUBSCRIPTION",
        "paymentGatewayType": "CASHFREE",
        subscriptionMasterId
    }
    try {
        const { data } = await axios.post(`${base_url}/cart/checkout`, body, config)
        return data.data?.body?.payment_session_id
    } catch (error) {
        console.log(error.response.data.message)
    }
}

export const adminContentExport = async (pageName) => {
    const userToken = localStorage.getItem('adminToken')
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${userToken}`
        }
    }
    try {
        const { data } = await axios.post(`${base_url}/admin/media/export`, {
            "search": {
                "category": pageName,
            }

        }, config)
        return data
    } catch (error) {
        toast.error(error.response.data.message)
        console.log(error.response.data.message)
    }
}

export const getAllRoles = async () => {
    const userToken = localStorage.getItem('adminToken')
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${userToken}`
        }
    }
    try {
        const { data } = await axios.get(`${base_url}/admin/roles`, config)
        const returnData = data?.data
        const finalData = returnData?.map(el => el?.name)
        return finalData

    } catch (error) {
        toast.error(error.response.data.message)
        console.log(error.response.data.message)
    }
}

export const getAllEmails = async () => {
    const userToken = localStorage.getItem('adminToken')
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${userToken}`
        }
    }
    try {
        const { data } = await axios.get(`${base_url}/admin/all`, config)
        const returnData = data?.data
        const finalData = returnData?.allAdmin?.map(el => el?.email)
        return finalData

    } catch (error) {
        toast.error(error.response.data.message)
        console.log(error.response.data.message)
    }
}

export const getDownloadAssetUrl = async (params) => {
    const userToken = localStorage.getItem('userToken')
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${userToken}`
        }
    }
    const { orderId, orderItemId } = params
    const body = {
        orderId,
        orderItemId
    }
    try {
        const { data } = await axios.post(`${base_url}/order/download/long`, body, config)
        const returnData = data.data?.url
        return returnData
    } catch (error) {
        toast.error(error.response.data.message)
        console.log(error.response.data.message)
    }
}


export const searchResultsSideImage = async ({ selectTerm, limit, keywords }) => {
    const params = {
        params: {
            "search[keyword]": keywords,
            "search[category]": selectTerm,
            "sortBy[viewCount]": true,
            limit: limit ? limit : 200
        }
    }
    try {
        const { data } = await axios.get(`${base_url}/media/search/full-text`, params)
        const returnData = data?.data?.mediaData
        let finalDataSideImage = returnData.map((obj) => ({
            id: obj.id,
            mediaSrc: obj.contentSizes[0]?.thumbnailSrc,
            previewSrc: obj.contentSizes[0]?.previewSrc
        }))
        return finalDataSideImage
    } catch (error) {
        // toast.error(error.response.data.message)
        console.log(error.response.data.message)
    }
}

export const getSearchResults = async ({ selectTerm, limit, keywords }) => {
    const params = {
        params: {
            "search[keywords]": keywords,
            "search[category]": selectTerm,
            "sortBy[viewCount]": true,
            limit: limit ? limit : 200
        }
    }
    try {
        const { data } = await axios.get(`${base_url}/media/search/full-text`, params)
        const returnData = data?.data?.mediaData
        let finalDataSideImage = returnData.map((obj) => ({
            id: obj.id,
            mediaSrc: obj.contentSizes[0]?.thumbnailSrc,
            previewSrc: obj.contentSizes[0]?.previewSrc
        }))
        return finalDataSideImage
    } catch (error) {
        // toast.error(error.response.data.message)
        console.log(error.response.data.message)
    }
}

export const sellerContentUpload = async (files) => {
    const sellerToken = localStorage.getItem('sellerToken')
    const config = {
        headers: {
            "Authorization": `Bearer ${sellerToken}`
        },
    }
    try {
        await Promise.all(files.map(async (file) => {
            const formData = new FormData()
            formData.append('file', file)
            await axios.post(SELLER_UPLOAD_URL, formData, config)
        }))
        toast.success('Files Uploaded Successfully')

    } catch (error) {
        toast.error(error.response.data.message)
    }
}

export const getSellerPageMediaByStatus = async (status) => {
    const sellerToken = localStorage.getItem('sellerToken')
    const config = {
        headers: {
            "Authorization": `Bearer ${sellerToken}`
        },
    }
    const body = {
        "search": {
            "mediaStatus": status
        }
    }
    try {
        const { data } = await axios.post(`${base_url}/seller/media/search`, body, config)
        const returnData = data?.data?.media
        let finalMedia = returnData?.map((obj) => ({
            id: obj.id,
            mediaSrc: obj.contentSizes[0]?.thumbnailSrc,
            previewSrc: obj.contentSizes[0]?.previewSrc
        }))
        return finalMedia
    } catch (error) {
        toast.error(error.response.data.message)
    }
}

export const getSellerProfile = async () => {
    const sellerToken = localStorage.getItem('sellerToken')
    const config = {
        headers: {
            "Authorization": `Bearer ${sellerToken}`
        },
    }
    try {
        const { data } = await axios.get(`${base_url}/seller`, config)
        const returnData = data?.data?.seller
        const finalData = { Name: `${returnData?.firstName} ${returnData?.lastName}`, Initials: `${returnData?.firstName[0]}${returnData?.lastName[0]}` }
        return finalData
    } catch (error) {
        toast.error(error.response.data.message)
    }
}