import React from "react";
import './header.css';
import SearchBar from "../../reusable/SearchBar";

const Header = ({ headerHeadingContent, headerSubHeading, headerTrending, style, searchBarStyles, initialState, headerHeight }) => {
    return (
        <div className={`header ${headerHeight}`} style={style}>
            <div className="header-container">
                <SearchBar searchBarStyles={searchBarStyles} initialState={initialState} />
            </div>
        </div>
    )
}

export default Header;