import React, { useEffect, useState } from "react";
import { Navbar } from "../../common/Navbar";
import { Header } from "../../pageContent/Header";
import './imagesPage.css';
import { BecomeASeller } from "../../common/BecomeASeller";
import { Footer } from "../../common/Footer";
import SubHeading from "../../pageContent/SubHeading/SubHeading";
import { getCategories, getCategoryData } from "../../../api";
import previewBannerVideo from '../../../assets/mastHead/mastheadBanner.mp4';
import ipfallBackImage from '../../../assets/images/ImageBanner.png';
import companyLogo from '../../../assets/logo/WorldArchivesImagesLight.png';
import PopularCategory from "../../common/PopularCategories/PopularCategory";
import { getAdminSetting } from "../../../features/admin/adminActions";
import { useDispatch, useSelector } from "react-redux";

const ImagesPage = () => {
    const dispatch = useDispatch()
    const {popularCategoryKeywords} = useSelector(state => state.admin)
    const [categories, setCategories] = useState([])
    const [imgArr, setImgArr] = useState([])


    useEffect(() => {
        if (!categories || !categories.length) return
        const getAllCategoriesData = async () => {
            let catData = await getCategories('IMAGES').then(res => res)
            const finalData = await Promise.all(catData?.map(async (category) => {
                return await getCategoryData('IMAGE', category)
            }),
            )
            if (categories.length && (categories.length === finalData.length)) setImgArr(finalData)
        }
        getAllCategoriesData()
    }, [categories])

    useEffect(() => {
        getCategories('IMAGES').then(res => setCategories(res))
        localStorage.setItem('selectTerm', 'IMAGE')
    }, [])

    useEffect(()=>{
        dispatch(getAdminSetting('IMAGE_PAGE'))
    },[dispatch])

    return (
        <div className="imagesPage">
            <div className="imagesPage-banner">
                <Navbar companyLogo={companyLogo} logoStyles='navbar-logoStyle-common' menuItemStyles="imagesPage-menu-items" cartColor="#FFFFFF" hamburgerColor="#ffffff" />
                <Header
                    headerHeadingContent='Unleash Your Creativity'
                    headerSubHeading="Add wonder to your stories with 200M+ photos,vectors, illustrations, and editorial images"
                    headerTrending="mountains,dark,nature,flowers,space"
                    searchBarStyles='imagePage-SearchBar'
                    initialState='IMAGE'
                    headerHeight='imagePageHeaderHeight'
                />
                <video className="image-banner-video" autoPlay loop muted width="100%">
                    <source src={previewBannerVideo} type="video/mp4" />
                    <img src={ipfallBackImage} alt="Fallback Image" />
                </video>
            </div>
            <PopularCategory popularCategoryKeywords={popularCategoryKeywords} selectTerm="IMAGE"/>
            <div className="imagesPage-pageContent">
                {categories.length ? categories.map((el, idx) => <SubHeading key={idx} heading={el} requiredArray={imgArr[idx]} pageName="IMAGE" />) : <></>}
            </div>
            <BecomeASeller />
            <Footer />
        </div>
    )
}

export default ImagesPage;