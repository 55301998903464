import React, { useState } from "react";
import './joinCommunity.css';
import { useNavigate } from "react-router-dom";
import { FormControl, FormControlLabel, Radio, RadioGroup, selectClasses } from '@mui/material';
import { useSellerPagesContext } from "../../contexts/SellerPagesContext";
import { ToastContainer, toast } from 'react-toastify';
import company_logo from '../../assets/logo/WorldArchivesDark.png'

const JoinCommunity = () => {
    const { selectedOption, setSelectedOption } = useSellerPagesContext()
    const navigate = useNavigate()

    const handleChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const onClicklogin = () => {
        navigate('/login')
    }

    const onClickNext = () => {
        if (selectedOption === 'INDIVIDUAL' || selectedOption === 'COMPANY') {
            localStorage.setItem("selectedOption",selectedOption)
            navigate('/seller/registration')
        } else {
            if (!toast.isActive('optionError')) {
                toast.error('Please select an Option', { toastId: 'optionError' });
            }

        }
    }

    return (
        <div className="seller">
            <div className="seller-companyLogo">
                <img src={company_logo} alt="Company Logo" className="navbar-logoStyle-common"/>
            </div>
            <div className="seller-container">
                <div className="jcp-heading">
                    Join the Community
                </div>
                <div className="jcp-joining-options">
                    <FormControl component="fieldset">
                        <RadioGroup value={selectedOption} onChange={handleChange}>
                            <FormControlLabel
                                sx={{
                                    fontFamily: 'Open Sans, sans-serif'
                                }}
                                value="INDIVIDUAL"
                                control={<Radio />}
                                label="Are you an individual creator?"
                            />
                            <FormControlLabel
                                sx={{
                                    fontFamily: 'Open Sans, sans-serif'
                                }}
                                value="COMPANY"
                                control={<Radio />}
                                label="Do you represent a company?"
                            />
                        </RadioGroup>
                    </FormControl>
                </div>
                <div className="jcp-button-link">
                    <button className="jcp-button" onClick={onClickNext}>Next</button>
                    <div className="jcp-link">
                        Already have an account?&nbsp;<span onClick={onClicklogin}>Log In</span>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default JoinCommunity;