import React, { useEffect } from 'react';
import './searchResultsPage.css';
import Navbar from '../../common/Navbar/Navbar';
import SearchBar from '../../reusable/SearchBar/index';
import Footer from '../../common/Footer/Footer';
import logoMain from '../../../assets/logo/WorldArchivesDark.png';
import { useSelector, useDispatch } from 'react-redux';
import ImageGallery from '../../reusable/ImageGallery/ImageGallery';
import { Loader } from '../../common/Loader'
import { searchResults } from '../../../features/search/searchActions';

const SearchResultsPage = () => {
    const dispatch = useDispatch()
    const { searchData, loading, nextKey, stampCategory } = useSelector((state) => state.search)
    let fieldTerm = localStorage.getItem('fieldTerm')
    let selectTerm = localStorage.getItem('selectTerm')

    useEffect(() => {
        dispatch(searchResults({ fieldTerm: fieldTerm, selectTerm: selectTerm }))
    }, [fieldTerm, selectTerm])

    // const handleClick = () => {
    //     dispatch(getMediaSearchPagination({ pageName: selectTerm, category: stampCategory, nextKey: nextKey }))
    //     window.scrollTo(0, 0)
    // }
    return (
        <div className='srp'>
            <Navbar menuItemStyles="navbar-menu-items" logoStyles="navbar-logoStyle" companyLogo={logoMain} cartColor="#666666" />
            <div className='srp-container'>
                <div className='srp-searchBar'>
                    <SearchBar searchBarStyles="srp-searchbar-styles" initialState={selectTerm} />
                </div>
                {fieldTerm ? <div className='srp-container-heading'>
                    <p>Showing results for</p><span className='fw-700'>&nbsp;{fieldTerm?.length ? fieldTerm : selectTerm}</span>
                </div> : null}
            </div>
            <div>
                {loading ? <Loader /> :
                    <>{searchData === null ?
                        <div className='txt-center' style={{ margin: "6.75rem" }}>No results found</div> :
                        <div style={{ margin: "3rem" }}><ImageGallery imageArray={searchData} pageName={selectTerm} />
                        </div>
                    }</>}
            </div>
            <Footer />
        </div>
    )
}

export default SearchResultsPage;