import React, { useState } from "react";
import './byProduct.css'

const ByProduct = () => {


    return (
        <div className="byProduct">
            <div className="byp-table-container">
                <div className="fw-600">There is no information to display</div>
            </div>
            <div className="byp-msg">
                We update your earnings data every hour. When viewing fewer dates, days without any downloads are hidden. All earnings are reflect in Indian Rupees.
            </div>
        </div>
    )
}

export default ByProduct;