import React from "react";
import { Navbar } from "../../common/Navbar";
import './mission.css';
import logoMain from '../../../assets/logo/WorldArchivesDark.png';
import { Footer } from "../../common/Footer";

const Mission = () => {
    return (
        <div className="mission">
            <Navbar menuItemStyles="navbar-menu-items" logoStyles="navbar-logoStyle" companyLogo={logoMain} cartColor="#666666" />
            <div className="mission-container">
                <div className="fs-22 fw-700 ft_mg_btm ft_ul_cmn">
                    Mission
                </div>
                <div className="mission-content">
                    <span className="fw-400">
                        Our goal is to capture, create, and preserve all kinds of stock
                        content from various cultural, social as well as commercial backgrounds.
                        World Archives aims at collecting, owning and selling the assets and
                        to open doors for our creative customers.
                        We aim to empower the creative economy and fuel up our visual
                        storytelling through our content globally.
                    </span>
                    <span className="fw-400">
                        Our mission is to help you create a niche for your brands,
                        your customers, readers and viewers and helps you to connect
                        with audiences at a larger level.
                    </span>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Mission;