import React, { useEffect, useState } from "react";
import './cartCheckout.css';
import { useAdminPagesContext } from "../../../contexts/AdminPagesContext";
import { useDispatch, useSelector } from "react-redux";
import { updateUserAddress } from "../../../features/search/searchActions";
import { cartCheckout, cartCheckoutCCAvenue, getUserAddress } from "../../../api";
import { useForm } from "react-hook-form";
import { Loader } from "../../common/Loader";
import { Box, Modal } from "@mui/material";
import { MdOutlineCancel } from 'react-icons/md';
import { useNavigate } from "react-router-dom";

const paymentOptionStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    outline: 'none',
    boxShadow: 24,
    padding: '1rem 1.5rem',
    height: 160
};

const CartCheckout = ({ getIcon }) => {
    const [loading, setLoading] = useState(false)
    const { register, handleSubmit, formState: { errors } } = useForm();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { cartData, cartDiscount, cartFinalPrice, cartItems, cartTotal, paymentSessionId } = useSelector(state => state.search)
    const { cartActiveStep, setCartActiveStep } = useAdminPagesContext()
    const [openPaymentOption, setOpenPaymentOption] = useState(false)
    const [data, setData] = useState({
        id: "",
        address_line_1: "",
        address_line_2: "",
        city: "",
        country: "",
        email: "",
        phone: "",
        state: "",
        zip: ""
    })

    const onClickCompletCheckout = async () => {
        await dispatch(updateUserAddress({ ...data, name: "test" }))
        setOpenPaymentOption(true)
    }

    const handleClosePaymentOption = () => {
        setOpenPaymentOption(false)
    }


    const onClickBackBtn = () => {
        setCartActiveStep(cartActiveStep - 1)
    }

    function handleCheckout(id) {
        const cashfree = new window.Cashfree(id);
        cashfree.redirect();
    }

    function handleCCAvenueCheckout(id) {
        navigate(`/payment/ccavenue/${id}`)
    }

    useEffect(() => {
        async function getAddressData() {
            const addressData = await getUserAddress()
            setData(addressData)
        }
        getAddressData()
    }, [])

    const onClickContinueWithCashFree = async () => {
        await cartCheckout(data?.id).then(res => handleCheckout(res))
    }

    const onClickContinueWithCCAvenue = async () => {
        await cartCheckoutCCAvenue(data?.id).then(res => handleCCAvenueCheckout(res))
    }

    const handleAddressInputChange = (event) => {
        const { name, value } = event?.target;
        setData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    return (
        <>
            {!loading ?
                <div className="cartCheckout">
                    <div className="cartCheckout-content">
                        <div className="cartCheckout-Heading-commonSty">
                            Billing Address
                        </div>
                        <div className="cartCheckout-billingInputFields">
                            <input
                                placeholder="Address Line 1"
                                name="address_line_1"
                                className={errors.address_line_1 ? "cartCheckout-inputCommonStyles outline-error" : "cartCheckout-inputCommonStyles"}
                                type="string"
                                value={data ? data?.address_line_1 : ""}
                                onChange={handleAddressInputChange}
                            />
                            <input
                                placeholder="Address Line 2"
                                name="address_line_2"
                                className={errors.address_line_2 ? "cartCheckout-inputCommonStyles outline-error" : "cartCheckout-inputCommonStyles"}
                                type="string"
                                value={data ? data?.address_line_2 : ""}
                                onChange={handleAddressInputChange}
                            />
                            <div className="cartCheckout-billinParallelFields-commonSty">
                                <input
                                    placeholder="Email"
                                    name="email"
                                    className={errors.email ? "cartCheckout-inputCommonStyles checkoutInputAddSty outline-error" : "cartCheckout-inputCommonStyles checkoutInputAddSty"}
                                    type="email"
                                    value={data ? data?.email : ""}
                                    onChange={handleAddressInputChange}
                                />
                                <input
                                    placeholder="Phone Number"
                                    name="phone"
                                    type="number"
                                    className={errors.phone ? "cartCheckout-inputCommonStyles outline-error" : "cartCheckout-inputCommonStyles"}
                                    value={data ? data?.phone : ""}
                                    onChange={handleAddressInputChange}
                                />
                            </div>
                            <div className="cartCheckout-billinParallelFields-commonSty">
                                <input
                                    placeholder="Country"
                                    name="country"
                                    className={errors.country ? "cartCheckout-inputCommonStyles checkoutInputAddSty outline-error" : "cartCheckout-inputCommonStyles checkoutInputAddSty"}
                                    type="country"
                                    value={data ? data?.country : ""}
                                    onChange={handleAddressInputChange}
                                />
                                <input
                                    placeholder="City"
                                    name="city"
                                    className={errors.city ? "cartCheckout-inputCommonStyles outline-error" : "cartCheckout-inputCommonStyles"}
                                    type="text"
                                    value={data ? data?.city : ""}
                                    onChange={handleAddressInputChange}
                                />
                            </div>
                            <div className="cartCheckout-billinParallelFields-commonSty">
                                <input
                                    placeholder="State/Province/Region"
                                    name="state"
                                    className={errors.state ? "cartCheckout-inputCommonStyles checkoutInputAddSty outline-error" : "cartCheckout-inputCommonStyles checkoutInputAddSty"}
                                    type="text"
                                    value={data ? data?.state : ""}
                                    onChange={handleAddressInputChange}
                                />
                                <input
                                    placeholder="Zip"
                                    name="zip"
                                    type="number"
                                    value={data ? data?.zip : `00000`}
                                    className={errors.zip ? "cartCheckout-inputCommonStyles outline-error" : "cartCheckout-inputCommonStyles"}
                                    onChange={handleAddressInputChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="viewCart-orderSummary">
                        <div className="viewCart-headingsCommonSty">
                            Order Summary
                        </div>
                        <div className="viewCart-orderSummaryContainer">
                            <div className="viewCart-orderSummaryContainer-orders">
                                {cartData?.map((item, index) => (
                                    <div className="viewCart-orderSummaryContainer-orderDetails" key={index}>
                                        <div className="viewCart-orderDetails-name">
                                            {getIcon(item?.category)}
                                            <p>{item?.name}</p>
                                        </div>
                                        <div className="viewCart-orderDetails-planAndPrice">
                                            <p className="fw-500">One time purchase</p>
                                            <p className="fw-600">{`INR ${item?.price}`}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="viewCart-orderTotalAmount">
                                <p>Estimated amount due today</p>
                                <p>{`INR ${cartFinalPrice}`}</p>
                            </div>
                            <button type="submit" className="viewCartPtc-button" onClick={onClickCompletCheckout}>Complete checkout</button>
                            <div className="viewCartCs-button">
                                <button onClick={onClickBackBtn}> Go Back </button>
                            </div>
                        </div>
                    </div>
                </div>
                : <div style={{ height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Loader /></div>
            }

            <Modal
                open={openPaymentOption}
                onClose={handleClosePaymentOption}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={paymentOptionStyle}>
                    <div className='paymentOptionPopup'>
                        <div className='paymentOptionPopup-headingAndClose'>
                            <div className="paymentOptionPopup-heading">
                                Are you sure you want to Delete ?
                            </div>
                            <div className='paymentOptionPopup-close' onClick={handleClosePaymentOption}>
                                <MdOutlineCancel fontSize="19px" />
                            </div>
                        </div>
                        <div className='paymentOptionPopup-optionBtn'>
                            <button onClick={onClickContinueWithCashFree}>Continue With Cash Free</button>
                            <span>OR</span>
                            <button onClick={onClickContinueWithCCAvenue}>Continue With CCAvenue</button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    )
}

export default CartCheckout;