import React from "react";

const Button = ({children, btnStyle, onClick, type,}) => {
    return(
        <button className={`btn ${btnStyle}`} onClick={onClick} type={`${type}`}>
            {children}
        </button>
    )
}

export default Button;