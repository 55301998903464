import React, { useEffect, useState } from "react";
import { BecomeASeller } from "../../common/BecomeASeller";
import { Footer } from "../../common/Footer";
import { Navbar } from "../../common/Navbar";
import { Header } from "../../pageContent/Header";
import SubHeading from "../../pageContent/SubHeading/SubHeading";
import './vfxPage.css';
import { getCategories, getCategoryData } from "../../../api";
import companyLogo from '../../../assets/logo/WorldArchivesVfxLight.png';
import previewBannerVideo from '../../../assets/mastHead/mastheadBanner.mp4';
import ipfallBackImage from '../../../assets/images/ImageBanner.png';
import PopularCategory from "../../common/PopularCategories/PopularCategory";
import { getAdminSetting } from "../../../features/admin/adminActions";
import { useDispatch, useSelector } from "react-redux";

const VfxPage = () => {
    const dispatch = useDispatch()
    const {popularCategoryKeywords} = useSelector(state => state.admin)
    const [categories, setCategories] = useState([])
    const [imgArr, setImgArr] = useState([])

    useEffect(() => {
        if (!categories || !categories.length) return
        const getAllCategoriesData = async () => {
            let catData = await getCategories('VFX').then(res => res)
            const finalData = await Promise.all(catData?.map(async (category) => {
                return await getCategoryData('VFX', category)
            })
            )
            if (categories.length && (categories.length === finalData.length)) setImgArr(finalData)
        }
        getAllCategoriesData()
    }, [categories])

    useEffect(() => {
        getCategories('VFX').then(res => setCategories(res))
        localStorage.setItem('selectTerm', 'VFX')
    }, [])

    useEffect(() => {
        dispatch(getAdminSetting('VFX_PAGE'))
    }, [dispatch])

    return (
        <div className="vfxPage">
            <div className="vfxPage-banner">
                <Navbar companyLogo={companyLogo} logoStyles='navbar-logoStyle-common' menuItemStyles="vfxPage-menu-items" cartColor="#FFFFFF" hamburgerColor="#ffffff" />
                <Header
                    headerHeadingContent='Making Imagery into Reality'
                    headerSubHeading="Alter reality with a mesmerising collection of visual effects."
                    searchBarStyles='vfxPage-SearchBar'
                    initialState="VFX"
                    headerHeight="vfxPageHeaderHeight"
                />
                <video className="vfx-banner-video" autoPlay loop muted width="100%">
                    <source src={previewBannerVideo} type="video/mp4" />
                    {/* Fallback image */}
                    <img src={ipfallBackImage} alt="Fallback Image" />
                </video>
            </div>
            <PopularCategory popularCategoryKeywords={popularCategoryKeywords} selectTerm="VFX"/>
            <div className="">
                {categories.length ? categories.map((el, idx) => <SubHeading key={idx} heading={el} requiredArray={imgArr[idx]} pageName="VFX" />) : <></>}
            </div>
            <BecomeASeller />
            <Footer />
        </div>
    )
}

export default VfxPage;