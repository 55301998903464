import { createSlice } from "@reduxjs/toolkit";
import { getSettings } from "./settingActions";

const initialState = {
    loading:'false',
    error: null,
    success: false
}


const settingSlice = createSlice({
    name:'setting',
    initialState,
    extraReducers: {
        [getSettings.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [getSettings.fulfilled]: (state, {payload}) => {
            state.loading = false
            state.error = null
            state.allSettings = payload
        },
        [getSettings.rejected]: (state) => {
            state.loading = false
            state.error = false
        }
    }
})

export default settingSlice.reducer