import React, { useState } from "react";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ToSubmit from "../../sellerPageContent/toSubmit/ToSubmit";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const SellerTabs = () => {
    const [mediaTabValue, setMediaTabValue] = useState(0)

    const handleMediaTabsChange = (event, newValue) => {
        setMediaTabValue(newValue)
    }

    return (
        <Box sx={{ width: '100%', '& .css-19kzrtu': { padding: '0px' } }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', fontFamily: "Open Sans" }}>
                <Tabs
                    value={mediaTabValue}
                    onChange={handleMediaTabsChange}
                    aria-label="basic tabs example"
                    sx={{
                        paddingLeft: "25px",
                        "& .css-heg063-MuiTabs-flexContainer": {
                            gap: '40px'
                        }
                    }}
                >
                    <Tab label="To Submit(0)" {...a11yProps(0)}
                        sx={{
                            textTransform: 'none',
                            fontFamily: "Open Sans",
                            fontSize: '14px',
                            fontWeight: '600',
                            color: '#444444',
                        }}
                    />
                    <Tab label="Under Revision(0)" {...a11yProps(1)}
                        sx={{
                            textTransform: 'none',
                            fontFamily: "Open Sans",
                            fontSize: '14px',
                            fontWeight: '600',
                            color: '#444444'
                        }}
                    />
                    <Tab label="Rejected(0)" {...a11yProps(2)}
                        sx={{
                            textTransform: 'none',
                            fontFamily: "Open Sans",
                            fontSize: '14px',
                            fontWeight: '600',
                            color: '#444444'
                        }}
                    />
                    <Tab label="Published(0)" {...a11yProps(3)}
                        sx={{
                            textTransform: 'none',
                            fontFamily: "Open Sans",
                            fontSize: '14px',
                            fontWeight: '600',
                            color: '#444444'
                        }}
                    />
                </Tabs>
            </Box>
            <TabPanel value={mediaTabValue} index={0}>
                <ToSubmit status="PENDING"/>
            </TabPanel>
            <TabPanel value={mediaTabValue} index={1}>
                <ToSubmit status="SUBMITTED"/>
            </TabPanel>
            <TabPanel value={mediaTabValue} index={2}>
                <ToSubmit status="REJECTED"/>
            </TabPanel>
            <TabPanel value={mediaTabValue} index={3}>
                <ToSubmit status="APPROVED"/>
            </TabPanel>
        </Box>
    );
}

export default SellerTabs;