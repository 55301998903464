import React, { useEffect } from 'react';
import './signUp.css';
import Logo from '../../../assets/logo/WorldArchivesDark.png'
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import Button from '../../reusable/Button';
import { FcGoogle } from 'react-icons/fc';
import { BsFacebook } from 'react-icons/bs';
import { userGoogleLogin, userFacebookLogin } from '../../../features/users/userActions'
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import PictureTiles from '../../reusable/PictureTiles/PictureTiles';
import { useSelector, useDispatch } from 'react-redux';
import { userSignup } from '../../../features/users/userActions'
import { ToastContainer } from 'react-toastify';
import { OutlinedInput, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { resetSignupSuccess } from '../../../features/users/userSlice';

const signUpPasswordStyle = {
  fontFamily: 'Open Sans',
  fontSize: '0.85rem',
  fontWeight: '600',
  border: '1px solid #c5c5c5',
  borderRadius: '5px',
  height: '2.4rem',
  width: '100%',
  padding: '0px 10px',
  '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
    fontFamily: 'Open Sans',
    padding: '0rem',
    fontWeight: '500',
    letterSpacing: '0px',
    color: '#000000'
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: "#C8C7C7",
    outline: 'none',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    border: '#C8C7C7',
  },
  '@media only screen and (min-width: 375px) and (max-width :425px)':{
    height: '2.2rem',
    fontSize: '0.8rem',
    color:"#000000"
  }
}

const SignUp = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID
  const fbAppId = process.env.REACT_APP_FB_APP_ID
  const { signupSuccess, fbSuccess, googleSuccess } = useSelector((state) => state.user)

  const { register, handleSubmit, formState: { errors } } = useForm();

  const onClickLogIn = () => {
    navigate('/login')
  }

  const onSubmit = (data) => {
    dispatch(userSignup(data))
  }

  const onClickGoBackToLogin = () => {
    dispatch(resetSignupSuccess())
    navigate('/login')
  }

  const onClickSignUpTermsAndConditions = () => {
    navigate('/terms-conditions')
  }

  const onClickSignUpPrivacyPolicy = () => {
    navigate('/privacy-policy')
  }

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const responseGoogle = (data) => {
    dispatch(userGoogleLogin(data.credential))
  }

  const responseFb = async ({ accessToken }) => {
    await dispatch(userFacebookLogin(accessToken))
  }

  useEffect(() => {
    if (fbSuccess || googleSuccess) setTimeout(() => navigate('/'), 2500)
  }, [fbSuccess, googleSuccess, navigate])

  return (
    <GoogleOAuthProvider
      clientId={googleClientId}
    >
      <div className='signUp'>
        <div className='signUp-form'>
          <div>
            <img src={Logo} alt="logo" className='signUp-company-logo' onClick={() => navigate('/')} />
          </div>
          {
            signupSuccess ?
              <>
                <div className='signUp_success_msgContainer'>
                  <div className='signUp_success_heading'>Welcome to World Archives!</div>
                  <div className='signUp_success_msg'>Thank you for signing up for our service. We have sent a&nbsp;<span className='fw-700'>verification link</span>&nbsp;to the&nbsp;<span className='fw-700'>email address</span>&nbsp;you provided. Please click on the&nbsp;<span className='fw-700'>link</span>&nbsp;to complete the&nbsp;<span className='fw-700'>verification process</span>&nbsp;and start using our service.</div>
                  <div className='signUp_success_msg'>If you do not see the email in your inbox, please check your spam or junk mail folder. If you still cannot find the email, please contact us for assistance.</div>
                  <button onClick={onClickGoBackToLogin}>Go back to login</button>
                  <div className='signUp_success_support'>
                    <span className='support_heading'>Contact us on</span>
                    <span className='support_emailId'>support@worldarchives.in</span>
                  </div>
                </div>
              </> :
              <div className='signUp-form-content'>
                <span className='signUp-heading'>Sign Up</span>
                <div className='signUp-text'>
                  <span style={{ color: '#888888' }}>Already Have An Account?</span>
                  <span onClick={onClickLogIn} className='signUp-form-redirection'>Sign In</span>
                </div>
                <form className='signUp-form-container' onSubmit={handleSubmit(onSubmit)}>
                  <div className='signUp-input-initial'>
                    <div className='signUp-input-initial-firstName'>
                      <input
                        className={errors.firstName ? 'signUp-input outline-error' : 'signUp-input'}
                        placeholder='First Name'
                        type="text"
                        {...register("firstName",
                          {
                            required: "First Name is required",
                          })}
                      />
                      <div className="signUp-text-error">{errors.firstName ? `${'*'}${errors.firstName.message}` : null}</div>
                    </div>
                    <div className='signUp-input-initial-lastName'>
                      <input
                        className={errors.lastName ? 'signUp-input outline-error' : 'signUp-input'}
                        placeholder='Last Name'
                        type="text"
                        {...register("lastName",
                          {
                            required: "Last Name is required",
                          })}
                      />
                      <div className="signUp-text-error">{errors.lastName ? `${'*'}${errors.lastName.message}` : null}</div>
                    </div>
                  </div>
                  <div className='signUp-form-textField'>
                    <input
                      className={errors.email ? 'signUp-input outline-error' : 'signUp-input'}
                      placeholder='Email address'
                      type="email"
                      {...register("email",
                        {
                          required: "Email is required"
                        })}
                    />
                    <div className="signUp-text-error">{errors.email ? `${'*'}${errors.email.message}` : null}</div>
                  </div>
                  <div className='signUp-form-textField inputCompanyAddSty'>
                    <input
                      className='signUp-input'
                      placeholder='Company Name'
                      type="text"
                    />
                  </div>
                  <div className='signUp-form-textField signUp-password-addSty'>
                    <OutlinedInput
                      className={errors.password ? 'signUp-input outline-error' : 'signUp-input'}
                      placeholder='Password'
                      type={showPassword ? 'text' : 'password'}
                      {...register("password", {
                        required: "Password is required",
                        pattern: {
                          value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,}$/,
                          message: `Entered value does not match our password policy`
                        },
                        minLength: { value: 8, message: "password is too short" },
                      })}
                      autoFocus
                      sx={signUpPasswordStyle}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff style={{ fontSize: "1.3rem", color: "#666666", marginRight: '0.2rem' }} /> : <Visibility style={{ fontSize: "1.3rem", color: "#666666", marginRight: '0.2rem' }} />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    <div className="signUp-text-error">{errors.password ? `${'*'}${errors.password.message}` : null}</div>
                  </div>
                  <Button btnStyle="button signUp-submit" type="submit">Create an account</Button>
                </form>
                <div className="signUp-wrapper">
                  <div className="signUp-line"></div>
                  <div className="signUp-line-text">
                    Or
                  </div>
                  <div className="signUp-line"></div>
                </div>
                <div>
                  <GoogleLogin
                    clientId={googleClientId}
                    render={renderProps => (
                      <Button onClick={renderProps.onClick} disabled={renderProps.disabled}><FcGoogle style={{ fontSize: '20px', marginRight: '10px' }} /> Sign in with Google</Button>
                    )}
                    buttonText="Login"
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    cookiePolicy={'single_host_origin'}
                    type='standard'
                    theme='outline'
                    size='medium'
                  />
                </div>
                <div className="signUp-btn-container">
                  <FacebookLogin
                    appId={fbAppId}
                    render={
                      renderProps => (
                        <Button onClick={renderProps.onClick} disabled={renderProps.disabled}><BsFacebook style={{ fontSize: '20px', marginRight: '5px', color: '#4267B2' }} />&nbsp;&nbsp;Sign in with Facebook </Button>
                      )
                    }
                    fields="name,email,picture"
                    scope="public_profile,email"
                    callback={responseFb}
                  />
                </div>
                <div className='signUp-policies'>
                  <span>By continuing, you agree to Worldarchives <span onClick={onClickSignUpTermsAndConditions} className='signup-policy-links'>Terms & Conditions</span> and <span onClick={onClickSignUpPrivacyPolicy} className='signup-policy-links'>Privacy Policy</span></span>
                </div>
              </div>
          }
        </div>
        <ToastContainer limit={3} theme="colored" autoClose={1200} />
      </div>
    </GoogleOAuthProvider>
  )
}


export default SignUp;