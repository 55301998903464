import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify"

const base_url = process.env.REACT_APP_BASE_URL
const ADMIN_SEARCH_URL = `${base_url}/admin/media/search`
const ADMIN_MEDIA_URL = `${base_url}/admin/media`
const ADMIN_IMPORT_URL = `${base_url}/admin/media/import`

export const adminContent = createAsyncThunk(
    'admin/adminContent',
    async ({
        pageName,
        keyword,
        shootLocation,
        pov,
        mediaStatus,
        title,
        camera,
        contributorName,
        price_less,
        price_more,
        date_less,
        date_more,
        resolution,
        videoDuration,
        contentCategory,
        typeOfAngle,
        visibility
    }, { rejectWithValue }) => {
        let body = {
            "search": {
                "category": pageName,
                "keyword": keyword ? keyword : '',
                shootLocation,
                pov,
                mediaStatus,
                title,
                camera,
                contributorName,
                price_less,
                price_more,
                date_less,
                date_more,
                resolution,
                videoDuration,
                contentCategory,
                typeOfAngle,
                visibility
            },
            "sortBy": {
                "createdAt": true
            },
            "nextKey": null,
            "showAll": true
        }
        const adminToken = localStorage.getItem('adminToken')
        const config = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${adminToken}`
            }
        }
        try {
            const { data } = await axios.post(ADMIN_SEARCH_URL, body, config)
            const mediaData = data?.data?.media
            const finalData = mediaData?.map(obj => ({
                id: obj.id,
                contentId: obj.id,
                img: obj.contentSizes[0]?.thumbnailSrc,
                imgName: obj?.title,
                price: obj?.price,
                user: obj?.contributorName,
                date: obj?.updatedAt,
                status: obj?.mediaStatus,
                visibility: obj?.visibility === "NOT_PUBLISHED" ? "NOTPUBLISHED" : obj?.visibility,
                reason: obj?.reason,
                scheduleDate: obj?.scheduleDate
            }))
            return { finalData, body }

        } catch (error) {
            if (error.response) {
                toast.error(error.response)
                return rejectWithValue(error.response)
            } else {
                toast.error(error.message)
                return rejectWithValue(error.message)
            }
        }
    }
)

export const adminContentPublished = createAsyncThunk(
    'admin/adminContentPublished',
    async ({ pageName }, { rejectWithValue }) => {
        let body = {
            "search": {
                "category": pageName,
                "visibility": "PUBLISHED"
            },
            "sortBy": {
                "createdAt": true
            },
            "nextKey": null
        }
        const adminToken = localStorage.getItem('adminToken')
        const config = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${adminToken}`
            }
        }
        try {
            const { data } = await axios.post(ADMIN_SEARCH_URL, body, config)
            const mediaData = data?.data?.media
            const finalData = mediaData?.map(obj => ({
                id: obj.id,
                contentId: obj.id,
                img: obj.contentSizes[0]?.thumbnailSrc,
                imgName: obj?.title,
                price: obj?.price,
                user: obj?.contributorName,
                date: obj?.updatedAt,
                status: obj?.mediaStatus,
                visibility: obj?.visibility,
                reason: obj?.reason
            }))
            return finalData

        } catch (error) {
            if (error.response) {
                toast.error(error.response)
                return rejectWithValue(error.response)
            } else {
                toast.error(error.message)
                return rejectWithValue(error.message)
            }
        }
    }
)

export const adminContentUnpublished = createAsyncThunk(
    'admin/adminContentUnpublished',
    async ({ pageName }, { rejectWithValue }) => {
        let body = {
            "search": {
                "category": pageName,
                "visibility": "NOT_PUBLISHED"
            },
            "sortBy": {
                "createdAt": true
            },
            "nextKey": null
        }
        const adminToken = localStorage.getItem('adminToken')
        const config = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${adminToken}`
            }
        }
        try {
            const { data } = await axios.post(ADMIN_SEARCH_URL, body, config)
            const mediaData = data?.data?.media
            const finalData = mediaData?.map(obj => ({
                id: obj.id,
                contentId: obj.id,
                img: obj.contentSizes[0]?.thumbnailSrc,
                imgName: obj?.title,
                price: obj?.price,
                user: obj?.contributorName,
                date: obj?.updatedAt,
                status: obj?.mediaStatus,
                visibility: obj?.visibility,
                reason: obj?.reason
            }))
            return finalData

        } catch (error) {
            if (error.response) {
                toast.error(error.response)
                return rejectWithValue(error.response)
            } else {
                toast.error(error.message)
                return rejectWithValue(error.message)
            }
        }
    }
)

export const adminContentRejected = createAsyncThunk(
    'admin/adminContentRejected',
    async ({ pageName }, { rejectWithValue }) => {
        let body = {
            "search": {
                "category": pageName,
                "mediaStatus": "REJECTED"
            },
            "sortBy": {
                "createdAt": true
            },
            "nextKey": null
        }
        const adminToken = localStorage.getItem('adminToken')
        const config = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${adminToken}`
            }
        }
        try {
            const { data } = await axios.post(ADMIN_SEARCH_URL, body, config)
            const mediaData = data?.data?.media
            const finalData = mediaData?.map(obj => ({
                id: obj.id,
                contentId: obj.id,
                img: obj.contentSizes[0]?.thumbnailSrc,
                imgName: obj?.title,
                price: obj?.price,
                user: obj?.contributorName,
                date: obj?.updatedAt,
                status: obj?.mediaStatus,
                visibility: obj?.visibility,
                reason: obj?.reason
            }))
            return finalData

        } catch (error) {
            if (error.response) {
                toast.error(error.response)
                return rejectWithValue(error.response)
            } else {
                toast.error(error.message)
                return rejectWithValue(error.message)
            }
        }
    }
)

export const adminContentApproved = createAsyncThunk(
    'admin/adminContentApproved',
    async ({ pageName }, { rejectWithValue }) => {
        let body = {
            "search": {
                "category": pageName,
                "mediaStatus": "ACCEPTED"
            },
            "sortBy": {
                "createdAt": true
            },
            "nextKey": null
        }
        const adminToken = localStorage.getItem('adminToken')
        const config = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${adminToken}`
            }
        }
        try {
            const { data } = await axios.post(ADMIN_SEARCH_URL, body, config)
            const mediaData = data?.data?.media
            const finalData = mediaData?.map(obj => ({
                id: obj.id,
                contentId: obj.id,
                img: obj.contentSizes[0]?.thumbnailSrc,
                imgName: obj?.title,
                price: obj?.price,
                user: obj?.contributorName,
                date: obj?.updatedAt,
                status: obj?.mediaStatus,
                visibility: obj?.visibility,
                reason: obj?.reason
            }))
            return finalData

        } catch (error) {
            if (error.response) {
                toast.error(error.response)
                return rejectWithValue(error.response)
            } else {
                toast.error(error.message)
                return rejectWithValue(error.message)
            }
        }
    }
)

export const adminContentPending = createAsyncThunk(
    'admin/adminContentPending',
    async ({ pageName }, { rejectWithValue }) => {
        let body = {
            "search": {
                "category": pageName,
                "mediaStatus": "PENDING"
            },
            "sortBy": {
                "createdAt": true
            },
            "nextKey": null
        }
        const adminToken = localStorage.getItem('adminToken')
        const config = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${adminToken}`
            }
        }
        try {
            const { data } = await axios.post(ADMIN_SEARCH_URL, body, config)
            const mediaData = data?.data?.media
            const finalData = mediaData?.map(obj => ({
                id: obj.id,
                contentId: obj.id,
                img: obj.contentSizes[0]?.thumbnailSrc,
                imgName: obj?.title,
                price: obj?.price,
                user: obj?.contributorName,
                date: obj?.updatedAt,
                status: obj?.mediaStatus,
                visibility: obj?.visibility,
                reason: obj?.reason
            }))
            return finalData

        } catch (error) {
            if (error.response) {
                toast.error(error.response)
                return rejectWithValue(error.response)
            } else {
                toast.error(error.message)
                return rejectWithValue(error.message)
            }
        }
    }
)

export const adminContentDeleteItem = createAsyncThunk(
    'admin/adminContentDeleteItem',
    async ({ id }, { rejectWithValue }) => {
        try {
            const adminToken = localStorage.getItem('adminToken')
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${adminToken}`
                }
            }
            await axios.delete(`${ADMIN_MEDIA_URL}/${id}`, config)
            toast.success('Item deleted successfully')
            return id
        } catch (error) {
            toast.error(error.response.data.message)
            return rejectWithValue(error.response.data.message)
        }
    }
)

export const adminContentDeleteAllItems = createAsyncThunk(
    'admin/adminContentDeleteAllItems',
    async (mediaIds, { rejectWithValue }) => {
        try {
            const adminToken = localStorage.getItem('adminToken')
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${adminToken}`
                }
            }
            const body = {
                mediaIds
            }

            await axios.post(`${ADMIN_MEDIA_URL}/delete-multiple`, body, config)
            toast.success('Items deleted successfully')
        } catch (error) {
            toast.error(error.response.data.message)
            return rejectWithValue(error.response.data.message)
        }
    }
)

export const adminContentImport = createAsyncThunk(
    'admin/adminContentImport',
    async (fileName, { rejectWithValue }) => {
        const formData = new FormData();
        formData.append("file", fileName)
        try {
            const adminToken = localStorage.getItem('adminToken')
            const config = {
                headers: {
                    "Authorization": `Bearer ${adminToken}`
                }
            }
            await axios.post(ADMIN_IMPORT_URL, formData, config)
            toast.success('file import successful')
        } catch (error) {
            if (error.response) {
                toast.error("Something went wrong")
                return rejectWithValue(error.response)
            } else {
                toast.error("Something went wrong")
                return rejectWithValue(error.message)
            }
        }
    }
)

export const getAdminContentMediaById = createAsyncThunk(
    'admin/getAdminContentMediaById',
    async ({ id }, { rejectWithValue }) => {
        try {
            const adminToken = localStorage.getItem('adminToken')
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${adminToken}`
                }
            }
            const { data } = await axios.get(`${ADMIN_MEDIA_URL}/${id}`, config)
            const returnData = data?.data?.media
            const finalData = {
                uid: returnData.id,
                format: returnData.contentSizes[0]?.format,
                codec: returnData.codec,
                resolution: returnData.resolution,
                imgSrc: returnData.contentSizes[0]?.thumbnailSrc,
                vidSrc: returnData.contentSizes[0]?.previewSrc,
                title: returnData.title,
                description: returnData.description,
                contentCategory: returnData.contentCategory,
                category: returnData.category,
                contributorName: returnData.contributorName,
                pov: returnData.pov,
                typeOfAngle: returnData.typeOfAngle,
                camera: returnData.camera,
                shootLocation: returnData.shootLocation,
                keywords: returnData.keywords,
                reason: returnData.reason,
                fileName: returnData.contentSizes[0]?.displayName,
                mediaStatus: returnData.mediaStatus,
                mediaInfo: returnData.mediainfo,
                price: returnData.price,
                visibility: returnData.visibility,
                scheduleDate: returnData.scheduleDate,
                isPremium: returnData.isPremium
            }
            return finalData

        } catch (error) {
            if (error.response) {
                toast.error(error.response)
                return rejectWithValue(error.response)
            } else {
                toast.error(error.message)
                return rejectWithValue(error.message)
            }
        }
    }
)

export const editAdminContentMedia = createAsyncThunk(
    'admin/editAdminContentMedia',
    async (fileData, { rejectWithValue }) => {
        try {
            const adminToken = localStorage.getItem('adminToken')
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${adminToken}`
                }
            }
            await axios.put(ADMIN_MEDIA_URL, fileData, config)
            toast.success('metadata updated successfully')
        } catch (error) {
            if (error.response) {
                toast.error(error.response.data.message)
                return rejectWithValue(error.response)
            } else {
                toast.error(error.message)
                return rejectWithValue(error.message)
            }
        }
    }
)

export const saveWithoutPublish = createAsyncThunk(
    'admin/editAdminContentMedia',
    async (fileData, { rejectWithValue }) => {
        try {
            const adminToken = localStorage.getItem('adminToken')
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${adminToken}`
                }
            }
            const formData = new FormData();
            Object.keys(fileData).forEach(key => formData.append(key, fileData[key]))
            await axios.put(ADMIN_MEDIA_URL, formData, config)
            toast.success('Data saved successfully')
        } catch (error) {
            if (error.response) {
                toast.error(error.response.data.message)
                return rejectWithValue(error.response)
            } else {
                toast.error(error.message)
                return rejectWithValue(error.message)
            }
        }
    }
)

export const adminContentReject = createAsyncThunk(
    'admin/adminContentReject',
    async ({ id, reason }, { rejectWithValue }) => {
        const adminToken = localStorage.getItem('adminToken')
        const config = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${adminToken}`
            }
        }
        const formData = new FormData()
        formData.append('id', id)
        formData.append('reason', reason)
        formData.append('visibility', 'NOT_PUBLISHED')
        formData.append('mediaStatus', 'REJECTED')
        try {
            await axios.put(`${ADMIN_MEDIA_URL}/visibility-status`, formData, config)
        } catch (error) {
            if (error.response) {
                toast.error(error.response)
                return rejectWithValue(error.response)
            } else {
                toast.error(error.message)
                return rejectWithValue(error.message)
            }
        }
    }
)

export const adminContentPublish = createAsyncThunk(
    'admin/adminContentPublish',
    async ({ id, price }, { rejectWithValue }) => {
        const formData = new FormData()
        formData.append('id', id)
        formData.append('price', price)
        formData.append('visibility', 'PUBLISHED')
        try {
            const adminToken = localStorage.getItem('adminToken')
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${adminToken}`
                }
            }
            await axios.put(`${ADMIN_MEDIA_URL}/visibility-status`, formData, config)
            toast.success('media updated successfully')
        } catch (error) {
            if (error.response) {
                toast.error(error.response)
                return rejectWithValue(error.response)
            } else {
                toast.error(error.message)
                return rejectWithValue(error.message)
            }
        }
    }
)

export const adminContentUnpublish = createAsyncThunk(
    'admin/adminContentUnpublish',
    async ({ id }, { rejectWithValue }) => {
        const formData = new FormData()
        formData.append('id', id)
        formData.append('visibility', 'NOT_PUBLISHED')
        try {
            const adminToken = localStorage.getItem('adminToken')
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${adminToken}`
                }
            }
            await axios.put(`${ADMIN_MEDIA_URL}/visibility-status`, formData, config)
            toast.success('media updated successfully')
        } catch (error) {
            if (error.response) {
                toast.error(error.response)
                return rejectWithValue(error.response)
            } else {
                toast.error(error.message)
                return rejectWithValue(error.message)
            }
        }
    }
)

export const adminContentScheduler = createAsyncThunk(
    'admin/adminContentScheduler',
    async ({ id, dateValue, timeValue, price }, { rejectWithValue }) => {
        const formData = new FormData()
        formData.append('id', id)
        formData.append('scheduleDate', dateValue)
        formData.append('schedulerTime', timeValue)
        formData.append('price', price)
        formData.append('visibility', 'SCHEDULED')
        try {
            const adminToken = localStorage.getItem('adminToken')
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${adminToken}`
                }
            }
            await axios.put(`${ADMIN_MEDIA_URL}/visibility-status/scheduler`, formData, config)
            toast.success('media scheduled successfully')
        } catch (error) {
            if (error.response) {
                toast.error(error.response)
                return rejectWithValue(error.response)
            } else {
                toast.error(error.message)
                return rejectWithValue(error.message)
            }
        }
    }
)