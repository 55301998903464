
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  adminContent,
  adminContentApproved,
  adminContentRejected,
  adminContentPending,
  adminContentPublished,
  adminContentUnpublished
} from "../../features/adminSearch/adminSearchActions";
import { useDispatch } from "react-redux";

const AdminPagesContext = React.createContext();

function AdminPagesProvider({ children }) {
  const dispatch = useDispatch()
  const { mediaData } = useSelector(state => state.adminSearch)
  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = React.useState(0);
  const [activeStep, setActiveStep] = useState(0)
  const [cartActiveStep, setCartActiveStep] = useState(0)
  const [openViewPopup, setOpenViewPopup] = useState(false)
  const [id, setId] = useState('')
  const [viewCheck, setViewCheck] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const initialDetails = Object.assign({}, mediaData)
  const [details, setDetails] = useState(initialDetails)
  const [modifiedContent, setModifiedContent] = useState(false)
  const [modalHeading, setModalHeading] = useState('')
  const [selectedIds, setSelectedIds] = useState([]);
  const [page, setPage] = useState(0);
  const [minMediaPrice, setMinMediaPrice] = useState();
  const [maxMediaPrice, setMaxMediaPrice] = useState();
  const [sliderValue, setSliderValue] = useState([])

  const [filterData, setFilterData] = useState({
    contentCategory: '',
    mediaStatus: '',
    visibility: '',
    typeOfAngle: '',
    pov: '',
    shootLocation: '',
    resolution: '',
    videoDuration: ''
  })

  const handleStartDateChange = (sDate) => {
    setStartDate(new Date(sDate.$d).toLocaleDateString())
  }

  const handleEndDateChange = (eDate) => {
    setEndDate(new Date(eDate.$d).toLocaleDateString())
  }

  const handleTabsChange = (event, newValue) => {
    setTabValue(newValue);
    setPage(0);
  };

  const handleOpenViewPopup = (e) => {
    setOpenViewPopup(true)
    setId(e.target.id)
    setViewCheck(true)
  }

  const handleOpenEditViewPopup = (e) => {
    setOpenViewPopup(true)
    setId(e.target.id)
    setViewCheck(false)
  }

  const handleCloseViewPopup = () => {
    setOpenViewPopup(false)
    setActiveStep(0)
    setDetails({})
  }

  const handleContentView = (heading, pageName) => {
    switch (heading) {
      case "Unpublished":
        dispatch(adminContentUnpublished({ pageName }))
        break;
      case "Published":
        dispatch(adminContentPublished({ pageName }))
        break;
      case "Pending":
        dispatch(adminContentPending({ pageName }))
        break;
      case "Approved":
        dispatch(adminContentApproved({ pageName }))
        break;
      case "Rejected":
        dispatch(adminContentRejected({ pageName }))
        break;
    }
  }

  const handleMinChange = (e) => {
    const newValue = parseInt(e.target.value, 10);
    setSliderValue([newValue, sliderValue[1]]);
  };

  const handleMaxChange = (e) => {
    const newValue = parseInt(e.target.value, 10);
    setSliderValue([sliderValue[0], newValue]);
  };

  const value = {
    loading,
    tabValue,
    handleTabsChange,
    activeStep,
    setActiveStep,
    cartActiveStep,
    setCartActiveStep,
    openViewPopup,
    setOpenViewPopup,
    id,
    setId,
    viewCheck,
    setViewCheck,
    handleOpenViewPopup,
    handleOpenEditViewPopup,
    handleCloseViewPopup,
    details,
    setDetails,
    filterData,
    setFilterData,
    startDate,
    handleStartDateChange,
    endDate,
    handleEndDateChange,
    handleContentView,
    modifiedContent,
    setModifiedContent,
    modalHeading,
    setModalHeading,
    selectedIds,
    setSelectedIds,
    page,
    setPage,
    handleMinChange,
    handleMaxChange,
    minMediaPrice,
    setMinMediaPrice,
    maxMediaPrice,
    setMaxMediaPrice,
    sliderValue,
    setSliderValue
  };
  return (
    <AdminPagesContext.Provider value={value}>
      {!loading && children}
    </AdminPagesContext.Provider>
  );
}

export default AdminPagesProvider

export function useAdminPagesContext() {
  return useContext(AdminPagesContext);
}
