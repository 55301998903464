export const checkUploadPermission = (role) => {
    switch (role) {
        case 'admin':
            return true;
        case 'metatagger':
            return false;
        case 'editor':
            return true;
        case 'scheduler':
            return false;
        default:
            break;
    }
}

export const checkDeletePermission = (role) => {
    switch (role) {
        case 'admin':
            return true;
        case 'metatagger':
            return false;
        case 'editor':
            return true;
        case 'scheduler':
            return false;
        default:
            break;
    }
}

export const checkImportExportPermission = (role) => {
    switch (role) {
        case 'admin':
            return true;
        case 'metatagger':
            return true;
        case 'editor':
            return false;
        case 'scheduler':
            return false;
        default:
            break;
    }
}

export const checkEditPermission = (role) => {
    switch (role) {
        case 'admin':
            return true;
        case 'metatagger':
            return true;
        case 'editor':
            return false;
        case 'scheduler':
            return true;
        default:
            break;
    }
}

export const returnPopupArray = (role) => {
    switch (role) {
        case 'admin':
            return ['Details', 'Checks', "Visibility"]
        case 'metatagger':
            return ['Details', 'Checks']
        case 'scheduler':
            return ['Checks', "Visibility"]
        default:
            return [];
    }
}

export const truncateString = (str, n) => {
    return (str.length > n) ? `${str.slice(0, n - 1)}...` : str;
}

export function standardizeTime(time) {
    let [hours] = time.split(":");
    hours = parseInt(hours);

    if (time.includes("PM") && hours !== 12) {
        hours += 12;
    } else if (time.includes("AM") && hours === 12) {
        hours = 0;
    }

    const formattedHours = hours.toString().padStart(2, "0");

    const [minutes] = time.split(":")[1].split(" ");
    return `${formattedHours}:${minutes}`;
}