import React from "react";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AdminTable from "../adminTable/AdminTable";
import { useAdminPagesContext } from "../../../contexts/AdminPagesContext";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const AdminTabs = () => {

    const { tabValue, handleTabsChange } = useAdminPagesContext()
    
    return (
        <Box sx={{ width: '100%', '& .css-19kzrtu': { padding: '0px' } }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', fontFamily: "Open Sans" }}>
                <Tabs
                    value={tabValue}
                    onChange={handleTabsChange}
                    aria-label="basic tabs example"
                    sx={{
                        paddingLeft: "25px",
                        "& .css-heg063-MuiTabs-flexContainer": {
                            gap: '40px'
                        }
                    }}
                >
                    <Tab label="Image" {...a11yProps(0)}
                        sx={{
                            textTransform: 'none',
                            fontFamily: "Open Sans",
                            fontSize: '15px',
                            fontWeight: '600',
                            color: '#444444',
                        }}
                    />
                    <Tab label="Video" {...a11yProps(1)}
                        sx={{
                            textTransform: 'none',
                            fontFamily: "Open Sans",
                            fontSize: '15px',
                            fontWeight: '600',
                            color: '#444444'
                        }}
                    />
                    <Tab label="Vector" {...a11yProps(2)}
                        sx={{
                            textTransform: 'none',
                            fontFamily: "Open Sans",
                            fontSize: '15px',
                            fontWeight: '600',
                            color: '#444444'
                        }}
                    />
                    <Tab label="VFX" {...a11yProps(3)}
                        sx={{
                            textTransform: 'none',
                            fontFamily: "Open Sans",
                            fontSize: '15px',
                            fontWeight: '600',
                            color: '#444444'
                        }}
                    />
                </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0}>
                <AdminTable />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                <AdminTable />
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
                Coming Soon ...
            </TabPanel>
            <TabPanel value={tabValue} index={3}>
                Coming Soon ...
            </TabPanel>
        </Box>
    );
}

export default AdminTabs;