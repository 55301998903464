import React, { useState } from "react";
import { BiSearchAlt2 } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { getUserByUid } from "../../../features/admin/adminActions";
import AdminNavbar from "../../adminCommon/adminNavbar/AdminNavbar";
import AdminSidebar from "../../adminCommon/adminSidebar/AdminSidebar";
import './userManagement.css';

const UserManagement = () => {
    window.FreshworksWidget('hide')
    const [searchInput, setSearchInput] = useState('')
    const dispatch = useDispatch()

    const onSubmitUidSearch = (e) => {
        e.preventDefault();
        dispatch(getUserByUid(searchInput))
    }

    const {userData} = useSelector(state => state.admin)

    return (
        <div className='userManagement'>
            <div className='userManagement-navbar' >
                <AdminNavbar />
            </div>
            <div className="userManagement-sidebarAndData">
                <div className="userManagement-sidebar">
                    <AdminSidebar />
                </div>
                <div className="userManagement-data">
                    <div className="userManagement-heading">
                        <span>User Management</span>
                    </div>
                    <div className="userManagement-search">
                        <form onSubmit={onSubmitUidSearch}>
                            <div className="userManagement-searchTextfield">
                                <input
                                    type="number"
                                    placeholder="Enter the UID"
                                    onChange={(e) => setSearchInput(e.target.value)}
                                />
                                <button className="userManagement-searchBtn" >
                                    <BiSearchAlt2 color="rgba(0, 0, 0, 0.4)" fontSize="20px" type="submit" />
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className="userManagement-userDetails">
                        <div className="userManagement-userDetails_heading">User's Details : </div>
                        <div className="um-userDetailsContainer-cmnSty">
                            <div className="um-udc-headingCmnSty">UID :</div>
                            <div className="um-udc-dataCmnSty">{userData && userData?.id}</div>
                        </div>
                        <div className="um-userDetailsContainer-cmnSty">
                            <div className="um-udc-headingCmnSty">Name :</div>
                            <div className="um-udc-dataCmnSty">{userData && userData?.name}</div>
                        </div>
                        <div className="um-userDetailsContainer-cmnSty">
                            <div className="um-udc-headingCmnSty">Email Id :</div>
                            <div className="um-udc-dataCmnSty">{userData && userData?.email}</div>
                        </div>
                        <div className="um-userDetailsContainer-cmnSty">
                            <div className="um-udc-headingCmnSty">Login Type :</div>
                            <div className="um-udc-dataCmnSty">{userData && userData?.type}</div>
                        </div>
                        <div className="um-userDetailsContainer-cmnSty">
                            <div className="um-udc-headingCmnSty">Account Status :</div>
                            <div className="um-udc-dataCmnSty">{userData && userData?.status}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserManagement;