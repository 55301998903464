import React from 'react';
import './sellerDashboard.css';
import SellerNavbar from '../../sellerCommon/sellerNavbar/SellerNavbar';
import SellerSidebar from '../../sellerCommon/sellerSidebar/sellerSidebar';
import { ToastContainer } from "react-toastify";

const SellerDashboard = () => {
    window.FreshworksWidget('hide')

    return (
        <div className='sellerDashboard'>
            <div className='sellerDashboard-navbar'>
                <SellerNavbar />
            </div>
            <div className="sellerDashboard-sidebarAndData">
                <div className="sellerDashboard-sidebar">
                    <SellerSidebar />
                </div>
                <div className="sellerDashboard-data">
                    <div className='sellerDashboard-headingAndToggle'>
                        <div className='sellerDashboard-heading'>
                            Dashboard
                        </div>
                    </div>
                    {/* <div className='adminDashboard-penAppRej'>
                        <div className='admDa-penAppRej-cmnSty'>
                            <PendingActionsIcon sx={{ color: '#077E8C' }} />
                            <div className='admDa-heading-commonSty'>Pending</div>
                            <div id='pending' className='admDa-count-commonSty' onClick={handleOpenDashboardDetailsModal}>{pendingMedia && pendingMedia !== undefined ? pendingMedia : <CircularProgress size={20} />}</div>
                        </div>
                        <div className='admDa-penAppRej-cmnSty'>
                            <VerifiedIcon sx={{ color: '#00FF00' }} />
                            <div className='admDa-heading-commonSty'>Accepted</div>
                            <div id='approved' className='admDa-count-commonSty' onClick={handleOpenDashboardDetailsModal}>{approveMedia && approveMedia !== undefined ? approveMedia : <CircularProgress size={20} />}</div>
                        </div>
                        <div className='admDa-penAppRej-cmnSty'>
                            <CancelRoundedIcon sx={{ color: 'red' }} />
                            <div className='admDa-heading-commonSty'>Rejected</div>
                            <div id='rejected' className='admDa-count-commonSty' onClick={handleOpenDashboardDetailsModal}>{rejectMedia && rejectMedia !== undefined ? rejectMedia : <CircularProgress size={20} />}</div>
                        </div>
                    </div> */}
                    {/* <div className='adminDashboard-downloadsWishlist'>
                <div className='admDa-commonSty'>
                    <AiOutlineDownload fontSize="23px" color='#5AC8CE' />
                    <div className='admDa-heading-commonSty'>Downloads</div>
                    <div className='admDa-count-commonSty'>{loading ? <Loader /> : downloadCount}</div>
                </div>
                <div className='admDa-commonSty'>
                    <TurnedInNotIcon style={{ fontSize: '23px', color: '#ED652B' }} />
                    <div className='admDa-heading-commonSty'>Wishlisted</div>
                    <div className='admDa-count-commonSty'>{loading ? <Loader /> : wishlistCount}</div>
                </div>
            </div> */}
                    {/* <div className='adminDashboard-publishedUnpublished'>
                        <div className='adminDashboard-mainDivCommonSty'>
                            <div className='adDash-headingViewAllCommonSty'>
                                <div className='adDash-headingCommonSty'>
                                    <span>Published files</span>
                                    <span>({loading ? "..." : publishedCount})</span>
                                </div>
                                {publishedFiles?.length > 0 && <div id='published' className='adDash-viewAllCommonSty' onClick={handleOpenDashboardDetailsModal}>View All</div>}
                            </div>
                            {!loading ?
                                publishedFiles && publishedFiles?.length > 0 ? publishedFiles?.map((obj) => {
                                    return (<div className='adDash-contentCardCommonSty' key={obj.id}>
                                        <div className='adDash-contentCard-contentCommonSty'>
                                            <img src={`${img_url}/${obj.thumbnail}`} alt="" height="28px" width="28px" />
                                            <p>{obj?.title}</p>
                                        </div>
                                        <div className='adDash-contentCard-viewAll' id={obj.id} onClick={handleOpenViewPopup} style={{ cursor: "pointer" }}>
                                            View
                                        </div>
                                    </div>)
                                }) : <div className='txt-center' style={{ height: '50vh', marginTop: '6rem' }}>No Data Found</div>
                                : <h4>Loading</h4>}

                        </div>
                        <div className='adminDashboard-mainDivCommonSty'>
                            <div className='adDash-headingViewAllCommonSty'>
                                <div className='adDash-headingCommonSty'>
                                    <span>Unpublished files</span>
                                    <span>({loading ? "..." : unpublishedCount})</span>
                                </div>
                                {unpublishedFiles?.length > 0 && <div id='unpublished' className='adDash-viewAllCommonSty' onClick={handleOpenDashboardDetailsModal}>View All</div>}
                            </div>
                            {!loading ?
                                unpublishedFiles && unpublishedFiles?.length > 0 ? unpublishedFiles?.map((obj) => {
                                    return (<div className='adDash-contentCardCommonSty' key={obj.id}>
                                        <div className='adDash-contentCard-contentCommonSty'>
                                            <img src={`${img_url}/${obj.thumbnail}`} alt="" height="28px" width="28px" />
                                            <p>{obj?.title}</p>
                                        </div>
                                        <div className='adDash-contentCard-viewAll' id={obj.id} onClick={handleOpenViewPopup} style={{ cursor: "pointer" }}>
                                            View
                                        </div>
                                    </div>)
                                }) : <div className='txt-center' style={{ height: '50vh', marginTop: '6rem' }}>No Data Found</div>
                                : <h4>Loading</h4>
                            }
                        </div>
                    </div>
                    <PopupContainer open={openViewPopup} handleCloseViewPopup={handleCloseViewPopup} itemId={id} viewCheck={viewCheck} />
                    <DashboardDetailsModal dashboardModalHeading={dashboardModalHeading} handleCloseDashboardDetailsModal={handleCloseDashboardDetailsModal} openDashboardDetailsModal={openDashboardDetailsModal} /> */}
                    <ToastContainer limit={3} theme="colored" autoClose={1200} />
                </div>
            </div>
        </div >

    )
}

export default SellerDashboard;