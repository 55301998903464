import React, { useEffect } from "react";
import './adminLogin.css'
import cLogo from "../../../assets/logo/WorldArchivesDark.png";
import Button from '@mui/material/Button';
import { useForm } from "react-hook-form";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom";
import { adminLogin } from "../../../features/admin/adminActions";

const AdminLogin = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { success } = useSelector(state => state.admin)

    window.FreshworksWidget('hide')

    const onSubmit = (data) => {
        dispatch(adminLogin(data))
    }

    useEffect(() => {
        if (success) setTimeout(() => navigate('/cms/dashboard'), 2500)
    }, [success, navigate])

    return (
        <div className="adminLogin">
            <div className="adminLogin-cLogo">
                <img src={cLogo} alt="Company Logo" height='2.5rem'/>
            </div>
            <div className="adminLogin-formContainer">
                <form className="adminLogin-form" onSubmit={handleSubmit(onSubmit)}>
                    <span className="adminLogin-formHeading">
                        Log In
                    </span>
                    <div className="adminLogin-formEmailAdd">
                        <input
                            className={errors.email ? 'adminInputStyle outline-error' : 'adminInputStyle'}
                            placeholder="Email Address"
                            type="email"
                            {...register("email",
                                {
                                    required: "Email is required",
                                })}
                        />
                        {<div className="adminLogin-text-error">{errors.email ? `${'*'}${errors.email.message}` : null}</div>}
                    </div>
                    <div className="adminLogin-formPassword">
                        <input
                            className={errors.password ? 'adminInputStyle outline-error' : 'adminInputStyle'}
                            placeholder="Password"
                            type="password"
                            {...register("password", {
                                required: "Password is required",
                            })}
                        />
                        {<div className="adminLogin-text-error">{errors.password ? `${'*'}${errors.password.message}` : null}</div>}
                    </div>
                    <div className="adminLogin-formBtn">
                        <Button
                            variant="contained"
                            type="submit"
                            sx={{
                                fontFamily: 'Open Sans',
                                fontWeight: '700',
                                fontSize: '15px',
                                height: '40px',
                                width: '120px',
                                textTransform: 'none',
                                background: '#4946C3',
                                '&:hover': {
                                    background: '#4946C3'
                                }
                            }}
                        >
                            Log In
                        </Button>
                    </div>
                </form>
            </div>
            <ToastContainer limit={3} theme="colored" autoClose={1200} />
        </div>
    )
}

export default AdminLogin;