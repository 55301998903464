import React, { useState } from 'react';
import './index.css'
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { searchResults } from '../../../features/search/searchActions';
import { useDispatch } from 'react-redux';

const searchSelectStyles = {
    fontFamily: 'Open Sans',
    height: "100%",
    width: '20%',
    '& .MuiOutlinedInput-notchedOutline': {
        border: "none",
        outline: 'none',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    '@media (min-width : 481px) and (max-width : 800px)': {
        '.MuiSelect-select':{
            fontSize: '0.8rem',
        }
    },
    '@media (min-width : 801px) and (max-width : 909px)': {
        fontSize: '0.9rem',
    },
    '@media (min-width : 320px) and (max-width : 465px)': {
        '.MuiSelect-select':{
            padding:'0.5rem',
            fontSize: '0.8rem',
            paddingRight: '0rem',
        },
        '.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input':{
            paddingRight: '2rem'
        },
        width: '25%'
    }
}

const searchMenuItemsStyle = {
    '@media (min-width : 481px) and (max-width : 767px)': {
        fontSize: '0.8rem'
    }
}

const searchTextfieldStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#D9DBE0',
    height: "100%",
    width: '100%',
    fontFamily: 'Open Sans',
    fontSize: '13px',
    border:'none',
    outline:'none'
}

const searchBtnsty = {
    backgroundColor: '#ED652B',
    color: 'white',
    width: '20%',
    height: '80%',
    textTransform: 'none',
    marginRight: '10px',
    '&:hover': {
        background: '#ED652B'
    },
    '@media (min-width : 320px) and (max-width : 465px)':{
        fontSize: '0.8rem',
        fontWeight: '500'
    }
}

const SearchBar = ({ searchBarStyles, initialState }) => {
    const [searchData, setSearchData] = useState({
        'selectTerm': initialState,
        'fieldTerm': ''
    })
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const onSubmitSearchForm = async (e) => {
        e.preventDefault()
        const searchTerm = decodeURIComponent(searchData?.fieldTerm.replace(/[^\w\s]/gi, function (c) {
            return '%' + c.charCodeAt(0).toString(16);
        }).replace(/\s+/g, '+'));
        navigate(`/search_results?keyword=${searchTerm}`)
        await dispatch(searchResults({ fieldTerm: searchData?.fieldTerm, selectTerm: searchData?.selectTerm }))
    }

    const searchTerm = searchData && searchData?.selectTerm ? `${searchData?.selectTerm?.charAt(0).toUpperCase()}${searchData?.selectTerm?.slice(1).toLowerCase()}` : 'Image'

    return (
        <div className={searchBarStyles}>
            <form
                className='searchBar-form'
                onSubmit={onSubmitSearchForm}
            >
                <Select
                    value={searchData?.selectTerm}
                    onChange={e => setSearchData({ ...searchData, selectTerm: e.target.value })}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={searchSelectStyles}
                >
                    <MenuItem sx={searchMenuItemsStyle} value={'IMAGE'}>Image</MenuItem>
                    <MenuItem sx={searchMenuItemsStyle} value={'VIDEO'}>Video</MenuItem>
                    <MenuItem sx={searchMenuItemsStyle} value={'MUSIC'}>Music</MenuItem>
                    <MenuItem sx={searchMenuItemsStyle} value={'VFX'}>VFX</MenuItem>
                    <MenuItem sx={searchMenuItemsStyle} value={'EDITORIAL'}>Editorial</MenuItem>
                    <MenuItem sx={searchMenuItemsStyle} value={'EXCLUSIVE'}>Exclusive</MenuItem>
                </Select>
                <input
                    fullWidth
                    style={searchTextfieldStyle}
                    placeholder={`Try searching for ${searchTerm}`}
                    id="fullWidth"
                    value={searchData?.fieldTerm}
                    onChange={e => setSearchData({ ...searchData, fieldTerm: e.target.value })}
                />
                <Button
                    type='submit'
                    sx={searchBtnsty}
                >Search</Button>
            </form>
        </div>
    )
}

export default SearchBar;