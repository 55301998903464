import React, { useState } from "react";
import './settings.css';
import AdminNavbar from '../../adminCommon/adminNavbar/AdminNavbar';
import AdminSidebar from '../../adminCommon/adminSidebar/AdminSidebar';
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { addContentCategory, getContentCategoryList, removeContentCategory, adminSettingsAddingKeywords } from "../../../features/admin/adminActions";
import ContentCategoryManagement from "./ContentCategoryManagement";
import PopularKeywordManagement from "./PopularKeywordManagement";


const ccSelectStyle = {
    fontFamily: 'Open Sans, sans-serif',
    fontSize: '13px',
    border: '1px solid #C8C7C7',
    borderRadius: '5px',
    height: '2rem',
    width: '100%',
    background: '#ffffff',
    '& .MuiOutlinedInput-notchedOutline': {
        border: "#C8C7C7",
        outline: 'none',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        border: '#C8C7C7',
    },
}

const Settings = () => {
    window.FreshworksWidget('hide')
    const { contentCategorySearchData, loading } = useSelector(state => state.admin)
    const [pageNameValue, setPageNameValue] = useState("");
    const [keywordValue, setKeywordValue] = useState([]);
    const dispatch = useDispatch()
    const [formData, setFormData] = useState({
        page_name: '',
        sort_order: 0,
        name: '',
        status: ''
    });
    const [searchFormData, setSearchFormData] = useState('')
    const [contentCategoryId, setContentCategoryId] = useState(0)
    const lastIndex = contentCategorySearchData?.length - 1;

    const handleChange = (e) => {
        const { name, value } = e.target;
        const parsedValue = name === 'sort_order' ? parseInt(value) : value;
        setFormData((prevData) => ({
            ...prevData,
            [name]: parsedValue
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await dispatch(addContentCategory(formData))
    };

    const handleSearchFormSubmit = async (e) => {
        e.preventDefault();
        await dispatch(getContentCategoryList(searchFormData))
    }

    const handleRemoveContentCat = async (e) => {
        e.preventDefault();
        await dispatch(removeContentCategory(contentCategoryId))
    }

    const handleAddKeyword = async () => {
        const keywords = keywordValue.split(',').map(keyword => keyword.trim());
        const payload = {
            key: pageNameValue,
            value: keywords
        };
        await dispatch(adminSettingsAddingKeywords(payload))
    };


    const pageName = ["IMAGES", "VIDEO","MUSIC", "VFX", "VECTOR", "EDITORIAL", "EXCLUSIVE"]

    return (
        <div className='adminSettings'>
            <div className='adminSettings-navbar' >
                <AdminNavbar />
            </div>
            <div className="adminSettings-sidebarAndData">
                <div className="adminSettings-sidebar">
                    <AdminSidebar />
                </div>
                <div className="adminSettings-data">
                    <div className="asd-heading">
                        <span>Settings</span>
                    </div>
                    <div className="asd-allSettings-container">
                        <ContentCategoryManagement
                            ccSelectStyle={ccSelectStyle}
                            formData={formData}
                            handleChange={handleChange}
                            handleSubmit={handleSubmit}
                            loading={loading}
                            lastIndex={lastIndex}
                            pageName={pageName}
                            searchFormData={searchFormData}
                            setSearchFormData={setSearchFormData}
                            handleSearchFormSubmit={handleSearchFormSubmit}
                            contentCategorySearchData={contentCategorySearchData}
                            contentCategoryId={contentCategoryId}
                            setContentCategoryId={setContentCategoryId}
                            handleRemoveContentCat={handleRemoveContentCat}
                        />
                        <PopularKeywordManagement
                            ccSelectStyle={ccSelectStyle}
                            pageNameValue={pageNameValue}
                            setPageNameValue={setPageNameValue}
                            keywordValue={keywordValue}
                            setKeywordValue = {setKeywordValue}
                            handleAddKeyword={handleAddKeyword}
                        />
                    </div>
                </div>
            </div>
            <ToastContainer limit={3} theme="colored" autoClose={1200} />
        </div>

    )

}

export default Settings;