import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const base_url = process.env.REACT_APP_BASE_URL
const seller_registration_url = `${base_url}/seller/register`
const seller_login_url = `${base_url}/seller/login`

export const sellerRegistration = createAsyncThunk(
    'seller/sellerSignUp',
    async (formData, { rejectWithValue }) => {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }

        try {
            const { data } = await axios.post(seller_registration_url, formData, config)
            toast.success('Registration Successful')
            return data

        } catch (error) {
            if (error.response && error.response.data.message) {
                toast.error(error.response.data.message)
                return rejectWithValue(error.response.data.message)
            } else {
                toast.error(error.message)
                return rejectWithValue(error.message)
            }
        }
    }

)

export const sellerLogin = createAsyncThunk(
    'seller/sellerLogin',
    async ({ email, password }, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
            const body = { email: email, password: password }
            const { data } = await axios.post(seller_login_url, body, config)
            localStorage.setItem('sellerToken', data?.data?.tokenData?.token)
            localStorage.setItem('sellerInfo', JSON.stringify(data?.data?.findUser))
            toast.success('Seller Login Successful')
            return data

        } catch (error) {
            if (error.response && error.response.data.message) {
                toast.error(error.response.data.message)
                return rejectWithValue(error.response.data)
            } else {
                toast.error(error.message)
                return rejectWithValue(error.message)
            }
        }
    }
)