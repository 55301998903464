import React from 'react';
import { Navbar } from '../../common/Navbar';
import './wishlistPage.css';
import logoMain from '../../../assets/logo/WorldArchivesDark.png';
import { RxImage } from 'react-icons/rx';
import SmartDisplayOutlinedIcon from '@mui/icons-material/SmartDisplayOutlined';
import { HiOutlineMusicNote } from 'react-icons/hi';
import { BsBox } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { getWishlistItems, removeWishlistItem } from '../../../features/search/searchActions';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../../common/Loader';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const WishlistPage = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { wishlistData, loading } = useSelector(state => state.search)

    useEffect(() => {
        dispatch(getWishlistItems())
    }, [dispatch])

    const getIcon = (type) => {
        switch (type) {
            case "IMAGE":
                return <RxImage color="#666666" />
            case "VIDEO":
                return <SmartDisplayOutlinedIcon style={{ color: "#666666", fontSize: '18px' }} />
            case 'MUSIC':
                return <HiOutlineMusicNote color="#666666" />
            case 'VFX':
                return <BsBox color="#666666" />
            default:
                return <RxImage color="#666666" />
        }
    }

    const handleView = (e) => {
        let category = wishlistData.find(el => el.id === e.target.id).category
        switch (category) {
            case "IMAGE":
                navigate(`/details_image/${e.target.id}`)
                break;
            case "VIDEO":
                navigate(`/details_video/${e.target.id}`)
                break;
            default:
                navigate(`/details_image/${e.target.id}`)
        }
    }

    const handleRemove = async (id, resolution) => {
        await dispatch(removeWishlistItem({ id, resolution }))
    }

    const aws_url = process.env.REACT_APP_S3_HOST

    return (
        <div className="wishlist">
            <Navbar menuItemStyles="navbar-menu-items" logoStyles="navbar-logoStyle" companyLogo={logoMain} cartColor="#666666" hamburgerColor="#000000"/>
            {!loading ?
                <div className='wishlist-container'>
                    <div className='wishlist-heading'>
                        {`Your Wishlist (${wishlistData?.length} items)`}
                    </div>
                    <div className='wishlist-content'>
                        {wishlistData && wishlistData?.length > 0 ? wishlistData?.map((data, index) => (
                            <div className="wishlist-content-itemDetails" key={data.id}>
                                <div className="wishlist-itemDetails-image">
                                    <img src={`${aws_url}/${data.thumbNail}`} alt="" />
                                </div>
                                <div className="wishlist-itemDetails-info">
                                    <div className="wishlist-itemDetails-nameFormat">
                                        <div className="wishlist-itemDetails-name">
                                            {getIcon(data?.category)}
                                            <p>{data?.name}</p>
                                        </div>
                                        <div className="wishlist-itemDetails-format">
                                            {data?.format}
                                        </div>
                                    </div>
                                    <div className="wishlist-itemDetails-ResoLice">
                                        {
                                            data?.type === 'MUSIC' ? null :
                                                <div className="wishlist-itemDetails-Reso">
                                                    <p className="wishlist-itemDetails-headCommonSty">Resolution</p>
                                                    <p className="wishlist-itemDetails-headDataCommonSty">{data?.resolution}</p>
                                                </div>
                                        }
                                        <div className="wishlist-itemDetails-Lice">
                                            <p className="wishlist-itemDetails-headCommonSty">License</p>
                                            <p className="wishlist-itemDetails-headDataCommonSty">Standard</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="wishlist-itemDetails-priceAndRemove">
                                    <button className="wishlist-itemDetails-view" onClick={handleView} id={data.id}>View</button>
                                    <button className="wishlist-itemDetails-removeBtn"
                                        onClick={() => handleRemove(data.id, wishlistData.find(el => el.id === data.id).resolution)}
                                        id={data.id}>
                                        Remove</button>
                                </div>
                            </div>
                        )) : <div className="wishlist-noDataDiv">You haven't wishlisted any media</div>}
                    </div>
                </div> :
                <div style={{ height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Loader /></div>}
            <ToastContainer limit={3} theme="colored" autoClose={1200} />
        </div>
    )
}

export default WishlistPage;