import React, { useEffect } from "react";
import { Navbar } from "../../common/Navbar";
import './dataDeletionPolicy.css';
import logoMain from '../../../assets/logo/WorldArchivesDark.png';
import { Footer } from "../../common/Footer";
// import { GoPrimitiveDot } from "react-icons/go";

const DataDeletionPolicy = () => {
    useEffect(() => {
        window.scrollTo(0,0)
    },[window.onload])
    return (
        // <div className="dDPol">
        //     <Navbar menuItemStyles="navbar-menu-items" logoStyles="navbar-logoStyle" companyLogo={logoMain} cartColor="#666666" />
        //     <div className="dDPol-container">
        //         <div className='fs-22 fw-700 ft_mg_btm ft_ul_cmn'>
        //             Data Deletion Policy
        //         </div>
        //         <div className='dDPol_content_infoCmnSty gapProvTwo'>
        //             <div className='dDPol_index_info'>
        //                 <span className='mg-rgt fw-600'>1.</span>
        //                 <div className='dDPol_infoContainer'>
        //                     <div className='fw-600 ft_ul_cmn'>Purpose :</div>
        //                     <div className='dDPol-contentCommonSty'>
        //                         <span>
        //                             This document sets out our policy for responding to requests for deletion of data under Indian data protection law. This document explains the rights of the data subject in relation to data deletion and the responsibilities of World Archives in responding with such a request.
        //                         </span>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //         <div className='dDPol_content_infoCmnSty gapProvTwo'>
        //             <div className='dDPol_index_info'>
        //                 <span className='mg-rgt fw-600'>2.</span>
        //                 <div className='dDPol_infoContainer'>
        //                     <div className='fw-600 ft_ul_cmn'>Individual Rights :</div>
        //                     <div className='dDPol-contentCommonSty'>
        //                         <span>
        //                             An individual has the right to erasure, also known as <span className="fw-600">‘The Right To Be Forgotten’.</span> The principle underpinning this right is to enable an individual to request the deletion or removal of personal data where there is no compelling reason for its continued processing.
        //                         </span>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //         <div className='dDPol_content_infoCmnSty gapProvTwo'>
        //             <div className='dDPol_index_info'>
        //                 <span className='mg-rgt fw-600'>3.</span>
        //                 <div className='dDPol_infoContainer'>
        //                     <div className='fw-600 ft_ul_cmn'>When does the right to erasure apply?</div>
        //                     <div className='dDPol-contentCommonSty'>
        //                         <span>
        //                             As stipulated in Indian data protection law, individuals have a right to have personal data erased and to prevent processing in specific circumstances:
        //                         </span>
        //                         <div className='dDPol-contentContainer-CommonSty'>
        //                             <span className='mg-rgt'><GoPrimitiveDot fontSize='0.8rem' /></span>
        //                             <span>Where the personal data is no longer necessary in relation to the purpose for which it was originally collected/processed;</span>
        //                         </div>
        //                         <div className='dDPol-contentContainer-CommonSty'>
        //                             <span className='mg-rgt'><GoPrimitiveDot fontSize='0.8rem' /></span>
        //                             <span>When the individual withdraws consent;</span>
        //                         </div>
        //                         <div className='dDPol-contentContainer-CommonSty'>
        //                             <span className='mg-rgt'><GoPrimitiveDot fontSize='0.8rem' /></span>
        //                             <span>When the individual objects to the processing and there is no other legal ground for the relevant processing activity;</span>
        //                         </div>
        //                         <div className='dDPol-contentContainer-CommonSty'>
        //                             <span className='mg-rgt'><GoPrimitiveDot fontSize='0.8rem' /></span>
        //                             <span>When the personal data was unlawfully processed;</span>
        //                         </div>
        //                         <div className='dDPol-contentContainer-CommonSty'>
        //                             <span className='mg-rgt'><GoPrimitiveDot fontSize='0.8rem' /></span>
        //                             <span>Where the personal data has to be erased in order to comply with a legal obligation.</span>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //         <div className='dDPol_content_infoCmnSty gapProvTwo'>
        //             <div className='dDPol_index_info'>
        //                 <span className='mg-rgt fw-600'>4.</span>
        //                 <div className='dDPol_infoContainer'>
        //                     <div className='fw-600 ft_ul_cmn'>What information does the World Archives retain?</div>
        //                     <div className='dDPol-contentCommonSty'>
        //                         <span>
        //                             World Archives online system <span className="fw-600 inc-color">“Interplace”</span> stores data about individuals in order to create a Team Role Report. We store the name, e-mail address, gender, organisation/department (if provided) and subsequent Team Role scores for each individual in order to create a report, which is stored on our secure servers in India for a period of 90 days.
        //                         </span>
        //                         <span>
        //                             World Archives stores data about individuals in order to create a recycling report. We store the name, e-mail and subsequent recycling item for each individual in order to create a report, which is stored on our secure servers. This data is stored and used in accordance with our Privacy Policy, which can be found at:
        //                         </span>
        //                         <a className="dDPol-hyperlink" href="https://prod-beyond-dreams.s3.ap-south-1.amazonaws.com/public/GENERAL+TERMS+%26+CONDITIONS.pdf">
        //                             Download Policy
        //                         </a>
        //                         <span>
        //                             If consent is provided, personal data (including the above, plus address, telephone number and notes) may be stored in our CRM system for the purposes of contacting you regarding World Archives news, products and promotions.
        //                         </span>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //         <div className='dDPol_content_infoCmnSty gapProvTwo'>
        //             <div className='dDPol_index_info'>
        //                 <span className='mg-rgt fw-600'>5.</span>
        //                 <div className='dDPol_infoContainer'>
        //                     <div className='fw-600 ft_ul_cmn'>How can data be deleted?</div>
        //                     <div className='dDPol-contentCommonSty'>
        //                         <span>
        //                             A user of World Archives, can delete the user account upon request (via email to <span className="fw-600 inc-color">support@worldarchives.in</span>) This data is deleted from the system immediately and cannot be recovered by any users or World Archivesemployees after this point. Data which has been deleted or otherwise destroyed can not be recovered at any time. Sufficient warning is given to the account administrator before data are permanently deleted.
        //                         </span>
        //                         <span>
        //                             Data may still remain in the systems back-up files, which will be deleted periodically.
        //                         </span>
        //                         <span>
        //                             Information may be deleted from our CRM upon request to <span className="fw-600 inc-color">support@worldarchives.in</span>. We undertake to perform the deletion within one month (30 calendar days) and will send you a confirmation once the information has been deleted. Wherever possible, we will aim to complete the request in advance of the deadline.
        //                         </span>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        //     <Footer />
        // </div>
        <div></div>
    )
}

export default DataDeletionPolicy;