import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from 'react-toastify'

const base_url = process.env.REACT_APP_BASE_URL
const SEARCH_URL = `${base_url}/media/search`
const SEARCHBYID_URL = `${base_url}/media/id`

export const searchResults = createAsyncThunk(
    'search/searchResults',
    async ({ fieldTerm, selectTerm, limit }, { rejectWithValue }) => {
        localStorage.setItem('selectTerm', selectTerm)
        localStorage.setItem('fieldTerm', fieldTerm)
        const params = {
            params: {
                "search[keyword]": fieldTerm,
                "search[category]": selectTerm,
                "sortBy[viewCount]": true,
                limit: limit ? limit : 200
            }
        }
        try {
            const { data } = await axios.get(`${base_url}/media/search/full-text`, params)
            const returnData = data?.data?.mediaData
            let finalData = returnData.map((obj) => ({
                id: obj.id,
                mediaSrc: obj.contentSizes[0]?.thumbnailSrc,
                previewSrc: obj.contentSizes[0]?.previewSrc
            }))
            return finalData
        } catch (error) {
            if (error.response) {
                toast.error(error.response)
                return rejectWithValue(error.response)
            } else {
                toast.error(error.message)
                return rejectWithValue(error.message)
            }
        }
    }
)

export const searchResultsById = createAsyncThunk(
    'search/searchResultsById',
    async ({ Id }, { rejectWithValue }) => {
        try {
            const { data } = await axios.get(`${SEARCHBYID_URL}/${Id}`)
            return data?.data?.media
        } catch (error) {
            if (error.response) {
                toast.error(error.response)
                return rejectWithValue(error.response)
            } else {
                toast.error(error.message)
                return rejectWithValue(error.message)
            }
        }
    }
)

export const getResultsByStampCategory = createAsyncThunk(
    'search/getResultsByStampCategory',
    async ({ category, pageName }, { rejectWithValue }) => {
        localStorage.setItem('fieldTerm', category)
        const params = {
            params: {
                "search[stampCategory]": category,
                "search[category]": pageName
            }
        }
        try {
            const { data } = await axios.get(SEARCH_URL, params)
            const returnData = data?.data?.mediaData
            let finalData = returnData.map((obj) => ({
                id: obj.id,
                mediaSrc: obj.contentSizes[0]?.thumbnailSrc
            }))
            return finalData
        } catch (error) {
            if (error.response) {
                toast.error(error.response)
                return rejectWithValue(error.response)
            } else {
                toast.error(error.message)
                return rejectWithValue(error.message)
            }
        }
    }
)

export const getMediaSearchPagination = createAsyncThunk(
    'search/getMediaSearchPagination',
    async ({ category, pageName, nextKey }, { rejectWithValue }) => {
        localStorage.setItem('categoryName', category)
        localStorage.setItem('categoryPageName', pageName)
        let body = {
            "search": {
                "category": pageName,
                "contentCategory": category
            },
            "limit": 200,
            "sortBy": {
                "viewCount": true
            },
        }
        try {
            const { data } = await axios.post(`${base_url}/media/search/full-text`, body)
            const nextKey = data?.data?.nextKey
            const returnData = data?.data?.mediaData
            let finalData = returnData.map((obj) => ({
                id: obj.id,
                mediaSrc: obj.contentSizes[0]?.thumbnailSrc,
                previewSrc: obj?.contentSizes[0]?.previewSrc
            }))
            return { finalData, nextKey, category }
        } catch (error) {
            if (error.response) {
                toast.error(error.response)
                return rejectWithValue(error.response)
            } else {
                toast.error(error.message)
                return rejectWithValue(error.message)
            }
        }
    })

export const addToCart = createAsyncThunk(
    'search/addToCart',
    async ({ id, resolution }) => {
        const userToken = localStorage.getItem('userToken')
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userToken}`
            }
        }
        const body = { "mediaId": id, resolution }
        try {
            const { data } = await axios.post(`${base_url}/cart`, body, config)
            toast.success(data.message)
            return data.success
        } catch (error) {
            toast.info(error.response.data.message)
        }
    }
)

export const removeCartItems = createAsyncThunk(
    'search/removeCartItems',
    async ({ id, resolution }, { rejectWithValue }) => {
        const userToken = localStorage.getItem('userToken')
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userToken}`
            }
        }
        try {
            await axios.delete(`${base_url}/cart?mediaId=${id}&resolution=${resolution}`, config)
            return id
        } catch (error) {
            if (error.response) {
                toast.error(error.response)
                return rejectWithValue(error.response)
            } else {
                toast.error(error.message)
                return rejectWithValue(error.message)
            }
        }
    }
)

export const getCartItems = createAsyncThunk(
    'search/getCartItems',
    async (_, { rejectWithValue }) => {
        const userToken = localStorage.getItem('userToken')
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userToken}`
            }
        }
        try {
            const { data } = await axios.get(`${base_url}/cart`, config)
            const returnData = data?.data
            const discount = returnData?.discount
            const finalPrice = returnData?.finalPrice
            const cartItems = returnData?.totalCartItem
            const total = returnData?.total
            const allMedia = returnData?.allMedia
            const media = allMedia.map(el => el.media)
            const ItemResolution = allMedia?.map(el => el?.resolution)
            const finalMedia = media?.map((obj, idx) => ({
                id: obj.id,
                format: obj.contentSizes[0]?.format,
                name: obj.contentSizes[0]?.displayName,
                price: obj?.price,
                thumbNail: obj.contentSizes[0]?.thumbnailSrc,
                category: obj.category,
                resolution: ItemResolution[idx],
                license: obj.license
            }))
            return { finalMedia, discount, finalPrice, cartItems, total }
        } catch (error) {
            if (error.response) {
                toast.error(error.response.data.message)
                return rejectWithValue(error.response)
            } else {
                toast.error(error.message)
                return rejectWithValue(error.message)
            }
        }
    }
)

export const addToWishlist = createAsyncThunk(
    'search/addToWishlist',
    async ({ id, resolution }, { rejectWithValue }) => {
        const userToken = localStorage.getItem('userToken')
        const body = {
            "mediaId": id,
            resolution
        }
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userToken}`
            }
        }
        try {
            const { data } = await axios.post(`${base_url}/wishlist`, body, config)
            toast.success(data?.message)
        } catch (error) {
            toast.info(error.response.data.message)
        }
    }
)

export const getWishlistItems = createAsyncThunk(
    'search/getWishlistItems',
    async (_, { rejectWithValue }) => {
        const userToken = localStorage.getItem('userToken')
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userToken}`
            }
        }
        try {
            const { data } = await axios.get(`${base_url}/wishlist`, config)
            const allMedia = data?.data?.allMedia
            const finalData = allMedia?.map(obj => ({
                id: obj.media.id,
                license: obj.media.license,
                format: obj.media.contentSizes[0]?.format,
                name: obj.media.contentSizes[0]?.displayName,
                category: obj.media.category,
                thumbNail: obj.media.contentSizes[0]?.thumbnailSrc,
                resolution: obj.resolution
            }))
            return finalData
        } catch (error) {
            if (error.response) {
                toast.error(error.response.data.message)
                return rejectWithValue(error.response)
            } else {
                toast.error(error.message)
                return rejectWithValue(error.message)
            }
        }
    }
)

export const removeWishlistItem = createAsyncThunk(
    'search/removeWishlistItem',
    async ({ id, resolution }, { rejectWithValue }) => {
        const userToken = localStorage.getItem('userToken')
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userToken}`
            }
        }
        try {
            await axios.delete(`${base_url}/wishlist/?mediaId=${id}&resolution=${resolution}`, config)
            return id
        } catch (error) {
            if (error.response) {
                toast.error(error.response)
                return rejectWithValue(error.response)
            } else {
                toast.error(error.message)
                return rejectWithValue(error.message)
            }
        }
    }
)

export const getUserAddress = createAsyncThunk(
    'search/getUserAddress',
    async (_, { rejectWithValue }) => {
        const userToken = localStorage.getItem('userToken')
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userToken}`
            }
        }
        try {
            const { data } = await axios.get(`${base_url}/address`, config)
            return data?.data?.address
        } catch (error) {
            if (error.response) {
                toast.error(error.response)
                return rejectWithValue(error.response)
            } else {
                toast.error(error.message)
                return rejectWithValue(error.message)
            }
        }
    }
)

export const updateUserAddress = createAsyncThunk(
    'search/updateUserAddress',
    async (data, { rejectWithValue }) => {
        const userToken = localStorage.getItem('userToken')
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userToken}`
            }
        }
        const formData = new FormData();
        Object.keys(data).forEach(key => formData.append(key, data[key]))
        try {
            const { data } = axios.post(`${base_url}/address`, formData, config)
            return data?.data?.updateAddress
        } catch (error) {
            if (error.response) {
                toast.error(error.response)
                return rejectWithValue(error.response)
            } else {
                toast.error(error.message)
                return rejectWithValue(error.message)
            }
        }
    }
)

export const cartCheckout = createAsyncThunk(
    'search/cartCheckout',
    async (addressId, { rejectWithValue }) => {
        const userToken = localStorage.getItem('userToken')
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userToken}`
            }
        }
        const body = {
            addressId,
            "couponCode": "",
            "type": "CART",
            "paymentGatewayType": "CASHFREE"
        }
        try {
            const { data } = await axios.post(`${base_url}/cart/checkout`, body, config)
            return data.data.body
        } catch (error) {
            if (error.response) {
                toast.error(error.response)
                return rejectWithValue(error.response)
            } else {
                toast.error(error.message)
                return rejectWithValue(error.message)
            }
        }
    }
)

export const purchaseHistory = createAsyncThunk(
    'search/purchaseHistory',
    async (_, { rejectWithValue }) => {
        const userToken = localStorage.getItem('userToken')
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userToken}`
            }
        }
        try {
            const { data } = await axios.get(`${base_url}/order`, config)
            const returnData = data.data?.orders
            const finalData = returnData?.map(obj => ({
                orderId: obj?.id,
                total: obj?.total,
                date: obj?.createdAt
            }))
            return finalData
        } catch (error) {
            if (error.response) {
                toast.error(error.response)
                return rejectWithValue(error.response)
            } else {
                toast.error(error.message)
                return rejectWithValue(error.message)
            }
        }
    }
)

export const ownedAssets = createAsyncThunk(
    'search/ownedAssets',
    async (_, { rejectWithValue }) => {
        const userToken = localStorage.getItem('userToken')
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userToken}`
            }
        }
        try {
            const { data } = await axios.get(`${base_url}/owned-assets`, config)
            const returnData = data.data?.allAssets
            const mediaData = returnData?.map(el => el?.media)
            const expiryData = returnData?.map(el => el?.expiryDate)
            const orderIdData = returnData?.map(el => el?.orderId)
            const orderItemIdData = returnData?.map(el => el?.orderItemId)
            const filteredMediaData = mediaData.filter(obj => obj && obj.contentSizes && obj.contentSizes[0]);
            const finalMedia = filteredMediaData?.map((obj, idx) => ({
                id: obj.id,
                format: obj.contentSizes[0]?.format,
                name: obj.contentSizes[0]?.displayName,
                thumbNail: obj.contentSizes[0]?.thumbnailSrc,
                src: obj.contentSizes[0]?.src,
                category: obj.category,
                resolution: obj.contentSizes[0]?.size,
                expiry: expiryData[idx],
                orderId: orderIdData[idx],
                orderItemId: orderItemIdData[idx]
            }))
            return finalMedia
        } catch (error) {
            if (error.response) {
                toast.error(error.response)
                return rejectWithValue(error.response)
            } else {
                toast.error(error.message)
                return rejectWithValue(error.message)
            }
        }
    }
)