
import React, { useContext, useState } from "react";

const SellerPagesContext = React.createContext();

function SellerPagesProvider({ children }) {
    const [selectedOption, setSelectedOption] = useState('INDIVIDUAL');
    const [mediaTabValue, setMediaTabValue] = useState(0)

    const handleMediaTabsChange = (event, newValue) => {
        setMediaTabValue(newValue);
      };

    const value = {
        selectedOption,
        setSelectedOption,
        mediaTabValue,
        handleMediaTabsChange
    };
    return (
        <SellerPagesContext.Provider value={value}>
            {children}
        </SellerPagesContext.Provider>
    );
}

export default SellerPagesProvider

export function useSellerPagesContext() {
    return useContext(SellerPagesContext);
}
