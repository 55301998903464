import React, { useState } from 'react';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';
import './passwordInput.css';

const PasswordInput = (props) => {
  const {
    name,
    value,
    onChange
  } = props
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleTogglePassword = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <>
      <input
        className='password-input'
        type={passwordVisible ? 'text' : 'password'}
        placeholder="Enter your password"
        name={name}
        value={value}
        onChange={onChange}
      />
      <span
        className="toggle-password"
        onClick={handleTogglePassword}
      >
        {passwordVisible ? <AiFillEyeInvisible fontSize={22} color='#888' cursor='pointer' /> : <AiFillEye fontSize={22} color='#888' cursor='pointer' />}
      </span>
    </>
  );
};

export default PasswordInput;
