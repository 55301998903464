import React from "react";
import './becomeASeller.css';
import Button from '../../reusable/Button/index';
import becomeASellerImage from '../../../assets/images/BecomeASellerImage.png';
import imageIcon from '../../../assets/images/imageIcon.png';
import musicIcon from '../../../assets/images/musicIcon.png';
import videoIcon from '../../../assets/images/videoIcon.png';
import vectorIcon from '../../../assets/images/vectorIcon.png';
import companyLogo from '../../../assets/logo/WorldArchivesLight.png';

const BecomeASeller = (props) => {
    const {
        onClickSellerContent
    } = props
    return (
        <div className="becomeASeller">
            <div className="becomeASeller-companyLogo">
                <img src={companyLogo} alt="Company Logo"/>
            </div>
            <div className="becomeASeller-allContentContainer">
            <div className="becomeASeller-details">
                <span className="becomeASeller-details-heading">
                    PUT YOUR CREATIVITY TO WORK
                </span>
                <span className="becomeASeller-details-subHeading">
                Join a creative community where top brands, global media, and leading advertising agencies can see your content. Earn money every time they license one of your photos, videos, illustrations, CGI assets, music or portfolios. If they love what they see, they'll but a license to use your content and you'll get paid. It could show up on
                    books, billboards, social media, websites, TV and film, almost anywhere.  If you have high Images, Portfolios, Videos, CGI Assets, Professionally Recorded Music, you can license it yourself.
                </span>
                {/* <span className="becomeASeller-details-heading">
                    Put Your Creativity to Work
                </span>
                <span className="becomeASeller-details-subHeading">
                    Join a creative community where top brands, global media, and leading advertising agencies can see your content.
                    Earn money every time they license one of your photos, videos, illustrations, CGI assets, music or portfolios.
                </span>
                <span className="becomeASeller-details-subHeading">
                    If they love what they see, they'll but a license to use your content and you'll get paid. It could show up on
                    books, billboards, social media, websites, TV and film, almost anywhere.
                </span>
                <span className="becomeASeller-details-subHeading">
                    If you have high Images, Portfolios, Videos, CGI Assets, Professionally Recorded Music, you can license it yourself.
                </span> */}
            </div>
            <div className="becomeASeller-wcContribute">
                <div className="becomeASeller-sellersBuyers-cmnSty becomeASeller-borderRight">
                    <div className="becomeASeller-details-heading">SELLERS</div>
                    <div className="becomeASeller-sb-cmnSty">
                        <div className="becomeASeller-spanContainer-cmnSty">
                            <span>Production House / Studios</span>
                            <span>VFX Studios</span>
                            <span>Photographers</span>
                            <span>Videographers</span>
                            <span>Musician / Arrangers</span>
                            <span>CGI Artists / Concept Artists</span>
                            <span>Illustrators / Painters</span>
                            <span>Graphic Designers</span>
                        </div>
                        <div className="becomeASeller-spanContainer-cmnSty">
                            <span>UI / UX Designers</span>
                            <span>Interior Designers</span>
                            <span>Models, Actors</span>
                            <span>Social Media Influencers</span>
                            <span>Travel Enthusiasts</span>
                            <span>Aggregators</span>
                            <span>Architects</span>
                            <span>Advertising Agencies</span>
                        </div>
                    </div>
                </div>
                <div className="becomeASeller-sellersBuyers-cmnSty">
                    <div className="becomeASeller-details-heading">BUYERS</div>
                        <div className="becomeASeller-sb-cmnSty">
                            <div className="becomeASeller-buyerSpanContainer-cmnSty">
                                <span>Brands</span>
                                <span>OTT Platforms</span>
                                <span>Production Companies</span>
                                <span>Content Studios</span>
                                <span>Animation Studios</span>
                                <span>Music Studios</span>
                                <span>Film Studios</span>
                            </div>
                            <div className="becomeASeller-buyerSpanContainer-cmnSty">
                                <span>Gaming Studios</span>
                                <span>Podcasts Companies</span>
                                <span>Event Companies</span>
                                <span>Music Labels</span>
                                <span>Food & Beverages</span>
                                <span>Corporate</span>
                                <span>Tech Companies</span>
                            </div>
                        </div>
                </div>
                {/* <span className="becomeASeller-details-heading2">
                    Who can Be A Contributor
                </span>
                <div className="becomeASeller-wcc-table">
                    <div className="becomeASeller-wcc-table-column bas-table-addSty">
                        <span>Photographers</span>
                        <span>Videographers</span>
                        <span>Vloggers/Influencers</span>
                        <span>Travel Enthusiasts</span>
                        <span>CGI Artists</span>
                        <span>Concept Artists</span>
                        <span>Designers</span>
                    </div>
                    <div className="becomeASeller-wcc-table-column">
                        <span>Models</span>
                        <span>Actors</span>
                        <span>Architects</span>
                        <span>Illustrators</span>
                        <span>Musicians</span>
                        <span>Production Houses</span>
                        <span>Studios</span>
                    </div>
                </div> */}
                {/* <div className="becomeASeller-wcc-btnContainer">
                    <Button onClick={onClickSellerContent} btnStyle='becomeASeller-button ff-OS fw-600'>Learn More</Button>
                </div> */}
            </div>
            </div>
        </div>
    )
}

export default BecomeASeller;