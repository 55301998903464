import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../features/users/userSlice';
import searchReducer from '../features/search/searchSlice'
import adminReducer from '../features/admin/adminSlice'
import adminSearchReducer from '../features/adminSearch/adminSearchSlice'
import settingSlice from '../features/setting/settingSlice';
import sellerReducer from '../features/seller/sellerSlice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    search: searchReducer,
    admin: adminReducer,
    adminSearch: adminSearchReducer,
    setting: settingSlice,
    seller: sellerReducer
  }
});