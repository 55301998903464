import React, { useEffect, useState } from "react";
import './videosPage.css';
import { BecomeASeller } from "../../common/BecomeASeller";
import { Footer } from "../../common/Footer";
import SubHeading from "../../pageContent/SubHeading/SubHeading";
import { Header } from "../../pageContent/Header";
import { Navbar } from "../../common/Navbar";
import { getCategories, getCategoryData } from "../../../api";
import previewBannerVideo from '../../../assets/mastHead/mastheadBanner.mp4';
import vpfallBackImage from '../../../assets/images/ImageBanner.png';
import companyLogo from '../../../assets/logo/WorldArchivesVideoLight.png';
import PopularCategory from "../../common/PopularCategories/PopularCategory";
import { getAdminSetting } from "../../../features/admin/adminActions";
import { useDispatch, useSelector } from "react-redux";


const VideosPage = () => {
    const dispatch = useDispatch()
    const {popularCategoryKeywords} = useSelector(state => state.admin)
    const [categories, setCategories] = useState([])
    const [imgArr, setImgArr] = useState([])

    useEffect(() => {
        if (!categories || !categories.length) return
        const getAllCategoriesData = async () => {
            let catData = await getCategories('VIDEO').then(res => res)
            const finalData = await Promise.all(catData?.map(async (category) => {
                return await getCategoryData('VIDEO', category)
            })
            )
            if (categories.length && (categories.length === finalData.length)) setImgArr(finalData)
        }
        getAllCategoriesData()
    }, [categories])

    useEffect(() => {
        getCategories('VIDEO').then(res => setCategories(res))
        localStorage.setItem('selectTerm', 'VIDEO')
    }, [])

    useEffect(()=>{
        dispatch(getAdminSetting('VIDEO_PAGE'))
    },[dispatch])

    return (
        <div className="videosPage">
            <div className="videosPage-banner">
                <Navbar companyLogo={companyLogo} logoStyles='navbar-logoStyle-common' menuItemStyles="videosPage-menu-items" cartColor="#FFFFFF" hamburgerColor="#ffffff"/>
                <Header
                    headerHeadingContent='Make a statement with Genuine, New Video.'
                    headerSubHeading="Find the most impressive stock video, in 4K or HD for the best rates."
                    headerTrending="mountains,dark,nature,flowers,space"
                    searchBarStyles='videoPage-SearchBar'
                    initialState='VIDEO'
                    headerHeight='videoPageHeaderHeight'
                />
                <video className="videoPage-banner-video" autoPlay loop muted width="100%">
                    <source src={previewBannerVideo} type="video/mp4" />
                    <img src={vpfallBackImage} alt="Fallback Image" />
                </video>
            </div>
            <PopularCategory popularCategoryKeywords={popularCategoryKeywords} selectTerm="VIDEO"/>
            <div className="videosPage-pageContent">
                {categories.length ? categories.map((el, idx) => <SubHeading key={idx} heading={el} requiredArray={imgArr[idx]} pageName="VIDEO" />):<></>}
            </div>
            <BecomeASeller />
            <Footer />
        </div>
    )
}

export default VideosPage;