import React, { useState } from "react";
import './adminTable.css';
import Radio from '@mui/material/Radio';
import { useAdminPagesContext } from "../../../contexts/AdminPagesContext";
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useDispatch, useSelector } from "react-redux";
import { adminContent, adminContentPublish, adminContentScheduler, adminContentUnpublish, saveWithoutPublish } from '../../../features/adminSearch/adminSearchActions'
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { standardizeTime } from '../../utils'

const PopupVisibility = () => {
    const dispatch = useDispatch()
    const { mediaData } = useSelector(state => state.adminSearch)
    const visibilityInfo = mediaData?.visibility
    const [selectedValue, setSelectedValue] = React.useState('a');
    const { activeStep, setActiveStep, id, handleCloseViewPopup, tabValue, details } = useAdminPagesContext()
    const [price, setPrice] = React.useState(mediaData?.price)
    const [dateValue, setDateValue] = React.useState((new Date()).toLocaleDateString());
    const [timeValue, setTimeValue] = useState('23:00')

    const onTimeChange = (e) => {
        const finalTime = standardizeTime(e.target.value)
        setTimeValue(finalTime)
    }

    const handlePrice = (e) => {
        setPrice(e.target.value)
    }

    const handleDateChange = (newValue) => {
        setDateValue(new Date(newValue.$d).toLocaleDateString());
    };

    const handleChangeRadioBtn = (event) => {
        setSelectedValue(event.target.value);
    }

    const handleBack = () => {
        setActiveStep(activeStep - 1)
    }

    const handleSaveClick = async () => {
        let dataN = { ...details, id: mediaData.uid, price, scheduleDate : dateValue, schedulerTime: timeValue }
        await dispatch(saveWithoutPublish(dataN))
        tabValue === 0 ? await dispatch(adminContent({ pageName: 'IMAGE' })) :
            await dispatch(adminContent({ pageName: 'VIDEO' }))
    }

    const handlePublishAndUnpublish = async () => {
        switch (selectedValue) {
            case "a":
                if (mediaData.mediaStatus === "REJECTED" || mediaData.mediaStatus === "PENDING") toast.error(`Can't publish a rejected or pending media`)
                else {
                    await dispatch(adminContentPublish({ id, price }))
                    tabValue === 0 ? await dispatch(adminContent({ pageName: 'IMAGE' })) :
                        await dispatch(adminContent({ pageName: 'VIDEO' }))
                }
                return
            case "b":
                if (mediaData.mediaStatus === "REJECTED" || mediaData.mediaStatus === "PENDING") toast.error(`Can't schedule a rejected or pending media`)
                else {
                    await dispatch(adminContentScheduler({ id, dateValue, timeValue, price }))
                    tabValue === 0 ? await dispatch(adminContent({ pageName: 'IMAGE' })) :
                        await dispatch(adminContent({ pageName: 'VIDEO' }))
                }
                return
            case "c":
                await dispatch(adminContentUnpublish({ id }))
                tabValue === 0 ? await dispatch(adminContent({ pageName: 'IMAGE' })) :
                    await dispatch(adminContent({ pageName: 'VIDEO' }))
                return
            default:
                return
        }
    }
    const aws_url = process.env.REACT_APP_S3_HOST

    return (
        <>
            <div className="popupVisibility">
                <div className="popupVisibility-content">
                    <div className="popupVisibility-content-heading">
                        Visibility
                    </div>
                    <div className="popupVisibility-content-subHeading">
                        Choose when to publish and who can see the content
                    </div>
                    <div className="popupVisibility-contentOptions">
                        {visibilityInfo === 'NOT_PUBLISHED' ? <div className="popupVisibility-contentOptions-commonSty">
                            <div className="popupVisibility-radiocommonSty">
                                <Radio
                                    sx={{
                                        '.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked': {
                                            color: '#4946C3'
                                        }
                                    }}
                                    size="small"
                                    checked={selectedValue === 'a'}
                                    onChange={handleChangeRadioBtn}
                                    value="a"
                                    name="radio-buttons"
                                />
                            </div>
                            <div className="popupVisibility-contentCommonSty">
                                <span className="popupVisibility-contentHeadingCommonSty">Publish</span>
                                <span className="popupVisibility-contentSubHeadingCommonSty">Make your {tabValue === 0 ? 'Image' : 'Video'} public, unlisted, or private</span>
                            </div>
                        </div> :
                            <div className="popupVisibility-contentOptions-commonSty">
                                <div className="popupVisibility-radiocommonSty">
                                    <Radio
                                        sx={{
                                            '.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked': {
                                                color: '#4946C3'
                                            }
                                        }}
                                        size="small"
                                        checked={selectedValue === 'c'}
                                        onChange={handleChangeRadioBtn}
                                        value="c"
                                        name="radio-buttons"
                                    />
                                </div>
                                <div className="popupVisibility-contentCommonSty">
                                    <span className="popupVisibility-contentHeadingCommonSty">Unpublish</span>
                                    <span className="popupVisibility-contentSubHeadingCommonSty">Take your {tabValue === 0 ? 'Image' : 'Video'} offline</span>
                                </div>
                            </div>
                        }
                        {visibilityInfo === 'NOT_PUBLISHED' ? <div className="popupVisibility-contentOptions-commonSty">
                            <div className="popupVisibility-radiocommonSty">
                                <Radio
                                    sx={{
                                        '.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked': {
                                            color: '#4946C3'
                                        }
                                    }}
                                    size="small"
                                    checked={selectedValue === 'b'}
                                    onChange={handleChangeRadioBtn}
                                    value="b"
                                    name="radio-buttons"
                                />
                            </div>
                            <div className="popupVisibility-contentCommonSty">
                                <span className="popupVisibility-contentHeadingCommonSty">Schedule</span>
                                <span className="popupVisibility-contentSubHeadingCommonSty">Select a date to make your video public</span>
                                {selectedValue === "b" ?
                                    <span className="popupVisibility-dateSelector">
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <MobileDatePicker
                                                inputFormat="DD/MM/YYYY"
                                                minDate={new Date()}
                                                value={dateValue}
                                                onChange={handleDateChange}
                                                renderInput={(params) => <input {...params} placeholder={params?.inputProps?.value} />}
                                            />
                                        </LocalizationProvider>
                                        <input
                                            type='time'
                                            value={timeValue}
                                            onChange={onTimeChange} />
                                    </span>
                                    : null
                                }

                            </div>
                        </div> : null}
                        {visibilityInfo === 'NOT_PUBLISHED' ? <div className="popupVisibility-contentOptions-inputField">
                            <label for='priceField'>Add Price</label>
                            <input placeholder="Add Price" name="priceField" type="number" min={10} max={10000} value={price} onChange={handlePrice} />
                        </div> : null}
                    </div>
                </div>
                <div className="popupVisibility-contentPreview">
                    {tabValue === 0 ?
                        <>
                            <div className="popupVis-imgTagContainer">
                                <img src={`${aws_url}/${mediaData?.vidSrc}`} alt="" />
                                {mediaData?.isPremium === true || details?.isPremium === true ? <div className="premium-tag">Premium</div> : <></>}
                            </div>
                            <div className='popup-contentPreview-mediaDataInfo' style={{ width: '100%' }}>UID: {mediaData?.uid}</div>
                            <span className='popup-contentPreview-mediaDataInfo' style={{ width: '100%' }}>{mediaData?.fileName}</span></> :
                        <>
                            {/* <ReactPlayer
                                config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                                playing={true}
                                width='100%'
                                height='100'
                                controls
                                url={`${aws_url}/${mediaData?.vidSrc}`}
                            /> */}
                            <div className="popupVis-imgTagContainer">
                                <video autoPlay height='150' width='100%' style={{ objectFit: 'fill' }} controls controlsList="nodownload">
                                    <source src={`${aws_url}/${mediaData?.vidSrc}`} />
                                </video>
                                {mediaData?.isPremium === true || details?.isPremium === true ? <div className="premium-tag">Premium</div> : <></>}
                            </div>
                            <div className='popup-contentPreview-mediaDataInfo' style={{ width: '100%' }}>UID: {mediaData?.uid}</div>
                            <span className='popup-contentPreview-mediaDataInfo' style={{ width: '100%' }}>{mediaData?.fileName}</span>
                        </>
                    }
                </div>
            </div>
            <div className={visibilityInfo === 'NOT_PUBLISHED' ? "popupVisibility-buttons" : "popupVisibility-buttons popupVisibility-buttons-AddSty"}>
                <button onClick={handleBack} className="popupVisibility-backBtn">Back</button>
                <button className="popupVisibility-publishBtn" onClick={() => {
                    handleSaveClick()
                    handleCloseViewPopup()
                }}>
                    Save
                </button>
                <button className="popupVisibility-publishBtn" onClick={() => {
                    handlePublishAndUnpublish()
                    handleCloseViewPopup()
                }}>
                    {selectedValue === 'b' ? 'Schedule' : visibilityInfo === 'NOT_PUBLISHED' ? 'Publish' : 'Unpublish'}
                </button>
            </div>
        </>
    )
}

export default PopupVisibility;