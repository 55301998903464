import React, {useEffect} from 'react';

function PaymentForm() {
  const urlObj = new URL(window.location.href)
  const pathArray = urlObj.pathname?.split('/')
  const id = pathArray[pathArray.length - 1]
  const accessCode = process.env.REACT_APP_CCAVENUE_ACCESS_CODE
  const securePaymentUrl = 'https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction'

  useEffect(() => {
    document.getElementById('payment-form').submit();
  }, []); 
  
  return (
    <div>
      <form id="payment-form" method="post" action={securePaymentUrl}>
        <input type="hidden" name="encRequest" value={id} />
        <input type="hidden" name="access_code" value={accessCode} />
      </form>
    </div>
  );
}

export default PaymentForm;