import React, { useEffect, useState } from 'react';
import { AiFillCheckCircle } from 'react-icons/ai';
import { MdCancel } from 'react-icons/md';
import { useAdminPagesContext } from '../../../contexts/AdminPagesContext';
import './adminTable.css';
import { Modal, Box, MenuItem, Select, Switch } from '@mui/material';
import { MdOutlineCancel } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { adminContentReject, adminContent } from '../../../features/adminSearch/adminSearchActions';
import Loader from '../../../components/common/Loader/Loader';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { updatePreview, updateThumbnail, assetVersioning } from '../../../api';
import { getAdminContentMediaById } from '../../../features/adminSearch/adminSearchActions';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import axios from 'axios';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    border: '1px solid #ffffff',
    borderRadius: '5px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    outline: 'none',
    padding: '1rem 1.5rem'
};

const ccSelectStyle = {
    fontFamily: 'Open Sans',
    fontSize: '13px',
    color: '#888888',
    border: '1px solid #C8C7C7',
    borderRadius: '5px',
    height: '35px',
    minWidth: '285px',
    maxWidth: '285px',
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
        border: "#C8C7C7",
        outline: 'none',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        border: '#C8C7C7',
    },
}

const PopupDetails = () => {
    const { activeStep, setActiveStep, tabValue, details, setDetails, id, viewCheck, setOpenViewPopup } = useAdminPagesContext()
    const [openRejectionModal, setOpenRejectionModal] = useState(false)
    const [reason, setReason] = useState('')
    const dispatch = useDispatch()
    const { mediaInfo, mediaData, loading } = useSelector(state => state.adminSearch)
    const { role } = useSelector(state => state.admin) ?? localStorage.getItem('loggedRole')
    const [keywordString, setKeywordString] = useState(details?.keywords ? details.keywords.join(', ') : mediaData?.keywords === [] ? mediaData?.keywords.map((keyword) => { return keyword }) : mediaData?.keywords);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        const fetchCategories = async () => {
            const response = await axios.get('https://api.worldarchives.in/setting');
            const settings = response?.data?.data?.settings;
            let contentCategories = [];
            for (let i = 0; i < settings.length; i++) {
                const setting = settings[i];
                if (setting.settingKey === 'CONTENT_CATEGORIES') {
                    contentCategories = setting.settingValue;
                    break;
                }
            }
            setCategories(contentCategories);
        };
        fetchCategories();
    }, []);

    const onClickNextBtn = () => {
        setActiveStep(activeStep + 1)
    }

    const onClickReject = () => {
        setOpenRejectionModal(true)
    }

    const handleCloseRejectModal = () => {
        setOpenRejectionModal(false)
    }

    const handleRejectSubmit = async () => {
        setOpenRejectionModal(false)
        setOpenViewPopup(false)
        await dispatch(adminContentReject({ id, reason }))
        tabValue === 0 ?
            await dispatch(adminContent({ pageName: 'IMAGE' })) :
            await dispatch(adminContent({ pageName: 'VIDEO' }))
    }

    const handleUpdateThumbnail = async (e) => {
        await updateThumbnail(e.target.files[0], e.target.id)
        await dispatch(getAdminContentMediaById({ id: e.target.id }))
        tabValue === 0 ?
            await dispatch(adminContent({ pageName: 'IMAGE' })) :
            await dispatch(adminContent({ pageName: 'VIDEO' }))
    }

    const handleUpdateAsset = async (e) => {
        await assetVersioning(e.target.files[0], e.target.id)
        await dispatch(getAdminContentMediaById({ id: e.target.id }))
        tabValue === 0 ?
            await dispatch(adminContent({ pageName: 'IMAGE' })) :
            await dispatch(adminContent({ pageName: 'VIDEO' }))
    }

    const handleUpdatePreview = async (e) => {
        await updatePreview(e.target.files[0], e.target.id)
        await dispatch(getAdminContentMediaById({ id: e.target.id }))
        tabValue === 0 ?
            await dispatch(adminContent({ pageName: 'IMAGE' })) :
            await dispatch(adminContent({ pageName: 'VIDEO' }))
    }

    const aws_url = process.env.REACT_APP_S3_HOST

    // const categories = ["Trending", "Recommended For you", "New Arrivals", "Visual FX", "Music", "Editorials", "Fashion", "Celebrity"]

    return (
        <>
            {/* <form onSubmit={handleSubmit(onSubmitDetails)}> */}
            {loading ?
                <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center', height: '50vh' }}><Loader /></div> :
                <>
                    <div className='popup-content'>
                        <div className='popup-contentInfo'>
                            <div className='popup-contentInfo-heading'>
                                Details
                            </div>
                            <div className='popup-contentInfo-commonSty'>
                                <div className='popup-contentInfo-resCodCommonSty'>
                                    <span className='headSpanCommonSty'>Resolution</span>
                                    <span className='downSpanCommonSty'>{mediaInfo?.resolution}</span>
                                </div>
                                <div className='popup-contentInfo-forDurCommonSty'>
                                    <span className='headSpanCommonSty'>Format</span>
                                    <span className='downSpanCommonSty'>{mediaInfo?.format}</span>
                                </div>
                            </div>
                            <div className='popup-contentInfo-commonSty'>
                                {tabValue === 0 ? <></> : <div className='popup-contentInfo-resCodCommonSty'>
                                    <span className='headSpanCommonSty'>Codec</span>
                                    <span className='downSpanCommonSty'>{mediaInfo?.codecID}</span>
                                </div>}
                                {tabValue === 1 && <div className='popup-contentInfo-forDurCommonSty'>
                                    <span className='headSpanCommonSty'>Duration</span>
                                    <span className='downSpanCommonSty'>{mediaInfo?.duration ? mediaInfo?.duration : '--'}</span>
                                </div>}
                            </div>
                            <div className='popup-contentInfo-inputFields'>
                                <div className='inputFieldsContainer_cmnSty'>
                                    <label className='headSpanCommonSty'>Title</label>
                                    <input
                                        className='popup-inputCommonSty titleAddStyle'
                                        placeholder='Add Title'
                                        spellCheck="true"
                                        value={details?.title || details?.title === "" ? details?.title : mediaData?.title}
                                        disabled={viewCheck}
                                        onChange={(e) => {
                                            setDetails({ ...details, title: e.target.value })
                                        }}
                                    />
                                </div>
                                <div className='inputFieldsContainer_cmnSty'>
                                    <label className='headSpanCommonSty'>Description</label>
                                    <textarea
                                        className='descriptionAddStyle'
                                        style={tabValue === 0 ? { height: '141px' } : { height: '105.5px' }}
                                        disabled={viewCheck}
                                        spellCheck="true"
                                        placeholder='Add Description' resize='none'
                                        value={details?.description || details?.description === "" ? details?.description : mediaData?.description}
                                        onChange={(e) => {
                                            setDetails({ ...details, description: e.target.value })
                                        }}

                                    />
                                </div>
                                <div className='inputFieldsContainer_cmnSty'>
                                    <label className='headSpanCommonSty'>POV</label>
                                    <input className='popup-inputCommonSty'
                                        disabled={viewCheck}
                                        placeholder='Add POV'
                                        spellCheck="true"
                                        value={details?.pov || details?.pov === "" ? details?.pov : mediaData?.pov}
                                        onChange={(e) => {
                                            setDetails({ ...details, pov: e.target.value })
                                        }} />
                                </div>
                                <div className='inputFieldsContainer_cmnSty'>
                                    <label className='headSpanCommonSty'>Content Category</label>
                                    {/* <input className='popup-inputCommonSty'
                                        disabled={viewCheck}
                                        placeholder='Add Content Category'
                                        spellCheck="true"
                                        value={details?.contentCategory || details?.contentCategory === "" ? details?.contentCategory : mediaData?.contentCategory}
                                        onChange={(e) => {
                                            setDetails({ ...details, contentCategory: e.target.value })
                                        }} /> */}
                                    <Select
                                        placeholder='Select Content Category'
                                        sx={ccSelectStyle}
                                        multiple
                                        disabled={viewCheck}
                                        value={details?.contentCategory ? (
                                            Array.isArray(details.contentCategory) ?
                                                details.contentCategory :
                                                [details.contentCategory]
                                        ) : mediaData?.contentCategory ? (
                                            typeof mediaData?.contentCategory === 'string' ?
                                                [mediaData?.contentCategory] :
                                                mediaData?.contentCategory
                                        ) : []}
                                        onChange={(e) => {
                                            setDetails({ ...details, contentCategory: e.target.value });
                                        }}
                                        renderValue={details?.contentCategory === undefined && mediaData?.contentCategory?.length === 0 ? () => 'Select Content Category' : undefined}
                                        displayEmpty
                                    >
                                        {categories && categories.map((category) => (
                                            <MenuItem
                                                key={category}
                                                value={category}
                                            >
                                                {category}
                                            </MenuItem>
                                        )
                                        )}
                                    </Select>
                                </div>
                                <div className='inputFieldsContainer_cmnSty'>
                                    <label className='headSpanCommonSty'>Contributor Name</label>
                                    <input className='popup-inputCommonSty'
                                        disabled={viewCheck}
                                        placeholder='Add Contributor Name'
                                        spellCheck="true"
                                        value={details?.contributorName || details?.contributorName === "" ? details?.contributorName : mediaData?.contributorName}
                                        onChange={(e) => {
                                            setDetails({ ...details, contributorName: e.target.value })
                                        }} />
                                </div>
                                <div className='inputFieldsContainer_cmnSty'>
                                    <label className='headSpanCommonSty'>Type Of Angle</label>
                                    <input className='popup-inputCommonSty'
                                        disabled={viewCheck}
                                        placeholder='Add Type of angle'
                                        spellCheck="true"
                                        value={details?.typeOfAngle || details?.typeOfAngle === "" ? details?.typeOfAngle : mediaData?.typeOfAngle}
                                        onChange={(e) => {
                                            setDetails({ ...details, typeOfAngle: e.target.value })

                                        }} />
                                </div>
                            </div>
                        </div>
                        <div className='popup-contentPreview'>
                            {tabValue === 0 && mediaData.category === 'IMAGE' ?
                                <>
                                    <img
                                        className='popup-cc-detailsPreview'
                                        src={`${aws_url}/${mediaData?.vidSrc}`}
                                        alt=""
                                        onClick={
                                            () => {
                                                const img = document.querySelector('.popup-cc-detailsPreview');
                                                if (img) {
                                                    if (img.requestFullscreen) {
                                                        img.requestFullscreen();
                                                    } else if (img.mozRequestFullScreen) { // For Firefox
                                                        img.mozRequestFullScreen();
                                                    } else if (img.webkitRequestFullscreen) { // For Chrome, Safari and Opera
                                                        img.webkitRequestFullscreen();
                                                    } else if (img.msRequestFullscreen) { // For IE/Edge
                                                        img.msRequestFullscreen();
                                                    }
                                                }
                                            }
                                        }
                                    />
                                    <div className='popup-contentPreview-mediaDataInfo'>UID: {mediaData.uid}</div>
                                    <span className='popup-contentPreview-mediaDataInfo'>{mediaData?.fileName}</span></> :
                                <>
                                    <video autoPlay height='150' width='80%' style={{ objectFit: 'fill' }} controls controlsList="nodownload">
                                        <source src={`${aws_url}/${mediaData?.vidSrc}`} />
                                    </video>
                                    <div className='popup-contentPreview-mediaDataInfo'>UID: {mediaData.uid}</div>
                                    <span className='popup-contentPreview-mediaDataInfo'>{mediaData?.fileName}</span>
                                </>
                            }
                            <div className='popup-content-rowTwo'>
                                <div className='popup-uploadThumbAndUpdateAsset'>
                                    {(role === "editor" || role === "admin") &&
                                        <>
                                            <label className="uploadThumbnail_btn" style={{ cursor: "pointer" }}>
                                                <input type="file" onChange={handleUpdateThumbnail} id={mediaData.uid} />
                                                <AddAPhotoIcon />
                                                <span className='addStyle_thumb'>Upload Thumbnail</span>
                                            </label>
                                            <label className='update-asset-btn-admin' style={{ cursor: "pointer" }}>
                                                <input type="file" onChange={handleUpdateAsset} id={mediaData.uid} />
                                                {mediaData?.category === "IMAGE" ? <AddAPhotoIcon /> : <VideocamOutlinedIcon fontSize='2rem' />}
                                                <span className='addStyle_thumb'>Update Asset</span>
                                            </label>
                                        </>
                                    }
                                </div>
                                <div className='popup-updatePrevAndPremiumTag'>
                                    <label className="uploadThumbnail_btn" style={{ cursor: "pointer" }}>
                                        <input type="file" onChange={handleUpdatePreview} id={mediaData.uid} />
                                        <AddAPhotoIcon />
                                        <span className='addStyle_thumb'>Update Preview</span>
                                    </label>
                                    <div className='premiumTagButton'>
                                        <span>Premium Tag</span>
                                        <Switch
                                            size='small'
                                            checked={details?.isPremium ? details.isPremium : mediaData?.isPremium}
                                            onChange={(e) => {
                                                setDetails({ ...details, isPremium: e.target.checked })
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='inputFieldsContainer_cmnSty'>
                                    <label className='headSpanCommonSty'>Camera</label>
                                    <input className='popup-inputCommonSty'
                                        disabled={viewCheck}
                                        placeholder='Add Camera'
                                        spellCheck="true"
                                        value={details?.camera || details?.camera === "" ? details?.camera : mediaData?.camera}
                                        onChange={(e) => {
                                            setDetails({ ...details, camera: e.target.value })

                                        }} />
                                </div>
                                <div className='inputFieldsContainer_cmnSty'>
                                    <label className='headSpanCommonSty'>Shoot Location</label>
                                    <input className='popup-inputCommonSty'
                                        disabled={viewCheck}
                                        placeholder='Add Shoot Location'
                                        spellCheck="true"
                                        value={details?.shootLocation || details?.shootLocation === "" ? details?.shootLocation : mediaData?.shootLocation}
                                        onChange={(e) => {
                                            setDetails({ ...details, shootLocation: e.target.value })
                                        }} />
                                </div>
                                <div className='inputFieldsContainer_cmnSty'>
                                    <label className='headSpanCommonSty'>Keywords</label>
                                    <textarea className='keywordsAddStyle'
                                        disabled={viewCheck}
                                        placeholder='Add Keywords'
                                        spellCheck="true"
                                        value={keywordString ? keywordString : mediaData && mediaData?.keywords === [] ? mediaData?.keywords.map((keyword) => { return keyword }) : mediaData?.keywords}
                                        onChange={(e) => {
                                            setKeywordString(e.target.value);
                                            setDetails({ ...details, keywords: e.target.value.split(',').map(item => item.trim()) });

                                        }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='popup-acceptRejectNextBtn'>
                        <div className='popup-acceptReject'>
                            {!viewCheck && <button disabled type='submit' className='popup-commonBtbSty popupAcceptDisable'>
                                <AiFillCheckCircle style={{ fontSize: '25px', marginRight: '5px', color: '#0CA65C' }} />
                                Accept
                            </button>}
                            {!viewCheck && <button className='popup-commonBtbSty enableButton' onClick={onClickReject}>
                                <MdCancel style={{ fontSize: '24px', marginRight: '5px', color: '#FF2121' }} />
                                Reject
                            </button>}
                        </div>
                        <button onClick={onClickNextBtn} className='popup-nextBtnSty'>
                            Next
                        </button>
                    </div></>}
            {/* </form> */}
            <Modal
                open={openRejectionModal}
                onClose={handleCloseRejectModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className='rejectPopup-headingCrossBtn'>
                        <div className="rejectPopup-heading">
                            Are you sure you want to reject ?
                        </div>
                        <div className='rejectPopup-close' onClick={handleCloseRejectModal}>
                            <MdOutlineCancel fontSize="19px" />
                        </div>
                    </div>
                    <textarea className='popup-inputCommonSty descriptionAddStyle' placeholder='Reason' resize='none' onChange={e => setReason(e.target.value)} />
                    <div className='rejectPopup-buttons'>
                        <button onClick={handleCloseRejectModal} className='rejectPopup-cancelBtn'>Cancel</button>
                        <button className='rejectPopup-rejectBtn' onClick={handleRejectSubmit}>Reject</button>
                    </div>
                </Box>
            </Modal>
        </>
    )
}

export default PopupDetails;