import React from "react";
import './support.css';
import logoMain from '../../../assets/logo/WorldArchivesDark.png';
import { Footer } from "../../common/Footer";
import { Navbar } from "../../common/Navbar";

const Support = () => {
    return(
        <div className="support">
            <Navbar menuItemStyles="navbar-menu-items" logoStyles="navbar-logoStyle" companyLogo={logoMain} cartColor="#666666" />
            <div className="support-container">
                <span className="support-heading">
                    Support
                </span>
                <div className="support-content">
                    <span> If you have any queries, suggestion, or operational technical issues, 
                    please reach out to us at our email&nbsp; <span className="fw-600 support_link">support@worldarchives.in</span></span>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Support;