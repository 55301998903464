import React from "react";
import './sellerEarnings.css';
import SellerNavbar from "../../sellerCommon/sellerNavbar/SellerNavbar";
import SellerSidebar from "../../sellerCommon/sellerSidebar/sellerSidebar";
import SeTabs from "./SeTab";

const SellerEarnings = () => {
    return (
        <div className='sellerEarnings'>
            <div className='sellerEarnings-navbar'>
                <SellerNavbar />
            </div>
            <div className="sellerEarnings-sidebarAndData">
                <div className="sellerEarnings-sidebar">
                    <SellerSidebar />
                </div>
                <div className="sellerEarnings-data">
                    <div className="se-heading-container">
                        <div className='se-heading'>Earnings Summary</div>
                        <div className="se-earnDown-cmnSty">
                            <span>Total Earnings</span>
                            <span className="fw-700">Rs.50000</span>
                        </div>
                        <div className="se-earnDown-cmnSty">
                            <span>Total Downloads</span>
                            <span className="fw-700">800</span>
                        </div>
                    </div>
                    <div>
                        <SeTabs/>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default SellerEarnings;