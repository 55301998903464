import React from "react";
import './pictureTiles.css';
import sideImage from '../../../assets/images/sideImage.png'

const PictureTiles = () => {
    return (
        <div className="pictureTile">
            {/* <div className="pictureTile-row1">
                <div className="pictureTile-row1-img1"><img src={pictureTile3} alt='' /></div>
                <div className="pictureTile-row1-img2"><img src={pictureTile12} alt='' /></div>
                <div className="pictureTile-row1-img3"><img src={pictureTile6} alt='' /></div>
                <div className="pictureTile-row1-img4"><img src={pictureTile11} alt='' /></div>
            </div>
            <div className="pictureTile-row2">
                <div className="pictureTile-row1-img4"><img src={pictureTile2} alt='' /></div>
                <div className="pictureTile-row1-img2"><img src={pictureTile4} alt='' /></div>
                <div className="pictureTile-row1-img3"><img src={pictureTile7} alt='' /></div>
                <div className="pictureTile-row1-img1"><img src={pictureTile10} alt='' /></div>
            </div>
            <div className="pictureTile-row3">
                <div className="pictureTile-row1-img1"><img src={pictureTile1} alt='' /></div>
                <div className="pictureTile-row1-img2"><img src={pictureTile5} alt='' /></div>
                <div className="pictureTile-row1-img3"><img src={pictureTile8} alt='' /></div>
                <div className="pictureTile-row1-img4"><img src={pictureTile9} alt='' /></div>
            </div> */}
             <img src={sideImage} alt ='' />
        </div>
    )
}

export default PictureTiles;