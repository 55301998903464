import React, { useEffect } from 'react';
import { Box, Modal } from '@mui/material';
import { MdOutlineCancel } from 'react-icons/md';
import PopupStepper from './PopupStepper';
import './adminTable.css';
import PopupDetails from './PopupDetails';
import { useAdminPagesContext } from '../../../contexts/AdminPagesContext';
import PopupChecks from './PopupChecks';
import PopupVisibility from './PopupVisibility';
import { useSelector } from 'react-redux';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 650,
    height: 595,
    bgcolor: 'background.paper',
    boxShadow: 24,
    padding: '1rem 1.5rem',
    outline: 'none',
    borderRadius: '10px'
};

export default function PopupContainer({ open, handleCloseViewPopup }) {
    const { activeStep, viewCheck } = useAdminPagesContext()
    const { role } = useSelector(state => state.admin) ?? localStorage.getItem('loggedRole')
    const { mediaData, loading } = useSelector(state => state.adminSearch)
    const getStepContent = (activeStep) => {
        switch (activeStep) {
            case 0:
                return !viewCheck && role === "scheduler" ? <PopupChecks /> : < PopupDetails />
            case 1:
                return !viewCheck && role === "scheduler" ? !viewCheck && <PopupVisibility /> : <PopupChecks />
            case 2:
                return !viewCheck && <PopupVisibility />
            default:
                return null
        }
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleCloseViewPopup}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className='adminTableViewPopup'>
                        <div className='adminTableViewPopup-headingAndClose'>
                            <div className="adminTableViewPopup-heading">
                                {!loading && mediaData?.title}
                            </div>
                            <div className='adminTableViewPopup-close' onClick={handleCloseViewPopup}>
                                <MdOutlineCancel fontSize="19px" />
                            </div>
                        </div>
                        <div className='adminTableViewPopup-stepper'>
                            <PopupStepper viewCheck={viewCheck} />
                        </div>
                        {getStepContent(activeStep)}
                    </div>
                </Box>
            </Modal>
        </div>
    );
}