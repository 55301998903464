import React, { useState } from "react";
import './adminContent.css';
import { BiSearchAlt2, BiImport, BiExport } from 'react-icons/bi';
import { AiOutlineFilter, AiOutlineDelete } from 'react-icons/ai';
import AdminTabs from "../../adminCommon/adminTabs/AdminTabs";
import { useDispatch, useSelector } from "react-redux";
import { adminContent, adminContentImport } from '../../../features/adminSearch/adminSearchActions'
import { useAdminPagesContext } from "../../../contexts/AdminPagesContext";
import { RxCross2 } from 'react-icons/rx'
import FilterPopup from "./FilterPopup";
import AdminSidebar from "../../adminCommon/adminSidebar/AdminSidebar";
import AdminNavbar from "../../adminCommon/adminNavbar/AdminNavbar";
import { saveAs } from "file-saver";
import DeleteAllPopup from "../../adminCommon/adminTable/DeleteAllPopup"

const AdminContent = () => {
    const [openDeletePopup, setOpenDeletePopup] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const [showFilterPopup, setShowFilterPopup] = useState(false)
    const { tabValue, setFilterData, modalHeading, setModalHeading, setModifiedContent, selectedIds, setPage, filterData } = useAdminPagesContext()
    const { role } = useSelector(state => state.admin) ?? localStorage.getItem('loggedRole')
    const dispatch = useDispatch()
    const { contentCategory, typeOfAngle, pov, shootLocation, resolution, videoDuration, mediaStatus, visibility } = filterData


    const onClickFilterBtn = () => {
        setShowFilterPopup(true)
    }

    const handleCloseFilterPopup = () => {
        setShowFilterPopup(false)
    }

    const handleExport = async () => {
        const base_url = process.env.REACT_APP_BASE_URL
        const userToken = localStorage.getItem('adminToken')
        switch (tabValue) {
            case 0:
                await fetch(`${base_url}/admin/media/export`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userToken}`,
                    },
                    body: JSON.stringify({
                        search: {
                            category: "IMAGE",
                            ids: selectedIds?.length ? selectedIds : undefined,
                            contentCategory: contentCategory && contentCategory?.length ? contentCategory : undefined,
                            mediaStatus: mediaStatus && mediaStatus?.length ? mediaStatus : undefined,
                            visibility: visibility && visibility?.length ? visibility : undefined,
                            typeOfAngle: typeOfAngle && typeOfAngle?.length ? typeOfAngle : undefined,
                            pov: pov && pov?.length ? pov : undefined,
                            shootLocation: shootLocation && shootLocation?.length ? shootLocation : undefined,
                            resolution: resolution && resolution?.length ? resolution : undefined,
                            videoDuration: videoDuration > 0 ? videoDuration : undefined
                        },
                    }),
                })
                    .then((response) => response.blob())
                    .then((data) => saveAs(data, "media-export.csv"));
                break;
            case 1:
                await fetch(`${base_url}/admin/media/export`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userToken}`,
                    },
                    body: JSON.stringify({
                        search: {
                            category: "VIDEO",
                            ids: selectedIds?.length ? selectedIds : undefined,
                            contentCategory: contentCategory && contentCategory?.length ? contentCategory : undefined,
                            mediaStatus: mediaStatus && mediaStatus?.length ? mediaStatus : undefined,
                            visibility: visibility && visibility?.length ? visibility : undefined,
                            typeOfAngle: typeOfAngle && typeOfAngle?.length ? typeOfAngle : undefined,
                            pov: pov && pov?.length ? pov : undefined,
                            shootLocation: shootLocation && shootLocation?.length ? shootLocation : undefined,
                            resolution: resolution && resolution?.length ? resolution : undefined,
                            videoDuration: videoDuration > 0 ? videoDuration : undefined
                        },
                    }),
                })
                    .then((response) => response.blob())
                    .then((data) => saveAs(data, "media-export.csv"));
                break;
            default:
                await fetch(`${base_url}/admin/media/export`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userToken}`,
                    },
                    body: JSON.stringify({
                        search: {
                            category: "IMAGE",
                            ids: selectedIds?.length ? selectedIds : undefined
                        },
                    }),
                })
                    .then((response) => response.blob())
                    .then((data) => saveAs(data, "media-export.csv"));
                break;
        }
    }

    const handleImport = (e) => {
        dispatch(adminContentImport(e.target.files[0]))
    }

    const handleSearch = async (e) => {
        e.preventDefault()
        setModalHeading('')
        setModifiedContent(false)
        setPage(0)
        switch (tabValue) {
            case 0:
                await dispatch(adminContent({ pageName: "IMAGE", keyword: searchValue }))
                break;
            case 1:
                await dispatch(adminContent({ pageName: "VIDEO", keyword: searchValue }))
                break;
            default:
                await dispatch(adminContent({ pageName: "IMAGE", keyword: searchValue }))
                break;
        }
    }

    const handleClear = async () => {
        setSearchValue('')
        setModalHeading('')
        setModifiedContent(false)
        switch (tabValue) {
            case 0:
                await dispatch(adminContent({ pageName: "IMAGE" }))
                break;
            case 1:
                await dispatch(adminContent({ pageName: "VIDEO" }))
                break;
            default:
                await dispatch(adminContent({ pageName: "IMAGE" }))
                break;
        }

    }

    const handleDisable = () => {
        if ((modalHeading?.length > 0 === false) && (searchValue.length > 0 === false)) return true
        return false
    }

    const handleOpenDeletePopup = () => {
        setOpenDeletePopup(true)
    }

    const handleCloseDeletePopup = () => {
        setOpenDeletePopup(false)
    }

    window.FreshworksWidget('hide')

    return (
        <div className="adminContent">
            <div className='adminContent-navbar'>
                <AdminNavbar />
            </div>
            <div className="adminContent-sidebarAndData">
                <div className="adminContent-sidebar">
                    <AdminSidebar />
                </div>
                <div className="adminDashboard-data">
                    <div className="adminContent-headingAndSearch">
                        <div className="adminContent-heading">
                            <span>{mediaStatus === '' && visibility === '' && modalHeading?.length ? modalHeading : null} Content</span>
                        </div>
                        <div className="adminContent-searchImpExp">
                            <div className="adminContent-searchFilterBtn">
                                <form onSubmit={handleSearch}>
                                    <div className="adminContent-searchTextfield">
                                        <input
                                            placeholder="Search"
                                            value={searchValue}
                                            onChange={(e) => setSearchValue(e.target.value)} />
                                        <div className="adminContent-searchBtn" onClick={handleSearch}>
                                            <BiSearchAlt2 color="rgba(0, 0, 0, 0.4)" fontSize="20px" type="submit" />
                                        </div>
                                    </div>
                                </form>
                                <div onClick={onClickFilterBtn} className="adminContent-filterBtn">
                                    <AiOutlineFilter />
                                </div>
                                <button
                                    className={(modalHeading?.length || searchValue?.length) ?
                                        `adminContent-clear` :
                                        `adminContent-clear-disabled`}
                                    onClick={handleClear}
                                    disabled={handleDisable()}>
                                    clear
                                </button>
                            </div>
                            {(role === "admin" || (role === "metatagger" || role === "scheduler")) ?
                                <div className="adminContent-impExtBtn">
                                    <label className="adminContent-import" style={{ cursor: "pointer" }}>
                                        <input type="file" accept={".csv"} onChange={handleImport} />
                                        <span><BiImport style={{ marginRight: "7px", fontSize: "20px" }} /></span>
                                        <span>Import</span>
                                    </label>
                                    <div className="adminContent-export" onClick={handleExport} style={{ cursor: "pointer" }}>
                                        <span><BiExport style={{ marginRight: "7px", fontSize: "20px" }} /></span>
                                        <span>Export</span>
                                    </div>
                                    {(role === "admin" && selectedIds?.length > 1) ? <div className="adminContent-export" onClick={handleOpenDeletePopup} style={{ cursor: "pointer", marginLeft: "20px" }}>
                                        <span><AiOutlineDelete style={{ marginTop: "4px", marginRight: "7px", fontSize: "20px" }} /></span>
                                        <span>Delete All</span>
                                    </div> : <></>}
                                </div> : <></>}
                        </div>
                        <div className="adminContent-filterSearchOptions">
                            <div className="searchOptions-commonSty">
                                <span className="searchOptions-nameCommonSty">Content category</span>
                                <RxCross2 fontSize={13} />
                            </div>
                            <div className="searchOptions-commonSty">
                                <span className="searchOptions-nameCommonSty">Master Shot</span>
                                <RxCross2 fontSize={13} />
                            </div>
                            <div className="searchOptions-commonSty">
                                <span className="searchOptions-nameCommonSty">POV</span>
                                <RxCross2 fontSize={13} />
                            </div>
                            <div className="searchOptions-commonSty">
                                <span className="searchOptions-nameCommonSty">Shoot Location</span>
                                <RxCross2 fontSize={13} />
                            </div>
                            <div className="searchOptions-commonSty">
                                <span className="searchOptions-nameCommonSty">Price Range</span>
                                <RxCross2 fontSize={13} />
                            </div>
                            <div className="searchOptions-commonSty">
                                <span className="searchOptions-nameCommonSty">Date Range</span>
                                <RxCross2 fontSize={13} />
                            </div>
                            <div className="searchOptions-commonSty">
                                <span className="searchOptions-nameCommonSty">Resolution</span>
                                <RxCross2 fontSize={13} />
                            </div>
                            <div className="searchOptions-commonSty">
                                <span className="searchOptions-nameCommonSty">Duration</span>
                                <RxCross2 fontSize={13} />
                            </div>
                        </div>
                    </div>
                    <div className="adminContent-table">
                        <AdminTabs />
                    </div>
                </div>
            </div>
            <DeleteAllPopup open={openDeletePopup} handleCloseDeletePopup={handleCloseDeletePopup} />
            <FilterPopup showFilterPopup={showFilterPopup} handleCloseFilterPopup={handleCloseFilterPopup} />
        </div>
    )
}

export default AdminContent;