import React, { useState } from "react";
import './byMonth.css'
import { MenuItem, Select } from "@mui/material";
import { BiExport } from "react-icons/bi";

const filterStyle = {
    fontFamily: 'Open Sans',
    fontSize: '13px',
    color: '#888888',
    border: '1px solid #C8C7C7',
    borderRadius: '5px',
    height: '35px',
    width: '120px',
    '& .MuiOutlinedInput-notchedOutline': {
        border: "#C8C7C7",
        outline: 'none',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        border: '#C8C7C7',
    },
}

const ByMonth = () => {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    const startYear = 2023
    const endYear = 2099
    const years = Array.from({ length: endYear - startYear + 1 }, (_, index) => startYear + index);
    const [month, setMonth] = useState('January')
    const [year, setYear] = useState('2023')


    return (
        <div className="byMonth">
            <div className="bym-head">
                May 2023
            </div>
            <div className="bym-filtersExp-container">
                <div className="bym-filters">
                    <Select
                        placeholder='Select Content Category'
                        sx={filterStyle}
                        value={month}
                        onChange={(e) => setMonth(e.target.value)}
                    // renderValue={details?.contentCategory === undefined && mediaData?.contentCategory?.length === 0 ? () => 'Select Content Category' : undefined}
                    // displayEmpty
                    >
                        {months && months.map((month) => (
                            <MenuItem
                                key={month}
                                value={month}
                            >
                                {month}
                            </MenuItem>
                        )
                        )}
                    </Select>
                    <Select
                        placeholder='Select Content Category'
                        sx={filterStyle}
                        value={year}
                        onChange={(e) => setYear(e.target.value)}
                    // renderValue={details?.contentCategory === undefined && mediaData?.contentCategory?.length === 0 ? () => 'Select Content Category' : undefined}
                    // displayEmpty
                    >
                        {years && years.map((year) => (
                            <MenuItem
                                key={year}
                                value={year}
                            >
                                {year}
                            </MenuItem>
                        )
                        )}
                    </Select>
                </div>
                <div className="bym-exportCsv">
                    <span><BiExport style={{ marginRight: "7px", fontSize: "20px" }} /></span>
                    <span>Export</span>
                </div>
            </div>
            <div className="bym-table-container">
                <div className="fw-600">There are no earnings for this month</div>
            </div>
            <div className="bym-msg">
                We update your earnings data every hour. When viewing fewer dates, days without any downloads are hidden. All earnings are reflect in Indian Rupees.
            </div>
        </div>
    )
}

export default ByMonth;