import { createSlice } from "@reduxjs/toolkit";
import {
    adminContent,
    adminContentDeleteItem,
    adminContentImport,
    getAdminContentMediaById,
    editAdminContentMedia,
    adminContentPublished,
    adminContentUnpublished,
    adminContentRejected,
    adminContentApproved,
    adminContentPending
} from "./adminSearchActions";

const initialState = {
    loading: false,
    error: null,
    success: false,
    contentData: null,
    mediaData: null,
    mediaInfo: null,
    filterParams: null
}

export const adminSearchSlice = createSlice({
    name: 'adminSearch',
    initialState,
    extraReducers: {
        [adminContent.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [adminContent.fulfilled]: (state, { payload }) => {
            state.success = true
            state.loading = false
            state.contentData = payload?.finalData
            state.filterParams = payload?.body
        },
        [adminContent.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },
        [adminContentDeleteItem.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [adminContentDeleteItem.fulfilled]: (state, { payload }) => {
            state.success = true
            state.loading = false
            state.contentData = state.contentData.filter(item => item.id !== payload)
        },
        [adminContentDeleteItem.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },
        [adminContentImport.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [adminContentImport.fulfilled]: (state) => {
            state.success = true
            state.loading = false
        },
        [adminContentImport.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },
        [getAdminContentMediaById.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [getAdminContentMediaById.fulfilled]: (state, { payload }) => {
            state.success = true
            state.loading = false
            state.mediaData = payload
            state.mediaInfo = payload.mediaInfo
        },
        [getAdminContentMediaById.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },
        [editAdminContentMedia.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [editAdminContentMedia.fulfilled]: (state, { payload }) => {
            state.success = true
            state.loading = false
        },
        [editAdminContentMedia.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },
        [adminContentPublished.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [adminContentPublished.fulfilled]: (state, { payload }) => {
            state.success = true
            state.loading = false
            state.contentData = payload
        },
        [adminContentPublished.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },
        [adminContentUnpublished.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [adminContentUnpublished.fulfilled]: (state, { payload }) => {
            state.success = true
            state.loading = false
            state.contentData = payload
        },
        [adminContentUnpublished.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },
        [adminContentRejected.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [adminContentRejected.fulfilled]: (state, { payload }) => {
            state.success = true
            state.loading = false
            state.contentData = payload
        },
        [adminContentRejected.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },
        [adminContentApproved.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [adminContentApproved.fulfilled]: (state, { payload }) => {
            state.success = true
            state.loading = false
            state.contentData = payload
        },
        [adminContentApproved.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },
        [adminContentPending.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [adminContentPending.fulfilled]: (state, { payload }) => {
            state.success = true
            state.loading = false
            state.contentData = payload
        },
        [adminContentPending.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },
    }
})

export default adminSearchSlice.reducer