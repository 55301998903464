import * as React from 'react';
import { Box, Modal } from '@mui/material';
import { MdOutlineCancel } from 'react-icons/md';
import { useAdminPagesContext } from "../../../contexts/AdminPagesContext";
import { adminContent, adminContentDeleteAllItems } from '../../../features/adminSearch/adminSearchActions'
import { useDispatch } from "react-redux";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    outline: 'none',
    boxShadow: 24,
    padding: '1rem 1.5rem',
    height: 160
};

export default function DeleteAllPopup({ open, handleCloseDeletePopup }) {
    const { tabValue, modalHeading, modifiedContent, selectedIds, handleContentView, setPage } = useAdminPagesContext()
    const dispatch = useDispatch()

    async function handleDeleteAll() {
        if (modalHeading?.length === 0 && !modifiedContent) {
            switch (tabValue) {
                case 0:
                    dispatch(adminContentDeleteAllItems(selectedIds))
                    await dispatch(adminContent({ pageName: 'IMAGE' }))
                    setPage(0)
                    break;
                case 1:
                    dispatch(adminContentDeleteAllItems(selectedIds))
                    await dispatch(adminContent({ pageName: 'VIDEO' }))
                    setPage(0)
                    break;
                default:
                    return
            }
        }
        else if (modalHeading?.length > 0 && modifiedContent) {
            switch (tabValue) {
                case 0:
                    dispatch(adminContentDeleteAllItems(selectedIds))
                    await handleContentView(modalHeading, 'IMAGE')
                    setPage(0)
                    break;
                case 1:
                    dispatch(adminContentDeleteAllItems(selectedIds))
                    await handleContentView(modalHeading, 'VIDEO')
                    setPage(0)
                    break;
                default:
                    return
            }
        }
    }


    return (
        <div>
            <Modal
                open={open}
                onClose={handleCloseDeletePopup}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className='adminTableDeletePopup'>
                        <div className='adminTableDeletePopup-headingAndClose'>
                            <div className="adminTableDeletePopup-heading">
                                {`Are you sure you want to delete all ${selectedIds?.length} media ?`}
                            </div>
                            <div className='adminTableDeletePopup-close' onClick={handleCloseDeletePopup}>
                                <MdOutlineCancel fontSize="19px" />
                            </div>
                        </div>
                        <div className='adminTableDeletePopup-cancelDeleteBtn'>
                            <button className='adminTableDeletePopup-cancel' onClick={handleCloseDeletePopup}>Cancel</button>
                            <button className='adminTableDeletePopup-delete' onClick={() => {
                                handleDeleteAll()
                                handleCloseDeletePopup()
                            }}>Delete All</button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}