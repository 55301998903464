import * as React from 'react';
import { Box, Modal } from '@mui/material';
import { MdOutlineCancel } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { adminContentDeleteItem } from '../../../features/adminSearch/adminSearchActions'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    outline: 'none',
    boxShadow: 24,
    padding: '1rem 1.5rem',
    height: 160
};

export default function DeletePopup({ open, handleCloseDeletePopup, itemId }) {
    const dispatch = useDispatch()

    return (
        <div>
            <Modal
                open={open}
                onClose={handleCloseDeletePopup}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className='adminTableDeletePopup'>
                        <div className='adminTableModal-headingAndClose'>
                            <div className="adminTableModal-heading">
                                Are you sure you want to Delete ?
                            </div>
                            <div className='adminTableModal-close' onClick={handleCloseDeletePopup}>
                                <MdOutlineCancel fontSize="19px" />
                            </div>
                        </div>
                        <div className='adminTableDeletePopup-cancelDeleteBtn'>
                            <button className='adminTableDeletePopup-cancel' onClick={handleCloseDeletePopup}>Cancel</button>
                            <button className='adminTableDeletePopup-delete' onClick={() => {
                                dispatch(adminContentDeleteItem({ id: itemId }));
                                handleCloseDeletePopup()
                            }}>Delete</button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}