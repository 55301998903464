import React, {useState, useEffect} from "react";
import './vectorPage.css';
import { Navbar } from "../../common/Navbar";
import { Header } from "../../pageContent/Header";
import { BecomeASeller } from "../../common/BecomeASeller";
import { Footer } from "../../common/Footer";
import companyLogo from '../../../assets/logo/WorldArchivesVectorLight.png';
import previewBannerVideo from '../../../assets/mastHead/mastheadBanner.mp4';
import ipfallBackImage from '../../../assets/images/ImageBanner.png';
import SubHeading from "../../pageContent/SubHeading/SubHeading";
import { getCategories, getCategoryData } from "../../../api";
import PopularCategory from "../../common/PopularCategories/PopularCategory";

const VectorPage = () => {
    const [categories, setCategories] = useState([])
    const [imgArr, setImgArr] = useState([])

    useEffect(() => {
        if (!categories || !categories.length) return
        const getAllCategoriesData = async () => {
            let catData = await getCategories('VECTOR').then(res => res)
            const finalData = await Promise.all(catData?.map(async (category) => {
                return await getCategoryData('VECTOR', category)
            })
            )
            if (categories.length && (categories.length === finalData.length)) setImgArr(finalData)
        }
        getAllCategoriesData()
    }, [categories])

    useEffect(() => {
        getCategories('VECTOR').then(res => setCategories(res))
        localStorage.setItem('selectTerm', 'VECTOR')
    }, [])

    return (
        <div className="vectorPage">
            <div className="vectorPage-banner">
                <Navbar companyLogo={companyLogo} logoStyles='navbar-logoStyle-common' menuItemStyles="vectorPage-menu-items" cartColor="#FFFFFF" hamburgerColor="#ffffff" />
                <Header
                    headerHeadingContent='Originality at its finest'
                    headerSubHeading="Immerse yourself in the arts like never before."  
                    searchBarStyles='vectorPage-SearchBar'
                    initialState='VECTOR'
                    headerHeight="vectorPageHeaderHeight"
                />
                <video className="vector-banner-video" autoPlay loop muted width="100%">
                    <source src={previewBannerVideo} type="video/mp4" />
                    {/* Fallback image */}
                    <img src={ipfallBackImage} alt="Fallback Image" />
                </video>
            </div>
            <PopularCategory/>
            <div className="">
                {categories.length ? categories.map((el, idx) => <SubHeading key={idx} heading={el} requiredArray={imgArr[idx]} pageName="VECTOR" />) : <></>}
            </div>
            <BecomeASeller />
            <Footer />
        </div>
    )
}

export default VectorPage;