import React, { useState, useEffect } from 'react';
import {BsFillArrowUpCircleFill} from 'react-icons/bs'
import './adminScroll.css';

function AdminScrollTop() {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      if (scrollTop > 200) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <button
      className={`scroll-to-top-button ${showButton ? 'show' : ''}`}
      onClick={handleClick}
    >
      <BsFillArrowUpCircleFill />
    </button>
  );
}

export default AdminScrollTop;
