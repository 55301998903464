import { createSlice } from "@reduxjs/toolkit";
import { adminLogin, adminDashboard, adminAddRole, adminEditRole, adminAuditLogs, adminDashboardDisplayContentNum, curatedContent, curatedContentInitial, adminAuditLogsSearch, getUserByUid, addContentCategory, getContentCategoryList, getAdminSetting, getMediaSizes } from "./adminActions";

const role = localStorage.getItem('loggedRole') ? localStorage.getItem('loggedRole') : null
const adminToken = localStorage.getItem('adminToken') ? localStorage.getItem('adminToken') : null
const adminInfo = localStorage.getItem('adminInfo') ? localStorage.getItem('adminInfo') : null

const initialState = {
    loading: false,
    ccLoading: false,
    adminInfo,
    adminToken,
    role,
    error: null,
    success: false,
    downloadCount: 0,
    wishlistCount: 0,
    publishedCount: 0,
    unpublishedCount: 0,
    publishedFiles: null,
    unpublishedFiles: null,
    auditData: null,
    contentCategorySearchData: null,
    curatedRoles: null,
    curatedUsers: null,
    curatedAllActivity: null,
    userData: null,
    popularCategoryKeywords: null,
    mediaSizes: null
}

export const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        adminLogout: (state) => {
            localStorage.clear()
            state.loading = false
            state.ccLoading = false
            state.success = false
            state.adminInfo = null
            state.adminToken = null
            state.error = null
            state.role = null
            state.wishlistCount = 0
            state.publishedCount = 0
            state.unpublishedCount = 0
            state.downloadCount = 0
            state.publishedFiles = null
            state.unpublishedFiles = null
            state.auditData = null
            state.curatedAllActivity = null
        },
    },
    extraReducers: {
        [adminLogin.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [adminLogin.fulfilled]: (state, { payload }) => {
            state.success = true
            state.loading = false
            state.adminInfo = JSON.stringify(payload?.adminInfo)
            state.adminToken = payload?.adminToken
            state.role = payload?.role
        },
        [adminLogin.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },
        [adminDashboard.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [adminDashboard.fulfilled]: (state, { payload }) => {
            state.success = true
            state.loading = false
            state.downloadCount = payload?.downloadCount
            state.wishlistCount = payload?.wishlistCount
            state.publishedCount = payload?.publishedCount
            state.unpublishedCount = payload?.unpublishedCount
            state.approveMedia = payload?.approveMedia
            state.rejectMedia = payload?.rejectMedia
            state.pendingMedia = payload?.pendingMedia
            state.publishedFiles = payload?.finalPublished
            state.unpublishedFiles = payload?.finalUnPublished
        },
        [adminDashboard.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },
        [adminDashboardDisplayContentNum.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [adminDashboardDisplayContentNum.fulfilled]: (state) => {
            state.loading = false
            state.success = true
        },
        [adminDashboardDisplayContentNum.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },
        [adminAddRole.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [adminAddRole.fulfilled]: (state, { payload }) => {
            state.success = true
            state.loading = false
        },
        [adminAddRole.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },
        [adminEditRole.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [adminEditRole.fulfilled]: (state, { payload }) => {
            state.success = true
            state.loading = false
        },
        [adminEditRole.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },
        [adminAuditLogs.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [adminAuditLogs.fulfilled]: (state, { payload }) => {
            state.success = true
            state.loading = false
            state.auditData = payload
        },
        [adminAuditLogs.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },
        [adminAuditLogsSearch.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [adminAuditLogsSearch.fulfilled]: (state, { payload }) => {
            state.success = true
            state.loading = false
            state.auditData = payload
        },
        [adminAuditLogsSearch.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },
        [curatedContent.pending]: (state) => {
            state.ccLoading = true
            state.error = null
        },
        [curatedContent.fulfilled]: (state, { payload }) => {
            state.success = true
            state.ccLoading = false
            state.curatedAllActivity = payload
        },
        [curatedContent.rejected]: (state, { payload }) => {
            state.ccLoading = false
            state.error = payload
        },
        [curatedContentInitial.pending]: (state) => {
            state.ccLoading = true
            state.error = null
        },
        [curatedContentInitial.fulfilled]: (state, { payload }) => {
            state.success = true
            state.ccLoading = false
            state.curatedRoles = payload.rolesList
            state.curatedUsers = payload.userList
            state.curatedAllActivity = payload
        },
        [curatedContentInitial.rejected]: (state, { payload }) => {
            state.ccLoading = false
            state.error = payload
        },
        [getUserByUid.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [getUserByUid.fulfilled]: (state, {payload}) => {
            state.loading = false
            state.error = null
            state.userData = payload
        },
        [getUserByUid.rejected]: (state, {payload}) => {
            state.loading = false
            state.error = payload
        },
        [addContentCategory.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [addContentCategory.fulfilled]: (state, { payload }) => {
            state.success = true
            state.loading = false
        },
        [addContentCategory.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },
        [getContentCategoryList.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [getContentCategoryList.fulfilled]: (state, { payload }) => {
            state.success = true
            state.loading = false
            state.contentCategorySearchData = payload
        },
        [getContentCategoryList.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },
        [getAdminSetting.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [getAdminSetting.fulfilled]: (state, { payload }) => {
            state.success = true
            state.loading = false
            state.popularCategoryKeywords = payload
        },
        [getAdminSetting.rejected]: (state, { payload }) => {
            state.ccLoading = false
            state.error = payload
        },
        [getMediaSizes.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [getMediaSizes.fulfilled]: (state, {payload}) => {
            state.loading = false
            state.error = null
            state.mediaSizes = payload
        },
        [getMediaSizes.rejected]: (state, {payload}) => {
            state.loading = false
            state.error = payload
        }
    }
})


export const { adminLogout } = adminSlice.actions

export default adminSlice.reducer