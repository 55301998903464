import React from "react";
import './editorialPage.css';
import { Navbar } from "../../common/Navbar";
import { Header } from "../../pageContent/Header";
import { BecomeASeller } from "../../common/BecomeASeller";
import { Footer } from "../../common/Footer";
import companyLogo from '../../../assets/logo/WorldArchivesLight.png';
import previewBannerVideo from '../../../assets/mastHead/mastheadBanner.mp4';
import ipfallBackImage from '../../../assets/images/ImageBanner.png';

const EditorialPage = () => {
    return(
        <div className="editorialPage">
            <div className="editorialPage-banner">
                <Navbar companyLogo={companyLogo} logoStyles='navbar-logoStyle-common' menuItemStyles="editorialPage-menu-items" cartColor="#FFFFFF" hamburgerColor="#ffffff"/>
                <Header
                    headerHeadingContent = 'Originality at its finest'
                    headerSubHeading="Immerse yourself in the arts like never before."
                    searchBarStyles='editorialPage-SearchBar'
                    initialState='EDITORIAL'
                    headerHeight="editorialPageHeaderHeight"
                />
                <video className="editorial-banner-video" autoPlay loop muted width="100%">
                    <source src={previewBannerVideo} type="video/mp4" />
                    {/* Fallback image */}
                    <img src={ipfallBackImage} alt="Fallback Image" />
                </video>
            </div>
            <div className="editorialPage-pageContent">
                <span style={{color: '#000000'}}>Hold Tight!</span>
                <span>Editorial Page is Coming Soon.</span>
            </div>
            <BecomeASeller />
            <Footer />
        </div>
    )
}

export default EditorialPage;