import React, { useState } from "react";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ByMonth from "../../sellerPageContent/byMonth/ByMonth";
import ByProduct from "../../sellerPageContent/byProduct/ByProduct";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const SeTabs = () => {
    const [earningsTabValue, setEarningsTabValue] = useState(0)

        const handleMediaTabsChange = (event,newValue) => {
            setEarningsTabValue(newValue)
        }

    return (
        <Box sx={{ width: '100%', '& .css-19kzrtu': { padding: '0px' } }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', fontFamily: "Open Sans" }}>
                <Tabs
                    value={earningsTabValue}
                    onChange={handleMediaTabsChange}
                    aria-label="basic tabs example"
                    sx={{
                        paddingLeft: "25px",
                        "& .css-heg063-MuiTabs-flexContainer": {
                            gap: '20px'
                        }
                    }}
                >
                    <Tab label="By Month" {...a11yProps(0)}
                        sx={{
                            textTransform: 'none',
                            fontFamily: "Open Sans",
                            fontSize: '14px',
                            fontWeight: '600',
                            color: '#444444',
                        }}
                    />
                    <Tab label="By Product" {...a11yProps(1)}
                        sx={{
                            textTransform: 'none',
                            fontFamily: "Open Sans",
                            fontSize: '14px',
                            fontWeight: '600',
                            color: '#444444'
                        }}
                    />
                </Tabs>
            </Box>
            <TabPanel value={earningsTabValue} index={0}>
                <ByMonth/>
            </TabPanel>
            <TabPanel value={earningsTabValue} index={1}>
                <ByProduct/>
            </TabPanel>
        </Box>
    );
}

export default SeTabs;