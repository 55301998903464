import { Button, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Footer } from "../../common/Footer";
import { Navbar } from "../../common/Navbar";
import './vfxDetailsPage.css';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import ShareIcon from '@mui/icons-material/Share';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import videoDetailsPageLogo from '../../../assets/logo/WorldArchivesVfxDark.png';
import { useSelector, useDispatch } from 'react-redux';
import { saveAs } from 'file-saver';
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { useParams, useNavigate } from 'react-router-dom';
import { searchResultsById, addToCart, addToWishlist, searchResults } from '../../../features/search/searchActions'
import { Loader } from "../../common/Loader";
import ImageGallery from "../../reusable/ImageGallery/ImageGallery";
import { searchResultsSideImage, getSearchResults } from '../../../api'
import DataTable from "../../common/DataTable";
import CurrencyInput from '../../reusable/Currency/Currency'

const vfxSrchTextField = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: "100%",
    width: '100%',
    fontFamily: 'Open Sans',
    fontSize: '12px',
    "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
        outline: "none"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
        border: "none"
    },
    ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
        padding: '0px',
        height: '100%'
    },
    "@media (min-width : 481px) and (max-width : 767px)": {
        fontSize: '0.8rem'
    },
    "@media (min-width : 320px) and (max-width : 465px)": {
        '.css-8j6b76-MuiInputBase-root-MuiOutlinedInput-root': {
            fontSize: '0.8rem'
        },
        '.css-1pnmrwp-MuiTypography-root': {
            fontSize: '0.85rem',
            fontWeight: '600'
        }
    }
}

const vfxSrchBtn = {
    backgroundColor: '#ED652B',
    color: 'white',
    width: 'fit-content',
    height: '100%',
    textTransform: 'none',
    marginRight: '10px',
    '&:hover': {
        background: '#ED652B'
    }
}

const VfxDetailsPage = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const dispatch = useDispatch()
    const user = localStorage.getItem('userInfo')
    const { searchIdData, searchData, loading } = useSelector(state => state.search)
    const img_url = process.env.REACT_APP_S3_HOST
    const [query, setQuery] = useState('');
    const [sideVfx, setSideVfx] = useState([]);
    const [similarVfx, setSimilarVfx] = useState([]);

    useEffect(() => {
        dispatch(searchResultsById({ Id: id }))
        window.scrollTo(0, 0)
    }, [dispatch, id])

    const onClickSaveIcon = () => {
        saveAs(`${img_url}/${searchIdData?.contentSizes[0]?.previewSrc}`, `${searchIdData?.contentSizes[0]?.displayName}${searchIdData?.contentSizes[0]?.format}`);
    }

    const onClickShare = async () => {
        let currentUrl = window.location.href
        await navigator.clipboard.writeText(currentUrl)
        toast.success('Copied to clipboard', { autoClose: 2500 })
    }

    const handleCart = async () => {
        await dispatch(addToCart({ id, resolution: searchIdData?.contentSizes[0]?.size }))
    }

    const handleWishlist = async () => {
        await dispatch(addToWishlist({ id, resolution: searchIdData?.contentSizes[0]?.size }))
    }

    function getInitials(name) {
        const words = name ? name?.split(' ') : "";
        const initials = [];
        for (const word of words) {
            if (word === "Mr." || word === "Dr." || word === "Ms." || word === "Mrs." || word === "Miss." || word === "Mx.") {
                continue;
            }
            initials.push(word[0]);
        }
        return initials.join('');
    }

    async function handleSubmit(event) {
        event.preventDefault();
        const searchTerm = decodeURIComponent(query.replace(/[^\w\s]/gi, function (c) {
            return '%' + c.charCodeAt(0).toString(16);
        }).replace(/\s+/g, '+'));
        navigate(`/search_results?keyword=${searchTerm}`)
        await dispatch(searchResults({ fieldTerm: query, selectTerm: 'VFX' }))
    }

    function loginFunction() {
        toast.info('Please login to add items to card', { autoClose: 1500 })
    }

    function wishlistLogin() {
        toast.info('Please login to wishlist media', { autoClose: 1500 })
    }

    const initials = getInitials(searchIdData && searchIdData?.contributorName);
    const category = searchIdData && searchIdData?.category
    const videoFormats = searchIdData && searchIdData?.mediainfo ? searchIdData?.mediainfo : {}
    const resolution = videoFormats && videoFormats?.resolution ? videoFormats?.resolution : ""

    const handleKeywordClick = async (keyword) => {
        localStorage.setItem('fieldTerm', keyword)
        navigate(`/search_results?keyword=${keyword}`)
        await dispatch(searchResults({ fieldTerm: keyword, selectTerm: 'VFX' }))
    }

    useEffect(() => {
        const fetchSideVfx = async () => {
            const tenthKeyword = searchIdData?.keywords[9]
            const sideVfx = await searchResultsSideImage({ selectTerm: 'VFX', limit: 3, keywords: tenthKeyword })
            setSideVfx(sideVfx);
        };

        const fetchSimilarVfx = async () => {
            const firstFiveWords = searchIdData?.keywords?.slice(0, 5)
            const similarVfx = await getSearchResults({ selectTerm: 'VFX', limit: 16, keywords: firstFiveWords })
            setSimilarVfx(similarVfx);
        };

        fetchSideVfx();
        fetchSimilarVfx();
    }, [searchIdData?.keywords]);

    function Video({ previewSrc, videoSrc, id, onClick }) {
        const [isHovering, setIsHovering] = useState(false);
        const [playDuration, setPlayDuration] = useState(2);
        const vfxRef = useRef(null);

        function handleTimeUpdate() {
            if (vfxRef.current.currentTime >= playDuration) {
                vfxRef.current.currentTime = 0;
                vfxRef.current.play();
            }
        }

        function handleVideoEnd() {
            vfxRef.current.currentTime = 0;
            vfxRef.current.play();
        }

        function handleMouseOver() {
            setIsHovering(true);
            vfxRef.current.play();
        }

        function handleMouseOut() {
            setIsHovering(false);
            vfxRef.current.pause();
        }

        return (
            <div
                onMouseEnter={handleMouseOver}
                onMouseLeave={handleMouseOut}
            >
                <video
                    className="vfx-videoPlay"
                    id={id}
                    ref={vfxRef}
                    width="100%"
                    height="100%"
                    src={videoSrc}
                    onTimeUpdate={handleTimeUpdate}
                    onEnded={handleVideoEnd}
                    onClick={onClick}
                    poster={previewSrc}
                />

            </div>
        );
    }

    const handleViewAllClick = async (e) => {
        navigate(`/search_results`)
        await dispatch(searchResults({ fieldTerm: searchIdData?.keywords[9], selectTerm: 'VIDEO' }))
    }

    const handleSideVideoClick = async (e) => {
        const id = e.currentTarget.getAttribute('id');
        await dispatch(searchResultsById({ Id: id }))
        navigate(`/details_vfx/${id}`)
    }

    return (
        <div className="vfx">
            <Navbar menuItemStyles="vfx-menu-items" companyLogo={videoDetailsPageLogo} cartColor="#666666" logoStyles="navbar-logoStyle" hamburgerColor="#000000" />
            <div className="vfx-container">
                <form onSubmit={handleSubmit}>
                    <div className="vfx-container-searchBar">
                        <TextField
                            fullWidth
                            placeholder={`Try searching for Vfx`}
                            value={query}
                            onChange={(event) => setQuery(event.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        Vfx
                                    </InputAdornment>
                                ),
                            }}
                            sx={vfxSrchTextField}
                        />
                        <Button
                            onClick={handleSubmit}
                            sx={vfxSrchBtn}
                        >Search</Button>
                    </div>
                </form>
                {loading ? <Loader /> :
                    <div className="vfx-container-video">
                        <div className="vfx-container-video-player">
                            <video autoPlay height='100%' width='100%' controls controlsList="nodownload">
                                <source src={`${img_url}/${searchIdData?.contentSizes[0]?.previewSrc}`} />
                            </video>
                            {searchIdData?.isPremium && <div className="idp-premium-tag">Premium</div>}
                        </div>
                        <div className='vfx-container-video-imageCollection'>
                            {sideVfx?.map((item, index) => {

                                if (index === 2) {
                                    return (
                                        <div className="vfx-thumbnail-side vfx-thumbnail-side-overlay" key={index}>
                                            <Video
                                                previewSrc={`${img_url}/${item.mediaSrc}`}
                                                videoSrc={`${img_url}/${item.previewSrc}`}
                                                id={item.id}
                                            />
                                            <div className="overlay-content">
                                                <span className="overlay-text" onClick={handleViewAllClick}>View All</span>
                                            </div>
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div className="vfx-thumbnail-side" key={index}>
                                            <Video
                                                previewSrc={`${img_url}/${item.mediaSrc}`}
                                                videoSrc={`${img_url}/${item.mediaSrc}`}
                                                id={item.id}
                                                onClick={handleSideVideoClick}
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    </div>
                }
                {!loading ?
                    <div className="vfx-container-optionsAndContentId">
                        <div className='vfx-container-contentId'>
                            <span className='vfx-contentId'>Content ID :- </span>
                            <span className='vfx-contentId-value'>{id && id}</span>
                        </div>
                        <div className='vfx-container-options'>
                            <span className='vfx-options-icons' onClick={user && (user !== null || user !== undefined) ?
                                handleWishlist : wishlistLogin}>
                                <BookmarkBorderIcon fontSize='18px' />&nbsp;Wishlist
                            </span>
                            <span className='vfx-options-icons' onClick={onClickShare}>
                                <ShareIcon fontSize='18px' />&nbsp;Share
                            </span>
                            <span className='vfx-options-icons' onClick={onClickSaveIcon}>
                                <SaveAltIcon fontSize='18px' />&nbsp;Save
                            </span>
                            <button
                                onClick={user && (user !== null || user !== undefined) ? handleCart : loginFunction}
                                className='vfx-addToCartBtn'
                            >Add to cart</button>
                        </div >
                    </div > : <></>}
                {
                    !loading ? <>
                        <div className='vfx-container-titleAndPrice'>
                            <div className='vfx-detailsContent-commonSty'>
                                <span className='vfx-heading'>Title</span>
                                <span className='vfx-details'>{searchIdData && searchIdData?.title}</span>
                            </div>
                            <div className='vfx-detailsContent-commonSty'>
                                <span className='vfx-heading'>Price</span>
                                <div className='vfx-detailsContent-price-selection'>
                                    <CurrencyInput/>
                                    <span className='idp-details-priceDesc idp-descAddSty'>0</span>
                                    {/* <span className='idp-details-priceDesc idp-descAddSty'>{searchIdData && searchIdData?.price}</span> */}
                                </div>
                                {/* <span className='vfx-details-priceDesc vfx-descAddSty'>{searchIdData && `Rs.${searchIdData?.price}`}</span> */}
                            </div>
                        </div>
                        <div className='vfx-container-desc'>
                            <span className='vfx-heading'>Description</span>
                            <div className='vfx-details-priceDesc vfx-descAddSty'>{searchIdData && searchIdData?.description}</div>
                        </div>
                        <div className='vfx-container-detailsCommonSty'>
                            <div className="vfx-detailsContent-container-cmnSty">
                                <div className='vfx-detailsContent-commonSty'>
                                    <span className='vfx-heading'>Contributor</span>
                                    <div className='imageDetailsPage-contributor-details'>
                                        <span className='vfx-contributor-nameInitials'>{initials}</span>
                                        <span className='vfx-contributor-name'>{searchIdData && searchIdData?.contributorName}</span>
                                    </div>
                                </div>
                                <div className='vfx-container-cc'>
                                    <span className='vfx-heading'>Category</span>
                                    <span className='vfx-details'>{category && category[0].toUpperCase() + category.slice(1).toLowerCase()}</span>
                                </div>
                            </div>
                            <div className="vfx-detailsContent-container-cmnSty">
                                <div className='vfx-detailsContent-commonSty'>
                                    <span className='vfx-heading'>Shoot Location</span>
                                    <span className='vfx-details'>{searchIdData && searchIdData?.shootLocation}</span>
                                </div>
                                <div className='vfx-detailsContent-commonSty'>
                                    <span className='vfx-heading'>Type of Angle</span>
                                    <span className='vfx-details'>{searchIdData && searchIdData?.typeOfAngle}</span>
                                </div>
                            </div>
                        </div>
                        <div className='vfx-container-detailsCommonSty'>
                            <div className="vfx-detailsContent-container-cmnSty">
                                <div className='vfx-container-form'>
                                    <span className='vfx-heading'>Available Formats</span>
                                    <span className='vfx-details vfx-details-addSty vfx-details-addSty'>{videoFormats && `${videoFormats?.width} × ${videoFormats?.height} pixels • FR ${videoFormats?.frameRate} • ${videoFormats?.format}`}</span>
                                    {/* <span className='vfx-details vfx-details-addSty vfx-details-addSty'>1000 × 590 pixels • 8.5 × 5 cm • DPI 300 • JPG</span> */}
                                </div>
                                <div className='vfx-detailsContent-commonSty'>
                                    <span className='vfx-heading'>Camera</span>
                                    <span className='vfx-details'>{searchIdData && searchIdData?.camera}</span>
                                </div>
                            </div>
                            <div className="vfx-detailsContent-container-cmnSty">
                                <div className='vfx-detailsContent-commonSty'>
                                    <span className='vfx-heading'>Resolution</span>
                                    <span className='vfx-details'>{resolution && resolution[0].toUpperCase() + resolution.slice(1).toLowerCase()}</span>
                                </div>
                                <div className='vfx-detailsContent-commonSty'>
                                    <span className='vfx-heading'>POV</span>
                                    <span className='vfx-details'>{searchIdData && searchIdData?.pov}</span>
                                </div>
                            </div>
                        </div>
                        <div className='vfx-container-desc'>
                            <span className='vfx-heading'>Keywords</span>
                            <div className='vfx-details-priceDesc'>
                                {searchIdData?.keywords?.map((keyword, index) => {
                                    return (
                                        <span onClick={() => handleKeywordClick(keyword)} className='vfx-details-keywordsSty' key={index}>{keyword}</span>
                                    )
                                })}
                            </div>
                        </div>
                        <DataTable mediaId={id} tableData={searchIdData && searchIdData?.contentSizes}/>
                    </> : <></>
                }
                {
                    !loading ? <div className='vfx-container-similarVideos'>
                        <div className='vfx-similarVideos-heading'>Similar Vfx</div>
                        <ImageGallery imageArray={similarVfx} pageName="VFX" />
                    </div> : <></>
                }
            </div >
            {
                !loading ? <Footer /> : <></>
            }

            {/* <ToastContainer limit={3} theme="colored" /> */}
        </div >
    )
}

export default VfxDetailsPage;