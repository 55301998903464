import React from "react";
import './settings.css';
import Loader from "../../../components/common/Loader/Loader";
import { BsInfoLg } from 'react-icons/bs';
import { MenuItem, Select, Tooltip } from "@mui/material";

const ContentCategoryManagement = (props) => {
    const {
        ccSelectStyle,
        formData,
        handleChange,
        pageName,
        searchFormData,
        setSearchFormData,
        handleSearchFormSubmit,
        contentCategorySearchData,
        contentCategoryId,
        setContentCategoryId,
        handleRemoveContentCat,
        handleSubmit,
        loading,
        lastIndex
    } = props
    return (
        <div className="asd-allSetting-ccms">
            <div className="asd-ccms-heading">
                Content Category Management System
            </div>
            <div className="asd-ccms-settingsCmnSty">
                <div className="asd-acc-heading">
                    Add Content Category
                </div>
                <div className="asd-acc-form">
                    <div className="asd-acc-fieldsContainer">
                        <div className="asd-acc-labelAndField">
                            <label className="asd-acc-labelAndField-label">Page Name</label>
                            <Select
                                placeholder='Select Page Name'
                                sx={ccSelectStyle}
                                name="page_name"
                                value={formData.page_name}
                                onChange={handleChange}
                                renderValue={formData?.page_name === "" ? () => 'Select Page Name' : ""}
                                displayEmpty
                            >
                                {pageName && pageName.map((page, index) => (
                                    <MenuItem
                                        key={index}
                                        value={page}
                                    >
                                        {page}
                                    </MenuItem>
                                )
                                )}
                            </Select>
                        </div>
                        <div className="asd-acc-labelAndField">
                            <label className="asd-acc-labelAndField-label">Category Order</label>
                            <input
                                className="asd-acc-labelAndField-input"
                                type="number"
                                placeholder="Enter Order"
                                name="sort_order"
                                value={formData.sort_order}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="asd-acc-fieldsContainer">
                        <div className="asd-acc-labelAndField">
                            <label className="asd-acc-labelAndField-label">
                                Category Name
                                <Tooltip title="Add one category at a time" placement="top">
                                    <button className="asd-acc-tooltipBtn"><BsInfoLg /></button>
                                </Tooltip>
                            </label>
                            <input
                                className="asd-acc-labelAndField-input"
                                placeholder="Add Category Name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="asd-acc-labelAndField">
                            <label className="asd-acc-labelAndField-label">Status</label>
                            <Select
                                placeholder='Add Status'
                                sx={ccSelectStyle}
                                name="status"
                                value={formData.status}
                                onChange={handleChange}
                                renderValue={formData?.status === "" ? () => 'Select Status' : ""}
                                displayEmpty
                            >
                                <MenuItem value={"ACTIVE"}>
                                    Active
                                </MenuItem>
                                <MenuItem value={"INACTIVE"}>
                                    Inactive
                                </MenuItem>
                            </Select>
                        </div>
                    </div>
                </div>
                <div className="asd-acc-btn">
                    <button className="asd-acc-btn-button" onClick={handleSubmit}>Submit</button>
                </div>
            </div>
            <div className="asd-ccms-settingsCmnSty">
                <div className="asd-acc-heading">
                    Content Category List
                </div>
                <div className="ccms-search-contentCategory">
                    <div className="asd-acc-searchLabelAndField">
                        <label className="asd-acc-labelAndField-label">Page Name
                            <Tooltip title="Select a page name and click the search button to retrieve all the content categories associated with the chosen page." placement="top">
                                <button className="asd-acc-tooltipBtn"><BsInfoLg /></button>
                            </Tooltip>
                        </label>
                        <Select
                            placeholder='Select Page Name'
                            sx={ccSelectStyle}
                            name="search_page_name"
                            value={searchFormData}
                            onChange={(e) => setSearchFormData(e.target.value)}
                            renderValue={searchFormData === "" ? () => 'Select Page Name' : ""}
                            displayEmpty
                        >
                            {pageName && pageName.map((page, index) => (
                                <MenuItem
                                    key={index}
                                    value={page}
                                >
                                    {page}
                                </MenuItem>
                            )
                            )}
                        </Select>
                    </div>
                    <button className="asd-acc-btn-button" onClick={handleSearchFormSubmit}>Search</button>
                </div>
                {searchFormData !== "" ? <div className="asd-acc-searchResult-heading">Content Categories for <span>{searchFormData}</span> </div> : <></>}
                <div className="ccms-searchResult-table">
                    <div className="ccms-searchResult-tableHead">
                        <div className="ccms-sr-col ccms-sr-headFont">Category Name</div>
                        <div className="ccms-sr-col ccms-sr-headFont">Content Category Id</div>
                        <div className="ccms-sr-col ccms-sr-headFont">Category Order</div>
                        <div className="ccms-sr-col ccms-sr-headFont" style={{ borderRight: '0px' }}>Status</div>
                    </div>
                    {loading === true ? (
                        <Loader />
                    ) : (
                        (searchFormData !== "" && contentCategorySearchData && Array.isArray(contentCategorySearchData) && contentCategorySearchData.length > 0) ? (
                            contentCategorySearchData.map((data, index) => (
                                <div key={index} className="ccms-searchResult-tableHead" style={{ borderBottom: index === lastIndex ? '0px' : '' }}>
                                    <div className="ccms-sr-col ccms-sr-dataFont">{data?.name}</div>
                                    <div className="ccms-sr-col ccms-sr-dataFont">{data?.id}</div>
                                    <div className="ccms-sr-col ccms-sr-dataFont">{data?.sort_order}</div>
                                    <div className="ccms-sr-col ccms-sr-dataFont" style={{ borderRight: '0px' }}>{data?.status}</div>
                                </div>
                            ))
                        ) : null
                    )}
                    {searchFormData !== "" && contentCategorySearchData && contentCategorySearchData.length === 0 && (
                        <div className="ccms-no-data">No Data Found</div>
                    )}
                </div>
            </div>
            <div className="asd-ccms-settingsCmnSty">
                <div className="asd-acc-heading">
                    Remove Content Category
                </div>
                <div className="ccms-search-contentCategory">
                    <div className="asd-acc-searchLabelAndField">
                        <label className="asd-acc-labelAndField-label">Content Category ID
                            <Tooltip title="You will find the content category ID from content category list." placement="top">
                                <button className="asd-acc-tooltipBtn"><BsInfoLg /></button>
                            </Tooltip>
                        </label>
                        <input
                            className="asd-acc-labelAndField-input"
                            type="number"
                            placeholder="Enter Content Category ID"
                            name="content_category_id"
                            value={contentCategoryId}
                            onChange={(e) => { setContentCategoryId(e.target.value) }}
                        />
                    </div>
                    <button className="asd-acc-btn-button" onClick={handleRemoveContentCat}>Remove</button>
                </div>
            </div>
        </div>
    )
}

export default ContentCategoryManagement;