import React, { useEffect, useRef } from 'react'
import PictureTiles from "../../reusable/PictureTiles/PictureTiles";
import Logo from '../../../assets/logo/WorldArchivesDark.png'
import { useForm } from "react-hook-form";
import Button from '../../reusable/Button'
import { useDispatch, useSelector } from "react-redux";
import { userResetPassword } from "../../../features/users/userActions";
import './resetPassword.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { OutlinedInput, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const updatePasswordStyle = {
    fontFamily: 'Open Sans',
    fontSize: '0.85rem',
    fontWeight: '600',
    border: '1px solid #c5c5c5',
    borderRadius: '5px',
    height: '2.4rem',
    width: '100%',
    padding: '0px 10px',
    '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
        fontFamily: 'Open Sans',
        padding: '0rem',
        fontWeight: '500',
        letterSpacing: '0px',
        color: '#000000'
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: "#C8C7C7",
        outline: 'none',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        border: '#C8C7C7',
    }
}

const ResetPassword = () => {
    const [showPassword, setShowPassword] = React.useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
    const { register, handleSubmit, formState: { errors }, watch } = useForm();
    const password = useRef({})
    password.current = watch("password", "")
    const dispatch = useDispatch()
    const location = useLocation();
    const navigate = useNavigate()
    const { resetPasswordSuccess } = useSelector((state) => state.user)

    const onSubmit = (data) => {
        const params = new URLSearchParams(location.search)
        const token = params.get("token")
        const dataWithToken = { resetPasswordToken: token, newPassword: data.confirmPassword }
        dispatch(userResetPassword(dataWithToken))
    }

    useEffect(() => {
        if (resetPasswordSuccess) navigate('/login')
    }, [resetPasswordSuccess, navigate])

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const confirmClickShowPassword = () => setShowConfirmPassword((show) => !show);

    return (
        <div className="resetPassword">
            <div className="resetPassword-sideImage-tile">
                <PictureTiles />
            </div>
            <div className="resetPassword-form">
                <div>
                    <img src={Logo} alt="logo" style={{ height: '4rem', cursor: 'pointer' }} onClick={() => navigate('/')} />
                </div>
                <div className="resetPassword-form-content">
                    <span className='fw-800 login-heading'>Update Password</span>
                    <form className="resetPassword-form-container" onSubmit={handleSubmit(onSubmit)}>
                        <div className='resetPassword-form-emailTextfield'>
                            <OutlinedInput
                                className={errors.password ? 'resetPassword-input outline-error' : 'resetPassword-input'}
                                placeholder='New Password'
                                name="password"
                                type={showPassword ? 'text' : 'password'}
                                {...register("password", {
                                    required: "Password is required",
                                    pattern: {
                                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,}$/,
                                        message: `Entered value does not match our password policy`
                                    },
                                    minLength: { value: 8, message: "password is too short" },
                                })}
                                autoFocus
                                sx={updatePasswordStyle}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff style={{ fontSize: "1.3rem", color: "#666666", marginRight: '0.2rem' }} /> : <Visibility style={{ fontSize: "1.3rem", color: "#666666", marginRight: '0.2rem' }} />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            {<div className="resetPassword-text-error">{errors.password ? `${'*'}${errors.password.message}` : null}</div>}
                        </div>
                        <div className='resetPassword-form-emailTextfield'>
                            <OutlinedInput
                                className={errors.confirmPassword ? 'resetPassword-input outline-error' : 'resetPassword-input'}
                                placeholder='Confirm Password'
                                type={showConfirmPassword ? 'text' : 'password'}
                                name="confirmPassword"
                                {...register("confirmPassword", {
                                    required: "Password is required",
                                    validate: value =>
                                        value === password.current || "The passwords do not match"
                                })}
                                autoFocus
                                sx={updatePasswordStyle}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={confirmClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showConfirmPassword ? <VisibilityOff style={{ fontSize: "1.3rem", color: "#666666", marginRight: '0.2rem' }} /> : <Visibility style={{ fontSize: "1.3rem", color: "#666666", marginRight: '0.2rem' }} />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            {<div className="resetPassword-text-error">{errors.confirmPassword ? `${'*'}${errors.confirmPassword.message}` : null}</div>}
                        </div>
                        <Button btnStyle="button resetPassword-submit" type="submit">Set new password</Button>
                    </form>
                </div>
            </div>
            <ToastContainer limit={3} theme="colored" autoClose={1200} />
        </div>
    )
}

export default ResetPassword