import React, { useState } from "react";
import './auditLogs.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from "@mui/material/TablePagination";
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from "react-redux";
import { adminAuditLogs, adminAuditLogsSearch } from "../../../features/admin/adminActions";
import AdminNavbar from "../../adminCommon/adminNavbar/AdminNavbar";
import AdminSidebar from "../../adminCommon/adminSidebar/AdminSidebar";
import Loader from "../../../components/common/Loader/Loader";
import { BiExport, BiSearchAlt2 } from "react-icons/bi";
import { saveAs } from 'file-saver'

const AuditLogs = () => {
    const dispatch = useDispatch()
    const { auditData, loading } = useSelector(state => state.admin)
    const [searchValue, setSearchValue] = useState('')
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [page, setPage] = useState(0);

    const base_url = process.env.REACT_APP_BASE_URL
    const userToken = localStorage.getItem('adminToken')

    React.useEffect(() => {
        dispatch(adminAuditLogs())
    }, [dispatch])

    const handleSearch = async (e) => {
        e.preventDefault()
        if (searchValue) await dispatch(adminAuditLogsSearch(searchValue))
        else await dispatch(adminAuditLogs())
        return
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric"
    };

    const tableStyle = {
        '.css-1ygcj2i-MuiTableCell-root': {
            padding: '8px',
            borderRight: '0.5px solid rgba(224, 224, 224, 1)'
        },
        '.css-1ex1afd-MuiTableCell-root': {
            padding: '8px',
            borderRight: '0.5px solid rgba(224, 224, 224, 1)'
        },
        '.css-34nofg-MuiTableRow-root:last-child td, .css-34nofg-MuiTableRow-root:last-child th': {
            borderBottom: '0px solid',
            borderRight: '0.5px solid rgba(224, 224, 224, 1)'
        }
    }
    const paperStyle = {
        width: '100%',
        overflow: 'hidden',
        '.css-1uk7rlk-MuiPaper-root:scroll': {
            display: 'hidden'
        }
    }
    const handleExport = async () => {
        await fetch(`${base_url}/admin/audit-logs/export`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/text',
                'Authorization': `Bearer ${userToken}`
            },
            body: JSON.stringify({
                search: {
                    email: "admin@gmail.com",
                    auditRole: "Admin"
                },
                nextKey: null
            })
        })
            .then(response => response.blob())
            .then(data => saveAs(data, 'Auditlogs.csv'))
    }

    window.FreshworksWidget('hide')

    function stableSort(array) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        return stabilizedThis.map((el) => el[0]);
    }

    return (
        <div className="auditLogs">
            <div className='auditLogs-navbar'>
                <AdminNavbar />
            </div>
            <div className="auditLogs-sidebarAndData">
                <div className="auditLogs-sidebar">
                    <AdminSidebar />
                </div>
                <div className="auditLogs-data">
                    <div className="auditLogs_heading">
                        Audit Logs
                    </div>
                    <div className="auditLogs-searchBarAndExport">
                        <form onSubmit={handleSearch}>
                            <div className="auditLogs-searchTextfield">
                                <input
                                    placeholder="Search"
                                    value={searchValue}
                                    onChange={(e) => setSearchValue(e.target.value)}
                                />
                                <div className="auditLogs-searchBtn" onClick={handleSearch}>
                                    <BiSearchAlt2 color="rgba(0, 0, 0, 0.4)" fontSize="20px" type="submit" />
                                </div>
                            </div>
                        </form>
                        <div className="auditLogs-export" style={{ cursor: "pointer" }} onClick={handleExport}>
                            <span><BiExport style={{ marginRight: "7px", fontSize: "20px" }} /></span>
                            <span>Export</span>
                        </div>
                    </div>
                    <div className="auditLogs_table_container">
                        <Paper sx={paperStyle}>
                            <TableContainer sx={{ maxHeight: '25rem' }} >
                                <Table stickyHeader aria-label="sticky table" sx={tableStyle}>
                                    <TableHead>
                                        <TableRow>
                                            {['User Id', 'Role', 'Content Id', 'Actions', 'Action Type', 'Fields', 'Date'].map(el =>
                                                <TableCell key={el}>{el}</TableCell>
                                            )}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {!loading ?
                                            auditData && auditData?.length ?
                                                stableSort(auditData)
                                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    .map((tableData, index) => {
                                                        return (
                                                            <TableRow
                                                                key={index}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell component="th" scope="row">{tableData?.userId}</TableCell>
                                                                <TableCell>{tableData?.role}</TableCell>
                                                                <TableCell>{tableData?.contentId}</TableCell>
                                                                <TableCell>{tableData?.actions}</TableCell>
                                                                <TableCell>{tableData?.actionType}</TableCell>
                                                                <TableCell>{tableData?.fields[0]}</TableCell>
                                                                <TableCell>{new Date(tableData?.date).toLocaleDateString('en-US', options)}</TableCell>
                                                            </TableRow>
                                                        )
                                                    }) :
                                                <TableRow>
                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '40vh', marginLeft: '230%', width: "60%" }}>No Data Found</div>
                                                </TableRow>
                                            : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '40vh', marginLeft: '350%' }}><Loader /></div>}

                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[100, 150, 200]}
                                component="div"
                                count={auditData?.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AuditLogs;