import React from 'react';
import './phoneInput.css';

const countryOptions = [
  { value: '+91', label: 'India' },
  { value: '+1', label: 'United States' },
  { value: '+44', label: 'United Kingdom' },
];

const PhoneInput = ({ name, value, onChange }) => {
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    onChange({
      target: {
        name,
        value: `${value?.split(' ')[0]} ${inputValue}`, // Combine the country code with the new phone number
      },
    });
  };

  return (
    <div className="phoneInput">
      <select
        id="country"
        className="phoneInput-select"
        value={value?.split(' ')[0] || ''}
        onChange={(e) =>
          onChange({
            target: {
              name,
              value: `${e.target.value} ${value?.split(' ')[1] || ''}`, // Combine the new country code with the existing phone number
            },
          })
        }
      >
        <option value="">Select Country</option> // Add an empty option as the default selection
        {countryOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <input
        className="phoneInput-input"
        type='number'
        id="phone"
        placeholder="Enter your Number"
        value={value?.split(' ')[1] || ''}
        onChange={handleInputChange}
      />
    </div>
  );
};

export default PhoneInput;
