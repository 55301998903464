import React, { useState } from 'react';
import './currency.css';

// const currencyOptions = [
//     { value: 'INR', label: 'India (INR)' },
//     { value: 'USD', label: 'United States (USD)' },
//     { value: 'GBP', label: 'United Kingdom (GBP)' },
//     { value: 'JPY', label: 'Japan (JPY)' },
//     { value: 'CNY', label: 'China (CNY)' },
//     { value: 'EUR', label: 'Germany (EUR)' },
//     { value: 'RUB', label: 'Russia (RUB)' },
//     { value: 'EUR', label: 'France (EUR)' },
//     { value: 'EUR', label: 'Italy (EUR)' },
//     { value: 'KRW', label: 'South Korea (KRW)' },
//     { value: 'EUR', label: 'Spain (EUR)' },
//     { value: 'AUD', label: 'Australia (AUD)' },
//     { value: 'CAD', label: 'Canada (CAD)' },
//     { value: 'BRL', label: 'Brazil (BRL)' },
//     { value: 'ZAR', label: 'South Africa (ZAR)' },
//     { value: 'SGD', label: 'Singapore (SGD)' },
//     { value: 'MXN', label: 'Mexico (MXN)' },
//     { value: 'AED', label: 'United Arab Emirates (AED)' },
//     { value: 'NZD', label: 'New Zealand (NZD)' },
//     { value: 'SEK', label: 'Sweden (SEK)' }
//     // Add more countries and currencies as needed
//   ];

const currencyOptions = [
    { value: '₹', label: 'Indian Rupee (INR)' },
    { value: '$', label: 'US Dollar (USD)' },
    { value: '£', label: 'British Pound (GBP)' },
    { value: '¥', label: 'Japanese Yen (JPY)' },
    { value: '¥', label: 'Chinese Yuan (CNY)' },
    { value: '€', label: 'Euro (EUR)' },
    { value: '₽', label: 'Russian Ruble (RUB)' },
    { value: '€', label: 'Euro (EUR)' },
    { value: '€', label: 'Euro (EUR)' },
    { value: '₩', label: 'South Korean Won (KRW)' },
    { value: '€', label: 'Euro (EUR)' },
    { value: '$', label: 'Australian Dollar (AUD)' },
    { value: '$', label: 'Canadian Dollar (CAD)' },
    { value: 'R$', label: 'Brazilian Real (BRL)' },
    { value: 'R', label: 'South African Rand (ZAR)' },
    { value: '$', label: 'Singapore Dollar (SGD)' },
    { value: 'Mex$', label: 'Mexican Peso (MXN)' },
    { value: 'د.إ', label: 'United Arab Emirates Dirham (AED)' },
    { value: 'NZ$', label: 'New Zealand Dollar (NZD)' },
    { value: 'kr', label: 'Swedish Krona (SEK)' }
    // Add more countries and currencies as needed
  ];
  
  

const CurrencyDropdown = ({ value, onChange }) => {
    const [disabled, setDisabled] = useState(true)
    const [currency,setCurrency] = useState('INR')
    const handleCurrencyChange = (e) => {
        setCurrency(e.target.value)
        setDisabled(false)
    }
  return (
    <>
    {
        disabled === false ? <span className='currencySpanValue' onClick={() => setDisabled(!disabled)}>{currency}</span> : 
        <select
            id="country"
            className="currencyDropdown"
            value={currency}
            onChange={handleCurrencyChange}
            >
            <option value="">Select Currency</option>
            {currencyOptions.map((option) => (
                <option key={option.value} value={option.value}>
                {option.label}
                </option>
            ))}
        </select>
    }

    </>
  );
};

export default CurrencyDropdown;
